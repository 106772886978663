import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { useEffect, useRef } from 'react';
import { isSafari } from 'react-device-detect';
import { useAppSelector } from '@hooks/useStore';
export const ContentLayout = (props) => {
    const { left, right, single } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const validationBanner = useAppSelector((state) => state.ui.validationBanner);
    const refInner = useRef();
    useEffect(() => {
        const aside = refInner.current;
        if (aside) {
            const startScroll = validationBanner ? 104 : 72;
            let endScroll = window.innerHeight - aside.offsetHeight - 500;
            let currPos = window.scrollY;
            let screenHeight = window.innerHeight;
            let asideHeight = aside.offsetHeight;
            aside.style.top = `${startScroll}px`;
            window.addEventListener('resize', () => {
                screenHeight = window.innerHeight;
                asideHeight = aside.offsetHeight;
            });
            document.addEventListener('scroll', () => {
                screenHeight = window.innerHeight;
                asideHeight = aside.offsetHeight;
                endScroll = window.innerHeight - aside.offsetHeight;
                const asideTop = parseInt(aside.style.top.replace('px;', ''));
                if (asideHeight > screenHeight) {
                    if (window.scrollY < currPos) {
                        if (asideTop < startScroll) {
                            aside.style.top = `${asideTop + currPos - window.scrollY}px`;
                        }
                        else if (asideTop >= startScroll && asideTop != startScroll) {
                            aside.style.top = `${startScroll}px`;
                        }
                    }
                    else {
                        if (asideTop > endScroll) {
                            aside.style.top = `${asideTop + currPos - window.scrollY}px`;
                        }
                        else if (asideTop < endScroll && asideTop != endScroll) {
                            aside.style.top = `${endScroll}px`;
                        }
                    }
                }
                else {
                    aside.style.top = `${startScroll}px`;
                }
                currPos = window.scrollY;
            }, {
                capture: true,
                passive: true,
            });
        }
    }, [validationBanner]);
    if (single) {
        return (_jsx("div", { style: isMobile
                ? {}
                : {
                    minHeight: '100%',
                    display: 'grid',
                    gridTemplateColumns: right ? '1fr 328px' : '1fr',
                    columnGap: 24,
                    maxWidth: 968,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }, children: _jsx("div", { style: { position: 'relative', maxWidth: isMobile ? 'inherit' : 614, justifySelf: 'center', width: '100%' }, children: left }) }));
    }
    return (_jsxs("div", { style: isMobile
            ? {}
            : {
                minHeight: '100%',
                display: 'grid',
                gridTemplateColumns: right ? '1fr 328px' : '1fr',
                columnGap: 24,
                maxWidth: 968,
                marginLeft: 'auto',
                marginRight: 'auto',
            }, children: [_jsx("div", { style: { position: 'relative' }, children: left }), right && !isMobile && (_jsx("div", { children: _jsx("div", { style: { position: 'sticky', top: validationBanner ? 112 : isSafari ? 72 : 72 }, ref: refInner, children: _jsx("div", { style: { paddingBottom: 24 }, children: right }) }) }))] }));
};
