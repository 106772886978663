var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FreeCertificate } from './variants/free/FreeCertificate';
import { PremiumCertificate } from './variants/premium/PremiumCertificate';
import { SignatureCertificate } from './variants/signature/SignatureCertificate';
import Style from './Certificate.scss';
import { CustomCertificate } from './variants/custom/CustomCertificate';
const Certificate = (props) => {
    const certProps = __rest(props, []);
    switch (certProps.pk) {
        case 'signature':
            return (_jsx("div", { className: Style.container, children: _jsx(SignatureCertificate, Object.assign({}, certProps)) }));
        case 'turingo-premium':
            return (_jsx("div", { className: Style.container, children: _jsx(PremiumCertificate, Object.assign({}, certProps)) }));
        case 'turingo-normal':
            return (_jsx("div", { className: Style.container, children: _jsx(FreeCertificate, Object.assign({}, certProps)) }));
        default:
            return (_jsx("div", { className: Style.container, children: _jsx(CustomCertificate, Object.assign({}, certProps)) }));
    }
};
export { Certificate };
