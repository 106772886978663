import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import { rateCreate, rateDelete, rateEdit } from '@state/actions';
import { Form, Input, Rate } from 'antd';
import { useAppDispatch } from '@hooks/useStore';
export const ReviewForm = (props) => {
    const { cm_pk, form, onFinish, b_pk, review, type } = props;
    const dispatch = useAppDispatch();
    const submit = (values) => {
        const { rate, content } = values;
        if (type == 'edit' && review) {
            dispatch(rateEdit({
                urlParams: { cm_pk, b_pk, p_pk: review.publicKey },
                bodyParams: {
                    kind: 'rate',
                    rate,
                    content,
                },
                extraParams: {
                    scope: 'all',
                },
                options: {
                    alerts: {
                        success: strings.alerts.rate.edit.success,
                        error: strings.alerts.rate.edit.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
            }));
        }
        else if (type == 'create') {
            dispatch(rateCreate({
                urlParams: { cm_pk, b_pk },
                bodyParams: {
                    kind: 'rate',
                    rate,
                    content,
                },
                extraParams: {},
                options: {
                    alerts: {
                        success: strings.alerts.rate.create.success,
                        error: strings.alerts.rate.create.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
            }));
        }
        else if (type == 'delete' && review) {
            dispatch(rateDelete({
                urlParams: { cm_pk, b_pk, target_pk: review.publicKey },
                options: {
                    alerts: {
                        success: strings.alerts.rate.delete.success,
                        error: strings.alerts.rate.delete.failure,
                    },
                    then: {
                        action: onFinish,
                    },
                },
                extraParams: {
                    scope: 'all',
                    parents: [],
                },
            }));
        }
    };
    return (_jsx(Form, { onFinish: submit, form: form, layout: 'vertical', initialValues: review ? { rate: review.rate, content: review.content } : undefined, children: type != 'delete' && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: ['rate'], label: strings.rates.form.rate, required: true, rules: [
                        {
                            validator: (rule, value) => {
                                if (!value || value == 0) {
                                    return Promise.reject(strings.screens.boards.course.rates.form.error);
                                }
                                else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ], children: _jsx(Rate, { style: { color: 'var(--cyan-6)' } }) }), _jsx(Form.Item, { name: ['content'], label: strings.rates.form.content.label, children: _jsx(Input.TextArea, { autoSize: { minRows: 3, maxRows: 3 }, showCount: true, maxLength: 500 }) })] })) }));
};
