import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Button } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { LinkOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { Colors } from '@util/color/Colors';
import { Download, Stretching } from '@icon-park/react';
import { GetTypeIcon } from '@components/content/posts/post_subtypes/attachments/AttachmentComponent';
export const MaterialCard = (props) => {
    const { item, kind } = props;
    const breakpoints = useBreakpoints();
    return (_jsx(Card, { bodyStyle: { padding: '8px 16px' }, style: {
            backgroundColor: Colors('POSTED_POLL_BACKGROUND'),
        }, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', marginRight: 16 }, children: [kind != 'material' && _jsx(LinkOutlined, { style: { fontSize: 24, marginRight: 16, color: Colors('ICON') } }), kind == 'material' && GetTypeIcon(item.mimeType), _jsx("div", { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: breakpoints.isMobile ? 'calc(100vw - 220px)' : 300,
                            }, children: _jsx(Paragraph, { strong: true, style: {
                                    lineHeight: '22px',
                                    wordBreak: 'break-word',
                                }, ellipsis: { rows: 1 }, children: kind == 'bibliography' ? item.title : kind == 'link' ? item.title : item.name }) })] }), _jsx("div", { children: _jsxs(Button, { shape: kind === 'link' ? 'default' : 'circle', onClick: () => kind == 'material'
                            ? (window.location.href = item.url)
                            : window.open('https://' + item.url.replace(/(^\w+:|^)\/\//, ''), '_blank').focus(), style: { padding: 0, zIndex: 3 }, children: [kind != 'material' && _jsx(Stretching, { style: { padding: 12, fontSize: 14, display: 'flex' } }), kind == 'material' && _jsx(Download, { style: { padding: 12, fontSize: 14, display: 'flex' } })] }) })] }) }));
};
