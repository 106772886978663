import { Client } from './core/Client';
import { Endpoint } from './core/Endpoint';
import { Authentication } from '@api/Authentication';
const Sagittarius = Endpoint(new Client(API_DOMAIN, new Authentication()));
const API = {
    authPresign: Sagittarius('/auth/presign?format=json', { method: 'GET' }),
    authLogin: Sagittarius('/auth/login', { authentication: 'provider.authtoken', method: 'POST', encoding: 'body.json' }),
    authSignup: Sagittarius('/auth/signup', { authentication: 'provider.authtoken', method: 'POST', encoding: 'body.json' }),
    authSocial: Sagittarius('/auth/oauth/:provider', { method: 'POST', encoding: 'body.json' }),
    authValidate: Sagittarius('/auth/validate/:validationKey', { method: 'POST' }),
    authValidateStatus: Sagittarius('/auth/validate', { method: 'GET' }),
    authRevalidate: Sagittarius('/auth/validate', { method: 'POST' }),
    authRecoverConfirm: Sagittarius('/auth/recover/:token', { method: 'POST' }),
    authRecover: Sagittarius('/auth/recover', { method: 'POST' }),
    authUpdateEmail: Sagittarius('/auth/email', { method: 'PATCH', encoding: 'body.json' }),
    authUpdatePassword: Sagittarius('/auth/password', { method: 'PATCH', encoding: 'body.json' }),
    oAuthAccounts: Sagittarius('/auth/oauth', { method: 'GET' }),
    oAuthEmailAccounts: Sagittarius('/auth/oauth/email/:token', { method: 'POST' }),
    authAddAccount: Sagittarius('/auth/oauth/:provider/add', { method: 'POST', encoding: 'body.json' }),
    authRemoveAccount: Sagittarius('/auth/oauth/:provider/remove', { method: 'POST', encoding: 'body.json' }),
    userSelf: Sagittarius('/user/self', { method: 'GET' }),
    userDelete: Sagittarius('/user/self', { method: 'DELETE', encoding: 'body.json' }),
    help: Sagittarius('/support/text', { method: 'POST' }),
    demo: Sagittarius('/support/demo', { method: 'POST' }),
    upload: Sagittarius('/upload', { method: 'POST', encoding: 'body.json' }),
    uploadConfirm: Sagittarius('/upload/:token', { method: 'PATCH' }),
    analyticsRouter: Sagittarius('/ui/navigation', { method: 'POST', encoding: 'body.json' }),
    analyticsAction: Sagittarius('/ui/action', { method: 'POST', encoding: 'body.json' }),
    analyticsSetting: Sagittarius('/ui/setting', { method: 'POST', encoding: 'body.json' }),
    analyticsError: Sagittarius('/ui/error', { method: 'POST', encoding: 'body.json' }),
    analyticsModal: Sagittarius('/ui/modal', { method: 'POST', encoding: 'body.json' }),
    countries: Sagittarius('/data/world/country', { method: 'GET' }),
    citySearch: Sagittarius('/data/world/city'),
    communityList: Sagittarius('/community'),
    communityDetails: Sagittarius('/community/:cm_pk'),
    communityEdit: Sagittarius('/community/:cm_pk', { method: 'PATCH', encoding: 'body.json' }),
    communityMembers: Sagittarius('/community/:cm_pk/enlisted'),
    communityMemberDelete: Sagittarius('/community/:cm_pk/member/:u_pk', { method: 'DELETE', encoding: 'body.json' }),
    communityMemberEdit: Sagittarius('/community/:cm_pk/member/:u_pk/team', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityExit: Sagittarius('/community/:cm_pk/member/self', { method: 'DELETE', encoding: 'body.json' }),
    userMentions: Sagittarius('/community/:cm_pk/member/mention', { method: 'GET', encoding: 'body.json' }),
    userChattable: Sagittarius('/community/:cm_pk/member/chattable', { method: 'GET', encoding: 'body.json' }),
    communityDirectory: Sagittarius('/community/:cm_pk/member/directory', { method: 'GET' }),
    communityMemberDetails: Sagittarius('/community/:cm_pk/member/:u_pk'),
    communityInvitationDelete: Sagittarius('/community/:cm_pk/invitation/:i_pk', {
        method: 'DELETE',
        encoding: 'body.json',
    }),
    communityInvitationCreate: Sagittarius('/community/:cm_pk/invitation/', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityInvitationEdit: Sagittarius('/community/:cm_pk/invitation/:i_pk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityInvitationLink: Sagittarius('/community/:cm_pk/invitation/link', { method: 'GET' }),
    communityInvitationEmail: Sagittarius('/community/:cm_pk/invitation/email', { method: 'GET' }),
    communityInvitation: Sagittarius('/community/:cm_pk/redeem/:invitationToken', { method: 'GET' }),
    communityMasks: Sagittarius('/community/:cm_pk/mask', { method: 'GET' }),
    communityInvitationAccept: Sagittarius('/community/:cm_pk/redeem/:invitationToken', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityCertificateCreate: Sagittarius('/community/:cm_pk/cert/', { method: 'POST', encoding: 'body.json' }),
    communityCertificateList: Sagittarius('/community/:cm_pk/cert/', { method: 'GET' }),
    communityCertificateDelete: Sagittarius('/community/:cm_pk/cert/:cert_pk', { method: 'DELETE' }),
    communityCertificateUpdate: Sagittarius('/community/:cm_pk/cert/:cert_pk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityEventCreate: Sagittarius('/community/:cm_pk/event/', { method: 'POST', encoding: 'body.json' }),
    communityEventDelete: Sagittarius('/community/:cm_pk/event/:e_pk', { method: 'DELETE' }),
    communityEventUpdate: Sagittarius('/community/:cm_pk/event/:e_pk', { method: 'PATCH', encoding: 'body.json' }),
    communityEventList: Sagittarius('/community/:cm_pk/event/', { method: 'GET' }),
    communityEventDetails: Sagittarius('/community/:cm_pk/event/:e_pk', { method: 'GET' }),
    communityEventJoin: Sagittarius('/community/:cm_pk/event/:e_pk/join', { method: 'POST', encoding: 'body.json' }),
    communityQuizJoin: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/zohoquiz', {
        method: 'GET',
    }),
    communityEventStart: Sagittarius('/community/:cm_pk/event/:e_pk/start', { method: 'POST', encoding: 'body.json' }),
    communityEventAttendanceAccept: Sagittarius('/community/:cm_pk/event/:e_pk/attendance', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityEventAttendanceReject: Sagittarius('/community/:cm_pk/event/:e_pk/attendance', { method: 'DELETE' }),
    communityEventAttendanceList: Sagittarius('/community/:cm_pk/event/:e_pk/attendance', { method: 'GET' }),
    communityPluginGet: Sagittarius('/community/:cm_pk/plugin/:plugin', { method: 'GET' }),
    communityPluginConnect: Sagittarius('/community/:cm_pk/plugin/:plugin', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityPluginDisconnect: Sagittarius('/community/:cm_pk/plugin/:plugin', {
        method: 'DELETE',
        encoding: 'body.json',
    }),
    communityChatList: Sagittarius('/chat/community/:cm_pk/room', {
        method: 'GET',
    }),
    communityChatGet: Sagittarius('/chat/community/:cm_pk/room/:r_pk', {
        method: 'GET',
    }),
    communityChatExists: Sagittarius('/chat/community/:cm_pk/room/exists', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityChatCreate: Sagittarius('/chat/community/:cm_pk/room', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityChatAccept: Sagittarius('/chat/community/:cm_pk/room/:r_pk/invite', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityChatSilence: Sagittarius('/chat/community/:cm_pk/room/:r_pk/notification', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityChatMessages: Sagittarius('/chat/community/:cm_pk/room/:r_pk/message', { method: 'GET' }),
    communityTeams: Sagittarius('/community/:cm_pk/team'),
    communityTeamDetail: Sagittarius('/community/:cm_pk/team/:t_pk'),
    communityTeamMembers: Sagittarius('/community/:cm_pk/team/:t_pk/member'),
    communityTeamCreate: Sagittarius('/community/:cm_pk/team', { method: 'POST', encoding: 'body.json' }),
    communityTeamEdit: Sagittarius('/community/:cm_pk/team/:t_pk', { method: 'PATCH', encoding: 'body.json' }),
    communityTeamDelete: Sagittarius('/community/:cm_pk/team/:t_pk', { method: 'DELETE' }),
    communityTeamMemberAdd: Sagittarius('/community/:cm_pk/team/:t_pk/member', {
        method: 'PUT',
        encoding: 'body.json',
    }),
    communityTeamMemberDelete: Sagittarius('/community/:cm_pk/team/:t_pk/member', {
        method: 'DELETE',
        encoding: 'body.json',
    }),
    communityPosts: Sagittarius('/community/:cm_pk/post'),
    communityBoardIndex: Sagittarius('/community/:cm_pk/board/index'),
    communityBoardCreate: Sagittarius('/community/:cm_pk/board', { method: 'POST', encoding: 'body.json' }),
    communityBoardDelete: Sagittarius('/community/:cm_pk/board/:b_pk', { method: 'DELETE', encoding: 'body.json' }),
    communityBoardEdit: Sagittarius('/community/:cm_pk/board/:b_pk', { method: 'PATCH', encoding: 'body.json' }),
    communityBoardMigrate: Sagittarius('/community/:cm_pk/board/:b_pk/merge', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityBoardStart: Sagittarius('/community/:cm_pk/board/:b_pk/start', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityBoardPropertyEdit: Sagittarius('/community/:cm_pk/board/:b_pk/:property', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityBoardPropertyCreate: Sagittarius('/community/:cm_pk/board/:b_pk/:property', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityBoardPropertyOrder: Sagittarius('/community/:cm_pk/board/:b_pk/:property/order', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityBoardPropertyDelete: Sagittarius('/community/:cm_pk/board/:b_pk/:property', {
        method: 'DELETE',
    }),
    communityBoardMentorEdit: Sagittarius('/community/:cm_pk/board/:b_pk/mentor/:m_pk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityBoardMentorCreate: Sagittarius('/community/:cm_pk/board/:b_pk/mentor', {
        method: 'POST',
        encoding: 'body.json',
    }),
    communityBoardMentorOrder: Sagittarius('/community/:cm_pk/board/:b_pk/mentor/order', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    communityBoardMentorDelete: Sagittarius('/community/:cm_pk/board/:b_pk/mentor/:m_pk', {
        method: 'DELETE',
    }),
    communityBoardDetails: Sagittarius('/community/:cm_pk/board/:b_pk'),
    boardList: Sagittarius('/community/:cm_pk/board'),
    boardDetails: Sagittarius('/community/:cm_pk/board/:b_pk'),
    boardEditDetails: Sagittarius('/community/:cm_pk/board/:b_pk'),
    boardOrder: Sagittarius('/community/:cm_pk/board/:kind/order', { method: 'PATCH', encoding: 'body.json' }),
    boardMembers: Sagittarius('/community/:cm_pk/board/:b_pk/member'),
    nextLesson: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/next', { method: 'GET', encoding: 'body.json' }),
    completeLesson: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/progress', { method: 'POST', encoding: 'body.json' }),
    boardProgress: Sagittarius('/community/:cm_pk/board/:b_pk/post/next', { method: 'GET', encoding: 'body.json' }),
    boardHighlight: Sagittarius('/community/:cm_pk/board/highlight'),
    rateList: Sagittarius('/community/:cm_pk/board/:b_pk/post/rate', { method: 'GET', encoding: 'body.json' }),
    rateCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post/rate', { method: 'POST', encoding: 'body.json' }),
    postList: Sagittarius('/community/:cm_pk/board/:b_pk/post', { method: 'GET', encoding: 'body.json' }),
    postDetails: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk', {
        method: 'GET',
        encoding: 'body.json',
    }),
    postAdminDetails: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/admin', {
        method: 'GET',
        encoding: 'body.json',
    }),
    quizGet: Sagittarius('/community/:cm_pk/board/:b_pk/quiz/:p_pk', { method: 'GET' }),
    analyticsProgress: Sagittarius('/community/:cm_pk/board/:b_pk/analytics/progress', { method: 'GET' }),
    quizAdminGet: Sagittarius('/community/:cm_pk/board/:b_pk/quiz/:p_pk/admin', { method: 'GET' }),
    quizCreate: Sagittarius('/community/:cm_pk/board/:b_pk/quiz', { method: 'POST', encoding: 'body.json' }),
    quizEdit: Sagittarius('/community/:cm_pk/board/:b_pk/quiz/:q_pk', { method: 'PATCH', encoding: 'body.json' }),
    answerCreate: Sagittarius('/community/:cm_pk/board/:b_pk/quiz/:q_pk/answer', {
        method: 'POST',
        encoding: 'body.json',
    }),
    answerDetails: Sagittarius('/community/:cm_pk/board/:b_pk/quiz/:q_pk/answer', {
        method: 'GET',
        encoding: 'body.json',
    }),
    postCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post', { method: 'POST', encoding: 'body.json' }),
    postEdit: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk', { method: 'PATCH', encoding: 'body.json' }),
    postOrder: Sagittarius('/community/:cm_pk/board/:b_pk/post/order', { method: 'PATCH', encoding: 'body.json' }),
    postAttachmentCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/attachment', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    postAttachmentDelete: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/attachment', { method: 'DELETE' }),
    postLinkCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/link', {
        method: 'POST',
        encoding: 'body.json',
    }),
    postLinkEdit: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/link', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    postLinkDelete: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/link', { method: 'DELETE' }),
    postLinks: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/link', { method: 'GET' }),
    postDelete: Sagittarius('/community/:cm_pk/board/:b_pk/post/:target_pk', { method: 'DELETE' }),
    postPin: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/pin', { method: 'PUT', encoding: 'body.json' }),
    postUnpin: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/pin', { method: 'DELETE' }),
    postReaction: Sagittarius('/community/:cm_pk/board/:b_pk/post/:target_pk/reaction', {
        method: 'POST',
        encoding: 'body.json',
    }),
    postManyReaction: Sagittarius('/community/:cm_pk/reaction', {
        method: 'POST',
        encoding: 'body.json',
    }),
    postReactionDelete: Sagittarius('/community/:cm_pk/board/:b_pk/post/:target_pk/reaction', {
        method: 'DELETE',
        encoding: 'body.json',
    }),
    postReactionList: Sagittarius('/community/:cm_pk/board/:b_pk/post/:target_pk/reaction', {
        method: 'GET',
    }),
    commentList: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/comment'),
    commentCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/comment', {
        method: 'POST',
        encoding: 'body.json',
    }),
    commentEdit: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/comment/:c_pk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    commentDelete: Sagittarius('/community/:cm_pk/board/:b_pk/post/:p_pk/comment/:c_pk', { method: 'DELETE' }),
    replyCreate: Sagittarius('/community/:cm_pk/board/:b_pk/post/:c_pk/comment', {
        method: 'POST',
        encoding: 'body.json',
    }),
    replyList: Sagittarius('/community/:cm_pk/board/:b_pk/post/:c_pk/comment'),
    userDetails: Sagittarius('/user/:u_pk'),
    userEdit: Sagittarius('/community/:cm_pk/member/self', { method: 'PATCH', encoding: 'body.json' }),
    userSegment: Sagittarius('/user/segment'),
    getUserMerits: Sagittarius('/user/:u_pk/merit'),
    getMerit: Sagittarius('/merit/:m_pk', { method: 'GET' }),
    billingCartCreate: Sagittarius('/billing/cart', { method: 'POST', encoding: 'body.json' }),
    billingCartGet: Sagittarius('/billing/cart/:cartPk', { method: 'GET', encoding: 'body.json' }),
    billingCartEdit: Sagittarius('/billing/cart/:cartPk', { method: 'PATCH', encoding: 'body.json' }),
    billingCartDelete: Sagittarius('/billing/cart/:cartPk', { method: 'DELETE' }),
    billingProvider: Sagittarius('/billing/provider', { method: 'GET' }),
    billingCheckoutPaypalStart: Sagittarius('/billing/checkout/paypal', {
        method: 'POST',
        encoding: 'body.json',
    }),
    billingCheckoutPaypalConfirm: Sagittarius('/billing/checkout/paypal/:paymentPk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    billingCheckoutDLocal: Sagittarius('/billing/checkout/dlocal/card', {
        method: 'POST',
        encoding: 'body.json',
    }),
    billingCheckoutDLocalGoStart: Sagittarius('/billing/checkout/dlocalgo', {
        method: 'POST',
        encoding: 'body.json',
    }),
    billingCheckoutDLocalGoConfirm: Sagittarius('/billing/checkout/dlocalgo/:paymentPk', {
        method: 'PATCH',
        encoding: 'body.json',
    }),
    billingCheckoutDLocalGoCancel: Sagittarius('/billing/checkout/dlocalgo/:paymentPk', { method: 'DELETE' }),
    billingLicenseRedeem: Sagittarius('/billing/redeem/license/:license', { method: 'POST' }),
    postReport: Sagittarius('/safety/post', { method: 'POST', encoding: 'body.json' }),
    chatReport: Sagittarius('/safety/chat/room', { method: 'POST', encoding: 'body.json' }),
    chatMessageReport: Sagittarius('/safety/chat/room/message', { method: 'POST', encoding: 'body.json' }),
    getLastNotifications: Sagittarius('/notification', { method: 'GET' }),
    markNotificationAsOpened: Sagittarius('/notification/mark/opened', { method: 'POST', encoding: 'body.json' }),
    markNotificationsAsSeen: Sagittarius('/notification/:notificationId/seen', {
        method: 'PUT',
    }),
    getCertificate: Sagittarius('/merit/:pk', { method: 'GET' }),
    linkPreview: Sagittarius('/og', { method: 'GET', authentication: 'required.header.bearer' }),
    debugEventEmail: Sagittarius('/debug/mail/event', { method: 'POST', encoding: 'body.json' }),
    debugChatEmail: Sagittarius('/debug/mail/chatsummary', { method: 'POST', encoding: 'body.json' }),
    debugDigestEmail: Sagittarius('/debug/mail/digest', { method: 'POST', encoding: 'body.json' }),
};
export { API };
