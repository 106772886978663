import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
export const ChatListSkeleton = (props) => {
    const { nElements = 9 } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsx("div", { style: {
            maxHeight: isMobile ? 'unset' : 'calc(100vh - 310px)',
            overflow: 'auto',
        }, children: Array(nElements)
            .fill(undefined)
            .map((e, i) => {
            return (_jsxs("div", { style: { display: 'flex', alignContent: 'center', marginBottom: i == nElements - 1 ? 0 : 32 }, children: [_jsx(Skeleton.Avatar, { active: true, size: 32, shape: 'circle', style: { marginRight: 18 } }), _jsxs("div", { style: { display: 'grid', gap: 8, width: '100%' }, children: [_jsx(Skeleton, { style: { width: 100 }, title: true, active: true, paragraph: null }), _jsx(Skeleton, { style: { width: '100%' }, title: true, active: true, paragraph: null })] })] }, i));
        }) }));
};
