import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { employmentStatus, getTitleByValue, professions, higherEducation } from '@components/content/entity/user_header/components/user_modal/UserModal';
const getTitleForId = (id, value) => {
    switch (id) {
        case 'profession':
            return getTitleByValue(professions, value);
        case 'employment-status':
            return getTitleByValue(employmentStatus, value);
        case 'higher-ed':
            return getTitleByValue(higherEducation, value);
    }
    return value;
};
const ProfessionCard = (props) => {
    const { memberProperties, userProperties } = props;
    if (!memberProperties || !userProperties)
        return _jsx(_Fragment, {});
    const properties = memberProperties === null || memberProperties === void 0 ? void 0 : memberProperties.filter((p) => p.category == 'profession' && userProperties[p.id]).sort((a, b) => a.order - b.order);
    if (properties.length <= 0)
        return _jsx(ProfessionCardEmpty, {});
    return (_jsx(Card, { title: strings.screens.profile.jobCard.title, bordered: false, children: properties.map((property) => {
            const propertyValue = userProperties[property.id];
            return (_jsxs("div", { style: { marginBottom: 8 }, children: [_jsx(Paragraph, { strong: true, className: "turingo-text", children: property === null || property === void 0 ? void 0 : property.title }), _jsx(Paragraph, { className: "turingo-text", children: getTitleForId(property.id, propertyValue) })] }, property.id));
        }) }));
};
const ProfessionCardEmpty = () => (_jsx(Card, { title: strings.screens.profile.jobCard.title, bordered: false, children: _jsx(Paragraph, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.jobCard.placeholder }) }));
export { ProfessionCard };
