import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Alert } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import strings from '@resources/localization';
export const AboutCard = (props) => {
    const { description } = props;
    const { u_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == u_pk;
    const color = useAppSelector((state) => state.ui.color);
    return (_jsxs(Card, { bordered: false, title: strings.screens.profile.aboutMe.title, children: [description && _jsx(Text, { children: description }), !description && !isLoggedUser && currentEntity && _jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.empty }), !description && isLoggedUser && _jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.aboutMe.description_owner }), !currentEntity && _jsx(Alert, { message: 'Conoce a otras personas emprendedoras ¡Inicia sesión en Turingo!' })] }));
};
