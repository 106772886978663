import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Form } from 'antd';
const HelpeableWrapper = (props) => {
    const { id, value = undefined, onChange, help, setHelp } = props;
    const { status } = Form.Item.useStatus();
    const triggerChange = (e) => {
        var _a;
        if ((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value);
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        }
    };
    useEffect(() => {
        if (status == 'error') {
            setHelp === null || setHelp === void 0 ? void 0 : setHelp(undefined);
        }
        else {
            setHelp === null || setHelp === void 0 ? void 0 : setHelp(help);
        }
    }, [status]);
    return (_jsx("span", { id: id, children: React.cloneElement(props.children, { onChange: triggerChange, value: value, className: status != 'error' && 'no-margin-error' }) }));
};
export { HelpeableWrapper };
