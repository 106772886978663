import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Row } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { postReaction, postReactionDelete } from '@state/actions';
import style from './Style.scss';
import { debounce } from 'lodash';
import { Colors } from '@util/color/Colors';
import { useParams } from 'react-router';
import { LikeIcon } from '@components/icons/LikeIcon';
import { PostContext } from '../../Post';
export const CommentReactAction = (props) => {
    const { reaction, target_pk, b_pk, totalReactions, parents } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const dispatch = useAppDispatch();
    (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like';
    const { mask } = useContext(PostContext);
    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams();
    const isFeed = !br_pk;
    const scope = pr_pk ? 'detail' : 'all';
    const like = () => {
        var _a, _b;
        void dispatch(postReaction({
            urlParams: { cm_pk, b_pk, target_pk: target_pk },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey })), { kind: 'react', content: 'like', parents }),
            extraParams: { scope: scope, feed: isFeed },
        }));
    };
    const dislike = () => {
        var _a, _b;
        void dispatch(postReactionDelete({
            urlParams: { cm_pk, b_pk, target_pk: target_pk },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey })), { kind: 'react', content: 'like', parents }),
            extraParams: { scope: scope, feed: isFeed },
        }));
    };
    const likeDebounce = debounce(like, 0);
    const dislikeDebounce = debounce(dislike, 0);
    return (_jsx(Row, { children: _jsxs(Row, { align: 'middle', className: style.btn, onClick: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' ? dislikeDebounce : likeDebounce, children: [_jsx(LikeIcon, { width: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' ? 16.62 : 17.86, height: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' ? 17.1 : 18.4, style: { display: 'flex' }, color: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' && 'var(--ant-primary-color)', filled: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' }), totalReactions > 0 && (_jsx("span", { style: { marginLeft: 5, color: (reaction === null || reaction === void 0 ? void 0 : reaction.content) == 'like' ? 'var(--ant-primary-color)' : Colors('NEUTRAL_13') }, children: totalReactions }))] }) }));
};
