import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { ReplySection } from './ReplySection';
import { useRef } from 'react';
import { CommentContent } from './CommentContent';
import { PlusOutlined } from '@ant-design/icons';
import { Colors } from '@util/color/Colors';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { PostContext } from '../../Post';
export const Comment = (props) => {
    const { comment } = props;
    const [showReplies, setShowReplies] = useState(false);
    const [showReplyField, setshowReplyField] = useState(false);
    const { p_pk, b_pk, mask } = useContext(PostContext);
    const childRef = useRef();
    const focus = (target) => {
        var _a, _b;
        (_a = childRef === null || childRef === void 0 ? void 0 : childRef.current) === null || _a === void 0 ? void 0 : _a.getFocus();
        (_b = childRef === null || childRef === void 0 ? void 0 : childRef.current) === null || _b === void 0 ? void 0 : _b.replyMention(target);
    };
    const replyField = (target) => {
        setShowReplies(true);
        setshowReplyField(true);
        setTimeout(() => focus(target), 100);
    };
    const reply = () => {
        setShowReplies(true);
    };
    useEffect(() => {
        setShowReplies(false);
    }, [mask.get]);
    const breakpoints = useBreakpoints();
    return (_jsxs("div", { style: { padding: breakpoints.isSmall ? '16px 16px' : '16px 24px' }, children: [_jsx(CommentContent, { b_pk: b_pk, showBorderReply: showReplies, parents: [p_pk], target: comment, level: 1, replyFieldAction: replyField }), !showReplies && comment.totalComments > 0 && (_jsxs("div", { style: { position: 'relative', paddingLeft: 54, paddingTop: 12 }, children: [_jsx("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 24,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        } }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: reply, children: [_jsx(PlusOutlined, { style: { marginRight: 8 } }), comment.totalComments == 1 && strings.formatString(strings.general.replies.one), comment.totalComments != 1 && strings.formatString(strings.general.replies.nary, comment.totalComments)] })] })), showReplies && (_jsx(ReplySection, { replyFieldAction: replyField, showReplyField: showReplyField, childRef: childRef, total: comment.totalComments, c_pk: comment.publicKey }))] }));
};
