import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { CreatePostContext } from '../../PostCreateProvider';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { Form } from 'antd';
import { FormSelect } from '@components/form/FormSelectValue/FormSelectModalItem';
const SpaceSelectionScreen = (props) => {
    const { boards, screen, setScreen, form } = useContext(CreatePostContext);
    const board = Form.useWatch(['form', 'turingo', 'board'], form);
    return (_jsxs("div", { children: [_jsx(Paragraph, { style: { marginBottom: 16 }, className: "turingo-text", children: strings.screens.boards.discussion.post.spaces.description }), _jsx(FormSelect, { defaultValue: board, items: boards, valueField: "publicKey", nameField: "name", afterAction: (board) => {
                    form.setFieldValue(['form', 'turingo', 'topics'], []);
                    form.setFieldValue(['form', 'turingo', 'board'], board);
                    if (screen == 'board_selection')
                        setScreen('creation');
                } })] }));
};
export { SpaceSelectionScreen };
