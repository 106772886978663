import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from '@api/API';
import { getRequest, useRequestState } from '@api/requests/AsyncRequests';
import { AvatarTuringo } from '@components/avatar/Avatar';
import strings from '@resources/localization';
import { Router } from '@Router';
import { Button, Card, Skeleton, Tooltip } from 'antd';
import { useHistory, useParams } from 'react-router';
export const MembersCard = () => {
    var _a;
    const { cm_pk, b_pk } = useParams();
    const history = useHistory();
    const [members, setMembers] = useRequestState();
    void getRequest(API.boardMembers, setMembers, [cm_pk, b_pk])({ urlParams: { cm_pk, b_pk } });
    if (!members || members.loading) {
        return (_jsxs(Card, { title: strings.screens.boards.discussion.subscribers, bordered: false, children: [' ', _jsx("div", { style: { display: 'flex', justifyContent: 'center', marginTop: 16, flexDirection: 'column' }, children: _jsx("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: 16, columnGap: 8 }, children: [...Array(12).fill(undefined)].map((member, index) => {
                            return _jsx(Skeleton.Avatar, { active: true, size: 40, shape: 'circle' }, `skeleton-member-${index}`);
                        }) }) }), _jsx("div", { style: { display: 'flex', justifyContent: 'center', marginTop: 16 }, children: _jsx(Button, { onClick: () => history.push(Router.Path.community_board_members({ cm_pk, b_pk })), children: strings.screens.community.members.action }) })] }));
    }
    return (_jsxs(Card, { title: strings.screens.boards.discussion.subscribers, bordered: false, children: [_jsx("div", { style: { display: 'flex', justifyContent: 'center', marginTop: 16, flexDirection: 'column' }, children: _jsx("div", { style: { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: 16, columnGap: 8 }, children: members &&
                        ((_a = members.data) === null || _a === void 0 ? void 0 : _a.map((member, index) => {
                            if (index < 12)
                                return (_jsxs(Tooltip, { placement: "top", title: member.name, getPopupContainer: (trigger) => trigger.parentElement, children: [_jsx(AvatarTuringo, { kind: "circle", name: member.name, avatar: member.avatarUrl, size: 16, width: 40, height: 40, style: { lineHeight: '40px', cursor: 'pointer' }, onClick: () => history.push(Router.Path.user_details({ u_pk: member.publicKey, cm_pk })) }, `member-${member.publicKey}`), ' '] }));
                        })) }) }), _jsx("div", { style: { display: 'flex', justifyContent: 'center', marginTop: 16 }, children: _jsx(Button, { onClick: () => history.push(Router.Path.community_board_members({ cm_pk, b_pk })), children: strings.screens.community.members.action }) })] }));
};
