import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { CreatePostContext } from '../PostCreateProvider';
import { SpaceSelectionScreen } from './spaces/SpaceSelectionScreen';
import { TopicSelectionScreen } from './topics/TopicSelectionScreen';
import { PollScreen } from './poll/PollScreen';
import { AlanScreen } from './alan/AlanScreen';
import { CrossPostingScreen } from './creation/CrossPostingScreen';
const MainScreen = (props) => {
    const { screen } = useContext(CreatePostContext);
    const getScreen = () => {
        if (screen == 'creation')
            return _jsx(CrossPostingScreen, {});
        if (screen == 'board_selection' || screen == 'board_final') {
            return _jsx(SpaceSelectionScreen, {});
        }
        if (screen == 'topic_selection' || screen == 'topic_final') {
            return _jsx(TopicSelectionScreen, {});
        }
        if (screen == 'poll_creation') {
            return _jsx(PollScreen, {});
        }
        if (screen == 'alan') {
            return _jsx(AlanScreen, {});
        }
    };
    return getScreen();
};
export { MainScreen };
