import { jsx as _jsx } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { UserProfileCard } from '../user_profile_card/UserProfileCard';
export const EntityCarousel = (props) => {
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const { items, clickeable = true, extra, mode = items.length == 1 || isMobile ? 'horizontal' : 'vertical' } = props;
    return (_jsx("div", { style: {
            display: 'grid',
            gridTemplateColumns: mode == 'horizontal' ? '1fr' : items.length == 2 ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: 16,
            justifyContent: 'center',
        }, children: items.map((mentor, index) => {
            return _jsx(UserProfileCard, { extra: extra, message: false, mode: mode, item: mentor, clickeable: clickeable }, `ent-${index}`);
        }) }));
};
