import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
export const SpinLoader = (props) => {
    const { loading, children } = props;
    if (loading) {
        return _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }), children: children });
    }
    else {
        return _jsxs(_Fragment, { children: [" ", children, " "] });
    }
};
