import { hideErrorAction, setErrorAction } from './../../actions/index';
import { createSlice } from '@reduxjs/toolkit';
import { showErrorAction } from '@state/actions';
const defaultState = {
    active: false,
};
const errorSlice = createSlice({
    name: 'error',
    initialState: defaultState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(showErrorAction, (state, action) => {
            state.active = true;
        });
        builder.addCase(hideErrorAction, (state, action) => {
            state.active = false;
        });
        builder.addCase(setErrorAction, (state, action) => {
            state.code = action.payload;
        });
    },
});
export { errorSlice };
