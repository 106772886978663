const ProfileOverrideRules = {
    'exverbo': new Set(['profile.services.hide', 'directory.services.hide']),
    'oldmacks': new Set(['profile.services.hide', 'directory.services.hide']),
    'colegio-san-ignacio': new Set(['profile.services.hide', 'directory.services.hide']),
    'manzana': new Set(['profile.services.hide', 'directory.services.hide']),
};
function communityOverrideRules(communityPk) {
    const rules = ProfileOverrideRules[communityPk];
    return rules !== undefined ? rules : new Set();
}
export { communityOverrideRules };
