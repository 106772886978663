import { jsx as _jsx } from "react/jsx-runtime";
import { ViewList } from '@icon-park/react';
import { PlayCircleOutlined, BugOutlined } from '@ant-design/icons';
const IconPost = (props) => {
    const { kind, style = {} } = props;
    if (kind === 'quiz') {
        return _jsx(ViewList, { style: style });
    }
    else if (kind === 'lesson') {
        return _jsx(PlayCircleOutlined, { style: style });
    }
    return _jsx(BugOutlined, { style: style });
};
export { IconPost };
