import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check } from '@icon-park/react';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
import USM from '@bundle/assets/landing_b2b/usm.png';
import Pista8 from '@bundle/assets/landing_b2b/pista8.png';
import Dadneo from '@bundle/assets/landing_b2b/dadneo.png';
import Liquimoly from '@bundle/assets/landing_b2b/liqui.png';
import Innspiral from '@bundle/assets/landing_b2b/innspiral.png';
import ProChile from '@bundle/assets/landing_b2b/prochile.png';
import strings from '@resources/localization';
const DemoInfo = (props) => {
    const breakpoints = useBreakpoints();
    const isMobileLarge = breakpoints.xxs || breakpoints.xs || breakpoints.sm || breakpoints.md || breakpoints.mdl;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    return (_jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }, children: [_jsxs("div", { children: [_jsxs("div", { style: {
                            fontSize: props.compact ? 30 : 38,
                            lineHeight: '46px',
                            fontWeight: 500,
                            maxWidth: breakpoints.isMobile ? 840 : 513,
                            alignSelf: 'center',
                            marginBottom: 16,
                        }, children: [strings.screens.landingb2b.footer[0], _jsx("div", { style: { color: '#4cc1be', display: 'inline' }, children: strings.screens.landingb2b.footer[1] }), " ", strings.screens.landingb2b.footer[2]] }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: 16 }, children: [_jsx(Check, { theme: "outline", size: "24", fill: "#4DC1BE", style: { marginRight: 8 } }), _jsx("div", { style: { fontSize: 18 }, children: strings.screens.landingb2b.demo_highlights[0] })] }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: 16 }, children: [_jsx(Check, { theme: "outline", size: "24", fill: "#4DC1BE", style: { marginRight: 8 } }), _jsx("div", { style: { fontSize: 18 }, children: strings.screens.landingb2b.demo_highlights[1] })] }), _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Check, { theme: "outline", size: "24", fill: "#4DC1BE", style: { marginRight: 8 } }), _jsx("div", { style: { fontSize: 18 }, children: strings.screens.landingb2b.demo_highlights[2] })] })] }), _jsxs("div", { style: { marginTop: props.compact ? 56 : 0 }, children: [_jsx("div", { style: { marginBottom: 24, fontWeight: 500, fontSize: 18 }, children: strings.screens.landingb2b.trustus }), _jsxs("div", { children: [_jsxs("div", { style: { marginBottom: 24 }, children: [_jsx("img", { src: Dadneo, alt: "", style: { marginRight: 32, height: props.compact ? 32 : 40 } }), _jsx("img", { src: USM, alt: "", style: { marginRight: 32, height: props.compact ? 32 : 40 } }), _jsx("img", { src: Liquimoly, alt: "", style: { height: props.compact ? 32 : 40 } })] }), _jsxs("div", { children: [_jsx("img", { src: Pista8, alt: "", style: { marginRight: 32, height: props.compact ? 32 : 40 } }), _jsx("img", { src: Innspiral, alt: "", style: { marginRight: 32, height: props.compact ? 16 : 24 } }), _jsx("img", { src: ProChile, alt: "", style: { height: props.compact ? 32 : 40 } })] })] })] })] }));
};
export { DemoInfo };
