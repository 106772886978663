import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from 'react';
import { Affix, Breadcrumb } from 'antd';
import { Redirect, useHistory, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Post, PostContext } from '@components/content/posts/Post';
import { postDetails, postList, postReaction } from '@state/actions';
import { Colors } from '@util/color/Colors';
import { Link } from 'react-router-dom';
import { Router } from '@Router';
import { PostScreenSkeleton } from '@components/skeletons/PostScreenSkeleton';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { CommunityDetails } from '@screens/community/components/community_details/CommunityDetails';
import { CommunityRules } from '@screens/community/components/community_rules/CommunityRules';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import Meta from 'react-meta-tags';
import { CommunityHeader } from '@components/content/entity/community_header/CommunityHeader';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
import queryString from 'query-string';
import { Playlist } from '@screens/community/boards/media/media_post/components/playlist/Playlist';
import { CertificateCard } from '@screens/community/components/certificate_card/CertificateCard';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import Paragraph from 'antd/lib/typography/Paragraph';
const defaultDiscussionLayout = {
    autor: 'header',
    topics: 'header',
    content: 'header',
    likes: 'footer',
    title: 'none',
    comments: 'inner',
    materials: 'none',
    right: {
        community: true,
        rules: true,
    },
};
const defaultRateLayout = {
    autor: 'header',
    topics: 'none',
    content: 'header',
    likes: 'none',
    title: 'none',
    comments: 'none',
    materials: 'none',
};
const defaultMediaLayout = {
    autor: 'none',
    topics: 'none',
    content: 'body',
    likes: 'none',
    title: 'footer',
    comments: 'out',
    materials: 'body',
    right: {
        playlist: true,
        certificate: true,
    },
};
const defaultCatalogLayout = {
    autor: 'none',
    topics: 'none',
    content: 'body',
    title: 'footer',
    materials: 'body',
    likes: 'none',
    comments: 'out',
};
export const kindToLayout = {
    basic: defaultDiscussionLayout,
    image: defaultDiscussionLayout,
    attachment: defaultDiscussionLayout,
    article: defaultMediaLayout,
    poll: defaultDiscussionLayout,
    event: defaultDiscussionLayout,
    preview: defaultDiscussionLayout,
    video: defaultDiscussionLayout,
    rate: defaultRateLayout,
    quiz: Object.assign(Object.assign({}, defaultMediaLayout), { comments: 'none', title: 'none' }),
    lesson: defaultMediaLayout,
    catalog_item: defaultCatalogLayout,
};
export const PostDetail = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const { isPanelAdmin } = props;
    const postProps = useParams();
    const panelAdminProps = useParams();
    let cm_pk = postProps.cm_pk;
    let b_pk = postProps.b_pk;
    let p_pk = postProps.p_pk;
    const isFeed = !b_pk;
    if (isPanelAdmin) {
        cm_pk = panelAdminProps.cm_pk;
        b_pk = panelAdminProps.item_pk;
        p_pk = panelAdminProps.p_pk;
    }
    const scope = p_pk ? 'detail' : 'all';
    const dispatch = useAppDispatch();
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const lessons = useAppSelector((state) => state.posts.all[b_pk]);
    const post = useAppSelector((state) => { var _a; return (_a = state.posts.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]; });
    const history = useHistory();
    const notifications = useContext(NotificationContext);
    const kind = (_b = (_a = post === null || post === void 0 ? void 0 : post.item) === null || _a === void 0 ? void 0 : _a.board) === null || _b === void 0 ? void 0 : _b.kind;
    const board = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c[b_pk]; });
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    const queryParams = queryString.parse(location.search);
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const boardAccessCommunity = (_d = (_c = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.boards; })) === null || _c === void 0 ? void 0 : _c.find((board) => board.publicKey == b_pk)) === null || _d === void 0 ? void 0 : _d.access;
    const boardAccessDetail = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c[b_pk]; });
    const isAdmin = ((_e = boardAccessCommunity === null || boardAccessCommunity === void 0 ? void 0 : boardAccessCommunity.posts) === null || _e === void 0 ? void 0 : _e.includes('edit')) || ((_h = (_g = (_f = boardAccessDetail === null || boardAccessDetail === void 0 ? void 0 : boardAccessDetail.item) === null || _f === void 0 ? void 0 : _f.access) === null || _g === void 0 ? void 0 : _g.posts) === null || _h === void 0 ? void 0 : _h.includes('edit'));
    const [currentMask, setCurrentMask] = useState(currentEntity);
    const see = (result) => {
        var _a;
        if (isLogged && kind == 'discussion' && ((_a = result.data.interactions) === null || _a === void 0 ? void 0 : _a.pinView) == undefined) {
            void dispatch(postReaction({
                urlParams: { cm_pk, b_pk, target_pk: p_pk },
                bodyParams: { kind: 'pin_view', content: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_medium) || undefined },
                extraParams: { scope: 'detail' },
            }));
        }
    };
    useEffect(() => {
        void dispatch(postDetails({
            urlParams: { cm_pk, b_pk, p_pk },
            extraParams: { scope: 'detail' },
            options: {
                then: {
                    action: see,
                },
            },
        }));
    }, [b_pk, p_pk]);
    useEffect(() => {
        if (kind == 'course' || kind == 'forum') {
            void dispatch(postList({ urlParams: { cm_pk, b_pk: b_pk }, searchParams: { kind: 'meta_playlist' } }));
        }
    }, [b_pk, p_pk]);
    const postRef = useRef();
    useEffect(() => {
        if (postRef && postRef.current) {
            const headerOffset = 80;
            const elementPosition = postRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
            });
        }
    }, []);
    if (post && post.state.error) {
        return _jsx(PostScreenSkeleton, {});
    }
    else if (!community || !community.item || ((_j = community.state) === null || _j === void 0 ? void 0 : _j.loading) || !post || !post.item || ((_k = post.state) === null || _k === void 0 ? void 0 : _k.loading)) {
        return _jsx(PostScreenSkeleton, {});
    }
    else {
        const showCertificate = ((_m = (_l = post.item.board) === null || _l === void 0 ? void 0 : _l.merits) === null || _m === void 0 ? void 0 : _m.find((m) => m.merit.kind == 'certificate')) && ((_p = (_o = community.item) === null || _o === void 0 ? void 0 : _o.ui) === null || _p === void 0 ? void 0 : _p.showcert) === true && !!((_s = (_r = (_q = board === null || board === void 0 ? void 0 : board.item) === null || _q === void 0 ? void 0 : _q.course) === null || _r === void 0 ? void 0 : _r.assign) === null || _s === void 0 ? void 0 : _s.cert);
        const isPrivate = !((_t = community.item) === null || _t === void 0 ? void 0 : _t.access);
        const layout = kindToLayout[(_u = post === null || post === void 0 ? void 0 : post.item) === null || _u === void 0 ? void 0 : _u.kind];
        const rightPanel = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if (layout.right) {
                return (_jsx(Affix, { offsetTop: 72, style: { zIndex: 0 }, children: _jsxs("div", { style: {
                            display: 'grid',
                            columnGap: isSmall ? 0 : 16,
                            rowGap: isMobile ? 16 : 24,
                            flexDirection: 'column',
                        }, children: [layout.right.community && (_jsx(CommunityDetails, { memberCount: (_a = community.item) === null || _a === void 0 ? void 0 : _a.memberCount, description: (_c = (_b = community.item) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.bio, contact: (_d = community.item) === null || _d === void 0 ? void 0 : _d.contact })), layout.right.rules && ((_e = community.item) === null || _e === void 0 ? void 0 : _e.rule) && _jsx(CommunityRules, { isCompact: true, rules: (_f = community.item) === null || _f === void 0 ? void 0 : _f.rule }), layout.right.playlist && lessons && _jsx(Playlist, { lessons: lessons === null || lessons === void 0 ? void 0 : lessons.items, kind: kind }), layout.right.certificate && !isMobile && showCertificate && !isPanelAdmin && (_jsx(CertificateCard, { entityPk: b_pk, pricing: (_h = (_g = post === null || post === void 0 ? void 0 : post.item) === null || _g === void 0 ? void 0 : _g.board) === null || _h === void 0 ? void 0 : _h.pricing, isStarted: true }))] }) }));
            }
            return null;
        };
        if (isPrivate) {
            if (isLogged) {
                return (_jsx(Redirect, { to: {
                        pathname: Router.Path.root({ cm_pk }),
                    } }));
            }
            return (_jsx(Redirect, { to: {
                    pathname: Router.Path.community_login({ cm_pk }),
                } }));
        }
        const breadcrumbItems = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
            if (kind == 'discussion') {
                return [
                    {
                        to: Router.Path.community_home({ cm_pk }),
                        title: community.item.name,
                    },
                    {
                        to: Router.Path.community_board({ cm_pk, b_pk }),
                        title: (_b = (_a = post === null || post === void 0 ? void 0 : post.item) === null || _a === void 0 ? void 0 : _a.board) === null || _b === void 0 ? void 0 : _b.name,
                    },
                    {
                        to: Router.Path.community_post_details({ cm_pk, b_pk, p_pk }),
                        title: strings.general.post.singular,
                    },
                ];
            }
            if (kind == 'course') {
                return [
                    {
                        to: Router.Path.course_list({ cm_pk }),
                        title: strings.general.course.plural,
                    },
                    {
                        to: Router.Path.course_details({ cm_pk, b_pk }),
                        title: (_d = (_c = post === null || post === void 0 ? void 0 : post.item) === null || _c === void 0 ? void 0 : _c.board) === null || _d === void 0 ? void 0 : _d.name,
                    },
                    {
                        to: Router.Path.course_post_details({ cm_pk, b_pk, p_pk }),
                        title: `${(_g = (_f = (_e = strings.screens.boards[kind]) === null || _e === void 0 ? void 0 : _e.post) === null || _f === void 0 ? void 0 : _f.title) === null || _g === void 0 ? void 0 : _g.singular} ${((_h = post.item) === null || _h === void 0 ? void 0 : _h.contentSequence) + 1}`,
                    },
                ];
            }
            if (kind == 'forum') {
                return [
                    {
                        to: Router.Path.forum_list({ cm_pk }),
                        title: strings.general.forum.plural,
                    },
                    {
                        to: Router.Path.forum_details({ cm_pk, b_pk }),
                        title: (_k = (_j = post === null || post === void 0 ? void 0 : post.item) === null || _j === void 0 ? void 0 : _j.board) === null || _k === void 0 ? void 0 : _k.name,
                    },
                    {
                        to: Router.Path.forum_post_details({ cm_pk, b_pk, p_pk }),
                        title: `${(_o = (_m = (_l = strings.screens.boards[kind]) === null || _l === void 0 ? void 0 : _l.post) === null || _m === void 0 ? void 0 : _m.title) === null || _o === void 0 ? void 0 : _o.singular} ${((_p = post.item) === null || _p === void 0 ? void 0 : _p.contentSequence) + 1}`,
                    },
                ];
            }
            if (kind == 'story') {
                return [
                    {
                        to: Router.Path.story_list({ cm_pk }),
                        title: strings.general.forum.plural,
                    },
                    {
                        to: Router.Path.story_post_details({ cm_pk, b_pk, p_pk }),
                        title: `${(_r = (_q = post === null || post === void 0 ? void 0 : post.item) === null || _q === void 0 ? void 0 : _q.lesson) === null || _r === void 0 ? void 0 : _r.title}`,
                    },
                ];
            }
            if (kind == 'catalog') {
                return [
                    {
                        to: Router.Path.catalogue({ cm_pk, b_pk }),
                        title: (_t = (_s = post === null || post === void 0 ? void 0 : post.item) === null || _s === void 0 ? void 0 : _s.board) === null || _t === void 0 ? void 0 : _t.name,
                    },
                    {
                        to: Router.Path.catalogue_details({ cm_pk, b_pk, p_pk }),
                        title: (_u = post === null || post === void 0 ? void 0 : post.item) === null || _u === void 0 ? void 0 : _u.title,
                    },
                ];
            }
        };
        return (_jsxs(PostContext.Provider, { value: {
                mask: { get: currentMask, set: setCurrentMask },
                post: post.item,
                isPanelAdmin,
                isAdmin,
                cm_pk,
                b_pk,
                p_pk,
                isFeed: isFeed,
                scope: scope,
            }, children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.community.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.community.browserDescription })] }), _jsx(ContentLayout, { single: kind == 'catalog' || kind == 'story', left: _jsxs("div", { style: { display: 'grid', rowGap: isLogged ? 0 : isMobile ? 16 : 24 }, children: [!isLogged && _jsx(CommunityHeader, { entity: community }), isLogged && (_jsxs("div", { style: { padding: isMobile ? 16 : '16px 24px', borderRadius: '4px 4px 0px 0px', background: Colors('BACKGROUND') }, children: [!isPanelAdmin && (_jsx(Breadcrumb, { children: breadcrumbItems().map((i, index) => {
                                            return (_jsx(Breadcrumb.Item, { children: _jsx(Link, { to: i.to, children: i.title }) }, index));
                                        }) })), isPanelAdmin && (_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(GoBackButton, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }, type: "default", showText: false, onClick: () => {
                                                    history.push(Router.Path.community_settings_detail({
                                                        cm_pk,
                                                        section: post.item.board.kind == 'catalog' ? 'catalogues' : 'onboarding',
                                                        item_pk: post.item.boardPk,
                                                    }));
                                                } }), _jsx(Paragraph, { strong: true, className: "turingo-text", children: post.item.title || ((_w = (_v = post.item) === null || _v === void 0 ? void 0 : _v.lesson) === null || _w === void 0 ? void 0 : _w.title) })] }))] })), _jsx("div", { ref: postRef, children: _jsx("div", { style: {
                                        display: 'grid',
                                        columnGap: isSmall ? 0 : 16,
                                        rowGap: isMobile ? 16 : 24,
                                        flexDirection: 'column',
                                    }, children: _jsx(Post, {}) }) })] }), right: rightPanel() })] }));
    }
};
