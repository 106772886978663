import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router';
import strings from '@resources/localization';
import { Button } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { ArrowLeftIcon } from '@components/icons/ArrowLeftIcon';
const GoBackButton = (props) => {
    const { showText = true, onClick, style, type = 'text', block } = props;
    const history = useHistory();
    const isMobile = useBreakpoints().isMobile;
    const goBack = () => {
        if (onClick) {
            onClick();
        }
        else {
            history.goBack();
        }
    };
    return (_jsxs(Button, { block: block, type: type, onClick: goBack, className: !showText && 'hover-svg', style: Object.assign(Object.assign(Object.assign({}, style), (!showText && { width: isMobile ? 44 : 38 })), { display: 'flex', alignItems: 'center', justifyContent: block ? 'center' : 'center' }), children: [_jsx(ArrowLeftIcon, { style: { height: 16, width: 16 } }), showText && _jsx("span", { children: strings.screens.auth.signup.goBack })] }));
};
export { GoBackButton };
