import { arraySimilarity } from '@util/array/equality';
import { trim } from '@util/string/Functions';
const formatVisibility = (visibility) => {
    if (visibility == false || visibility == 'public')
        return 'public';
    if (visibility == true || visibility == 'private')
        return 'private';
};
const formatVisibilityBoolean = (visibility) => {
    if (visibility == false || visibility == 'public')
        return true;
    if (visibility == true || visibility == 'private')
        return false;
};
const buildUserEditRequest = (form, user) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    let count = 0;
    const body = {};
    if (form.name != user.name) {
        body.name = trim(form.name);
        count++;
    }
    if (form.job != ((_a = user.profile) === null || _a === void 0 ? void 0 : _a.job)) {
        if (trim(form.job) == '') {
            body.job = null;
        }
        else {
            body.job = trim(form.job);
        }
        count++;
    }
    if (form.biography != ((_b = user.profile) === null || _b === void 0 ? void 0 : _b.bio)) {
        if (trim(form.biography) == '') {
            body.biography = null;
        }
        else {
            body.biography = trim(form.biography);
        }
        count++;
    }
    if (form.url != ((_c = user.profile) === null || _c === void 0 ? void 0 : _c.url)) {
        if (trim(form.url) == '') {
            body.url = null;
        }
        else {
            body.url = trim(form.url);
        }
        count++;
    }
    if (form.linkedin != ((_d = user.contact) === null || _d === void 0 ? void 0 : _d.linkedin)) {
        if (trim(form.linkedin) == '') {
            body.linkedin = null;
        }
        else {
            body.linkedin = trim(form.linkedin);
        }
        count++;
    }
    if (form.email != ((_e = user.contact) === null || _e === void 0 ? void 0 : _e.email)) {
        if (trim(form.email) == '') {
            body.email = null;
        }
        else {
            body.email = trim(form.email);
        }
        count++;
    }
    if (form.phone != ((_f = user.contact) === null || _f === void 0 ? void 0 : _f.phone)) {
        if (form.phone == '') {
            body.phone = null;
        }
        else {
            body.phone = form.phone;
        }
        count++;
    }
    if (formatVisibility(form.visibility) != user.visibility) {
        body.visibility = formatVisibilityBoolean(form.visibility);
        count++;
    }
    let cityId;
    if (((_g = form === null || form === void 0 ? void 0 : form.ubication) === null || _g === void 0 ? void 0 : _g.value) && (cityId = parseInt((_h = form === null || form === void 0 ? void 0 : form.ubication) === null || _h === void 0 ? void 0 : _h.value)) && !isNaN(cityId)) {
        body.cityId = cityId;
        count++;
    }
    else if (form.ubication === undefined) {
        body.cityId = null;
        count++;
    }
    const currentServices = (_j = user.service) === null || _j === void 0 ? void 0 : _j.map((s) => s.publicKey);
    if (!arraySimilarity(currentServices, form.service)) {
        body.service = form.service;
        count++;
    }
    const currentIndustries = (_k = user.industry) === null || _k === void 0 ? void 0 : _k.map((s) => s.publicKey);
    if (!arraySimilarity(currentIndustries, form.industry)) {
        body.industry = form.industry;
        count++;
    }
    const currentInterests = (_l = user.interest) === null || _l === void 0 ? void 0 : _l.map((s) => s.publicKey);
    if (!arraySimilarity(currentInterests, form.interest)) {
        body.interest = form.interest;
        count++;
    }
    return { body, count };
};
export { buildUserEditRequest };
