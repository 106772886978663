import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Check } from '@icon-park/react';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import style from './FormSelectModalItem.scss';
export const FormSelectModalItem = (props) => {
    const { beforeAction, afterAction, items, mode = 'single', kind, isFullScreen, defaultValue, maxItems } = props;
    const isMultiple = mode === 'multiple';
    const isCategorized = kind === 'categorized';
    const [selected, setSelected] = useState(defaultValue ? defaultValue : []);
    const parentRef = useRef();
    useEffect(() => {
        setSelected(defaultValue ? defaultValue : []);
    }, [defaultValue]);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const onSelect = (item) => {
        if (isMultiple) {
            let newValue = [];
            if (item) {
                if (isSelected(item))
                    newValue = selected.filter((itemFilter) => itemFilter != item);
                else {
                    if (selected.length >= maxItems) {
                        void message.destroy();
                        void message.error(`Solo puedes seleccionar hasta ${maxItems}`);
                        return;
                    }
                    newValue = [...selected, item];
                }
                if (afterAction)
                    afterAction(newValue);
            }
        }
        else {
            if (item)
                setSelected([item]);
            if (afterAction)
                afterAction([item]);
        }
    };
    const onDeselect = (item) => {
        if (isMultiple) {
            let newValue = [];
            if (item) {
                if (isSelected(item))
                    newValue = selected.filter((itemFilter) => itemFilter != item);
                else {
                    if (selected.length >= 3) {
                        void message.destroy();
                        void message.error(`Solo puedes seleccionar hasta ${maxItems} servicios`);
                        return;
                    }
                    newValue = [...selected, item];
                }
                if (afterAction)
                    afterAction(newValue);
            }
        }
        else {
            if (item)
                setSelected([]);
            if (afterAction)
                afterAction([]);
        }
    };
    const isSelected = (item) => {
        if (!selected)
            return false;
        if (selected.find((s) => (s === null || s === void 0 ? void 0 : s.publicKey) == (item === null || item === void 0 ? void 0 : item.publicKey)))
            return true;
        return false;
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: 'scrollStyle', style: { overflowY: 'auto', maxHeight: isMobile ? '60vh' : '293px' }, children: _jsx("div", { style: {
                    width: '100%',
                    position: 'relative',
                }, children: items === null || items === void 0 ? void 0 : items.map((item, index) => {
                    var _a, _b;
                    const itemValue = isCategorized ? items[index] : (_a = items[index]) === null || _a === void 0 ? void 0 : _a.publicKey;
                    const itemName = isCategorized ? items[index] : (_b = items[index]) === null || _b === void 0 ? void 0 : _b.name;
                    return (_jsxs("div", { onClick: () => isSelected(items[index]) ? onDeselect(items[index]) : onSelect(items[index]), className: isSelected(items[index]) ? style.itemSelected : style.item, style: {
                            display: 'flex',
                            alignItems: 'center',
                            padding: isMobile ? '10px 16px' : '4px 16px',
                            width: 'fit-content',
                            lineHeight: '20px',
                            marginBottom: isMobile ? 24 : 16,
                            paddingRight: isSelected(items[index]) ? (isMobile ? 40 : 34) : 16,
                            position: 'relative',
                        }, children: [isCategorized ? itemName : itemName, ' ', isSelected(items[index]) ? (_jsx(Check, { style: {
                                    marginLeft: 4,
                                    position: 'absolute',
                                    right: isMobile ? 14 : 13,
                                    top: isMobile ? 12 : 6,
                                }, theme: "outline", size: isMobile ? 16 : 14 })) : ('')] }, index));
                }) }) }) }));
};
