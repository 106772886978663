import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { replyList } from '@state/actions';
import { Spin } from 'antd';
import { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { ReplyField } from './components/ReplyField';
import { CommentContent } from './CommentContent';
import { Colors } from '@util/color/Colors';
import { PlusOutlined } from '@ant-design/icons';
import { PostContext } from '../../Post';
export const ReplySection = (props) => {
    const { cm_pk, p_pk, b_pk, mask, scope, isFeed } = useContext(PostContext);
    const { c_pk, childRef, showReplyField, replyFieldAction, total } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const replies = useAppSelector((state) => { var _a; return (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk].childrens.items[c_pk].childrens; });
    const dispatch = useAppDispatch();
    useEffect(() => {
        fetchData(false);
    }, [b_pk, p_pk]);
    const fetchData = (pagination) => {
        var _a, _b, _c, _d;
        if (!pagination) {
            void dispatch(replyList(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk, c_pk } }, (currentEntity.publicKey != ((_a = mask.get) === null || _a === void 0 ? void 0 : _a.publicKey) && { searchParams: { mask: (_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey } })), { extraParams: { p_pk, scope: scope, feed: isFeed }, headerParams: {
                    page: null,
                } })));
        }
        else {
            void dispatch(replyList(Object.assign(Object.assign({ urlParams: { cm_pk, b_pk, c_pk } }, (currentEntity.publicKey != ((_c = mask.get) === null || _c === void 0 ? void 0 : _c.publicKey) && { searchParams: { mask: (_d = mask.get) === null || _d === void 0 ? void 0 : _d.publicKey } })), { extraParams: { p_pk, scope: scope, feed: isFeed }, headerParams: {
                    page: replies === null || replies === void 0 ? void 0 : replies.listState.pagination,
                } })));
        }
    };
    const totalReplies = replies.items ? total - Object.keys(replies.items).length : total;
    return (_jsxs("div", { children: [(replies === null || replies === void 0 ? void 0 : replies.listState.pagination) && !(replies === null || replies === void 0 ? void 0 : replies.listState.loading) && (_jsxs("div", { style: { position: 'relative', paddingLeft: 54, paddingTop: 12 }, children: [_jsx("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 24,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        } }), _jsx("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: '100%',
                            width: 24,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                        } }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: () => fetchData(true), children: [_jsx(PlusOutlined, { style: { marginRight: 8 } }), totalReplies == 1 && strings.formatString(strings.general.replies_previuos.one), totalReplies != 1 && strings.formatString(strings.general.replies_previuos.nary, totalReplies)] })] })), (replies === null || replies === void 0 ? void 0 : replies.listState.loading) && (_jsx("div", { style: { padding: 24, textAlign: 'center' }, children: _jsx(Spin, {}) })), replies &&
                replies.items &&
                Object.keys(replies.items).map((key, index) => (_jsxs("div", { style: { paddingBottom: 16, position: 'relative', paddingLeft: 54, paddingTop: 16 }, children: [_jsx("div", { style: {
                                position: 'absolute',
                                top: 0,
                                left: 24,
                                height: 36,
                                width: 24,
                                borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                                borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                                borderBottomLeftRadius: 6,
                            } }), (index != Object.keys(replies.items).length - 1 || showReplyField) && (_jsx("div", { style: {
                                position: 'absolute',
                                top: 0,
                                left: 24,
                                height: '100%',
                                width: 24,
                                borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            } })), _jsx(CommentContent, { b_pk: b_pk, parents: [p_pk, c_pk], replyFieldAction: replyFieldAction, level: 2, target: replies.items[key].item }, key)] }, index))), showReplyField && (_jsxs("div", { style: { position: 'relative', paddingLeft: 54, paddingTop: 13 }, children: [_jsx("div", { style: {
                            position: 'absolute',
                            top: 0,
                            left: 24,
                            height: 36,
                            width: 24,
                            borderBottom: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderLeft: `1px solid ${Colors('NEUTRAL_5')}`,
                            borderBottomLeftRadius: 6,
                        } }), _jsx(ReplyField, { ref: childRef, b_pk: b_pk, p_pk: p_pk, c_pk: c_pk })] }))] }));
};
