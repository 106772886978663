import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Card, Progress, Row, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { postVote } from '@state/actions';
import { useParams } from 'react-router';
import { Colors } from '@util/color/Colors';
import { useRequestState } from '@api/requests/AsyncRequests';
import { infoBetweenDates } from '@util/dates/Functions';
import strings from '@resources/localization';
import { LinkColor } from '@components/buttons/link_color/LinkColor';
import useBreakpoints from '@hooks/useBreakpoints';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { ReactionModal } from '../../components/ReactionModal';
import { ConditionalWrap } from '@components/content/conditional_wrap/ConditionalWrap';
export const PollPost = (props) => {
    const { target, isCreator } = props;
    const [isVoted, setIsVoted] = useState(target === null || target === void 0 ? void 0 : target.poll.isVoted);
    const [showResults, setShowResults] = useState(target === null || target === void 0 ? void 0 : target.poll.isVoted);
    const afterVote = () => {
        setIsVoted(true);
    };
    const dispatch = useAppDispatch();
    const { cm_pk, b_pk: br_pk, p_pk: pr_pk } = useParams();
    const scope = pr_pk ? 'detail' : 'all';
    const isFeed = !br_pk;
    const [state, setState] = useRequestState(undefined, false, false);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const [showReactionModal, setShowReactionModal] = useState(false);
    const vote = (id) => {
        if (isLogged) {
            dispatch(postVote({
                urlParams: { cm_pk, b_pk: target.boardPk, target_pk: target.publicKey },
                bodyParams: { kind: 'vote', choice: id },
                options: {
                    redux: {
                        stateHandler: setState,
                    },
                    then: { action: afterVote },
                },
                extraParams: { scope: scope, feed: isFeed },
            }));
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const todayDate = new Date();
    const dateFinish = new Date(target === null || target === void 0 ? void 0 : target.poll.dateFinish);
    const daysLeft = infoBetweenDates(todayDate, dateFinish);
    return (_jsxs(Spin, { spinning: state.editing, children: [showReactionModal && (_jsx(ReactionModal, { kind: "vote", target: target, open: showReactionModal, onCancel: () => setShowReactionModal(false) })), _jsxs(Card, { bordered: false, bodyStyle: { backgroundColor: Colors('POSTED_POLL_BACKGROUND') }, children: [_jsx("p", { style: { fontWeight: 500, overflowWrap: 'break-word' }, children: target === null || target === void 0 ? void 0 : target.poll.question }), !isVoted &&
                        !showResults &&
                        daysLeft &&
                        (target === null || target === void 0 ? void 0 : target.poll.options.slice().sort((a, b) => a.choice_id - b.choice_id).map((item, index) => {
                            return (_jsx(Button, { block: true, onClick: () => vote(item.choice_id), style: {
                                    marginBottom: index != (target === null || target === void 0 ? void 0 : target.poll.options.length) - 1 ? 8 : 0,
                                    borderRadius: 4,
                                    borderColor: Colors('OPTION_POLL_BORDER'),
                                }, children: item.answer }, `pollOptions#${index}`));
                        })), (isVoted || !daysLeft || showResults) &&
                        (target === null || target === void 0 ? void 0 : target.poll.options.slice().sort((a, b) => a.choice_id - b.choice_id).map((item, index) => {
                            return (_jsxs("div", { style: { marginBottom: 8 }, children: [item.answer, _jsx(Progress, { status: "normal", strokeColor: '#FA8C16', success: { strokeColor: '#FA8C16' }, percent: Math.trunc((item.dv_total_votes * 100.0) / (target === null || target === void 0 ? void 0 : target.poll.dv_total_votes)) })] }, `pollOptions#${index}`));
                        })), _jsxs(Row, { style: { fontSize: isMobile ? 14 : 12, color: Colors('NEUTRAL_8'), marginTop: 24 }, children: [_jsx(ConditionalWrap, { condition: isCreator, wrap: (children) => _jsx(LinkColor, { onClick: () => setShowReactionModal(true), children: children }), children: _jsx(_Fragment, { children: (target === null || target === void 0 ? void 0 : target.poll.dv_total_votes) == 1
                                        ? strings.formatString(strings.general.votes.one, target === null || target === void 0 ? void 0 : target.poll.dv_total_votes)
                                        : strings.formatString(strings.general.votes.nary, target === null || target === void 0 ? void 0 : target.poll.dv_total_votes) }) }), ' ', _jsx("div", { style: { marginLeft: 8, marginRight: 8, lineHeight: '17px' }, children: '•' }), daysLeft ? daysLeft : strings.general.votes.finish, ' ', isCreator && !isVoted && daysLeft && _jsx("div", { style: { marginLeft: 8, marginRight: 8, lineHeight: '17px' }, children: '•' }), isCreator && !isVoted && daysLeft && (_jsx(LinkColor, { onClick: () => setShowResults(!showResults), children: showResults
                                    ? strings.screens.boards.discussion.post.kind.poll.results.hide
                                    : strings.screens.boards.discussion.post.kind.poll.results.show }))] })] })] }));
};
