import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import { useParams } from 'react-router';
import { useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { Check } from '@icon-park/react';
import useBreakpoints from '@hooks/useBreakpoints';
const ColorSelector = (props) => {
    const { selected, setSelected } = props;
    const { cm_pk } = useParams();
    const theme = useAppSelector((state) => state.ui.theme);
    const breakpoints = useBreakpoints();
    const optionsColors = {
        light: [
            ...(cm_pk == 'turingo' ? [{ color: '#ff3862', value: 'turingo_color', hint: strings.screens.admin.general.color.turingo }] : []),
            { color: '#CF1322', value: 'red', hint: strings.screens.admin.general.color.red },
            { color: '#1890FF', value: 'blue', hint: strings.screens.admin.general.color.blue },
            { color: '#08979C', value: 'cyan', hint: strings.screens.admin.general.color.cyan },
            { color: '#389E0D', value: 'green', hint: strings.screens.admin.general.color.green },
            { color: '#EB2F96', value: 'magenta', hint: strings.screens.admin.general.color.magenta },
            { color: '#FA8C16', value: 'orange', hint: strings.screens.admin.general.color.orange },
            { color: '#FA541C', value: 'volcano', hint: strings.screens.admin.general.color.volcano },
        ],
        dark: [
            ...(cm_pk == 'turingo' ? [{ color: '#e82e56', value: 'turingo_color', hint: strings.screens.admin.general.color.turingo }] : []),
            { color: '#D32029', value: 'red', hint: strings.screens.admin.general.color.red },
            { color: '#3C9AE8', value: 'blue', hint: strings.screens.admin.general.color.blue },
            { color: '#33BCB7', value: 'cyan', hint: strings.screens.admin.general.color.cyan },
            { color: '#6ABE39', value: 'green', hint: strings.screens.admin.general.color.green },
            { color: '#CB2B83', value: 'magenta', hint: strings.screens.admin.general.color.magenta },
            { color: '#D87A16', value: 'orange', hint: strings.screens.admin.general.color.orange },
            { color: '#E87040', value: 'volcano', hint: strings.screens.admin.general.color.volcano },
        ],
    };
    const border = theme != 'dark' ? '2px solid #595959' : '2px solid #F0F0F0';
    return (_jsx("div", { style: { display: 'flex', flexFlow: 'wrap' }, children: optionsColors[theme].map((item) => {
            return (_jsx(Tooltip, { title: item.hint, children: _jsxs("div", { style: { position: 'relative' }, children: [selected == item.value && (_jsx("div", { style: {
                                position: 'absolute',
                                top: 1,
                                left: 1,
                                height: breakpoints.xxs || breakpoints.xs ? 30 : breakpoints.isMobile ? 42 : 38,
                                width: breakpoints.xxs || breakpoints.xs ? 30 : breakpoints.isMobile ? 42 : 38,
                                zIndex: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: _jsx(Check, { style: { display: 'flex', justifyContent: 'center', color: 'white' } }) })), _jsx("div", { onClick: () => setSelected(item.value), style: {
                                backgroundColor: item.color,
                                height: breakpoints.xxs || breakpoints.xs ? 32 : breakpoints.isMobile ? 44 : 40,
                                width: breakpoints.xxs || breakpoints.xs ? 32 : breakpoints.isMobile ? 44 : 40,
                                borderRadius: 4,
                                marginRight: 16,
                                marginBottom: 16,
                                cursor: 'pointer',
                                border: selected == item.value ? border : 'unset',
                            } })] }) }, `color-${item.value}`));
        }) }));
};
export { ColorSelector };
