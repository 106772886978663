import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SVG from '@components/assets/SVG';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
import { Link } from 'react-router-dom';
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton';
import style from './GradientCard.scss';
import ImageIsotypeLeft from '@bundle/assets/branding/decoration/isotype-left.svg';
import ImageIsotypeRight from '@bundle/assets/branding/decoration/isotype-right.svg';
export const GradientCard = (props) => {
    const { title, href, actionTitle, kind, src, minHeight, to } = props;
    const theme = useAppSelector((state) => state.ui.theme);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const isSmall = breakpoints.isSmall;
    return (_jsxs("div", { style: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ borderRadius: kind == 'image' ? 0 : 4, position: 'relative' }, (kind == 'image' && { background: `url(${src})`, minHeight: minHeight ? minHeight : 320, backgroundSize: 'cover' })), (kind == 'cyan' && { background: 'linear-gradient(86.35deg, #008BF2 -3.22%, #00CCA5 106.07%)', minHeight: minHeight ? minHeight : 320, })), (kind == 'purple' && { background: theme == 'dark' ? '#161D40' : '#F0F5FF', minHeight: minHeight ? minHeight : 320, })), (kind == 'turingo' && theme == 'dark' && { background: '#262626', minHeight: minHeight ? minHeight : 320, })), (kind == 'turingo' && theme == 'light' && { background: '#F0F0F0', minHeight: minHeight ? minHeight : 320, })), className: style.itemCard, children: [_jsxs("div", { className: style.content, children: [_jsx("span", { className: style.courseTitle, style: Object.assign(Object.assign({}, (kind == 'image' && { fontSize: isSmall ? 24 : 38 })), (kind == 'purple' && { fontSize: isSmall ? 24 : 30 })), children: title }), kind == 'turingo' && !isMobile && (_jsx(SVG, { content: ImageIsotypeLeft, style: {
                            position: 'absolute',
                            left: 0,
                            bottom: '-4px',
                            width: '150px',
                        } })), kind == 'turingo' && !isMobile && (_jsx(SVG, { content: ImageIsotypeRight, style: {
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '150px',
                        } })), (kind == 'cyan') && href && (_jsx("a", { href: href, style: { color: 'white', width: isMobile ? '100%' : 'inherit' }, target: '_blank', rel: "noreferrer", children: _jsx("button", { style: { color: 'white', borderRadius: 4, width: isMobile ? '100%' : 'inherit' }, className: style.callToAction, children: actionTitle }) })), (kind == 'cyan') && to && (_jsx(Link, { to: to, style: { color: 'white' }, children: _jsx("button", { style: { color: 'white', borderRadius: 4, }, className: style.callToAction, children: actionTitle }) }))] }), (kind == 'turingo' || kind == 'image' || kind == 'purple') && href && (_jsx(ExpandableButton, { href: href, target: '_blank', rel: "noreferrer", type: "primary", children: actionTitle })), (kind == 'turingo' || kind == 'image' || kind == 'purple') && to && (_jsx(Link, { to: to, style: { color: 'white' }, children: _jsx(ExpandableButton, { type: "primary", children: actionTitle }) }))] }));
};
