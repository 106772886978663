import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import { Router } from '@Router';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { Button, Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory, useParams } from 'react-router';
const CommunityAbout = (props) => {
    const { compact = false, description } = props;
    const { cm_pk } = useParams();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const onClick = () => {
        if (isLogged) {
            history.push(Router.Path.community_about({ cm_pk }));
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    return (_jsxs(Card, { title: strings.general.about, bordered: false, children: [_jsx(Paragraph, { ellipsis: compact ? { rows: 2 } : false, children: description }), compact && (_jsx("div", { style: { display: 'flex', justifyContent: 'center', marginTop: 16 }, children: _jsx(Button, { onClick: onClick, children: strings.general.read_more }) }))] }));
};
export { CommunityAbout };
