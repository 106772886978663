import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Button, Divider, Row } from 'antd';
import { PostContext } from '../../Post';
import { PostAction } from '../post_actions/PostAction';
import { LikeIcon } from '@components/icons/LikeIcon';
import strings from '@resources/localization';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import useBreakpoints from '@hooks/useBreakpoints';
import { postReaction, postReactionDelete } from '@state/actions';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { debounce } from 'lodash';
import Icon from '@ant-design/icons';
import { ShareAltOutlined } from '@ant-design/icons';
import { Router } from '@Router';
import { API } from '@api/API';
import { MaskButton } from '../mask_button/MaskButton';
import { useParams } from 'react-router';
import { ReactionModal } from '../ReactionModal';
import { PostViewReadModal } from '../PostViewReadModal';
import { PostCommentsTag } from '../PostCommentsTag';
import { EyeOutlined } from '@ant-design/icons';
import { PostReactionsTag } from '../PostReactionsTag';
import { LinkColor } from '@components/buttons/link_color/LinkColor';
const PostFooter = (props) => {
    var _a, _b, _c, _d;
    const { setShowCommentArea, setShowMasks, commentInputRef } = props;
    const { mask, post, isAdmin } = useContext(PostContext);
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const dispatch = useAppDispatch();
    const isLiked = ((_a = post.interactions) === null || _a === void 0 ? void 0 : _a.react) == 'like';
    const isRead = (_b = post.interactions) === null || _b === void 0 ? void 0 : _b.pinRead;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const { cm_pk, b_pk: br_pk, p_pk } = useParams();
    const isFeed = !br_pk;
    const scope = p_pk ? 'detail' : 'all';
    const [showReactionModal, setShowReactionModal] = useState(false);
    const [showReadStatModal, setReadStatModal] = useState(false);
    const like = () => {
        if (isLogged) {
            void dispatch(postReaction({
                urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
                bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey })), { kind: 'react', content: 'like' }),
                extraParams: { scope: scope, feed: isFeed },
            }));
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    const dislike = () => {
        void dispatch(postReactionDelete({
            urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
            bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey })), { kind: 'react', content: 'like' }),
            extraParams: { scope: scope, feed: isFeed },
        }));
    };
    const focus = () => {
        var _a;
        (_a = commentInputRef === null || commentInputRef === void 0 ? void 0 : commentInputRef.current) === null || _a === void 0 ? void 0 : _a.getFocus();
    };
    const onComment = () => {
        if (isLogged) {
            setShowCommentArea(true);
            setTimeout(focus, 100);
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    const onShowComments = () => {
        if (isLogged) {
            setShowCommentArea((prev) => {
                return !prev;
            });
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    const onShowReactions = () => {
        if (isLogged) {
            setShowReactionModal(true);
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    const likeDebounce = debounce(like, 0);
    const dislikeDebounce = debounce(dislike, 0);
    const openShareModal = () => {
        dispatch(modalAction({
            modal: 'share',
            active: true,
            link: window.location.origin + Router.Path.community_post_details({ cm_pk: cm_pk, b_pk: post.boardPk, p_pk: post.publicKey }),
            referenceCommunity: cm_pk,
            referenceBoard: post.boardPk,
            referencePost: post.publicKey,
            e_kind: 'community',
            kind: 'discussion.post',
        }));
        void API.analyticsModal({ bodyParams: { modal: 'modal.share', state: 'open' } });
    };
    const read = () => {
        if (isLogged) {
            void dispatch(postReaction({
                urlParams: { cm_pk, b_pk: post.boardPk, target_pk: post.publicKey },
                bodyParams: Object.assign(Object.assign({}, (currentEntity.publicKey != mask.get.publicKey && { mask: mask.get.publicKey })), { kind: 'pin_read' }),
                extraParams: { scope: scope, feed: isFeed },
            }));
        }
        else {
            dispatch(modalAction({ modal: 'see_more', state: true }));
        }
    };
    return (_jsxs(_Fragment, { children: [(post.totalReactions > 0 || post.totalComments > 0 || (post.totalViews > 0 && isAdmin)) && (_jsxs(Row, { justify: "space-between", style: { padding: '0px 24px 16px 24px', marginTop: 16 }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(PostReactionsTag, { totalReactions: post.totalReactions, onClick: onShowReactions }), post.pin != 'mandatory' && isAdmin && (_jsxs(_Fragment, { children: [post.totalReactions > 0 && _jsx("div", { style: { marginLeft: 8, marginRight: 8, lineHeight: '17px' }, children: '•' }), _jsx(EyeOutlined, { style: { marginRight: 4 } }), _jsx("div", { style: { lineHeight: '17px' }, children: post.totalViews })] }))] }), _jsx(PostCommentsTag, { totalComments: post.totalComments, onClick: onShowComments })] })), (post.totalReactions > 0 || post.totalComments > 0 || post.kind == 'basic' || (post.totalViews > 0 && isAdmin)) && (_jsx("div", { style: { padding: breakpoints.isMobile ? '0px 16px' : '0px 24px' }, children: _jsx(Divider, { style: { margin: 0 } }) })), _jsxs("div", { style: { display: 'flex', alignItems: 'center', paddingLeft: isMobile ? 16 : 24 }, children: [showReactionModal && (_jsx(ReactionModal, { kind: "react", target: post, open: showReactionModal, onCancel: () => setShowReactionModal(false) })), showReadStatModal && (_jsx(PostViewReadModal, { target: post, kind: "pin_view", open: showReadStatModal, onCancel: () => setReadStatModal(false) })), isAdmin && _jsx(MaskButton, { onClick: () => setShowMasks(true) }), _jsxs("div", { style: {
                            display: 'grid',
                            padding: isMobile ? '16px' : '16px 24px 16px 16px',
                            flexGrow: 1,
                            gridTemplateColumns: isLogged ? '1fr 1fr 1fr' : '1fr 1fr',
                        }, children: [_jsx(PostAction, { icon: _jsx(LikeIcon, {}), iconActive: _jsx(LikeIcon, { filled: true, color: 'var(--ant-primary-color)' }), title: strings.general.like, onClick: isLiked ? () => dislikeDebounce() : () => likeDebounce(), active: isLiked }, "reactionBtn"), _jsx(PostAction, { icon: _jsx("span", { style: { fontSize: 14, width: 14, height: 14, display: 'flex' }, children: _jsx("svg", { viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M.833 2.5c0-.46.373-.833.834-.833h16.666c.46 0 .834.373.834.833V15c0 .46-.373.834-.834.834H9.78l-3.99 1.995a.833.833 0 01-1.207-.745v-1.25H1.667A.833.833 0 01.833 15V2.5zm1.667.834v10.833h2.917c.46 0 .833.373.833.833v.735l2.96-1.48a.833.833 0 01.373-.088H17.5V3.334h-15z", fill: 'var(--neutral-13)', fillOpacity: "1" }) }) }), title: strings.general.comments.action, onClick: onComment }, "commentBtn"), isLogged && (_jsx(PostAction, { icon: _jsx(Icon, { component: ShareAltOutlined, style: { fontSize: 14, width: 14, height: 14, display: 'flex' } }), title: strings.general.share, onClick: openShareModal }, "shareBtn"))] })] }), post.pin == 'mandatory' && (_jsxs("div", { style: { padding: '0px 24px 16px 24px' }, children: [_jsx(Divider, { style: { marginTop: 0 } }), _jsx(Button, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, disabled: isRead, type: "primary", onClick: read, block: true, children: _jsxs("div", { style: {
                                display: 'flex',
                                alignItems: 'center',
                            }, children: [_jsx(EyeOutlined, { style: { display: 'flex', marginRight: 4 }, color: isRead ? undefined : 'white' }), ' ', isRead ? strings.screens.markasread.actions.read : strings.screens.markasread.actions.unread] }) })] })), post.pin == 'mandatory' && isAdmin && (_jsx("div", { style: { padding: '0px 24px 16px 24px' }, children: _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: [_jsx("div", { style: { lineHeight: '17px' }, children: post.totalViews == 1
                                ? strings.screens.markasread.details.viewers.singular
                                : strings.formatString(strings.screens.markasread.details.viewers.plural, ((_c = post.totalViews) === null || _c === void 0 ? void 0 : _c.toString()) || '0') }), _jsx("div", { style: { marginLeft: 8, marginRight: 8, lineHeight: '17px' }, children: '•' }), _jsx("div", { style: { lineHeight: '17px' }, children: post.totalReads == 1
                                ? strings.screens.markasread.details.readers.singular
                                : strings.formatString(strings.screens.markasread.details.readers.plural, ((_d = post.totalReads) === null || _d === void 0 ? void 0 : _d.toString()) || '0') }), _jsx("div", { style: { marginLeft: 8, marginRight: 8, lineHeight: '17px' } }), _jsx(LinkColor, { onClick: () => {
                                setReadStatModal(true);
                            }, children: strings.general.moreDetails })] }) }))] }));
};
export { PostFooter };
