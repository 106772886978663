import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Redirect, useHistory, useParams } from 'react-router';
import { Breadcrumb, Button } from 'antd';
import { EditOutlined, ShareAltOutlined, MessageOutlined } from '@ant-design/icons';
import style from './UserHeader.scss';
import strings from '@resources/localization';
import { entityColor } from '@util/color/EntityColorHash';
import useBreakpoints from '@hooks/useBreakpoints';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { Router } from '@Router';
import { EntityName } from './components/UserName';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { addWebp } from '@util/browser/addWebp';
import { UserModal } from './components/user_modal/UserModal';
import { API } from '@api/API';
import { communityChatExists } from '@state/actions';
import { v4 } from 'uuid';
import { useState } from 'react';
const UserHeader = (props) => {
    var _a;
    const { cm_pk, u_pk } = useParams();
    const { showBreadcrumb } = props;
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const user = useAppSelector((state) => state.user.items[u_pk]);
    const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == user.item.publicKey;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const openEditModal = () => {
        setVisibleEditModal(true);
    };
    const history = useHistory();
    const colors = entityColor(user.item.name);
    const dispatch = useAppDispatch();
    const openShareModal = () => {
        dispatch(modalAction({
            modal: 'share',
            active: true,
            link: window.location.origin + Router.Path.user_details({ u_pk, cm_pk }),
            e_kind: 'user',
            referenceCommunity: cm_pk,
            referenceUser: u_pk,
            kind: 'entity',
        }));
        void API.analyticsModal({ bodyParams: { modal: 'modal.share', state: 'open' } });
    };
    const [localId, setLocalId] = useState(v4());
    const afterCreate = (result) => {
        setLocalId(result.data.publicKey);
    };
    const chatDetail = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.messages) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[localId]; });
    const onClick = () => {
        const local = v4();
        void dispatch(communityChatExists({
            urlParams: {
                cm_pk,
            },
            bodyParams: { users: [u_pk] },
            extraParams: {
                localId: local,
                users: [
                    Object.assign(Object.assign({}, user.item), { roomAccess: 'member', roomState: 'invited' }),
                    Object.assign(Object.assign({}, currentEntity), { roomAccess: 'owner', roomState: 'present' }),
                ],
            },
            options: {
                then: {
                    action: afterCreate,
                    error: () => setLocalId(local)
                },
            },
        }));
    };
    if (chatDetail) {
        return _jsx(Redirect, { to: { pathname: Router.Path.community_chat({ cm_pk }), state: { roomPk: localId } } });
    }
    return (_jsxs("div", { children: [visibleEditModal && _jsx(UserModal, { setVisibility: setVisibleEditModal, open: visibleEditModal }), showBreadcrumb && (_jsx("div", { style: { padding: isMobile ? 16 : '16px 24px' }, children: _jsxs(Breadcrumb, { children: [props.from == 'directory' && (_jsx(Breadcrumb.Item, { children: _jsx("p", { style: { fontSize: breakpoints.isMobile ? 16 : 14, cursor: 'pointer', marginBottom: 0 }, onClick: () => history.goBack(), children: strings.screens.directory.title }) }, 'directory')), props.from == 'members' && (_jsx(Breadcrumb.Item, { children: _jsx("p", { style: { fontSize: breakpoints.isMobile ? 16 : 14, cursor: 'pointer', marginBottom: 0 }, onClick: () => history.goBack(), children: strings.screens.boards.discussion.subscribers }) }, 'members')), _jsx(Breadcrumb.Item, { children: _jsx("p", { style: { fontSize: breakpoints.isMobile ? 16 : 14, marginBottom: 0 }, children: strings.screens.profile.title }) }, 'profile')] }) })), _jsxs("div", { className: style.imgContainer, children: [user.item.bannerUrl && (_jsx("div", { style: { paddingTop: '40%', position: 'relative' }, children: _jsx("img", { alt: "example", style: { objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }, src: addWebp((_a = user.item) === null || _a === void 0 ? void 0 : _a.bannerUrl), loading: "lazy" }) })), !user.item.bannerUrl && (_jsx("div", { style: { paddingTop: '40%', position: 'relative' }, children: _jsx("div", { style: {
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                background: colors.banner,
                            } }) })), _jsx(AvatarTuringo, { className: style.profileImg, size: breakpoints.xxs || breakpoints.xs ? 32 : 64, avatar: user.item.avatarUrl, name: user.item.name })] }), _jsx("div", { className: style.infoContainer, children: _jsxs("div", { className: style.editContainer, children: [_jsx(Button, { style: {}, size: "large", icon: _jsx(ShareAltOutlined, {}), onClick: openShareModal, children: isMobile || breakpoints.lg ? '' : strings.screens.profile.shareButton }, `cb#2`), isLoggedUser && (_jsx(Button, { size: "large", icon: _jsx(EditOutlined, {}), onClick: openEditModal, children: isMobile ? '' : strings.screens.profile.editButton })), !isLoggedUser && cm_pk != 'turingo' && _jsx(Button, { size: "large", icon: _jsx(MessageOutlined, {}), onClick: onClick, children: isMobile ? '' : strings.screens.help.tabs.contactus.action.title })] }) }), _jsx(EntityName, Object.assign({}, user.item))] }));
};
export { UserHeader };
