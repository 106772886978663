var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Meta from 'react-meta-tags';
import { Card, Checkbox, DatePicker, Form, Input, Steps } from 'antd';
import strings from '@resources/localization';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Redirect, useParams } from 'react-router';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
import Paragraph from 'antd/lib/typography/Paragraph';
import useBreakpoints from '@hooks/useBreakpoints';
import { FormNameItem } from '@components/form/FormNameItem/FormNameItem';
import { FooterModal } from '@components/modals/components/FooterModal';
import { FormDeferredItem } from '@components/form/FormDeferredItem/FormDeferredItem';
import { API } from '@api/API';
import { FormPhoneItem } from '@components/form/FormPhoneItem/FormPhoneItem';
import { userEdit } from '@state/actions';
import { Router } from '@Router';
import dayjs from 'dayjs';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeEN from 'antd/es/date-picker/locale/en_US';
import { FormHelpItem } from '@components/form/HelpeableWrapper/FormHelpItem';
require('dayjs/locale/es');
require('dayjs/locale/en');
const Onboarding = (props) => {
    var _a, _b, _c, _d, _e;
    const loginStrings = strings.screens.auth.login;
    const { cm_pk } = useParams();
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const community = useAppSelector((state) => { var _a; return (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.item; });
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const [current, setCurrent] = useState(0);
    const [formValues, setFormValues] = useState({});
    const isSmall = breakpoints.isSmall;
    const [formInstance1] = Form.useForm();
    const [formInstance2] = Form.useForm();
    const [formInstance3] = Form.useForm();
    const language = useAppSelector((state) => state.ui.language);
    dayjs.locale(language);
    const locale = language == 'es' ? localeES : localeEN;
    const [hideNumber, setHideNumber] = useState(false);
    const onHideNumber = (e) => {
        setHideNumber(e.target.checked);
    };
    const next = () => {
        if (current == 0) {
            void formInstance1
                .validateFields()
                .then((values) => {
                setFormValues((prev) => {
                    return Object.assign(Object.assign({}, prev), values);
                });
                setCurrent(current + 1);
            })
                .catch((info) => { });
        }
        if (current == 1) {
            void formInstance2
                .validateFields()
                .then((values) => {
                setFormValues((prev) => {
                    return Object.assign(Object.assign({}, prev), values);
                });
                setCurrent(current + 1);
            })
                .catch((info) => { });
        }
        if (current == 2) {
            void formInstance3
                .validateFields()
                .then((values) => {
                submit(Object.assign(Object.assign(Object.assign({}, formValues), values), { privacyPhone: hideNumber }));
            })
                .catch((info) => { });
        }
    };
    const dispatch = useAppDispatch();
    const submit = (values) => {
        var _a;
        const { memberProperties } = values;
        for (const key in memberProperties) {
            const p = community === null || community === void 0 ? void 0 : community.memberProperties.find((p) => p.id == key);
            if (p.type == 'time:years') {
                memberProperties[key] = parseInt(memberProperties[key]);
            }
        }
        void dispatch(userEdit({
            urlParams: { cm_pk: currentCommunity },
            bodyParams: Object.assign(Object.assign({}, values), { memberProperties, cityId: formValues.ubication.value, phone: ((_a = values.phone) === null || _a === void 0 ? void 0 : _a.length) > 0 ? values.phone : null }),
        }));
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const steps = [
        {
            title: '1',
        },
        {
            title: '2',
        },
        {
            title: '3',
        },
    ];
    const currentUser = useAppSelector((state) => state.auth.currentEntity);
    const loading = useAppSelector((state) => state.auth.loading);
    const overrideJob = (_a = community === null || community === void 0 ? void 0 : community.memberProperties) === null || _a === void 0 ? void 0 : _a.find((p) => p.override == 'profile:job');
    if (!((_c = (_b = currentUser.onboard) === null || _b === void 0 ? void 0 : _b.require) === null || _c === void 0 ? void 0 : _c.name)) {
        const paths = location === null || location === void 0 ? void 0 : location.pathname.split('/');
        return _jsx(Redirect, { to: { pathname: Router.Path.community_home({ cm_pk: paths[1] }), state: { welcome: true } } });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: strings.screens.auth.signup.browserTitle }), _jsx("meta", { name: "description", content: strings.screens.auth.signup.browserDescription })] }), _jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    minHeight: '80vh',
                    paddingLeft: breakpoints.xxs ? 0 : 16,
                    paddingRight: breakpoints.xxs ? 0 : 16,
                }, children: _jsx(Card, { style: { height: 'fit-content', minWidth: 'min(590px,100%)' }, children: _jsx("div", { style: { maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }, children: _jsxs("div", { style: { display: 'flex', justifyContent: 'center', flexDirection: 'column' }, children: [community && (_jsx(AvatarTuringo, { conteinerStyle: { display: 'flex', justifyContent: 'center' }, avatar: community.logoUrl, color: (_e = (_d = community.ui) === null || _d === void 0 ? void 0 : _d.style) === null || _e === void 0 ? void 0 : _e.color, size: 16, width: 56, height: 56, style: {
                                        border: ' 1px solid var(--hover)',
                                        lineHeight: '56px',
                                        cursor: 'pointer',
                                    }, name: community.name })), _jsx(Paragraph, { style: {
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        fontSize: isMobile ? 24 : 30,
                                        lineHeight: isMobile ? '32px' : '38px',
                                        marginTop: 16,
                                        marginBottom: 16,
                                    }, children: current == 2 ? strings.screens.onboarding.step_three.title : strings.screens.onboarding.step_one.title }), _jsx(Steps, { responsive: false, direction: "horizontal", style: { marginBottom: 56 }, current: current, progressDot: true, items: steps }), _jsxs("div", { style: { display: 'flex', justifyContent: 'center' }, children: [current == 0 && (_jsxs(Form, { initialValues: {
                                                name: currentUser.name,
                                            }, form: formInstance1, style: { maxWidth: isMobile ? '100%' : 400, minWidth: isMobile ? '100%' : 400, marginBottom: 40 }, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(FormNameItem, { name: 'name', label: strings.screens.profile.editModal.name.label, validations: ['required', 'format', 'long', 'short'] }), !overrideJob && (_jsx(FormHelpItem, { name: 'job', label: strings.screens.profile.editModal.position.label, rules: [
                                                        { required: true, message: strings.screens.profile.editModal.position.validations.required },
                                                        { max: 50, message: strings.screens.profile.editModal.position.validations.long },
                                                        { min: 3, message: strings.screens.profile.editModal.position.validations.short },
                                                    ], children: _jsx(Input, { showCount: true, maxLength: 50 }) })), overrideJob && overrideJob.type == 'time:years' && (_jsx(Form.Item, { rules: [{ required: true, message: 'La generación no puede quedar en blanco' }], getValueFromEvent: (e) => e === null || e === void 0 ? void 0 : e.format('YYYY'), getValueProps: (e) => ({
                                                        value: e ? dayjs(e) : '',
                                                    }), name: ['memberProperties', overrideJob.id], label: overrideJob.title, children: _jsx(DatePicker, { getPopupContainer: (trigger) => trigger.parentElement, placeholder: "Selecciona un a\u00F1o", style: { width: '100%' }, format: 'YYYY', locale: locale, picker: "year" }) }))] })), current == 1 && (_jsxs(Form, { form: formInstance2, style: { maxWidth: isMobile ? '100%' : 400, minWidth: isMobile ? '100%' : 400, marginBottom: 40 }, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.onboarding.step_two.description }), _jsx(FormEmailItem, { name: 'email', placeholder: strings.screens.profile.editModal.email.placeholder, label: _jsx("div", { style: { display: 'flex', alignItems: 'center' }, children: _jsx(Paragraph, { children: strings.screens.profile.editModal.email.label }) }), validations: ['required', 'format', 'long', 'short'] }), _jsx(Form.Item, { rules: [{ required: true, message: strings.screens.profile.editModal.location.validations.required }], name: ['ubication'], label: strings.screens.profile.editModal.location.label, children: _jsx(FormDeferredItem, { onSearchDeferred: (term) => __awaiter(void 0, void 0, void 0, function* () {
                                                            const results = yield API.citySearch({ searchParams: { term } });
                                                            const data = results['data'];
                                                            if (!data || !data.cities)
                                                                return 'wait';
                                                            return data.cities.map((city) => ({
                                                                key: `city-${city.id}`,
                                                                label: `${city.name}, ${city.countryName}`,
                                                                value: `${city.id}`,
                                                            }));
                                                        }), getPopupContainer: (trigger) => trigger.parentElement, placeholder: strings.screens.profile.editModal.location.placeholder }) })] })), current == 2 && (_jsxs(Form, { form: formInstance3, style: { maxWidth: isMobile ? '100%' : 400, minWidth: isMobile ? '100%' : 400, marginBottom: 40 }, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(Paragraph, { style: { marginBottom: 16 }, children: strings.screens.onboarding.step_three.description }), _jsx(FormPhoneItem, { name: 'phone', form: formInstance3 }), _jsx(Checkbox, { value: hideNumber, onChange: onHideNumber, children: strings.screens.profile.editModal.phone.description })] }))] }), _jsx(FooterModal, { back: current > 0 && {
                                        action: prev,
                                        customText: strings.general.back,
                                        kind: 'back',
                                    }, primary: {
                                        loading: current == 2 ? loading : false,
                                        action: next,
                                        customText: strings.general.next,
                                    } }, 'footer_modal')] }) }) }) })] }));
};
export { Onboarding };
