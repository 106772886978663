import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Paragraph from 'antd/lib/typography/Paragraph';
import { ExpandableButton } from '@components/buttons/expandable_button/ExpandableButton';
import useBreakpoints from '@hooks/useBreakpoints';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const SettingItem = (props) => {
    var _a;
    const { buttonAlign = 'flex-end', gap = 16, style } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const items = isMobile ? (_a = props.action) === null || _a === void 0 ? void 0 : _a.reverse() : props.action;
    return (_jsxs("div", { style: Object.assign(Object.assign({}, (!isMobile && { display: 'flex', justifyContent: 'space-between', alignItems: 'center' })), style), children: [_jsxs("div", { style: { marginRight: isMobile ? 0 : 24 }, children: [_jsxs("div", { style: { display: 'flex', marginBottom: isMobile ? 16 : 0, alignItems: isMobile ? 'start' : 'center' }, children: [props.iconPark && _jsx(props.iconPark, { size: 16, style: { marginRight: 4, alignItems: 'center', display: 'flex' } }), props.iconAnt && _jsx(props.iconAnt, { style: { fontSize: 16, marginRight: 8, paddingTop: isMobile ? 4 : 0 } }), props.icon && _jsx(props.icon, { height: 16, width: 16, style: { fontSize: 16, marginRight: 8, paddingTop: isMobile ? 4 : 0 } }), _jsx(Paragraph, { style: { marginBottom: 0, fontWeight: props.description ? 500 : 400 }, children: props.title })] }), props.description && _jsx(Paragraph, { style: { marginTop: isMobile ? 0 : gap, marginBottom: isMobile ? gap : 0 }, children: props.description })] }), _jsx("div", { style: { display: 'grid', gap: 16, gridAutoFlow: isMobile ? 'row' : 'column', alignSelf: buttonAlign }, children: items === null || items === void 0 ? void 0 : items.map((item, i) => {
                    if (item.dropdownItems) {
                        return (_jsx(Dropdown, { menu: item.dropdownItems, trigger: ['click'], children: _jsx(ExpandableButton, { disabled: item.disabled, ByteIcon: item.icon, type: item.btnType || 'primary', children: _jsxs(Space, { children: [item.title, _jsx(DownOutlined, {})] }) }, `btn_${i}`) }, `dropdown_${i}`));
                    }
                    return (_jsx(ExpandableButton, { disabled: item.disabled, ByteIcon: item.icon, type: item.btnType || 'primary', onClick: item.onClick, children: item.title }, i));
                }) })] }));
};
export { SettingItem };
