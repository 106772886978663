import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Dropdown } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { MoreOutlined } from '@ant-design/icons';
const MoreButton = (props) => {
    const { items, type = 'default', style, iconStyle } = props;
    const isMobile = useBreakpoints().isMobile;
    return (_jsx(Dropdown, { menu: { items }, trigger: ['click'], children: _jsx(Button, { onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
            }, type: type, style: Object.assign({ padding: 0, zIndex: 3 }, style), children: _jsx(MoreOutlined, { style: Object.assign({ padding: 12, fontSize: 14 }, iconStyle) }) }) }));
};
export { MoreButton };
