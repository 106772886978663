import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Checkbox, Form } from 'antd';
import { CreationScreen } from './CreationScreen';
import { CreatePostContext } from '../../PostCreateProvider';
const CrossPostingScreen = (props) => {
    const [activeKey, setActiveKey] = useState('turingo');
    const { form } = useContext(CreatePostContext);
    const errorCommunity = Form.useWatch(['form', 'turingo', 'error'], form);
    const errorLinkedin = Form.useWatch(['form', 'linkedin', 'error'], form);
    const errorX = Form.useWatch(['form', 'X', 'error'], form);
    const onChange = (newActiveKey) => {
        setActiveKey(newActiveKey);
    };
    const initialItems = [
        {
            label: (_jsxs("div", { className: 'turingo-text', children: [_jsx(Checkbox, { style: { marginRight: 16 }, disabled: true, defaultChecked: true }), " Comunidad"] })),
            children: _jsx(CreationScreen, { currentTab: activeKey, consumer: 'turingo' }),
            key: 'turingo',
            closable: false,
            forceRender: true,
        },
        {
            label: (_jsxs("div", { className: 'turingo-text', children: [_jsx(Checkbox, { onChange: (e) => form.setFieldValue(['form', 'linkedin', 'active'], e.target.checked), style: { marginRight: 16 } }), " Linkedin"] })),
            children: _jsx(CreationScreen, { currentTab: activeKey, consumer: 'linkedin' }),
            key: 'linkedin',
            closable: false,
            forceRender: true,
        },
        {
            label: (_jsxs("div", { className: 'turingo-text', children: [_jsx(Checkbox, { onChange: (e) => form.setFieldValue(['form', 'X', 'active'], e.target.checked), style: { marginRight: 16 } }), " X ", errorX && 'Error'] })),
            children: _jsx(CreationScreen, { currentTab: activeKey, consumer: 'X' }),
            key: 'X',
            closable: false,
            forceRender: true,
        },
    ];
    return _jsx(CreationScreen, { currentTab: activeKey, consumer: 'turingo' });
};
export { CrossPostingScreen };
