const strings = {
    notifications: {
        title: 'Notificaciones',
        empty: 'No hay nuevas notificaciones',
        showMore: 'Mostrar todas las notificaciones',
        new: 'Nuevas',
        previous: 'Anteriores',
        kind: {
            'usr.community.invited': {
                default: 'te invitó a la comunidad',
            },
            'usr.community.join': {
                default: 'se unió a la comunidad',
            },
            'usr.community.post': {
                default: 'publicó en la comunidad',
            },
            'admin.community.post': {
                default: 'ha realizado una publicación en',
            },
            'usr.post.like': {
                default: 'le gustó tu publicación',
                one: 'y 1 persona más les gustó tu publicación',
                many: 'y {0} personas más les gustó tu publicación',
            },
            'usr.comment.like': {
                default: 'le gustó tu comentario',
                one: 'y 1 persona más les gustó tu comentario',
                many: 'y {0} personas más les gustó tu comentario',
            },
            'usr.post.comment': {
                default: 'comentó tu publicación',
                one: 'y 1 persona más comentaron tu publicación',
                many: 'y {0} personas más comentaron tu publicación',
            },
            'usr.post.mention': {
                default: 'te mencionó en una publicación',
                one: 'y 1 persona más te mencionaron en una publicación',
                many: 'y {0} personas más te mencionaron en una publicación',
            },
            'usr.comment.mention': {
                default: 'te mencionó en un comentario',
                one: 'y 1 persona más te mencionaron en un comentario',
                many: 'y {0} personas más te mencionaron en un comentario',
            },
            'usr.comment.reply': {
                default: 'respondió tu comentario',
                one: 'y 1 persona más respondieron tu comentario',
                many: 'y {0} personas más respondieron tu comentario',
            },
            'usr.poll.vote': {
                default: 'votó en tu encuesta',
                one: 'y 1 persona más votaron en tu encuesta',
                many: 'y {0} personas más votaron en tu encuesta',
            },
            'usr.classroom.complete': {
                default: '¡Felicitaciones! Has obtenido un certificado por terminar el curso',
            },
            'sys.poll.expired': {
                default: 'Tu encuesta ha finalizado, revisa los resultados',
            },
            'sys.classroom.remind': {
                default: 'Aún no has terminado de ver el curso {0}. Complétalo y ¡obtén tu certificado!',
            },
            'sys.account.validation': {
                default: '',
            },
            'mkt.classroom.launch': {
                default: 'Nuevo lanzamiento de curso {0}. ¡Obtén tu certificado!',
            },
            'mkt.classroom.discount': {
                default: '{0}% off en curso',
            },
            'usr.post.report': {
                default: 'Se ha enviado un reporte de una publicación',
            },
            'sys.poke': {
                default: 'Notificacion de sistema',
            },
            'com.team.added': {
                default: 'Te han agregado al equipo',
            },
            'com.community.invited': {
                default: 'Te han invitado a la comunidad',
            },
            'com.community.post': {
                default: 'ha realizado una publicación en',
            },
            'com.admin.added': {
                default: 'te ha nombrado Admin de la comunidad',
            },
            'com.course.rate': {
                default: 'Se ha creado una nueva valoración en el curso',
            },
            'com.course.quiz.approved': {
                default: '¡Felicitaciones! Has aprobado el examen del curso',
            },
            'com.course.merit': {
                default: '¡Felicitaciones! Has obtenido un certificado por terminar el curso',
            },
            'com.course.comment': {
                default: 'Se ha creado un nuevo comentario en el curso',
            },
            'com.catalog.comment': {
                default: 'Se ha creado un comentario en el ítem',
            },
            'com.post.pinned.mandatory': {
                default: 'ha destacado una publicación.',
            },
            'com.post.pinned.normal': {
                default: 'ha destacado una publicación.',
            },
            'com.event.added': {
                default: 'Te han invitado al evento',
            },
        },
    },
    reports: {
        kinds: {
            fake: 'Información falsa',
            sales: 'Ventas no autorizadas',
            inappropiate: 'Lenguaje inapropiado',
            spam: 'Spam',
            abuse: 'Infringe las reglas de la comunidad',
            other: 'Otro',
        },
        initial: {
            title: 'Reportar',
            description: {
                rate: 'Selecciona el motivo por el que reportas esta valoracion:',
                comment: 'Selecciona el motivo por el que reportas este comentario:',
                post: 'Selecciona el motivo por el que reportas esta publicación:',
                room: 'Selecciona el motivo por el que reportas esta conversación:',
                message: 'Selecciona el motivo por el que reportas este mensaje:',
            },
        },
        selected: {
            title: 'Seleccionaste la opción',
            description: 'Esta información será enviada a nuestro equipo para ser analizada lo antes posible.',
            continue: '¿Deseas continuar con el reporte?',
            other: {
                label: '¿Qué estás tratando de reportar?',
                prompt: 'Ayúdanos a entender el problema en una frase',
            },
            other_details: {
                label: 'Entréganos más detalles',
                placeholder: 'Otra persona de la comunidad se hace pasar por mí, se hizo una cuenta con mi nombre y apellido y no sé qué hacer.',
            },
        },
        finish: {
            success: {
                title: '¡Gracias! Recibimos tu reporte',
                description: 'Esta comunidad la construimos en conjunto, por lo que tu ayuda es fundamental para crear un ambiente seguro y confiable.',
            },
            error: {
                title: 'notfound',
                description: 'Tuvimos un problema al enviar tu reporte. Por favor, intenta de nuevo.',
            },
        },
    },
    rates: {
        title: 'Valoraciones',
        createAction: 'Crear valoración',
        more: 'Ver más',
        form: {
            rate: '¿Con cuántas estrellas lo valorarías?',
            content: {
                label: 'Cuéntale a otras personas por qué  ',
                placeholder: 'Me gustó porque pude hacerlo a mi ritmo y pude aplicar el contenido a mi emprendimiento. ¡Muy recomendado! ',
            },
            edit_title: 'Edita tu valoración',
        },
        delete: {
            title: '¿Quieres remover esta valoración?',
            description: 'Una vez eliminada, no se podrá recuperar.',
        },
    },
    comments: {
        title: 'Comentarios',
        empty: 'Sé parte de la comunidad e interactúa con otras personas comentando sobre este contenido.',
        more: 'Ver más comentarios',
        form: {
            placeholder: 'Comparte con otras personas qué te pareció este contenido.',
            help: '¿Necesitas ayuda?',
            contactUs: 'Escríbenos',
            edit_title: 'Edita tu comentario',
        },
        delete: {
            title: '¿Quieres remover este comentario?',
            description: 'Una vez eliminado, no se podrá recuperar.',
        },
    },
    general: {
        discussion: {
            singular: 'Espacio',
            plural: 'Espacios',
        },
        course: {
            singular: 'Curso',
            plural: 'Cursos',
        },
        organization: {
            singular: 'Organización',
            plural: 'Organizaciones',
        },
        user: {
            singular: 'Persona',
            plural: 'Personas',
        },
        forum: {
            singular: 'Charla',
            plural: 'Charlas',
        },
        community: {
            singular: 'Comunidad',
            plural: 'Comunidades',
        },
        story: {
            singular: 'Historia',
            plural: 'Historias',
        },
        catalog: {
            singular: 'Catálogo',
            plural: 'Catálogos',
        },
        lesson: {
            singular: 'Lección',
            plural: 'Lecciones',
        },
        post: {
            singular: 'Publicación',
            plural: 'Publicaciones',
        },
        session: {
            singular: 'Sesión',
            plural: 'Sesiones',
        },
        material: {
            singular: 'Material',
            plural: 'Materiales',
        },
        attendance: {
            singular: 'Asistirá',
            plural: 'Asistirán',
        },
        time: {
            hours: {
                zero: '0 horas',
                one: '1 hora',
                nary: '{0} horas',
            },
            minutes: {
                zero: '0 minutos',
                one: '1 minuto',
                nary: '{0} minutos',
            },
            seconds: {
                zero: '0 segundos',
                one: '1 segundo',
                nary: '{0} segundos',
            },
            hoursCompact: {
                zero: '0 hrs',
                one: '1 hr',
                nary: '{0} hrs',
            },
            minutesCompact: {
                zero: '0 min',
                one: '1 min',
                nary: '{0} min',
            },
            secondsCompact: {
                zero: '0 s',
                one: '1 s',
                nary: '{0} s',
            },
            hhmm: '{0} y {1}',
            times: '{0} veces',
        },
        languages: {
            english: 'Inglés',
            spanish: 'Español',
        },
        audio: 'Audio',
        subtitles: 'Subtítulos',
        duration: 'Duración',
        downloadableContent: 'Material adicional',
        download: 'Descargar',
        share: 'Compartir',
        watchFree: 'Ver gratis',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthYear: '{0} del {1}',
        date: 'dddd, D MMMM [del] YYYY',
        createdBy: 'Creado por',
        sponsor: 'Patrocinado por',
        contributor: 'En colaboración con',
        promoter: 'Promovido por',
        supporter: 'Apoyado por',
        watching: 'Viendo ahora',
        play: 'Reproducir',
        next: 'Siguiente',
        edit: 'Editar',
        report: 'Reportar',
        delete: 'Eliminar',
        loading: 'Cargando',
        seeMore: 'Ver más {0}',
        send: 'Enviar',
        add: 'Agregar',
        save: 'Guardar',
        cancel: 'Cancelar',
        remove: 'Remover',
        back: 'Atrás',
        detail: 'Detalle',
        description: 'Descripción',
        example: {
            singular: 'Ejemplo',
            plural: 'Ejemplos',
        },
        about: 'Acerca de',
        return: 'Volver',
        continue: 'Continuar',
        finish: 'Listo',
        affirmation: 'Sí',
        negative: 'No',
        include: 'Incluido',
        notInclude: 'No incluido',
        title: 'Título',
        language: 'Idioma',
        localization: 'Localización',
        fullName: 'Nombre y apellidos',
        yes: 'si',
        no: 'no',
        availableOn: 'Disponible:',
        releasesOn: 'Estrena:',
        image: {
            plural: 'Imágenes',
            singular: 'Imagen',
        },
        certificate: {
            title: { singular: 'Certificado', plural: 'Certificados' },
            included: 'Incluido',
            notIncluded: 'No incluido',
        },
        email_option: 'O con tu email',
        create: 'Crear',
        buy: 'Comprar',
        availableMaterial: 'Material adicional',
        comments: {
            action: 'Comentar',
            zero: '0 comentarios',
            one: '1 comentario',
            nary: '{0} comentarios',
        },
        votes: {
            title: 'Votaciones',
            zero: '0 votos',
            one: '1 voto',
            nary: '{0} votos',
            finish: 'Finalizada',
            time: {
                hours: {
                    zero: 'Quedan 0 horas',
                    one: 'Queda 1 hora',
                    nary: 'Quedan {0} horas',
                },
                minutes: {
                    zero: 'Quedan 0 minutos',
                    one: 'Queda 1 minuto',
                    nary: 'Quedan {0} minutos',
                },
                days: {
                    zero: 'Quedan 0 días',
                    one: 'Queda 1 día',
                    nary: 'Quedan {0} días',
                },
            },
        },
        placeholder: {
            courseTitle: 'Maravilloso Curso',
            person: 'Juan Manzana-semilla',
        },
        replies_previuos: {
            zero: '0 respuestas anteriores',
            one: '1 respuesta anterior',
            nary: '{0} respuestas anteriores',
        },
        replies: {
            action: 'Responder',
            zero: '0 respuestas',
            one: '1 respuesta',
            nary: '{0} respuestas',
        },
        like: 'Me gusta',
        topost: 'Publicar',
        more: 'Más',
        seeMoreText: 'Leer más',
        seeLessText: 'Leer menos',
        newRelease: 'Nuevo lanzamiento',
        dateFormat: 'DD [de] MMMM',
        dateTimeFormat: 'DD [de] MMMM [del] YYYY [a las] HH[:]mm [hrs]',
        mentors: 'Mentores',
        participants: 'Participantes',
        audience: 'Ideal para',
        abilities: 'Al terminar',
        purshased: 'Comprado',
        continents: {
            na: 'América del Norte',
            sa: 'América del Sur',
            eu: 'Europa',
            af: 'Africa',
            oc: 'Oceania',
            as: 'Asia',
            an: 'Antártida',
        },
        pay: 'Pagar',
        read_more: 'Leer más',
        visibility: 'Visibilidad',
        created: 'Creado',
        public: 'Pública',
        private: 'Privada',
        create_your_community: 'Crea tu comunidad',
        turingo_community: 'Comunidad Turingo',
        noTags: 'Sin etiquetas',
        noDescription: 'Sin descripción',
        goToProfile: 'Ir al perfil',
        filter: 'Filtrar',
        sortBy: 'Ordenar por',
        clean: 'Limpiar',
        country: 'País',
        services: 'Servicios',
        industries: 'Industrias',
        interests: 'Intereses',
        noResults: 'No se han encontrado resultados',
        mute: 'Silenciar',
        unmute: 'Dejar de silenciar',
        accept: 'Aceptar',
        searchMember: 'Buscar integrante',
        searchInvitation: 'Buscar invitación',
        onlyNamesAndNumbers: 'Sólo puede incluir letras y números.',
        moreDetails: 'Más detalles',
        payment: 'Pago',
        safebuy: 'Compra segura',
        httpsinfo: 'Este sitio utiliza conexión HTTPS, protegiendo la transferencia de tus datos.',
        reorderModal: {
            title: 'Reordenar {0}',
            description: 'Modifica el orden de los {0} arrastrando y soltándolos.',
            action: 'Reordenar',
            success: 'Los {0} han sido reordenados',
        },
    },
    navigation: {
        sidebar: {
            home: 'Inicio',
            courses: 'Cursos',
            forums: 'Charlas',
            stories: 'Historias',
            stories_short: 'Historias',
            community: 'Comunidad',
            spaces: 'Espacios',
            events: 'Eventos',
            directory: 'Directorio',
            admin: 'Panel Admin',
            support: 'Soporte',
            highlights: 'Destacados',
        },
        usermenu: {
            title: 'Mi cuenta',
            options: {
                profile: 'Perfil',
                settings: 'Ajustes',
                contact: 'Contáctanos',
                help: 'Preguntas frecuentes',
                terms: 'Términos de condiciones',
                privacy: 'Políticas de privacidad',
                blog: 'Blog',
                aboutus: 'Sobre Turingo',
                logout: 'Cerrar sesión',
                faq: 'Preguntas frecuentes',
            },
        },
    },
    modals: {
        mask: {
            title: 'Cambiar interaccion',
            description: 'Selecciona con qué nombre deseas interactuar en la comunidad.',
        },
        share: {
            title: 'Compartir',
            email: {
                subject: '¡Hola! Vi esto y creo que puede interesarte💚',
                body: '¡Hola! Espero que te encuentres muy bien hoy😊\nEncontré esto en turingo.com y creo que te puede servir.\nTuringo es una plataforma que tiene cursos especialmente creados para emprendedoras, emprendedores y líderes de pyme.\n¡Cuentame si te gusta!.',
            },
            description: 'O copia este link',
            action: 'Copiar',
            message: 'Copiado',
        },
        invitation: {
            errors: {
                expired: {
                    title: 'La invitación ha expirado',
                    description: 'No es posible acceder a la comunidad. Es necesario que te vuelvan a invitar para poder ingresar',
                },
                accepted: {
                    title: 'La invitación ya ha sido aceptada',
                    description: 'No es posible acceder a la comunidad. Intenta iniciando sesión con el email al que se envió la invitación',
                },
                rejected: {
                    title: 'La invitación ya ha sido rechazada',
                    description: 'No es posible acceder a la comunidad. Es necesario que te vuelvan a invitar para poder ingresar',
                },
                email: {
                    title: 'El email no corresponde al email de la invitación',
                    description: 'No es posible acceder a la comunidad. Intenta iniciando sesión con el email al que se envió la invitación',
                },
                general: {
                    title: 'Ocurrió un problema',
                    description: 'No se pudo aceptar la invitación, intenta nuevamente',
                },
                'application.community.invitation.member': {
                    title: 'Ocurrió un problema',
                    description: 'No es posible aceptar esta invitación porque ya eres parte de la comunidad. Cierra la sesión e intenta nuevamente.',
                },
            },
        },
        welcome: {
            title: '¡{0}, te damos la bienvenida a la comunidad!',
            description: 'Está todo listo para que comiences. Preséntate a la comunidad y ¡comparte tu primera publicación!',
            later: 'Más tarde',
            post: 'Saluda a la comunidad',
        },
        whatsapp: {
            title: '¡No te pierdas las publicaciones destacadas y recibe las notificaciones directamente desde tu WhatsApp!',
            description: 'Al agregar tu número de teléfono en el perfil, recibirás notificaciones solo para las publicaciones destacadas directamente en tu WhatsApp. Si deseas desactivarlo, puedes hacerlo en los ajustes de notificaciones de tu cuenta.',
            toProfile: 'Ir al perfil',
        },
    },
    screens: {
        exit: {
            title: 'Tu cuenta ha sido eliminada',
            description: 'Gracias por haber sido parte de la comunidad. Si deseas volver, es necesario que te vuelvan a invitar para poder ingresar.',
        },
        onboarding: {
            step_one: {
                title: '¡Completa tu perfil en 3 pasos!',
            },
            step_two: {
                description: 'Ingresa un email de contacto para compartir con la comunidad, ya sea el mismo de registro o uno diferente.',
            },
            step_three: {
                title: '¡Estás solo a 1 paso!',
                description: 'Recibe notificaciones en WhatsApp solo para publicaciones destacadas. Edita los ajustes en tu cuenta para cambiarlo.',
            },
        },
        markasread: {
            title: 'Destacado',
            banner: {
                plural: 'Tienes {0} publicaciones destacadas sin leer.',
                singular: 'Tienes 1 publicación destacada sin leer.',
                action: 'Ir a destacados',
            },
            details: {
                title: 'Detalle de la publicación',
                description: 'Analiza en detalle la publicación destacada y el medio a través del cual se accedió.',
                medium: {
                    community: 'Comunidad',
                    whatsapp: 'Vía WhatsApp',
                    email: 'Vía Email',
                },
                readers: {
                    title: 'Leídos',
                    plural: '{0} leídos',
                    singular: '1 leído',
                },
                viewers: {
                    title: 'Vistos',
                    plural: '{0} vistos',
                    singular: '1 visto',
                },
            },
            actions: {
                highlight: 'Marcar como destacado',
                unhighlight: 'Remover destacado',
                unread: 'Marcar como leído',
                read: 'Leído',
            },
            pin_modal: {
                title: 'Marcar como destacado',
                description: 'Resalta la publicación para aumentar su visibilidad y agrégala a la sección de Destacados de tu comunidad.',
                email: {
                    title: 'Enviar email',
                    description: 'Notifica vía email a las personas del espacio seleccionado, sin importar su configuración desde notificaciones.',
                },
                whatsapp: {
                    title: 'Enviar mensaje por WhatsApp',
                    description: 'Notifica vía WhatsApp a las personas del espacio seleccionado, según su configuración de notificaciones.',
                },
                read: {
                    title: 'Marcar como leído',
                    description: 'Agrega una confirmación para que las personas del espacio seleccionado puedan marcar el mensaje como leído. De lo contrario, solo se destacará.',
                },
            },
            unpin_modal: {
                title: '¿Quieres remover el destacado de esta publicación?',
                description: 'Una vez removido, no aparecerá en la sección de destacados de tu comunidad.',
            },
        },
        chat: {
            showAll: 'Mostrar todos los mensajes',
            browserTitle: 'Mensajes',
            empty: 'No tienes nuevos mensajes',
            browserDescription: 'Mensajes de la comunidad',
            title: 'Mensajes',
            search: 'Buscar integrantes',
            notification: {
                options: {
                    markAsRead: 'Marcar como leído',
                },
            },
            room: {
                options: {
                    profile: 'Ir al perfil',
                    markAsSilent: 'Silenciar conversación',
                    markAsUnsilent: 'Dejar de silenciar conversación',
                    report: 'Reportar conversacioón',
                },
                create: {
                    title: 'Nuevo mensaje',
                    description: 'Envía mensajes y conecta con otras personas que integran tu comunidad',
                },
            },
            messages: {
                urlDisclaimer: 'Este enlace externo puede contener información no segura. Asegúrate que la persona que te lo envía sea de confianza.',
                options: {
                    delete: {
                        title: 'Eliminar mensaje',
                        confirm: '¿Quieres remover este mensaje?',
                        warning: 'Una vez eliminado, desaparecerá para ambas personas y no se podrá recuperar.',
                    },
                    report: 'Reportar mensaje',
                },
                placeholder: 'Escribe un mensaje',
                newRoomAlert: '¿Deseas iniciar una conversación con esta persona?',
                deleteMessage: 'El mensaje ha sido eliminado ',
                isTyping: {
                    name: '{0} está escribiendo',
                    withoutName: 'escribiendo',
                },
            },
            silentModal: {
                title: 'Silenciar conversación',
                description: '¿Quieres silenciar esta conversación?',
                subdescription: 'Al hacerlo, no recibirás notificaciones de nuevos mensajes de esta persona.',
            },
            unsilentModal: {
                title: 'Dejar de silenciar conversación',
                description: '¿Quieres dejar de silenciar esta conversación?',
                subdescription: 'Al hacerlo, volverás a recibir notificaciones de nuevos mensajes de esta persona.',
            },
        },
        directory: {
            browserTitle: 'Directorio',
            browserDescription: 'Directorio de la comunidad',
            title: 'Directorio',
            subtitle: 'Integrantes de la comunidad',
        },
        evaluation: {
            title: 'Examen',
            action: 'Realizar examen',
            done: 'Tu examen ya fue realizado',
            to_done_evaluation: {
                title: 'No has terminado de completar todas las lecciones',
                description: 'Necesitas completar al menos el 80% de cada lección para acceder a el examen.',
            },
            to_done_certificate: {
                title: 'No has realizado el examen',
                description: 'Para obtener tu certificado, necesitas primero realizar el examen.',
            },
            rejected: {
                title: 'No es posible entregar el certificado',
                description: 'No has obtenido el porcentaje de aprobación para obtener el certificado.',
            },
            quiz: {
                actions: {
                    send: {
                        title: 'Enviar',
                    },
                },
                sendModal: {
                    title: 'Enviar examen',
                    description: '¿Deseas enviar el examen y obtener los resultados?',
                    send: 'Enviar',
                },
                progress: '{0} de {1}',
                results: {
                    success: {
                        label: 'Respuesta correcta',
                        certificate: {
                            title: '¡Felicitaciones, {0}!',
                            description: 'Has aprobado el examen del curso {0} y recibirás un certificado por tu logro.',
                            subdescription: 'Todos tus certificados de cursos están disponibles en la sección “Mi perfil”.',
                            actions: {
                                getCertificate: 'Obtener certificado',
                            },
                        },
                        noCertificate: {
                            title: '¡Muy bien, {0}!',
                            description: 'Has aprobado el examen del curso {0}. ¡Felicitaciones por tu logro!',
                            actions: {
                                finishCourse: 'Terminar curso',
                            },
                        },
                    },
                    error: {
                        title: 'Lo sentimos, {0}',
                        label: 'Respuesta incorrecta',
                        certificate: 'No has reunido la puntuación necesaria para aprobar y obtener el certificado del curso {0}.',
                        noCertificate: 'No has reunido la puntuación necesaria para aprobar en el curso {0}.',
                        attemptLeft: 'Te invitamos a repasar los contenidos y volver a tomar el examen. Tienes {0} intentos más disponibles.',
                        oneAttemptLeft: 'Te invitamos a repasar los contenidos y volver a tomar el examen. Tienes {0} intento más disponible.',
                        noAttemptLeft: 'Has llegado al máximo de intentos disponibles para realizar el examen.',
                        noAttempt: 'Te invitamos a repasar los contenidos.',
                        actions: {
                            reviewContents: 'Repasar contenidos',
                            repeat: 'Repetir examen',
                        },
                    },
                },
                error: {
                    empty: 'La respuesta no puede quedar en blanco',
                    help1: 'No es posible enviar el examen. Es necesario que todas las preguntas sean respondidas',
                    help2: 'Ocurrió un problema al enviar el examen, intenta nuevamente',
                },
            },
        },
        events: {
            browserTitle: 'Eventos',
            browserDescription: 'Eventos de la comunidad',
            title: 'Eventos',
            upcomingEvents: 'Próximos eventos',
            tabs: {
                next: 'Próximos',
                now: 'Ahora',
                old: 'Pasados',
            },
            empty: 'No se han programado eventos en este momento.',
            add: 'Crear evento',
            old_tag: 'Evento finalizado',
            actions: {
                accept: 'Asistiré',
                reject: 'No asistiré',
                details: 'Más detalles',
            },
            edit: {
                title: 'Editar evento',
                eventInvitations: {
                    error: 'No es posible editar la invitación de un evento. Para hacerlo, es necesario crear uno nuevo.',
                },
            },
            detail: {
                empty: 'No se ha agregado información sobre el evento',
                description: 'Descripción',
                detail: 'Detalle',
                attendances: 'Asistentes',
                attendanceNumberOne: `{0} persona ha confirmado su asistencia.`,
                attendanceNumber: `{0} personas han confirmado su asistencia.`,
                attendanceAlert: `Tu respuesta ha sido actualizada`,
                acceptedAttendances: 'Asistentes',
                rejectedAttendances: 'No asistirán',
                noreplyAttendances: 'Sin responder',
                goToDetails: 'Ir al detalle',
                enterInfo: 'Se activará el acceso una vez que el evento comience',
                enter: 'Entrar al evento',
                rightNow: 'En este momento',
                addtoCalendar: 'Agregar a mi calendario',
                moreDetails: 'Para más detalles visita:',
            },
            delete: {
                title: 'Eliminar evento',
                description: '¿Quieres remover este evento?',
                subdescription: 'Al hacerlo, no podrás recuperarlo y se perderá la información de éste.',
            },
            create: {
                title: 'Crear evento',
                eventBanner: { label: 'Imagen de portada' },
                eventTitle: { label: 'Título', validations: { required: 'El nombre del evento no puede quedar en blanco' } },
                eventDateStart: { label: 'Fecha de inicio' },
                eventDateEnd: { label: 'Fecha de término' },
                eventDateTimeStart: { label: 'Fecha y hora de inicio' },
                eventDateTimeEnd: { label: 'Fecha y hora de término' },
                eventAllDay: 'Todo el día',
                eventKind: {
                    label: 'Tipo de evento',
                    items: {
                        online: 'Online',
                        presential: 'Presencial',
                    },
                },
                eventPlace: {
                    label: 'Lugar',
                    validations: { required: 'Debes incluir el lugar donde se realizará el evento' },
                },
                eventPlaceItems: {
                    zoom: 'Zoom',
                    withLink: 'Con link',
                    withoutLink: 'Sin link',
                },
                eventZoom: {
                    connectDescription: 'Inicia sesión con tu cuenta de Zoom para crear eventos en tu comunidad.',
                    connectAction: 'Iniciar sesión',
                    disconnectAction: 'Desvincular cuenta',
                    connectedAccount: 'Cuenta conectada de Zoom',
                    error: 'Es necesario que inicies sesión en Zoom para crear eventos',
                    disconnectModal: {
                        title: '¿Quieres desvincular la cuenta de Zoom? ',
                        description: 'Al hacerlo, no podrás crear eventos Zoom y todos los creados previamente con esta opción, cambiarán al modo evento sin enlace, es decir, las personas invitadas no tendrán un acceso directo para acceder al evento.',
                        action: 'Desvincular',
                    },
                },
                eventLink: { label: 'Link del evento', validations: { required: 'Debes incluir un link para ingresar al evento' } },
                eventWithoutLinkAlert: 'Agrega en la descripción las instrucciones para participar del evento',
                eventDescription: {
                    label: 'Descripción',
                    prompt: 'Cuéntale a la comunidad de qué se trata el evento',
                    validations: {
                        required: 'La descripción no puede quedar en blanco en un evento sin link',
                        short: 'La descripción debe tener como mínimo 3 caracteres',
                    },
                },
                eventInvitations: {
                    label: 'Enviar invitación',
                    items: {
                        community: { label: 'A toda la comunidad', help: 'La invitación se enviará a todos los espacios de esta comunidad' },
                        boards: {
                            placeholder: 'Selecciona espacios',
                            label: 'Seleccionar espacios',
                            prompt: 'Escribe los espacios que deseas invitar',
                            help: 'Sólo las personas de los espacios seleccionados podrán ver y unirse al evento',
                            validations: { required: 'Selecciona a quién deseas enviar la invitación' },
                        },
                        users: {
                            placeholder: 'Selecciona integrantes',
                            label: 'Selecciona integrantes',
                            prompt: 'Escribe las personas que deseas invitar',
                            help: 'Sólo las personas seleccionadas podrán ver y unirse al evento',
                            validations: { required: 'Selecciona a quién deseas enviar la invitación' },
                        },
                    },
                },
                eventEmail: {
                    title: 'Enviar email',
                    description: 'Notifica vía email a las personas seleccionadas una vez que se cree el evento, 24 horas antes y cuando comience.',
                },
            },
        },
        redeem: {
            error: {
                title: 'Ha ocurrido un error',
                description: 'Al parecer la licencia ingresada ya fue ocupada o alcanzo su limite máximo de usos',
                action: 'Ir al inicio',
            },
        },
        admin: {
            title: 'Panel Administrativo',
            browserTitle: 'Panel Admin',
            browserDescription: 'Panel Administrativo de la comunidad',
            actions: {
                abandon: 'Abandonar comunidad',
                back: 'Volver a la comunidad',
                adminPanel: 'Panel admin',
            },
            notifications: {
                title: 'Notificaciones',
                description: 'Selecciona la frecuencia con la que deseas enviar las novedades de tu comunidad.',
                options: {
                    weekly: 'Semanal',
                    biweekly: 'Bisemanal',
                    monthly: 'Mensual',
                    none: 'Nunca',
                },
            },
            onboarding: {
                title: 'Onboarding',
                preamble: 'Descubre recursos clave para construir una comunidad exitosa.',
                description: 'Selecciona la frecuencia con la que deseas enviar las novedades de tu comunidad.',
            },
            analytics: {
                title: 'Analítica',
                preamble: 'Encuentra y analiza el rendimiento de tu comunidad',
                description: 'Encuentra y analiza el rendimiento de tu comunidad',
                courseTable: {
                    emptyState: 'No hay cursos creados',
                    userTable: {
                        title: 'Lista de integrantes',
                        columnName: 'Nombre',
                        columnEmail: 'Email',
                        columnAttempts: 'Intentos',
                        columnProgress: 'Progreso',
                        columnStatus: 'Estado',
                        columnTeams: 'Equipos',
                        userState: {
                            approved: 'Aprobado',
                            pending: 'Pendiente',
                            rejected: 'Reprobado',
                        },
                    },
                },
            },
            courses: {
                title: 'Cursos',
                preamble: 'Crea cursos y ofrece capacitación valiosa para tu comunidad.',
                description: 'Crea cursos y ofrece capacitación valiosa para tu comunidad.',
                addButton: {
                    title: 'Crear curso',
                },
                createdCourse: 'Cursos creados',
                editButton: {
                    title: 'Editar curso',
                },
                edit: {
                    success: 'Curso actualizado correctamente.',
                    failure: 'Ha ocurrido un error al actualizar el curso.',
                },
                publishIssues: {
                    lessonsEmpty: 'Se necesita al menos una lección visible.',
                },
                courseAddModal: {
                    title: 'Crear curso',
                    fieldImage: { label: 'Imagen' },
                    fieldName: {
                        label: 'Nombre',
                        help: 'Escribe el nombre del curso',
                        validations: {
                            required: 'El nombre no puede quedar en blanco',
                            short: 'El nombre del curso debe tener como mínimo 3 caracteres',
                        },
                    },
                    fieldDesc: {
                        label: 'Descripción',
                        help: 'Cuéntale a la comunidad de qué se trata el curso',
                        validations: {
                            required: 'La descripción no puede quedar en blanco',
                        },
                    },
                    fieldTarget: { label: 'Dirigido a', description: 'Agrega máximo 5 descripciones indicando para quién está dirigido el curso.' },
                    fieldObjective: {
                        label: 'Objetivos del curso',
                        description: 'Agrega máximo 5 descripciones especificando los logros que alcanzarán al terminar el curso.',
                    },
                    fieldMentor: {
                        label: 'Dictado por',
                        description: 'Agrega máximo 5 personas que dictan el curso.',
                    },
                    fieldLanguage: {
                        label: 'Lenguaje de audio',
                        placeholder: 'Español',
                        help: 'Selecciona el idioma del audio',
                        validations: {
                            required: 'El lenguaje de audio no puede quedar en blanco',
                        },
                    },
                    fieldSubtitle: {
                        label: 'Subtitulo',
                        placeholder: 'Español',
                        help: 'Selecciona el idioma si el curso cuenta con subtítulo',
                    },
                    addDescriptionButton: {
                        title: 'Agregar Descripción',
                    },
                    editDescriptionButton: {
                        title: 'Editar descripción',
                    },
                    addMentorButton: {
                        title: 'Agregar Persona',
                    },
                    editMentorButton: {
                        title: 'Editar Persona',
                    },
                    errorUnique: 'No pueden existir dos cursos con el mismo título',
                    detail: 'Detalle',
                    moreInfo: ['Una vez creado el curso, puedes agregar las lecciones y más opciones.', 'Puedes editar los accesos desde la sección Equipos'],
                },
                courseEditModal: {
                    title: 'Editar curso',
                },
                courseDeleteModal: {
                    title: 'Eliminar curso',
                    description: '¿Quieres remover este curso?',
                    disclaimer: 'Una vez eliminado, no se podrá recuperar.',
                },
                coursesTable: {
                    sectionTitle: 'General',
                    reminderToggle: 'Enviar recordatorio',
                    reminderToggleHint: 'Después de 7 días de inactividad desde el inicio del curso.',
                    columnName: 'Nombre',
                    columnImage: 'Imagen',
                    columnDescription: 'Descripción',
                    columnTarget: 'Dirigido a',
                    columnObjective: 'Objetivos del curso',
                    columnMentor: 'Dictado por',
                    columnDetail: 'Detalle del curso',
                    columnLanguage: 'Lenguaje de audio',
                    columnSubtitles: 'Subtítulos',
                    actions: {
                        edit: { title: 'Editar', description: 'Edita la información general del curso' },
                    },
                    moreInfo: 'Puedes editar los accesos del curso desde la sección Equipos.',
                },
                abilitiesTable: {
                    sectionTitle: 'Objetivos',
                    description: 'Agrega máximo 5 descripciones especificando los logros que alcanzarán al terminar el curso.',
                    buttonAdd: { title: 'Agregar descripción' },
                    buttonVisible: { title: 'Visibilidad Pública' },
                    buttonReorder: { title: 'Reordenar' },
                    descriptionTable: {
                        title: 'Objetivos',
                        empty: 'No existen descripciones agregadas',
                    },
                },
                audienceTable: {
                    sectionTitle: 'Dirigido a',
                    description: 'Agrega máximo 5 descripciones indicando para quién está dirigido el curso.',
                    buttonAdd: { title: 'Agregar descripción' },
                    buttonVisible: { title: 'Visibilidad Pública' },
                    buttonReorder: { title: 'Reordenar' },
                    descriptionTable: {
                        title: 'Dirigido',
                        empty: 'No existen descripciones agregadas',
                    },
                },
                evaluationTable: {
                    sectionTitle: 'Evaluación',
                    description: 'Crea un examen para evaluar los conocimientos de las personas que toman el curso.',
                    buttonAdd: { title: 'Crear examen' },
                    examSeparator: 'Examen',
                    columnQuestionDate: 'Programado',
                    columnDateCreated: 'Creado {0}',
                    columnVersion: 'Versión {0}',
                    columnQuestionCount: 'Número de preguntas',
                    columnShowAnswers: 'Mostrar respuestas',
                    columnRepeats: 'Repetir examen',
                    columnApproval: 'Criterio aprobación',
                    empty: 'No existen exámenes creados',
                    actions: {
                        buttonEditQuestions: { title: 'Editar preguntas' },
                        buttonEditConfig: { title: 'Editar configuración' },
                        buttonDelete: { title: 'Eliminar' },
                    },
                    error: {
                        fetch: 'Ocurrió un problema al crear el examen, intenta nuevamente',
                        exist: 'No es posible crear el examen, te sugerimos verificar si ya está creado',
                    },
                },
                mentorsTable: {
                    sectionTitle: 'Mentores',
                    description: 'Agrega máximo 5 personas que dictan el curso.',
                    buttonAdd: { title: 'Agregar persona' },
                    buttonVisible: { title: 'Visibilidad Pública' },
                    buttonReorder: { title: 'Reordenar' },
                    peopleAddModal: {
                        title: 'Agregar persona',
                        avatarTitle: 'Imagen',
                        fieldJob: { label: 'Cargo', placeholder: 'Escribe el cargo de quien dicate el curso' },
                        fieldName: { label: 'Nombre y apellido', placeholder: 'Escribe el nombre de quien dicta el curso' },
                        success: 'La persona ha sido agregada',
                    },
                    peopleEditModal: {
                        title: 'Editar persona',
                        avatarTitle: 'Imagen',
                        fieldJob: { label: 'Cargo', placeholder: 'Escribe el cargo de quien dicate el curso' },
                        fieldName: { label: 'Nombre y apellido', placeholder: 'Escribe el nombre de quien dicta el curso' },
                        success: 'La persona ha sido editada',
                    },
                    peopleDeleteModal: {
                        title: 'Remover persona',
                        description: '¿Quieres remover esta persona? ',
                        disclaimer: 'Una vez removida, no se podrá recuperar.',
                        success: 'La persona ha sido removida',
                    },
                    peopleTable: {
                        title: 'Mentores',
                        actionEdit: { title: 'Editar' },
                        actionDelete: { title: 'Eliminar' },
                        empty: 'No existen personas agregadas',
                    },
                },
                mentorReorderModal: {
                    title: 'Reordenar mentores',
                    description: 'Modifica el orden de los mentores arrastrando y soltándolos.',
                    success: 'Los mentores han sido reordenados',
                },
                lessonsTable: {
                    sectionTitle: 'Lecciones',
                    number: 'Numero de lección',
                    columnLessons: 'Lecciones creadas',
                    columnName: 'Nombre',
                    columnDescription: 'Descripción',
                    columnAdditionalMaterial: 'Material adicional',
                    empty: 'No existen lecciones creadas',
                    actions: {
                        add: {
                            title: 'Crear lección',
                            description: 'Crea lecciones con información clave para potenciar tu curso y crear una experiencia de aprendizaje completa.',
                        },
                        reorder: 'Reordenar',
                        edit: { title: 'Editar', description: 'Edita la información general de la lección' },
                    },
                    dragModal: {
                        title: 'Reordenar lección',
                        preamble: 'Modifica el orden de las lecciones arrastrando y soltándolas.',
                    },
                    addModal: {
                        title: 'Crear lección',
                        add: {
                            success: 'Leccion creada correctamente',
                            failure: '',
                        },
                        fieldVideo: { label: 'Video de la lección' },
                        fieldName: {
                            label: 'Nombre',
                            placeholder: 'Escribe el nombre de la lección',
                            validations: {
                                required: 'El nombre no puede quedar en blanco',
                            },
                        },
                        fieldDesc: {
                            label: 'Descripción',
                            placeholder: 'Describe de qué se trata la lección',
                            validations: {
                                required: 'La descripción no puede quedar en blanco',
                            },
                        },
                    },
                    uploading: 'El video se está procesando. Recibirás una notificación cuando esté disponible',
                },
                lessonModalAdd: {
                    title: 'Crear lección',
                    fieldVideo: {
                        label: 'Video lección',
                        validations: {
                            required: 'No es posible crear la lección sin subir un video',
                        },
                    },
                    fieldName: {
                        label: 'Nombre',
                        help: 'Escribe el nombre de la lección',
                        validations: {
                            required: 'El nombre de la lección no puede quedar en blanco',
                            short: 'El nombre de la lección debe tener como mínimo 3 caracteres',
                        },
                    },
                    fieldDescription: {
                        label: 'Descripción',
                        help: 'Describe de qué se trata la lección',
                    },
                    success: 'La lección ha sido creada',
                    moreInfo: 'Una vez creada la lección, puedes agregar material adicional',
                    errorUnique: 'No pueden existir dos lecciones con el mismo título',
                },
                lessonModalEdit: {
                    title: 'Editar lección',
                    success: 'La lección ha sido editada',
                },
                lessonModalDelete: {
                    title: 'Eliminar lección',
                    description: '¿Quieres remover esta lección? ',
                    disclaimer: 'Una vez eliminada, no se podrá recuperar.',
                    success: 'La lección ha sido eliminada.',
                },
                lessonMaterialTable: {
                    description: 'Agrega máximo 5 documentos y 5 links como material adicional para complementar la lección.',
                    buttonAdd: { title: 'Agregar material' },
                    dropdownAdd: {
                        attachment: 'Documento',
                        link: 'Link',
                    },
                    sectionLinks: 'Links',
                    sectionLinksEmpty: 'No hay links agregados',
                    sectionDocuments: 'Documentos',
                    sectionDocumentsEmpty: 'No hay documentos agregados',
                },
                lessonLinkAddModal: {
                    title: 'Agregar link',
                    titleEdit: 'Editar link',
                    description: 'Agrega un link y un título con el cual mostrarlo.',
                    fieldTitle: {
                        label: 'Título',
                        placeholder: 'Revisa este sitio',
                        validations: {
                            required: 'El título no puede estar en blanco.',
                        },
                    },
                    fieldLink: {
                        label: 'Link',
                        placeholder: 'https://mysite.com',
                        validations: {
                            required: 'El link no puede estar en blanco',
                        },
                    },
                },
                lessonDocumentAddModal: {
                    title: 'Agregar documento',
                    titleEdit: 'Editar documento',
                    description: 'Sube un documento en formato pdf, word, docx, xls, ppt, xlsx, pptx, txt, csv con peso de hasta 10MB.',
                    fieldFile: {
                        label: 'Documento',
                        validations: {
                            required: 'El archivo es requerido',
                        },
                    },
                },
                lessonLinkDeleteModal: {
                    title: 'Eliminar link',
                    description: '¿Quieres remover este link? ',
                    disclaimer: 'Una vez eliminado, no se podrá recuperar.',
                },
                lessonDocumentDeleteModal: {
                    title: 'Eliminar documento',
                    description: '¿Quieres remover este documento? ',
                    disclaimer: 'Una vez eliminado, no se podrá recuperar.',
                },
                certificateTable: {
                    sectionTitle: 'Certificado',
                    preamble: 'Crea máximo 5 certificados personalizados o selecciona uno ya existente para los cursos de tu comunidad.',
                    addButton: { title: 'Agregar certificado' },
                    buttonVisible: { title: 'Otorga un certificado para este curso' },
                    disclaimer: 'Los certificados corresponden a todos los disponibles en tu comunidad y pueden ser utilizados para 1 o más cursos. Eliminar un certificado puede afectar a otros cursos que estés realizando',
                    actions: {
                        delete: { title: 'Eliminar' },
                        preview: { title: 'Mostrar' },
                    },
                    preview: {
                        title: 'Certificado',
                        actionDelete: { title: 'Eliminar' },
                        actionPreview: { title: 'Mostrar' },
                        active: {
                            one: 'Activo en 1 curso',
                            zero: 'Sin cursos asignados',
                            nary: 'Activo en {0} cursos',
                        },
                    },
                },
                descriptionModalAdd: {
                    title: 'Agregar descripción',
                    success: 'La descripción ha sido agregada',
                    field: {
                        label: 'Descripción',
                        validations: {
                            required: 'No puede quedar en blanco',
                        },
                    },
                },
                descriptionModalEdit: {
                    title: 'Editar descripción',
                    delete: {
                        title: 'Eliminar descripción',
                        description: '¿Quieres remover esta descripción?',
                        disclaimer: 'Una vez eliminada, no se podrá recuperar.',
                        success: 'Descripción eliminada',
                    },
                    success: 'La descripción ha sido editada',
                },
                descriptionReorderModal: {
                    title: 'Reordenar descripciones',
                    description: 'Modifica el orden de las descripciones arrastrando y soltándolas.',
                    success: 'Las descripciones han sido reordenadas',
                },
            },
            integrations: {
                title: 'Integraciones',
                description: 'Vincula las cuentas de tu comunidad y comunícate con tu audiencia a través de Turingo.',
                whatsapp: {
                    title: 'WhatsApp',
                    description: 'Puedes vincular tu cuenta WhatsApp Business y notificar publicaciones destacadas.',
                    help: 'Para configurar tu cuenta de WhatsApp, contacta al equipo Soporte Turingo.',
                },
            },
            general: {
                title: 'General',
                color: {
                    turingo: 'Turingo',
                    red: 'Rojo',
                    cyan: 'Cian',
                    blue: 'Azul',
                    green: 'Verde',
                    magenta: 'Magenta',
                    orange: 'Naranja',
                    volcano: 'Volcán',
                },
                editButton: {
                    title: 'Editar',
                },
                network: {
                    facebookTitle: 'Facebook',
                    instagramTitle: 'Instagram',
                    linkedinTitle: 'Linkedin',
                    twitterTitle: 'X',
                    youtubeTitle: 'Youtube',
                    websiteTitle: 'Sitio Web',
                    emailTitle: 'Email',
                },
                sectionNameTitle: 'Nombre de la comunidad',
                sectionAboutTitle: 'Sobre la comunidad',
                sectionColorTitle: 'Color de apariencia',
                sectionNetworkTitle: 'Redes sociales',
                sectionRulesTitle: 'Reglas de convivencia',
                empty: 'Todavía no has agregado información sobre tu comunidad',
                modalEdit: {
                    title: 'Edita tu comunidad',
                    buttonAddRule: {
                        title: 'Agregar regla (máximo 5)',
                    },
                    fieldName: {
                        label: 'Nombre comunidad',
                        placeholder: '',
                        validations: {
                            pattern: 'Sólo puede incluir letras y números.',
                            short: 'El nombre es muy corto, debe tener por lo menos 3 letras.',
                            required: 'El nombre no puede quedar en blanco.',
                        },
                    },
                    fieldRuleTitle: {
                        label: 'Regla',
                        help: 'Regla',
                        validations: {
                            required: 'El título de la regla no puede quedar en blanco.',
                            short: 'El título de la regla es muy corto.',
                            format: 'El título de la regla solo puede tener caracteres alfanuméricos y tíldes',
                        },
                    },
                    fieldRuleBody: {
                        label: 'Descripción',
                        help: 'Descripción',
                        validations: {
                            required: 'La descripción de la regla no puede quedar en blanco.',
                            short: 'La descripción es muy corta.',
                            format: 'La descripción solo puede tener caracteres alfanuméricos y tíldes',
                        },
                    },
                    fieldEmail: {
                        label: 'Email',
                        placeholder: 'contacto@empresa.com',
                    },
                    fieldWebsite: {
                        label: 'Sitio Web',
                        placeholder: 'https://www.empresa.com',
                    },
                    formRuleTitlePrefix: 'Regla {0}',
                    formRuleDescPrefix: 'Descripción',
                },
            },
            boards: {
                title: 'Espacios',
                preamble: 'Crea espacios sobre temas específicos para organizar tu comunidad.',
                addButton: { title: 'Crear espacio' },
                editButton: { title: 'Editar espacio' },
                boardsTable: {
                    columnName: 'Espacio',
                    columnDescription: 'Descripción',
                    columnTopics: 'Etiquetas',
                    actions: {
                        edit: 'Editar espacio',
                        delete: 'Eliminar espacio',
                    },
                },
                createdBoards: 'Espacios creados',
                empty: 'No existen espacios creados',
                modalAdd: {
                    title: 'Crear espacio',
                    preamble: 'Crea un espacio para mantener conversaciones sobre un tema específico y añade etiquetas para clasificar en subtemas.',
                    editTitle: 'Editar espacio',
                    fieldName: {
                        label: 'Nombre del espacio',
                        validations: {
                            required: 'El nombre del espacio no puede quedar en blanco',
                            pattern: 'El nombre del espacio no puede incluir simbolos',
                        },
                    },
                    fieldDesc: { label: 'Descripción' },
                    moreInfo: 'El espacio se creará en modo no visible hasta que des los accesos correspondientes desde la sección Equipos.',
                    fieldTopics: {
                        label: 'Etiquetas',
                        prompt: 'Agregar etiqueta (máximo 20)',
                        validations: {
                            required: 'La etiqueta no puede quedar en blanco',
                            short: 'Debes agregar al menos una etiqueta',
                            long: 'Máximo 20 etiquetas con 20 caracteres cada una.',
                            pattern: 'La etiqueta no puede incluir simbolos',
                        },
                    },
                },
                modalDelete: {
                    title: 'Eliminar espacio',
                    preambleConfirm: '¿Quieres remover este espacio?',
                    preambleConsequence: 'Al hacerlo, se eliminarán todas las publicaciones realizadas en este lugar.',
                },
                modalMigrate: {
                    title: 'Migrar contenido',
                    preamble: 'Traslada las publicaciones entre espacios manteniendo las interacciones.',
                    description: 'Selecciona el espacio al que quieres migrar las publicaciones.',
                    alert: 'Al migrar el contenido, solo será visible para quienes tengan acceso. Una vez realizados los cambios, no se podrá recuperar el contenido.',
                    fieldBoard: {
                        label: 'Espacio',
                        placeholder: 'Selecciona el nuevo espacio',
                    },
                    fieldTopic: {
                        label: 'Etiqueta',
                        placeholder: 'Selecciona la nueva etiqueta',
                        help: 'Solo es posible seleccionar una etiqueta de destino del nuevo espacio.',
                    },
                },
            },
            members: {
                title: 'Integrantes',
                inviteButton: { title: 'Enviar invitación' },
                invitePreamble: 'Invita a más personas a ser parte de tu comunidad a través de un email.',
                actions: {
                    inviteResend: 'Reenviar invitación',
                    inviteEdit: 'Editar invitación',
                    inviteRemove: 'Eliminar invitación',
                    memberEdit: 'Editar equipos',
                    memberRemove: 'Remover integrante',
                },
                tabs: {
                    members: {
                        title: 'Lista de integrantes',
                        search: { label: 'Buscar', placeholder: 'Buscar integrante' },
                        table: {
                            columnName: 'Nombre',
                            columnEmail: 'Email',
                            columnStatus: 'Estado',
                            columnTeams: 'Equipos',
                            teamsEmpty: 'Ninguno',
                        },
                    },
                    invitations_email: {
                        title: 'Invitaciones vía email',
                        search: { label: 'Buscar', placeholder: 'Buscar invitación' },
                        filters: {
                            sent: 'Enviadas',
                            resent: 'Reenviadas',
                            expired: 'Expiradas',
                        },
                        table: {
                            columnEmail: 'Email',
                            columnStatus: 'Estado',
                            columnTeams: 'Equipos',
                            states: {
                                sent: 'Enviada',
                                resent: 'Reenviada',
                                expired: 'Expirada',
                            },
                            teamsEmpty: 'Ninguno',
                        },
                    },
                    invitations_link: {
                        title: 'Invitaciones vía link',
                        search: { label: 'Buscar', placeholder: 'Buscar invitación' },
                        filters: {
                            active: 'Activa',
                            expired: 'Expirada',
                        },
                        table: {
                            formatUses: '{0} de {1}',
                            columnName: 'Nombre',
                            columnStatus: 'Estado',
                            columnExpiration: 'Expiración',
                            columnUses: 'Usos',
                            columnTeams: 'Equipos',
                            columnLink: 'Link',
                            copyLink: 'Copiar link',
                            teamsEmpty: 'Ninguno',
                        },
                    },
                },
                modalSuccess: {
                    title: 'Link de invitación creado',
                    preamble: `Este link expira {0} o después que {1} personas creen una cuenta. Una vez que se cumpla una de las opciones.`,
                    access: `Cualquier persona con este link podrá entrar a tu comunidad con accesos de equipo a {0}.`,
                    expires: `Puedes crear un nuevo link si este expira.`,
                },
                modalAdd: {
                    action: 'Enviar invitación',
                    email: {
                        title: 'Invitación vía email',
                        preamble: 'Invita a más personas a unirse a tu comunidad mediante un email personalizado. Las invitaciones son válidas por 14 días.',
                        selectTeamTip: 'Agrega accesos extras de equipos específicos junto con los de acceso general',
                    },
                    link: {
                        title: 'Invitación vía link',
                        preamble: 'Invita a más personas a unirse a tu comunidad mediante la creación de un link.',
                        selectTeamTip: 'Incluye accesos adicionales para equipos específicos junto con el acceso general. Para invitar al equipo Admin, por seguridad, se debe hacer vía email.',
                    },
                    linkSettings: 'Ajustes del link',
                    linkPreamble: 'Selecciona el tiempo de expiración del enlace y, como opción, el máximo de usos permitidos. El enlace expirará una vez que se cumpla una de las opciones:',
                    fieldExpiration: {
                        label: 'Tiempo de expiración',
                        options: {
                            '1h': '1 hora',
                            '12h': '12 horas',
                            '1d': '1 día',
                            '7d': '7 días',
                            '14d': '14 días',
                        },
                    },
                    fieldUses: {
                        label: 'Máximo de usos',
                        placeholder: 'Selecciona una opción',
                        options: {
                            '10': '10 usos',
                            '25': '25 usos',
                            '50': '50 usos',
                            '100': '100 usos',
                            '200': '200 usos',
                        },
                    },
                    linkAlert: 'Al editar el tiempo de expiración y/o máximo de usos, el contador vuelve a empezar desde cero sin afectar a las personas ya registradas.',
                    accessDescription: 'Otorga accesos generales o a equipos específicos inmediatos:',
                    generalTip: 'Son los permisos por defecto seleccionados en “Equipo-General” para todas la comunidad',
                    generalAdminTip: 'Son los permisos por defecto seleccionados en “Equipo-General” para toda la comunidad más “Equipo-Admin”',
                    selectTeamGeneral: 'General + Equipos específicos',
                    selectTeam: 'Equipos específicos',
                    messageDefault: 'Te invitamos a participar en nuestra Comunidad {0}, en donde podrás conectar con una red de contactos, colaborar y adquirir nuevas habilidades como profesional.',
                    fieldInvitationTitle: 'Ingresa los emails separados por comas',
                    fieldInvitationHelp: 'Puedes enviar máximo 100 invitaciones',
                    fieldKeepMessage: 'Mantener mensaje personalizado para futuras invitaciones',
                    fieldTeams: {
                        label: 'Equipos',
                        placeholder: 'Escribe los equipos que deseas dar acceso',
                        validations: {
                            required: 'La selección de equipo no puede quedar en blanco',
                        },
                    },
                    fieldName: {
                        label: 'Nombre',
                        help: 'Nombra el link solo para referencia interna',
                        validations: {
                            required: 'El nombre no puede quedar en blanco',
                            format: 'Incorpora un espacio entre el nombre y apellido',
                            pattern: 'Sólo puede incluir letras.',
                            short: 'Debe tener mínimo {0} caracteres',
                            long: 'Debe tener máximo {0} caracteres',
                        },
                    },
                    fieldEmail: {
                        label: 'Email',
                        validations: {
                            format: 'El email no es válido (recuerda que debe ser nombre@email.com).',
                            required: 'Tu email no puede quedar en blanco.',
                            alreadyUsed: 'No se puede enviar la invitación a un email ya registrado en la comunidad',
                        },
                    },
                    fieldMessage: {
                        label: 'Mensaje',
                    },
                    fieldMessagePreamble: '¡Te damos la bienvenida a la comunidad {0}!',
                    fieldMessageAppendix: 'Desde hoy tienes 14 días para activar tu cuenta.',
                    buttonAddInvite: {
                        title: 'Agregar invitación (máximo 10)',
                    },
                },
                modalEdit: {
                    title: 'Editar equipos',
                    preambleAll: 'Edita los equipos en los cuales puede participar esta persona.',
                    preambleTeams: 'Selecciona los equipos en los cuales puede participar esta persona',
                    invitationAcceptedAlert: 'Una vez aceptada la invitación, no se puede editar el nombre de la persona.',
                    fieldName: {
                        label: 'Nombre',
                        placeholder: '',
                    },
                    fieldTeams: {
                        label: 'Equipos',
                        placeholder: 'Escribe los equipos que deseas dar acceso',
                    },
                },
                modalDeleteInvite: {
                    title: 'Anular invitación',
                    preambleConfirm: '¿Quieres remover la invitación?',
                    preambleConsequence: 'Al hacerlo, esta persona podrá ver la invitación, pero no podrá acceder a la comunidad.',
                    buttonRemove: { title: 'Anular invitación' },
                },
                modalDeleteInviteLink: {
                    title: 'Eliminar invitación',
                    preambleConfirm: '¿Quieres remover la invitación?',
                    preambleConsequence: 'Una vez eliminada, no se podrá recuperar.',
                    buttonRemove: { title: 'Eliminar invitación' },
                },
                modalDeleteMember: {
                    title: 'Remover integrante',
                    preambleConfirm: '¿Quieres remover a este integrante?',
                    preambleConsequence: 'Al hacerlo, esta persona no podrá ver tu comunidad.',
                    buttonRemove: { title: 'Remover integrante' },
                },
            },
            teams: {
                title: 'Equipos',
                addPreamble: 'Crea equipos y asigna permisos de acceso para los distintos espacios de tu comunidad.',
                addButton: { title: 'Crear equipo' },
                teamName: 'Equipo {0}',
                allMembersTitle: 'Integrantes (por defecto)',
                allMembersDescription: 'Administra los accesos que tendrán las personas que ingresen a tu comunidad y los permisos respectivos por defecto.',
                allMembersButton: { title: 'Editar accesos' },
                membersTable: {
                    columnName: 'Integrante',
                    columnEmail: 'Email',
                    actions: {
                        addMember: 'Agregar integrante',
                        accessEdit: 'Editar equipo',
                        removeMember: 'Remover integrante',
                    },
                },
                defaultTeams: 'Equipos por defecto',
                createdTeams: 'Equipos creados',
                members: 'General',
                admins: 'Administradores',
                paid: 'Monetizado',
                modalAdd: {
                    title: 'Crear equipo',
                    preamble: 'Invita a otros integrantes de tu comunidad a colaborar en la administración de los espacios que has creado.',
                    preamblePermissions: 'Selecciona los espacios en los que pueden interactuar las personas que integran este equipo y sus respectivos permisos.',
                    preamblePermissionsAll: 'Selecciona los accesos que tendrán las personas que ingresen a tu comunidad y los permisos respectivos por defecto.',
                    fieldName: { label: 'Nombre nuevo equipo' },
                    fieldMembers: { label: 'Integrantes' },
                },
                modalEdit: {
                    title: 'Editar equipo',
                    fieldName: { label: 'Nombre' },
                },
                modalGeneralTeam: {
                    title: 'Accesos de integrantes',
                },
                modalDelete: {
                    title: 'Eliminar equipo',
                    buttonDelete: { title: 'Eliminar equipo' },
                    preambleConfirm: '¿Quieres remover este equipo?',
                    preambleConsequence: 'Al hacerlo, las personas que lo integran perderán los permisos asociados a este equipo.',
                },
                modalAddMember: {
                    title: 'Agregar integrante',
                    preamble: 'Agrega a personas de tu comunidad a este equipo.',
                    fieldMember: {
                        label: 'Integrantes',
                        validations: {
                            required: 'Debes agregar al menos un integrante',
                        },
                    },
                },
                modalRemoveMember: {
                    title: 'Remover integrante del equipo',
                    preambleConfirm: '¿Quieres remover este integrante?',
                    preambleConsequence: 'Al hacerlo, esta persona perderá los permisos asociados a este equipo.',
                    buttonRemove: { title: 'Remover integrante' },
                },
                permissionsTable: {
                    comment: 'Comentar',
                    moderate: 'Moderar',
                    post: 'Publicar',
                    list: 'Mostrar',
                    access: 'Acceder',
                },
            },
            catalogues: {
                title: 'Catálogos',
                preamble: 'Personaliza tu comunidad y crea catálogos para múltiples usos según tus necesidades y preferencias.',
                description: 'Descripción del catálogo',
                createdCatalog: 'Catálogos creados',
                addButton: { title: 'Crear catálogo' },
                editButton: { title: 'Editar catálogo' },
                empty: 'No existen catálogos creados',
                add: {
                    title: 'Crear catálogo',
                    preamble: 'Crea un catálogo ingresando la información principal y luego agrega ítems a este.',
                    editTitle: 'Editar catálogo',
                    fieldName: { label: 'Título' },
                    fieldDesc: { label: 'Descripción' },
                    fieldIcon: { label: 'Selecciona un ícono' },
                    fieldVisibility: {
                        label: 'Visibilidad pública',
                        placeholder: 'Puedes publicar inmediatamente tu catálogo o hacerlo una vez que agregues los ítems a este.',
                    },
                    moreInfo: 'Una vez creado el catálogo, puedes agregar más detalles.',
                },
                delete: {
                    title: 'Eliminar catálogo',
                    preambleConfirm: '¿Quieres remover este catálogo?',
                    preambleConsequence: 'Al hacerlo, se eliminarán todos los items publicados en este lugar.',
                },
                items: {
                    title: 'Ítems',
                    empty: 'Sin ítems',
                    search: 'Buscar en {0}',
                    goToSite: 'Ir al sitio web',
                    addButton: { title: 'Agregar ítem' },
                    editButton: { title: 'Editar ítem' },
                    modalAdd: {
                        title: 'Agregar ítem',
                        preamble: 'Agrega información sobre cada ítem para ser agregado a la lista del catálogo.',
                        editTitle: 'Editar ítem',
                        fieldName: { label: 'Título' },
                        fieldDesc: { label: 'Descripción' },
                        fieldImage: { label: 'Imagen' },
                        fieldLink: { label: 'Link' },
                    },
                    modalDelete: {
                        title: 'Eliminar ítem',
                        preambleConfirm: '¿Quieres remover este ítem?',
                        preambleConsequence: 'Una vez eliminado, no se podrá recuperar.',
                    },
                },
            },
            certificates: {
                title: 'Certificados',
                add: {
                    fieldName: { label: 'Nombre' },
                    fieldSignature: { label: 'Firma' },
                    fieldVisualization: { label: 'Visualización' },
                },
                deleteButton: {
                    title: 'Eliminar certificado',
                },
                deleteModal: {
                    title: 'Eliminar certificado',
                    preambleConfirm: '¿Quieres remover tu certificado?',
                    preambleConsequence: 'Si lo eliminas no podrás recuperarlo.',
                },
                creator: {
                    steps: {
                        title: 'Nombre',
                        signature: 'Firma',
                        preview: 'Certificado',
                    },
                    title: 'Nuevo certificado',
                    sectionSignatureTitle: 'Firma {0}',
                    fieldName: {
                        label: 'Nombre certificado',
                    },
                    fieldLogo: {
                        label: 'Imagen logo',
                    },
                    fieldBackground: {
                        label: 'Imagen fondo',
                    },
                    fieldSignatureImage: {
                        label: 'Imagen',
                        validations: {
                            required: 'La imagen de la firma es requerida',
                        },
                    },
                    fieldSignatureName: {
                        label: 'Nombre y apellidos',
                        description: 'Juan Manzanasemilla',
                    },
                    fieldSignatureJob: {
                        label: 'Cargo',
                        placeholder: 'Director',
                    },
                    buttonAddSignature: {
                        title: 'Agregar firma (Máximo {0})',
                    },
                },
            },
            quizzes: {
                addQuizModal: {
                    title: 'Crear evaluación',
                    instructions: 'Realiza preguntas para evaluar a las personas que toman el curso, y luego, edita las configuraciones de la evaluación.',
                    fieldTitle: {
                        label: 'Título',
                        validations: {
                            required: 'El título no puede quedar en blanco',
                        },
                    },
                    fieldDescription: {
                        label: 'Descripción',
                        validations: {
                            required: 'La descripción no puede quedar en blanco',
                        },
                    },
                    fieldQuestion: {
                        label: 'Pregunta {0} de {1}',
                        validations: {
                            required: 'La pregunta no puede quedar en blanco',
                        },
                    },
                    fieldQuestionImage: {
                        label: 'Imagen',
                    },
                    fieldAlternative: {
                        label: 'Alternativa',
                        validations: {
                            required: 'La alternativa no puede quedar en blanco',
                        },
                    },
                    buttonAddTitle: {
                        title: 'Agregar título',
                    },
                    buttonAddDescription: {
                        title: 'Agregar descripción',
                    },
                    buttonAddQuestion: {
                        title: 'Agregar pregunta',
                    },
                    sectionAddAlternative: 'Agrega respuestas y selecciona la opción correcta',
                },
                editQuizModal: {
                    title: 'Editar evaluación',
                },
                configQuizModal: {
                    title: 'Configuración examen',
                    optionShowAnswers: {
                        title: 'Mostrar respuestas correctas',
                        description: 'Al entregar los resultados, se mostrarán las respuestas correctas al finalizar la evaluación.',
                    },
                    optionStartDate: {
                        title: 'Programa el día y la hora en que se habilitará',
                        hint: '',
                    },
                    optionAfter: {
                        title: 'Selecciona después de qué lección deseas que se ubique',
                    },
                    optionTiming: {
                        title: 'Selecciona el momento en que se podrá realizar el examen',
                        timeAnytime: 'En cualquier momento',
                        timeSequentially: 'De forma secuencial, bloqueando las siguientes lecciones',
                    },
                    optionRepeats: {
                        title: 'Selecciona cuándo pueden repetir la evaluación',
                        repeatNever: 'Nunca',
                        repeatOnce: 'Solo 1 vez',
                        repeatVariadic: 'Personaliza e ingresa el número de repeticiones permitidas',
                    },
                    optionApproval: {
                        title: 'Selecciona el criterio de aprobación del curso',
                        completionNormal: 'Una vez completada la evaluación',
                        completionScored: 'Una vez completada y aprobada, ingresa el porcentaje necesario.',
                    },
                },
                deleteQuizModal: {
                    title: 'Eliminar examen',
                    description: '¿Quieres remover este examen? ',
                    disclaimer: 'Una vez eliminado, no se podrá recuperar.',
                },
            },
        },
        home: {
            title: 'Inicio',
            browserTitle: 'Inicio',
            browserDescription: 'Turingo es el primer ecosistema de evolución digital colaborativa formado por emprendedoras, emprendedores y líderes de pymes en Latinoamérica.',
            upcoming: {
                title: 'Novedades',
                classroom: 'Nuevo curso',
                forum: 'Nueva charla',
                story: 'Nueva historia',
            },
            sections: {
                title: 'Nuestros contenidos disponibles',
                classroom: 'Aprende con destacados referentes internacionales sobre innovación, transformación digital y más.',
                forum: 'Revive nuestros ciclos en vivo en los que expertos y expertas comparten las últimas tendencias digitales.',
                story: 'Inspírate con historias de personas que han emprendido en el área digital para resolver problemáticas de la sociedad. ',
            },
        },
        community: {
            title: '¡Súmate a la comunidad Turingo!',
            browserTitle: 'Comunidad Turingo',
            browserDescription: 'Únete a nuestra comunidad, conoce a otras personas emprendedoras, comparte experiencias y adquiere nuevos conocimientos.',
            description: 'Interactúa con Turingers de todas partes del mundo 24/7 en nuestro chat de Telegram. ¡Y sorpresa! ¡Pronto tendremos más y mejores maneras en las que podrás conectar con personas que poseen experiencia en temas digitales, Pymes y más!.',
            features: [
                {
                    title: 'Redes colaborativas',
                    description: 'Podrás generar redes de contacto y apoyo entre Turingers con expertise en diversas disciplinas digitales.',
                },
                {
                    title: 'Aprendizaje continuo',
                    description: 'No sólo aprendemos de nuestra charlas y cursos. La comunidad Turingo también comparte y cren contenidos 24/7.',
                },
                {
                    title: 'Conexiones globales',
                    description: 'Nuestra comunidad ya tiene integrantes de varios países, adaptándose ante los nuevos desafíos de la era digital.',
                },
            ],
            members: {
                title: 'Integrantes de este espacio',
                description: '{0} personas son parte de este espacio de conversación.',
                action: 'Más integrantes',
            },
            card: {
                title: '¡Súmate a nuestro chat en Telegram!',
                action: 'Ingresar',
            },
            postedIn: 'Publicado en {0} | {1}',
            publicIn: 'Público en',
            goToEvents: 'Ir a eventos',
        },
        boards: {
            discussion: {
                title: 'Comunidad',
                browserTitle: '{0} - Comunidad',
                browserDescription: 'Comunidad en Turingo',
                subscribers: 'Integrantes',
                details: {
                    rules: {
                        title: 'Reglas de convivencia',
                        description: 'Para mantener un ambiente seguro, conoce las reglas básicas de esta comunidad.',
                    },
                    filter: {
                        title: 'Filtra las publicaciones por categorías',
                        description: 'Al elegir una categoría, sólo verás publicaciones sobre esa temática',
                        action: 'Filtrar',
                    },
                    complete_profile: {
                        title: 'Completa tu perfil para que la comunidad te conozca.',
                        action: 'Ir a mi perfil',
                    },
                    empty: 'Aún no existen publicaciones en esta comunidad.',
                    no_more: 'Ya estás al día con las novedades de la comunidad.',
                    new: 'Nuevas publicaciones',
                },
                post: {
                    title: {
                        singular: 'Publicación',
                        plural: 'Publicaciones',
                    },
                    create: {
                        title: 'Crea una publicación',
                        description: 'Público en Comunidad',
                        placeholder: '¿Sobre qué quieres hablar?',
                        language: {
                            placeHolder: 'Por favor, selecciona idiomas',
                        },
                    },
                    edit: {
                        title: 'Edita tu publicación',
                    },
                    spaces: {
                        action: 'Continuar',
                        title: 'Seleccionar espacio',
                        description: 'Selecciona el espacio en el que deseas publicar tu contenido.',
                    },
                    topic: {
                        action: 'Agregar etiquetas',
                        title: 'Agregar etiquetas',
                        description: 'Al elegir una etiqueta, ayudas a la comunidad a encontrar contenido según temática, lo que te dará más visibilidad en audiencias específicas.',
                    },
                    delete: {
                        title: '¿Quieres remover esta publicación?',
                        description: 'Una vez eliminada, no se podrá recuperar.',
                    },
                    alan: {
                        action: 'Insertar',
                        title: 'Crea una publicación',
                        description: 'Dale contexto a Alan sobre lo que deseas publicar y él transformará tus ideas en publicaciones personalizadas.',
                        fieldText: {
                            placeholder: 'Hola, soy Alan, dime sobre qué quieres publicar',
                        },
                        fieldVoice: {
                            items: {
                                grateful: 'Agradecimiento',
                                uplifting: 'Alentador',
                                casual: 'Casual',
                                inspirational: 'Inspiracional',
                                informative: 'Informativo',
                                optimistic: 'Optimista',
                                professional: 'Profesional',
                            },
                            placeholder: 'Selecciona un tono',
                        },
                        fieldResult: {
                            placeholder: 'Ingresa el contexto para generar texto',
                        },
                        warning: 'Queda un {0}% disponible de tu plan. Para más detalles, contacta al equipo soporte.',
                        errors: {
                            'socket.ai.prompt.short': 'El contexto ingresado es demasiado corto',
                            'socket.ai.prompt.long': 'El contexto ingresado es demasiado largo',
                            'socket.ai.malformed': 'El mensaje del socket está mal formado',
                            'socket.ai.voice.unrecognized': 'No se ha seleccionado voz válida',
                            'socket.ai.voice.locale': 'La voz requiere una configuración regional (locale)',
                            'socket.ai.voice.badlocale': 'Configuración regional (locale) no aceptable',
                            'socket.ai.chatid': 'Se requiere el ID de autocompletado',
                            'application.feat.unavailable': 'Esta función no está incluida en tu plan',
                            'application.feat.cycle.notfound': 'No se encontró ningún plan de pago',
                            'application.feat.cycle.exceed': 'Has alcanzado el uso máximo permitido. Para detalles, contacta soporte.',
                        },
                    },
                    kind: {
                        image: {
                            name: 'Imagen',
                            upload: {
                                add: 'Agregar',
                                edit: 'Editar imagen',
                                single: {
                                    title: 'Agregar imagen',
                                    description: 'O arrastra y suéltala en esta área',
                                    help: 'Puedes subir una imagen en formato jpg, jpeg, png, gif, webp con peso de hasta {0}.',
                                    errors: {
                                        size: 'El archivo excede el máximo. Puedes subir una imagen de hasta {0}.',
                                        format: 'No podemos subir ese formato de imagen. Puedes subir en formato jpg, jpeg, png, gif y webp.',
                                        big_dimensions: 'La imagen es muy grande ({0}x{1}), debe ser de {2}x{3}',
                                        small_dimensions: 'La imagen es muy pequeña ({0}x{1}), debe ser de {2}x{3}',
                                        other: 'Tuvimos un problema al subir la imagen, por favor inténtalo de nuevo.',
                                    },
                                },
                                multiple: {
                                    title: 'Agregar imagen',
                                    description: 'O arrastra y suéltalas en esta área',
                                    help: 'Puedes subir hasta {0} imágenes en formato jpg, jpeg, png, gif, webp con peso de hasta {0}.',
                                    errors: {
                                        size: 'El archivo excede el máximo. Puedes subir {0} imágenes de hasta {1} cada una.',
                                        format: 'No podemos subir ese formato de imagen. Puedes subir en formato jpg, jpeg, png, gif y webp.',
                                        big_dimensions: 'La imagen es muy grande ({0}x{1}), debe ser de {2}x{3}',
                                        small_dimensions: 'La imagen es muy pequeña ({0}x{1}), debe ser de {2}x{3}',
                                        other: 'Tuvimos un problema al subir la imagen, por favor inténtalo de nuevo.',
                                    },
                                },
                            },
                        },
                        video: {
                            name: 'Video',
                            placeholder: 'Agregar video',
                            upload: {
                                title: 'Agregar video',
                                description: 'O arrastra y suéltalo en esta área',
                                help: 'Puedes subir un video en formato mp4, mpeg, avi, webm, con peso de hasta {0}.',
                                errors: {
                                    size: 'El archivo excede el máximo. Puedes subir un video de hasta {0}.',
                                    format: 'No podemos subir ese formato de video. Puedes subir en formato mp4, mpeg, avi y webm.',
                                    other: 'Tuvimos un problema al subir el video, por favor inténtalo de nuevo.',
                                },
                            },
                        },
                        attachment: {
                            name: 'Documento',
                            placeholder: 'Agregar documento',
                            upload: {
                                single: {
                                    title: 'Agregar documento',
                                    description: 'O arrastra y suéltalo en esta área',
                                    help: 'Puedes subir un documento en formato {0} con peso de hasta {1}.',
                                    errors: {
                                        size: 'El archivo excede el máximo. Puedes subir un documento de hasta {0}.',
                                        format: 'No podemos subir ese formato de archivo. Puedes subir en formato {0}',
                                        other: 'Tuvimos un problema al subir el documento, por favor inténtalo de nuevo.',
                                    },
                                },
                                multiple: {
                                    title: 'Agregar documentos',
                                    description: 'O arrastra y suéltalos en esta área',
                                    help: 'Puedes subir hasta {0} documentos en formato {1} con peso de hasta {2}.',
                                    errors: {
                                        size: 'El archivo excede el máximo. Puedes subir un documento de hasta {0}.',
                                        format: 'No podemos subir ese formato de archivo. Puedes subir en formato {0}',
                                        other: 'Tuvimos un problema al subir el documento, por favor inténtalo de nuevo.',
                                    },
                                },
                            },
                        },
                        poll: {
                            name: 'Encuesta',
                            placeholder: 'Agrega una encuesta',
                            results: {
                                show: 'Mostrar resultados',
                                hide: 'Ocultar resultados',
                            },
                            form: {
                                title: 'Crea una encuesta',
                                description: 'Para mantener un ambiente seguro y confiable en nuestra comunidad, no está permitido solicitar opiniones políticas, de religión e información confidencial.',
                                question: {
                                    title: 'Tu pregunta',
                                    placeholder: '¿Cuántas personas trabajan en tu empresa?',
                                    validations: {
                                        required: 'Ingresa una pregunta',
                                        short: 'Tu pregunta es muy corta (debe tener más de 8 caracteres)',
                                        long: 'Tu pregunta es muy larga (debe tener a lo más de 20 caracteres)',
                                    },
                                },
                                option_validations: {
                                    required: 'La opción no puede quedar en blanco',
                                    short: 'La opción es muy corta (debe tener más de 8 caracteres)',
                                    long: 'La opción es muy larga (debe tener a lo más de 20 caracteres)',
                                },
                                option: [
                                    {
                                        title: 'Opción 1',
                                        placeholder: '0-10 personas',
                                    },
                                    {
                                        title: 'Opción 2',
                                        placeholder: '11-50 personas',
                                    },
                                    {
                                        title: 'Opción 3',
                                        placeholder: '51-100 personas',
                                    },
                                    {
                                        title: 'Opción 4',
                                        placeholder: 'Más de 100 personas',
                                    },
                                ],
                                duration: {
                                    title: 'Duración de la encuesta',
                                    options: {
                                        day: '1 día',
                                        '3day': '3 días',
                                        week: '1 semana',
                                        '2week': '2 semanas',
                                    },
                                },
                            },
                        },
                    },
                },
                comment: {
                    placeholder: 'Comparte tu opinión',
                },
            },
            course: {
                title: 'Cursos',
                browserTitle: '{0} - Curso',
                browserDescription: 'Cursos',
                subscribers: 'Participantes',
                finished: '¡Felicidades por terminar el curso!',
                list: {
                    card: {
                        free: 'Gratis',
                        watchNow: 'Reproducir',
                        continue: 'Continuar',
                        watchAgain: 'Ver de nuevo',
                        details: 'Más info',
                        progress: {
                            notstarted: 'No iniciado',
                            inprogress: '{0} de {1} lecciones completadas',
                            completed: '{0} completado',
                        },
                    },
                    hero: {
                        title: '¡Expande tus conocimientos junto a nuestros cursos online!',
                        separator: 'Cursos disponibles',
                        features: [
                            {
                                title: 'Multiplataforma',
                                description: 'Aprende a tu ritmo, sin horarios y desde cualquier dispositivo y plataforma con nuestros cursos grabados.',
                            },
                            {
                                title: 'Producción de alta calidad',
                                description: 'Contenido en alta calidad para entregarte la mejor experiencia de aprendizaje.',
                            },
                            {
                                title: 'Talento inigualable',
                                description: 'Profesionales validados por la industria te guiarán en el desarrollo de tus proyectos.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Buscar {0}',
                    },
                    suscriptions: {
                        title: 'Mis {0}',
                        empty: 'Acá podrás ver los {0} a los que te has suscrito.',
                        success: '¡Muy bien! Agregaste un nuevo contenido a la sección "Mis {0}".',
                    },
                    explore: '{0} disponibles',
                    create: 'Nuevo {0}',
                },
                details: {
                    mentors: 'Dictado por',
                    audience: 'Ideal para:',
                    abilities: 'Al terminar lograrás:',
                },
                post: {
                    title: {
                        singular: 'Lección',
                        plural: 'Lecciones',
                    },
                },
                certificate: {
                    see: 'Mostrar certificado',
                    example: 'Mostrar ejemplo',
                    download: {
                        title: 'Certificado',
                        action: 'Descargar certificado',
                        message: 'Obtén tu certificado, compártelo en redes sociales o agrégalo a tu Linkedin.',
                    },
                    progress: {
                        title: 'No has terminado de completar todas las lecciones',
                        description: 'Completa al menos el 80% de cada lección para obtener tu certificado.',
                    },
                    congrats: {
                        title: 'Ahora puedes acceder a tu certificado',
                        description: 'Recuerda que éste y todos tus certificados estarán disponibles en la sección Perfil',
                        get: 'Ir al certificado',
                    },
                    signatures: {
                        bernardo: {
                            name: 'Bernardo Morelos-Zaragoza',
                            position: 'Director & Cofundador Turingo',
                        },
                        ivan: {
                            name: 'Iván Vera Muñoz',
                            position: 'Director & Fundador Turingo',
                        },
                    },
                },
                rates: {
                    title: '¿Que te pareció el {0}?',
                    empty: 'Toma el {0} y comparte con la comunidad que te pareció.',
                    empty_subscriptor: 'Comparte con la comunidad qué te pareció este {0}.',
                    form: {
                        error: 'Valora seleccionando la cantidad de estrellas.',
                    },
                    progress: {
                        title: 'No has terminado de completar todas las lecciones.',
                        description: 'Completa las siguientes lecciones para poder hacer una valoración.',
                        more: 'y 3 lecciones más restantes.',
                    },
                },
            },
            forum: {
                title: 'Charlas',
                browserTitle: '{0} - Charla',
                browserDescription: 'Charlas',
                subscribers: 'Participantes',
                finished: '¡Felicidades por terminar la {0}!',
                list: {
                    card: {
                        free: 'Gratis',
                        watchNow: 'Reproducir',
                        continue: 'Continuar',
                        watchAgain: 'Ver de nuevo',
                        details: 'Más info',
                        progress: {
                            notstarted: 'No iniciado',
                            inprogress: '{0} de {1} sesiones completadas',
                            completed: '{0} completada',
                        },
                    },
                    hero: {
                        title: '¡Revive y entérate de las nuevas tendencias digitales!',
                        separator: 'Charlas disponibles',
                        features: [
                            {
                                title: 'Sesiones de una hora',
                                description: 'Aprende sobre las principales tendencias digitales del momento en sesiones de una hora.',
                            },
                            {
                                title: 'Asincrónico',
                                description: 'Revisa el contenido las veces que quieras, desde cualquier lugar y dispositivo 24/7.',
                            },
                            {
                                title: 'Talento inigualable',
                                description: 'Grandes exponentes comparten su experiencia y conocimiento desarrollándose en entornos digitales.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Buscar {0}',
                    },
                    suscriptions: {
                        title: 'Mis {0}',
                        empty: 'Acá podrás ver las {0} a los que te has suscrito.',
                        success: '¡Muy bien! Agregaste un nuevo contenido a la sección "Mis {0}".',
                    },
                    explore: '{0} disponibles',
                    create: 'Nueva {0}',
                },
                details: {
                    mentors: 'Con la participación de',
                    audience: 'Una {0} ideal para',
                    abilities: 'Al terminar esta {0} serás capaz de',
                    continue: {
                        title: 'Continúa aprendiendo',
                        items: [
                            'Referentes del ecosistema de innovación, emprendimiento y Transformación Digital comparten las últimas tendencias en sus respectivas áreas de desarrollo.',
                            'Conecta con personas que comparten tus objetivos, dudas y propósito.',
                            'Aprende conceptos clave en sólo cinco sesiones de aproximadamente una hora cada una.',
                            'Comparte, interactúa e intégrate a una comunidad online de miles de personas.',
                        ],
                    },
                },
                post: {
                    title: {
                        singular: 'Sesión',
                        plural: 'Sesiones',
                    },
                },
                rates: {
                    title: '¿Que te pareció la {0}?',
                    empty: 'Toma la {0} y comparte con la comunidad que te pareció.',
                    empty_subscriptor: 'Comparte con la comunidad qué te pareció esta {0}.',
                    form: {
                        error: 'Valora seleccionando la cantidad de estrellas.',
                    },
                    progress: {
                        title: 'No has terminado de completar todas las sesiones.',
                        description: 'Completa las siguientes sesiones para poder hacer una valoración.',
                        more: 'y 3 sesiones más restantes.',
                    },
                },
            },
            story: {
                title: 'Historias',
                browserTitle: '{0} - Historia',
                browserDescription: 'Historias',
                subscribers: 'Reproducciones',
                finished: '¡Felicidades por terminar la {0}!',
                list: {
                    card: {
                        free: 'Gratis',
                        watchNow: 'Reproducir',
                        continue: 'Continuar',
                        watchAgain: 'Ver de nuevo',
                        details: 'Más info',
                        progress: {
                            notstarted: 'No iniciado',
                            inprogress: 'Aún no la finalizas',
                            completed: '{0} completada',
                        },
                    },
                    hero: {
                        title: 'Inspírate con historias de personas como tú',
                        separator: 'Historias disponibles',
                        features: [
                            {
                                title: 'Breves',
                                description: 'En máximo 7 minutos conoce potentes historias de emprendimientos con propósito claro.',
                            },
                            {
                                title: 'Reales',
                                description: 'Conoce historias exitosas provenientes de nuestra propia Comunidad Turingo',
                            },
                            {
                                title: 'Cercanas',
                                description: 'Identifícate y siéntete acompañado en este viaje emprendedor con relatos en primera persona.',
                            },
                        ],
                    },
                    search: {
                        placeholder: 'Buscar {0}',
                    },
                    suscriptions: {
                        title: 'Mis {0}',
                        empty: 'Acá podrás ver las {0} a las que te has suscrito.',
                        success: '¡Muy bien! Agregaste un nuevo contenido a la sección "Mis {0}".',
                    },
                    explore: '{0} disponibles',
                    create: 'Nueva {0}',
                },
                post: {
                    title: {
                        singular: '{0}',
                        plural: '{0}',
                    },
                },
            },
        },
        landingb2b: {
            browserTitle: 'Turingo - Construye una comunidad digital',
            browserDescription: 'Turingo - Construye una comunidad digital',
            title: 'Turingo - Construye una comunidad digital',
            headline: ['Construye una comunidad digital', 'para potenciar la evolución de tu organización.'],
            subheadline: 'SaaS Turingo es la plataforma que te ayuda a crear y gestionar tu comunidad conectando con tus grupos de interés para el crecimiento y éxito de tu organización.',
            action: 'Solicita una demo',
            tabs: {
                feed: {
                    title: 'Feed',
                    description: 'Crea espacios para interactuar y compartir diferentes tipos de contenido sobre un tema específico.',
                },
                events: {
                    title: 'Eventos',
                    description: 'Impulsa la interacción en tu comunidad a través de la creación de eventos online y presenciales.',
                },
                profile: {
                    title: 'Perfil',
                    description: 'Obtén información valiosa y mejora la visibilidad de los integrantes con perfiles personalizados.',
                },
                chat: {
                    title: 'Mensajes',
                    description: 'Fomenta la comunicación entre las personas que integran tu comunidad a través de mensajería directa y privada.',
                },
                admin: {
                    title: 'Panel Admin',
                    description: 'Gestiona roles otorgando accesos personalizados a miembros y colaboradores de tu comunidad.',
                },
                capacitation: {
                    title: 'Capacitación',
                    description: 'Ofrece cursos especializados para capacitar y certificar a tu comunidad en temas específicos.',
                },
            },
            trustus: 'Confían en nosotros',
            highlights: {
                title: '¿Qué podrás conseguir construyendo tu comunidad en Turingo?',
                items: [
                    {
                        title: 'Crear oportunidades de negocio',
                        description: 'Crea productos y servicios personalizados y exclusivos para tu comunidad, e innova conectando con otros actores del ecosistema.',
                    },
                    {
                        title: 'Fidelizar tu comunidad',
                        description: 'Mejora la interacción y colaboración en tu comunidad y empresa mediante la comunicación constante y la interacción entre usuarios.',
                    },
                    {
                        title: 'Monetizar estratégicamente',
                        description: 'Genera ingresos sostenibles y rentables mediante la creación de contenidos, patrocinios estratégicos y alianzas que fortalezcan la relación con tus miembros.',
                    },
                ],
            },
            demo_highlights: ['Fideliza tu comunidad', 'Crea oportunidades de negocio', 'Monetiza estratégicamente'],
            phrase: [
                'Te aportamos nuestra',
                'tecnología y experiencia',
                'en comunidades para potenciar tu negocio en un',
                'espacio seguro, medible y exclusivo',
                'para tus miembros, lo que ayuda a desarrollar la confianza y fomentar la colaboración con ellos.',
            ],
            services: {
                title: 'Productos y servicios que tendrás en tu comunidad',
                items: [
                    {
                        title: 'Comparte contenido personalizado',
                        description: 'Facilita contenido para tus grupos de interés a través de cursos, charlas, publicaciones, eventos y más.',
                    },
                    {
                        title: 'Obtención de analítica',
                        description: 'Centraliza los datos y facilita la toma de decisiones de forma eficiente para hacer crecer tu comunidad.',
                    },
                    {
                        title: 'Administración de tu propia comunidad',
                        description: 'Colabora y mejora la gestión de tu comunidad con acceso exclusivo desde la administración.',
                    },
                    {
                        title: 'Eventos',
                        description: 'Atrae y fideliza a tu audiencia a través de eventos masivos o privados y potencia tu comunidad.',
                    },
                    {
                        title: 'Directorio',
                        description: 'Fomenta la colaboración facilitando un espacio para que los miembros se encuentren en la comunidad.',
                    },
                    {
                        title: 'Certificaciones de cursos online',
                        description: 'Mejora el valor de tu marca ofreciendo cursos online con certificación incluida en tu comunidad.',
                    },
                    {
                        title: 'Mensajería directa',
                        description: 'Mejora, fomenta la comunicación y fortalece las relaciones de tu comunidad en tiempo real.',
                    },
                    {
                        title: 'Generador de texto con IA',
                        description: 'Optimiza la creación de contenido con Alan, con múltiples tonos e ideas creativas personalizadas para tu comunidad.',
                    },
                ],
            },
            footer: ['¡Crea ', 'tu comunidad', 'con SAAS Turingo!'],
            turingo_community: {
                title: 'Conoce nuestro caso de uso en la Comunidad de emprendimiento Turingo',
                action: 'Conoce la Comunidad Turingo',
            },
            demo: {
                title: 'Ingresa tus datos',
                description: 'Nuestro equipo de ventas se comunicará contigo para brindarte más detalles.',
                company_name: {
                    label: '¿Cuál es el nombre de tu empresa?',
                    placeholder: 'Nombre de empresa',
                    validations: {
                        required: 'El nombre de empresa no puede quedar en blanco',
                    },
                },
                company_profile: {
                    label: '¿A qué se dedica tu empresa?',
                    placeholder: 'Mi empresa se dedica a...',
                    validations: {
                        required: 'El campo de texto no puede quedar en blanco',
                    },
                },
                action: 'Solicita una demo',
                success: {
                    title: 'Tu solicitud ha sido enviada con éxito',
                    description: 'El equipo Turingo se pondrá en contacto contigo a la brevedad.',
                },
                error: 'Ocurrió un problema enviando tu solicitud, intenta nuevamente',
            },
        },
        landing: {
            title: 'Conecta, colabora y emprende.',
            browserTitle: 'Conecta, colabora y emprende.',
            browserDescription: 'Turingo es el primer ecosistema de evolución digital colaborativa formado por emprendedoras, emprendedores y líderes de pymes en Latinoamérica.',
            carrousel: {
                title: 'Revisa más cursos Turingo y lleva tu negocio al siguiente nivel.',
                action: 'Ir a cursos',
                tags: {
                    highlight: 'Destacado',
                    popular: 'Más visto',
                },
            },
            free: 'Nuestros cursos',
            start: '¡Conviértete en turinger!',
            drawer: '¡Potencia tu negocio con Turingo!',
            headline: 'Conecta, colabora y emprende con la comunidad Turingo',
            subheadline: 'Democratizamos el acceso al emprendimiento y a la innovación, en pos del progreso de las pymes y startups de Latinoamérica.',
            next: 'Próximamente',
            benefitsTitle: 'Beneficios de ser parte de una comunidad de empredimiento',
            benefits: [
                {
                    title: 'Da el paso al siguiente nivel',
                    description: 'Pertenecer a un ecosistema de emprendimiento es lo que necesitas para tener un negocio exitoso. Comparte tus ideas con la comunidad; recibe retroalimentación; y entérate de las últimas tendencias ¡evoluciona en colaboración!',
                },
                {
                    title: 'Conoce personas como tú',
                    description: 'Ser independiente no tiene por qué significar soledad. Únete a la comunidad Turingo e intercambia experiencias con otras personas fundadoras o profesionales independientes ¡sé parte de un grupo de espíritu emprendedor!',
                },
            ],
            phrases: {
                bernardo: [
                    `"A través del `,
                    `emprendimiento y la innovación`,
                    `tenemos la oportunidad de impactar positivamente a nuestras empresas, familias y sociedad"`,
                ],
                yaz: `"En Turingo queremos dar acceso al ecosistema de emprendimiento e innovación, a través de las comunidades y sus conexiones"`,
            },
            highlights: {
                main: 'En Turingo te ofrecemos herramientas concretas para crecer',
                title: 'Cursos destacados',
                courses: {
                    icon: 'Cursos de capacitación',
                    title: 'Cursos de capacitación',
                    description: 'Aprende sobre e-commerce, marketing digital, financiamiento y más, con nuestro contenido exclusivo creado por personas expertas.',
                    action: 'Conoce los cursos',
                },
                forums: {
                    icon: 'Charlas',
                    title: 'Charlas',
                    description: 'Obtén más conocimientos sobre las tendencias digitales que te ayudarán en el crecimiento de tu negocio, con expertas y expertos de diversos rubros.',
                    action: 'Conoce las charlas',
                },
                sucessStories: {
                    icon: 'Historias de éxito',
                    title: 'Historias de éxito',
                    description: 'Conoce a miembros de la comunidad de otros países, genera colaboración y aprovecha la posibilidad de hacer negocios en nuestros eventos Turingo.',
                    action: 'Conoce las historias',
                },
            },
            features: {
                title: 'Sé parte de la comunidad Turingo',
                items: [
                    {
                        title: 'Haz crecer tu emprendimiento',
                        description: 'Conecta con personas emprendedoras de tu país y de otros países a través de nuestra comunidad ¡ábrete a la posibilidad de cerrar nuevos negocios!',
                    },
                    {
                        title: 'Crea redes de contacto',
                        description: 'Relaciónate con líderes empresariales, representantes de la academia y personas expertas en distintos rubros ¡haz nuevos vínculos!',
                    },
                    {
                        title: 'Colabora y evoluciona',
                        description: 'Si quieres ir rápido ve solo, si quieres ir lejos ve acompañado. Este es nuestro lema, si tú también lo compartes ¡ya eres turinger!',
                    },
                    {
                        title: 'Mantente al día',
                        description: 'Entérate de las últimas tendencias en innovación, marketing digital, economía circular, financiamiento y todo lo que pueda afectar a tu empresa ¡no te quedes atrás! ',
                    },
                    {
                        title: 'Inspira a otros',
                        description: 'Comparte tu experiencia y conocimientos ante un público de miles de personas relacionadas al emprendimiento ¡aprovecha esta oportunidad para darte a conocer!',
                    },
                    {
                        title: 'Aprende y conecta en tu idioma',
                        description: 'Apoyamos el crecimiento de emprendimientos y pymes en países de habla hispana a través de cursos, charlas y una comunidad en español ¡hablamos tu idioma!',
                    },
                ],
            },
            soon: {
                title: 'Destacados',
                course: 'NUEVO CURSO',
                forum: 'NUEVA CHARLA',
                story: 'NUEVA HISTORIA',
                soon: 'PRÓXIMAMENTE',
                release: 'LANZAMIENTO',
            },
            blog: { title: 'Infórmate de nuestras novedades en el blog Turingo', action: 'Ir al blog' },
            banner: { title: 'NUEVA HISTORIA CON PROPÓSITO: "De la carne"' },
            bannerValidate: {
                title: 'Aún no confirmas tu email de contacto. ',
                description: '¿No te llegó?',
                action: 'Reenviar email',
                sended: 'Hemos reenviado el email de verificación. Revisa tu bandeja de entrada.',
            },
            reviews: {
                title: '¿Qué dicen de nuestros cursos?',
                items: [
                    {
                        course: 'Curso: El arte de rentabilizar tu negocio',
                        content: 'Me dio una idea más clara de cómo llevar las finanzas de mi pequeño negocio, muchas gracias.',
                    },
                    {
                        course: 'Curso: Creatividad en los nuevos negocios',
                        content: 'Me gustó, porque es muy dinámico y sus casos son reales, nos sirven como inspiración para crear nuestras propias ideas.',
                    },
                    {
                        course: 'Curso: E-Commerce',
                        content: 'Excelente oportunidad de aprender y desarrollar habilidades en el área de negocios, publicidad y marketing digital.',
                    },
                    {
                        course: 'Curso: Herramientas digitales para tu empresa',
                        content: 'Este curso es muy completo, adquirimos conocimientos que son importantes a la hora de proyectar una mejor visión para nuestros proyectos o empresas.',
                    },
                    {
                        course: 'Curso: ¿Cómo ganarle al algoritmo?',
                        content: 'Estamos en la era de las tecnologías, y es de muchísima importancia para nosotros los profesionales y emprendedores el saber manejarlas.',
                    },
                    {
                        course: 'Curso: Emprendimiento Femenino',
                        content: 'Me gustó porque pude hacerlo a mi ritmo, en casa y sin apuros. Buen contenido.',
                    },
                ],
            },
        },
        help: {
            title: 'Preguntas Frecuentes',
            browserTitle: 'Preguntas Frecuentes',
            browserDescription: 'Crea un ecosistema digital de evolución colaborativa formado por comunidades de personas emprendedoras y líderes de pymes.',
            tabs: {
                general: {
                    title: 'General',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Por qué nace Turingo?',
                            answer: 'Creamos Turingo en el 2018 con la convicción de que a través de la innovación, digitalización e internacionalización, podemos mejorar nuestras vidas y la de nuestro entorno. Y los emprendimientos y líderes de Pymes son un motor fundamental para conseguir este objetivo.',
                        },
                        {
                            title: '¿Cuál es su propósito?',
                            answer: 'En Turingo, nuestro propósito es que emprendedores y líderes de Pymes logren desarrollar las capacidades necesarias para adaptarse, trascender y evolucionar exitosamente en la vertiginosa era digital, sin importar su localidad.',
                        },
                        {
                            title: '¿El contenido gratuito tiene un tiempo limitado para ser visto?',
                            answer: 'No, puedes revisarlo cuando quieras, y cuantas veces quieras.',
                        },
                    ],
                },
                courses: {
                    title: 'Cursos y comunidad',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Qué es Turingo?',
                            answer: "Turingo es una plataforma para emprendedoras, emprendedores y líderes de pymes, que ofrece un espacio de colaboración, creación de redes, aprendizaje y apoyo para la adaptación a la era digital en Latinoamérica.\n\nSi quieres aprender una habilidad nueva que te pueda ayudar en tu negocio o idea de negocio, toma uno de nuestros cursos siguiendo los siguientes pasos:\n\n- Regístrate gratis \n- Elige el curso que más te guste \n- Haz click en 'Reproducir'",
                        },
                        {
                            title: '¿Qué es la comunidad Turingo?',
                            answer: 'Es un espacio donde emprendedoras, emprendedores y líderes de pymes de Latinoamérica pueden compartir contenido sobre emprendimiento, innovación, digitalización y todo lo que crean que puede ser útil para ayudar a otros emprendedores y emprendedoras a salir adelante.\n\nTodos los miembros de nuestra comunidad tienen acceso a nuestros cursos pregrabados, charlas y muro de publicaciones.',
                        },
                        {
                            title: '¿Cuáles son nuestras políticas de comunidad?',
                            answer: '1. Sé amable y cordial.\n2. No hagas bullying ni uses lenguaje ofensivo.\n3. No publiques promociones ni spam.',
                        },
                        {
                            title: '¿La comunidad de Turingo es gratis?',
                            answer: 'Sí, la comunidad Turingo es gratis.',
                        },
                        {
                            title: '¿Qué debo hacer para tener mi propia comunidad en Turingo?',
                            answer: 'Debes escribirnos a contacto@turingo.com',
                        },
                        {
                            title: '¿Cómo puedo tomar un curso?',
                            answer: 'Para tomar un curso, sólo debes ir a la sección "Cursos disponibles", hacer click en el botón "Más info" y luego en "Reproducir" en el que hayas elegido. Una vez que comiences el curso, podrás encontrarlo en la sección "Mis cursos" de tu cuenta, y verlo cuantas veces quieras.',
                        },
                        {
                            title: '¿Todos los cursos son gratuitos?',
                            answer: 'En nuestro catálogo puedes encontrar cursos gratis y cursos pagados. Los cursos gratis son de iniciación, si necesitas profundizar en alguna temática en particular puedes tomar nuestros cursos pagados.',
                        },
                        {
                            title: '¿Todos los cursos están grabados o hay algunos en directo?',
                            answer: 'Todos los cursos son asincrónicos, esto quiere decir que los puedes completar a tu propio ritmo, desde donde quieras, y desde cualquier dispositivo con internet.',
                        },
                        {
                            title: '¿Existe un tiempo determinado para completar un curso?',
                            answer: 'No, puedes terminarlo a tu propio ritmo, cuando puedas y desde donde quieras.',
                        },
                        {
                            title: '¿Qué pasa si tomo un curso y no lo completo?',
                            answer: 'Lo que pasará es que no podrás obtener tu certificado de curso completado. Siempre te sugeriremos terminar tus cursos para, además de aprender, obtener este documento que te puede ayudar a validar esta nueva adquisición de conocimientos.',
                        },
                        {
                            title: '¿Cómo obtengo un certificado de curso completado y dónde puedo ver mis certificados?',
                            answer: 'Al terminar cualquier curso Turingo, te avisaremos que tu certificado está listo, tanto para verlo desde tu cuenta, o bien para descargarlo, compartirlo en tus redes sociales para validar aptitudes, o lo que necesites. Además, siempre podrás acceder a éstos en tu sección Perfil.',
                        },
                    ],
                },
                forums: {
                    title: 'Charlas',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Qué es una charla?',
                            answer: 'Son sesiones online en las que destacados exponentes en temas de innovación, emprendimiento, transformación digital, entre otros, se reúnen para compartir las últimas tendencias en dichos temas. Durante las sesiones, puedes interactuar en vivo con speakers y con toda la comunidad Turingo presente.',
                        },
                        {
                            title: '¿Son gratis?',
                            answer: 'Sí, son completamente gratuitos.',
                        },
                        {
                            title: '¿Cómo me inscribo a una charla?',
                            answer: 'A través de nuestras redes sociales (Instagram, Twitter, Facebook y Linkedin) informaremos con anticipación las vías por las que te puedes inscribir.',
                        },
                        {
                            title: '¿Puedo volver a revisarlo en cualquier momento?',
                            answer: 'Sí. En Turingo alojamos las charlas que realizamos en nuestra sección “Charlas”.',
                        },
                        {
                            title: '¿Obtengo un certificado si veo una charla completada?',
                            answer: 'No, solo tomando un curso podrás obtener certificados.',
                        },
                    ],
                },
                stories: {
                    title: 'Historias',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Por qué se llaman Historias con propósito?',
                            answer: 'Las nombramos así porque son historias de emprendimientos que, impulsados por un propósito claro, han logrado superar las dificultades de emprender y, sobretodo, de digitalizar sus negocios de manera exitosa en esta vertiginosa era digital.',
                        },
                        {
                            title: '¿En qué consisten?',
                            answer: 'En cápsulas audiovisuales breves te presentamos historias inspiradoras de líderes de Pymes y emprendimientos, quienes te contarán en primera persona y de manera cercana su experiencia ¡Esperamos que la próxima historia que veas acá sea la tuya!',
                        },
                        {
                            title: '¿Puedo sugerir un emprendimiento para una HCP?',
                            answer: '¡Claro que sí! Puedes enviarnos tus sugerencias a través de nuestro formulario de contacto, al que puedes acceder desde sección Mi cuenta → Ayuda → Contáctanos.',
                        },
                        {
                            title: '¿Son gratuitas?',
                            answer: 'Sí, son gratuitas, al igual que nuestras charlas y algunos de nuestros cursos.',
                        },
                    ],
                },
                events: {
                    title: 'Eventos',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Qué es un evento?',
                            answer: 'Un evento es una instancia donde los usuarios de la comunidad, o dentro de un espacio, pueden reunirse presencial o remotamente.',
                        },
                        {
                            title: '¿Cómo se crea un evento?',
                            answer: 'Solo administradores o miembros de la comunidad con el permiso de crear eventos pueden hacerlos (sujeto a cambio). Para crearlos necesitas ir a Eventos en el menú principal y presionar "Crear Evento".',
                        },
                        {
                            title: '¿Puedo conectar Zoom?',
                            answer: '¡Claro que sí! Hemos creado una integración profunda con Zoom, esta queda asociada a la cuenta del usuario que hace la integración.',
                        },
                        {
                            title: '¿Qué ocurre al hacer un evento con Zoom?',
                            answer: 'Cuando creas un evento y lo asocias a Zoom, automáticamente crearemos una reunión para la misma fecha en Zoom. También, el día del evento, todos los participantes del evento recibirán un link directo a la reunión por Zoom.',
                        },
                        {
                            title: '¿Tengo que hacer login en Zoom siempre?',
                            answer: 'Para nada, Turingo guarda seguramente tu sesión de Zoom para que cada evento que hagas, tengas la opción. Si esto no es de tu agrado, puedes desvincular Zoom en cualquier momento.',
                        },
                        {
                            title: '¿Cómo agrego Zoom a un evento?',
                            answer: 'En el formulario de creación del evento:',
                            steps: [
                                'Ir a la sección que dice "Lugar"',
                                'Elije la opción Zoom',
                                'Haz login en la cuenta de Zoom de tu empresa o personal',
                                'Una vez terminado, tu evento quedará asociado a Zoom, y puedes reutilizar esta conexión en el futuro.',
                            ],
                        },
                        {
                            title: 'Quiero desvincular mi Zoom, ¿Cómo lo hago?',
                            answer: 'Al crear otro evento puedes eliminar la conexión a Zoom, o también puedes hacerlo a través de de Zoom:',
                            steps: [
                                'Entra a tu cuenta de Zoom y navega al App Marketplace',
                                'Presiona en "Manage" y ve a "Added Apps" y busca la Aplicación "Turingo Community"',
                                'Haz click en "Turingo Community"',
                                'Presiona remover',
                            ],
                        },
                        {
                            title: '¿Qué consecuencias tiene desvincular Zoom?',
                            answer: 'No tiene mayores consecuencias, simplemente no podrás crear mas eventos asociados a esa cuenta de Zoom hasta que la agregues nuevamente. Los eventos tanto de Turingo como los de Zoom no se borran, seguirán existiendo y funcionando, si deseas, puedes eliminarlos manualmente.',
                        },
                    ],
                },
                account: {
                    title: 'Mi cuenta',
                    description: 'Preguntas Frecuentes',
                    questions: [
                        {
                            title: '¿Cómo crear una cuenta en Turingo?',
                            answer: "Para hacerte una cuenta en Turingo sólo tienes que ingresar a turingo.com, hacer click en el botón 'Regístrate gratis' y escribir tu nombre, email y una contraseña.\n\nTambién puedes registrarte con tu cuenta de Gmail, Facebook o Linkedin.",
                        },
                        {
                            title: '¿Cómo editar mi cuenta en Turingo.com?',
                            answer: "Para editar tu cuenta en Turingo, ingresa a tu sesión en turingo.com. A continuación haz click en 'Mi cuenta', ubicado en la esquina superior derecha.  Luego haz click sobre 'Mi perfil'. Una vez en tu perfil, presiona el botón 'Editar perfil'. En ese espacio podrás editar tu cargo, lugar actual, tu email, tu teléfono de contacto y tu sitio web entre otros. ",
                        },
                        {
                            title: '¿Por qué debo escribir mi nombre completo en mi perfil de Turingo?',
                            answer: 'Porque para poder entregarte tu certificado de curso completado con tu nombre escrito, necesitamos esa información de antemano. ',
                        },
                        {
                            title: '¿Cómo validar la dirección de correo electrónico asociada a mi cuenta en Turingo?',
                            answer: "Al registrarte en turingo.com te llegará un mail pidiéndote que valides tu cuenta. Sólo debes hacer click en el botón 'Validar cuenta'.",
                        },
                        {
                            title: '¿Cómo cambiar las preferencias de idiomas en Turingo?',
                            answer: "Para cambiar las preferencias de idioma debes hacer click en 'Mi cuenta' y luego ir a 'Ajustes'. Ahí podrás elegir si quieres que tu cuenta esté en español o inglés. ",
                        },
                        {
                            title: '¿Cómo cambiar la apariencia de Turingo? Dark/Light',
                            answer: "Para cambiar la apariencia de Turingo debes hacer click en 'Mi cuenta' y luego ir a 'Ajustes'",
                        },
                        {
                            title: 'Ingresé mal mis datos al registrarme ¿Los puedo modificar?',
                            answer: 'Sí. Puedes modificarlos ingresando a Mi cuenta→ Ajustes. Ahí podrás cambiar tu email de registro y contraseña.',
                        },
                        {
                            title: '¿Puedo cambiar el mail con el que me registré?',
                            answer: '¡Claro que sí! Puedes hacerlo en Mi cuenta→ Ajustes→ Cambiar mail de registro.',
                        },
                        {
                            title: '¿Mi mail de registro es el mismo que aparecerá en la sección "Contacto" de mi perfil?',
                            answer: 'Puedes tener el mismo o usar uno distinto. De todas maneras, deberás completar la información de tu perfil para que, en un futuro cercano, otras personas de la comunidad te puedan contactar.',
                        },
                        {
                            title: '¿Puedo acceder a mis certificados de cursos realizados en todo momento?',
                            answer: 'Sí, estarán siempre disponibles en Mi cuenta→ Perfil una vez que inicies sesión. Podrás descargarlos para compartirlos en redes sociales, o donde lo necesites.',
                        },
                        {
                            title: '¿Puedo eliminar mi cuenta de turingo.com?',
                            answer: 'Si así lo deseas, puedes hacerlo solicitándolo a través de nuestro formulario de contacto.',
                        },
                        {
                            title: '¿Cómo puedo recuperar o cambiar mi contraseña?',
                            answer: 'Si deseas cambiarla, sólo debes ir a Mi cuenta→ Ajustes→ Cambiar contraseña. Si la olvidaste, puedes dar click a "¿Olvidaste tu contraseña?", en esta misma sección, o bien cuando inicies sesión.',
                        },
                    ],
                },
                contactus: {
                    title: 'Contáctanos',
                    description: '¿Necesitas ayuda? Llena este formulario para contactarnos.',
                    name: {
                        label: '¿Cuál es tu nombre y apellido?',
                        placeholder: 'Juan Semilla',
                    },
                    email: {
                        label: '¿Cuál es tu email?',
                        placeholder: 'nombre@email.com',
                    },
                    subject: {
                        label: '¿Cuál es el motivo por el que nos contactas?',
                        placeholder: 'Intento cambiar mi email registrado pero no lo logro',
                    },
                    message: {
                        label: 'Cuéntanos más detalles',
                        placeholder: 'El sistema no guarda mi cambio de email y lo intenté varias veces. Necesito que ustedes lo hagan',
                    },
                    action: { title: 'Enviar mensaje' },
                },
            },
        },
        settings: {
            title: 'Ajustes',
            browserTitle: 'Ajustes',
            browserDescription: 'Ajustes de Cuenta',
            sections: {
                language: {
                    title: 'Idioma',
                    description: 'Selecciona el idioma predeterminado de tu cuenta.',
                    options: {
                        es: 'Español',
                        en: 'Inglés',
                        pt: 'Portugués',
                    },
                },
                theme: {
                    title: 'Apariencia',
                    description: 'Selecciona la apariencia predeterminada de tu cuenta.',
                    options: {
                        light: 'Modo claro',
                        dark: 'Modo oscuro',
                    },
                },
                notifications: {
                    title: 'Notificaciones',
                    activity: {
                        title: 'Nueva actividad',
                        sound: {
                            title: 'Sonido',
                            description: 'Reproduce un sonido cuando recibes un nuevo mensaje.',
                        },
                    },
                    email: {
                        title: 'Email',
                        alert: 'Las publicaciones destacadas se enviarán incluso si has desactivado la opción de email, debido a su importancia',
                        chat: {
                            title: 'Mensajes',
                            description: 'Recibe emails para cuando te envían mensajes.',
                        },
                        event: {
                            title: 'Eventos',
                            description: 'Recibe emails para cuando te invitan a eventos y recordatorios.',
                        },
                        digest: {
                            title: 'Novedades de la comunidad',
                            description: 'Recibe emails con las noticias más recientes de tu comunidad.',
                        },
                    },
                    direct_message: {
                        title: 'Mensajería instantánea',
                        whatsapp: {
                            help: 'Para recibir los mensajes, agrega tu número de teléfono desde tu perfil',
                            title: 'WhatsApp',
                            description: 'Recibe notificaciones de publicaciones destacadas.',
                        },
                    },
                },
                signup: {
                    title: 'Configuración de cuenta',
                    changeEmail: {
                        title: 'Email',
                        description: 'Email de registro',
                        help: 'No es visible a la comunidad y con él, puedes recuperar o hacer cambio de contraseña.',
                        action: 'Editar',
                        modal: {
                            title: 'Cambio de email',
                            description: 'Te enviaremos un correo a tu nueva dirección para verificarla.',
                            password: {
                                label: 'Para confirmar el cambio, ingresa tu contraseña',
                                placeholder: '•••••••••',
                            },
                            email: {
                                label: 'Escribe tu nuevo email',
                                placeholder: 'nombre@email.com',
                            },
                            actions: {
                                cancel: 'Cancelar',
                                accept: 'Cambiar',
                            },
                        },
                        modal_create: {
                            title: 'Cambio de email',
                            description: 'Escribe tu nuevo email y crea tu contraseña para poder iniciar sesión en el futuro. Te enviaremos un correo a tu nueva dirección para verificarla.',
                            password: {
                                label: 'Crea tu contraseña',
                                placeholder: '•••••••••',
                            },
                            email: {
                                label: 'Escribe tu nuevo email',
                                placeholder: 'nombre@email.com',
                            },
                            actions: {
                                cancel: 'Cancelar',
                                accept: 'Cambiar',
                            },
                        },
                        success: {
                            title: 'Te enviamos un email',
                            description: 'Revisa tu bandeja de entrada para verificar tu nueva dirección.',
                            action: 'OK',
                        },
                    },
                    changePassword: {
                        title: 'Contraseña',
                        description: 'Puedes editar tu contraseña si lo deseas',
                        description_not_setted: 'Aún no has creado una contraseña. Crea una ahora.',
                        action: 'Editar',
                        modal: {
                            title: 'Cambio de contraseña',
                            description: 'Ingresa primero tu contraseña actual y luego tu nueva contraseña.',
                            oldPassword: {
                                label: 'Escribe tu contraseña actual',
                                placeholder: '•••••••••',
                            },
                            newPassword: {
                                label: 'Escribe tu nueva contraseña',
                                placeholder: '•••••••••',
                            },
                            actions: {
                                cancel: 'Cancelar',
                                accept: 'Cambiar',
                            },
                        },
                        modal_create: {
                            title: 'Creación de contraseña',
                            description: 'Ingresa tu contraseña para poder iniciar sesión en el futuro.',
                            newPassword: {
                                label: 'Crea tu contraseña',
                                placeholder: '•••••••••',
                            },
                            actions: {
                                cancel: 'Cancelar',
                                accept: 'Crear contraseña',
                            },
                        },
                        success_create: {
                            title: 'Tu contraseña ha sido creada con éxito',
                            description: 'Desde ahora puedes iniciar sesión con tu email y contraseña.',
                            action: 'OK',
                        },
                        success: {
                            title: 'Contraseña modificada con éxito',
                            description: 'Ahora puedes acceder con tu nueva contraseña.',
                            action: 'OK',
                        },
                        error: {
                            insufficientChar: 'Debe tener al menos 5 caracteres',
                        },
                    },
                },
                accounts: {
                    title: 'Cuentas conectadas',
                    social: {
                        connected: 'Tu cuenta {0} está actualmente vinculada a tu cuenta de Turingo.',
                        disconnected: 'Puedes vincular tu cuenta {0} e iniciar sesión en Turingo.',
                    },
                    modals: {
                        added: {
                            success: {
                                title: 'Tu cuenta ha sido agregada con éxito',
                                description: 'Desde ahora puedes iniciar sesión con tu cuenta de {0}.',
                                action: 'OK',
                            },
                            warning: {
                                title: 'Esta cuenta ya está vinculada a otra cuenta Turingo.',
                                description: 'Verifica si tienes otra cuenta Turingo que esté vinculada con tu cuenta de {0}. Si el problema persiste, contáctanos a contacto@turingo.com.',
                                action: 'OK',
                            },
                        },
                    },
                    delete: {
                        title: 'Eliminar cuenta',
                        description: '¿Deseas eliminar tu cuenta?',
                        subdescriptionOne: 'Una vez eliminada, no se podrá recuperar y no tendrás acceso a los mensajes, contactos y espacios de la comunidad a la que perteneces.',
                        subdescriptionMany: 'Una vez eliminada, no se podrá recuperar y no tendrás acceso a los mensajes, contactos y espacios de las comunidades a la que perteneces.',
                        content: 'Si deseas continuar, se cerrará la sesión en todos tus dispositivos y perderás la información de tu cuenta.',
                        confirm: 'Confirmo que quiero eliminar mi cuenta',
                        keep: 'Mantener cuenta',
                    },
                    error: {
                        part1: 'Necesitas tener al menos una cuenta vinculada o puedes',
                        part2: 'crear una contraseña',
                        part3: 'para poder iniciar sesión en el futuro.',
                    },
                },
            },
        },
        profile: {
            title: 'Perfil',
            browserTitle: 'Perfil',
            browserDescription: 'Perfil de Turingo',
            shareButton: 'Compartir perfil',
            editButton: 'Editar',
            empty: 'Esta persona no ha completado sus datos',
            aboutMe: {
                title: 'Biografía',
                description_owner: 'Agrega una descripción sobre ti',
            },
            segments: {
                service: {
                    title: 'Servicios ofrecidos',
                    description_owner: 'Agrega los servicios que ofreces',
                },
                industry: {
                    title: 'Industria de experiencia',
                    description_owner: 'Agrega las industrias en las que has trabajado',
                },
                interest: {
                    title: 'Intereses',
                    description_owner: 'Agrega tus intereses',
                },
            },
            jobCard: {
                title: 'Formación y estado laboral',
                placeholder: 'Agrega los detalles de formación y estado laboral',
            },
            certificates: {
                title: 'Certificados',
                description_owner: 'Acá aparecerán tus certificados de cursos',
                description_empty: 'Esta persona no ha obtenido certificados',
            },
            contactCard: {
                title: 'Contacto',
                email: { title: 'Email', tooltip: 'Copiar email', message: 'El email ha sido copiado' },
                phone: { title: 'Teléfono', tooltip: 'Copiar teléfono', message: 'El teléfono ha sido copiado' },
                website: { title: 'Sitio web', tooltip: 'Abrir sitio web' },
                prompt: 'Agrega tus datos de contacto',
            },
            editModal: {
                title: 'Edita tu perfil',
                avatar: 'Edit profile picture',
                banner: 'Edit banner',
                name: {
                    label: 'Nombre y apellido',
                    placeholder: 'Claudia Urrutia',
                    prompt: 'Escribe tu nombre y apellido',
                },
                position: {
                    label: 'Cargo y empresa',
                    placeholder: 'Periodista en revista Emprendimiento',
                    prompt: 'Cuéntale a la comunidad dónde trabajas y a qué te dedicas',
                    validations: {
                        required: 'El cargo no puede quedar en blanco',
                        long: 'Tu cargo muy largo, debe tener a lo más 50 caracteres',
                        short: 'Tu cargo es muy corto, debe tener por lo menos 3 caracteres',
                    },
                },
                location: {
                    label: 'Lugar actual',
                    placeholder: 'Santiago, Chile',
                    prompt: '¿Dónde vives actualmente?',
                    validations: {
                        required: 'El lugar no puede quedar en blanco',
                    },
                },
                aboutMe: {
                    label: 'Sobre mí',
                    placeholder: 'Tengo 50 años, llevo más de 15 años liderando emprendimientos...',
                    prompt: 'Cuéntale quién eres a la comunidad',
                },
                email: {
                    label: 'Email público',
                    placeholder: 'tucuenta@email.com',
                    prompt: 'Ingresa tu email para que otras personas te puedan contactar',
                    help: 'Se mostrará a la comunidad como email de contacto.',
                    validations: {
                        required: 'El email público no puede quedar en blanco',
                    },
                },
                phone: {
                    help: 'Al agregar tu número de teléfono, recibirás notificaciones solo para las publicaciones destacadas en tu WhatsApp. Para desactivarlas, ajusta las notificaciones en tu cuenta.',
                    label: 'Teléfono de contacto',
                    placeholder: '+569 9 1234 567',
                    prompt: 'Comparte tu número de contacto con la comunidad',
                    validations: {
                        general: 'Tu teléfono de contacto no es válido',
                    },
                    description: 'Ocultar número a la comunidad',
                },
                website: {
                    label: 'Sitio web',
                    placeholder: 'www.tusitioweb.com',
                    prompt: 'Comparte el link a tu sitio web',
                    validations: {
                        short: 'El enlace es muy corto, debe tener por lo menos 5 caracteres',
                        long: 'El enlace es muy largo, debe tener a lo más 100 caracteres',
                        pattern: 'El enlace debe ser una url válida',
                    },
                },
                services: {
                    label: 'Servicios ofrecidos',
                    placeholder: 'Selecciona los servicios que ofreces',
                    validations: {
                        long: 'Solo puedes escoger hasta 3 servicios',
                    },
                },
                industries: {
                    label: 'Industria de experiencia ',
                    placeholder: 'Selecciona las industrias trabajadas',
                    validations: {
                        long: 'Solo puedes escoger hasta 3 industrias',
                    },
                },
                interests: {
                    label: 'Intereses',
                    placeholder: 'Selecciona tus intereses',
                    validations: {
                        long: 'Solo puedes escoger hasta 3 intereses',
                    },
                },
                private_account: 'Cuenta privada',
                actions: {
                    cancel: 'Cancelar',
                    accept: 'Guardar',
                },
            },
        },
        about_us: {
            title: 'Sobre Turingo',
            mision: {
                title: 'Misión',
                description: 'Descifrar los nuevos códigos de la comunicación y colaboración entre actores y grupos de interés del mundo empresarial en la vertiginosa era digital.',
            },
            vision: {
                title: 'Visión',
                description: 'Crear el primer ecosistema digital de evolución empresarial colaborativa de América Latina.',
            },
            purpose: {
                title: 'Propósito',
                description: 'Nuestro motor es el progreso de Latinoamérica. Promoviendo el acceso democratizado al ecosistema empresarial, de emprendimiento e innovación, mejorando su sostenibilidad e impacto en la economía y sociedad.',
            },
            history: {
                title: 'Nuestra historia',
                description: 'Turingo nace en octubre del 2018 con el propósito de que emprendedoras, emprendedores y líderes de pymes adquierieran nuevas herramientas y habilidades para lograr digitalizar sus negocios a través de cursos online y contenidos especialmente creados para conseguir este objetivo.\n\nHoy en día, Turingo está enfocado en crear comunidades digitales para desarrollar ecosistemas de evolución colaborativa.',
            },
            whyus: {
                title: '¿Qué es Turingo?',
                description: 'Turingo es un software as a service donde puedes crear y administrar una comunidad que conecte a tus grupos de interés, lo que permitirá influir positivamente en ellos para impulsar el crecimiento y el éxito de tu organización.\n\nTe aportamos nuestra tecnología y experiencia en comunidades para potenciar tu negocio, en un espacio seguro, medible y exclusivo para tus miembros, lo que ayuda a desarrollar la confianza y fomentar la colaboración.',
            },
            ourteam: {
                title: 'Nuestro equipo',
            },
            items: [
                {
                    title: 'Somos nómades digitales',
                    description: 'Trabajamos desde cualquier lugar del mundo, obteniendo siempre los mejores resultados, sin importar las distancias.',
                },
                {
                    title: 'Colaboración',
                    description: 'El trabajo en equipo es clave para aprender, innovar y despegar en la era digital, y lo aplicamos en nuestro día a día.',
                },
            ],
        },
        auth: {
            signup: {
                title: '¡Conviértete en Turinger!',
                browserTitle: 'Registro',
                browserDescription: '¡Regístrate gratis y conviértete en Turinger!',
                action: 'Regístrate gratis',
                mobileAction: 'Únete',
                social: {
                    facebook: 'Regístrate con Facebook',
                    google: 'Regístrate con Google',
                    linkedin: 'Regístrate con Linkedin',
                    zoom: 'Crea una cuenta',
                },
                goLogin: {
                    ask: '¿Ya tienes cuenta?',
                    action: 'Ingresa aquí',
                },
                error: {
                    fetch: 'Ocurrió un problema',
                    email: 'Ya existe una cuenta con ese email',
                    missingParams: 'Estos parámetros no son válidos, inténtalo de nuevo',
                },
                goBack: 'Volver',
                free: '¡Regístrate gratis y conviértete en Turinger!',
                leftPanel: {
                    onlineCourses: 'Cursos online',
                    onlineCoursesDesc: 'Aprende desde cualquier parte del mundo',
                    chat: 'Charlas',
                    chatDesc: 'Profundiza junto a profesionales destacados',
                    conceptSharing: 'Historias con Propósito',
                    conceptSharingDesc: 'Inspírate con historias de personas como tú',
                },
                policies: {
                    previousMessage: 'Al registrarte, aceptas las',
                    privacy: 'Políticas de privacidad',
                    and: 'y los',
                    conditions: 'Términos de condiciones.',
                },
                conflict_modal: {
                    title: 'Conflicto de cuenta',
                    description: 'Existe otra cuenta Turingo con el email {0}. ¿Deseas vincular ambas cuentas?',
                    subdescription: 'Te enviaremos un email de confirmación para verificar tus datos.',
                    action: 'Vincular cuentas',
                },
                conflict_modal_success: {
                    title: 'Te enviamos un email',
                    description: 'Revisa tu bandeja de entrada y sigue las instrucciones para vincular ambas cuentas.',
                },
            },
            login: {
                title: 'Inicio de sesión',
                browserTitle: 'Iniciar sesión',
                browserDescription: 'Iniciar sesión',
                action: 'Inicia sesión',
                mobileAction: 'Inicia sesión',
                social: {
                    facebook: 'Continuar con Facebook',
                    google: 'Continuar con Google',
                    linkedin: 'Continuar con Linkedin',
                    zoom: 'Inicia sesión',
                },
                goSignup: {
                    ask: '¿No tienes cuenta?',
                    action: 'Crea tu cuenta gratis',
                },
                forgotenPassword: '¿Olvidaste tu contraseña?',
                error: 'No existe una cuenta con esa combinación de email y contraseña.',
                authExpired: 'Tu sesión ha expirado',
                greetings: '¡Hola de nuevo Turinger!',
                goLogin: 'Inicia sesión',
                goBack: 'Volver',
            },
            logout: {
                title: 'Cierre de sesión',
                description: '¿Quieres cerrar tu sesión? Al hacerlo, te enviaremos a la pantalla de inicio.',
                actions: {
                    accept: 'Si, cerrar sesión',
                    cancel: 'No, seguir navegando',
                },
            },
            expiration: {
                title: 'Tu sesión en Turingo ha expirado',
                description: 'Por seguridad, necesitamos que vuelvas a iniciar sesión con tu cuenta Turingo.',
                actions: {
                    accept: 'Iniciar sesión',
                },
            },
            validation: {
                title: 'Valida tu cuenta',
                success: {
                    title: '¡Gracias por activar tu cuenta!',
                    description: 'Desde ahora, podrás acceder a cursos y charlas online, además de todos nuestros contenidos.',
                    action: 'Iniciar sesión',
                },
                error: {
                    title: 'Ha ocurrido un error',
                    description: 'Lo lamentamos, no hemos podido validar tu cuenta, por favor, intentalo más tarde. Si el problema persiste, contáctanos a contacto@turingo.com.',
                    action: 'Volver al inicio',
                },
            },
            verification: {
                title: 'Verificar tu cuenta',
                success: {
                    title: 'Tu cuenta ha sido verificada con éxito',
                    description: 'Ya está todo listo para que inicies sesión.',
                    action: 'Descubre los contenidos',
                },
                error: {
                    title: 'Ha ocurrido un error',
                    description: 'Lo lamentamos, no hemos podido verificar tu cuenta, por favor, intentalo más tarde. Si el problema persiste, contáctanos a contacto@turingo.com.',
                    action: 'Volver al inicio',
                },
            },
            recover: {
                notFound: 'No existe una cuenta con ese mail.',
                title: '¿Olvidaste tu contraseña?',
                description: 'Ingresa tu email para crear una nueva contraseña.',
                action: 'Enviar',
                success: {
                    title: 'Te enviamos un mail',
                    description: 'Revisa tu bandeja de entrada y sigue las instrucciones para crear una nueva contraseña.',
                    action: 'Volver al inicio',
                },
            },
            recover_confirm: {
                title: 'Crea tu nueva contraseña',
                description: 'Estas cerca de crear una nueva contraseña para tu cuenta',
                action: 'Crear contraseña',
                success: {
                    title: 'Tu contraseña ha sido cambiada con éxito',
                    description: 'Ya está todo listo para que puedas usar tu cuenta.',
                    action: 'Iniciar sesión',
                },
            },
            blocked: {
                title: 'Tu cuenta ha sido bloqueada temporalmente',
                description: 'Necesitas validar tu email para poder seguir usando tu cuenta Turingo.',
                actions: {
                    resendEmail: 'Validar email',
                    logout: 'Cerrar sesión',
                },
                success: {
                    title: 'Te enviamos un email',
                    description: 'Revisa tu bandeja de entrada para poder validar tu cuenta.',
                },
                error: {
                    title: 'Ha ocurrido un error',
                    description: 'Ha sucedido un error al reenviar el email de validación',
                },
            },
        },
        billing: {
            title: 'Compra',
            browserTitle: 'Carrito de compra',
            browserDescription: 'Compra en Turingo',
            stepper: {
                select: 'Selecciona',
                pay: 'Paga',
                enjoy: 'Disfruta',
            },
            payment: {
                title: 'Información de pago',
                input: {
                    email: {
                        tooltip: 'Necesitamos tu email para tener un medio de contacto ante cualquier consulta o problema con tu compra.',
                        label: '¿Cuál es tu email?',
                        placeholder: 'tucuenta@email.com',
                    },
                    country: {
                        label: '¿Cuál es tu país de compra?',
                        placeholder: 'Selecciona un país',
                        required: 'Debes seleccionar un país',
                    },
                },
            },
            dlocal: {
                info: 'Turingo nunca guardará tu información de pago.',
                pan: {
                    label: 'Número de tarjeta',
                    empty: 'Ingresa el número de tarjeta de crédito',
                },
                expiration: {
                    label: 'Fecha de expiración',
                    empty: 'Ingresa la fecha de expiración de la tarjeta',
                    invalid: 'La tarjeta ha expirado',
                    placeholder: 'MM/AA',
                },
                cvv: {
                    label: 'Código de seguridad',
                    empty: 'Ingresa el código de verificación (CVV)',
                },
                name: {
                    empty: 'Ingresa el nombre de la persona titular de la tarjeta',
                    format: 'Sólo puede incluir letras.',
                },
                dni: {
                    empty: 'Ingresa tu documento de identidad',
                    format: 'Ingresa un documento de identidad válido',
                },
                other: {
                    empty: 'Este campo es obligatorio',
                    format: 'El campo no tiene el formato correcto',
                },
            },
            paymentMethod: {
                title: 'Medio de pago',
                methods: {
                    paypal: 'Al utilizar PayPal Checkout, te enviaremos a los servidores seguros de PayPal. Los pagos de PayPal son realizados en USD ($) y el monto que ves en tu moneda local es un aproximado que puede variar de acuerdo a tu banco y tipo de cambio del día.',
                    dlocalgo: 'Al elegir este medio de pago, te enviaremos al proveedor de pagos seguros llamado DLocal, que ofrece una gran variedad de opciones de pago.',
                },
                more: '+{0} más',
            },
            purchaseDetail: {
                title: 'Detalle de compra',
                course: 'Curso',
                currency: {
                    USD: 'US$',
                },
                warning: {
                    description: {
                        title: 'Al realizar el pago aceptas los',
                        terms: 'Términos de condiciones',
                        split: 'y',
                        privacy: 'Políticas de privacidad',
                    },
                },
                payBadge: {
                    payWith: 'Pagar con',
                },
            },
            checkout: {
                gratefulness: '¡Gracias por tu compra!',
                order: 'Número de orden',
                emailConfirmation: 'Recibirás una confirmación de compra a tu email',
                goToCourse: 'Ir al curso',
                goLogin: 'Crear Cuenta',
                generalError: 'No se pudo completar la compra',
            },
        },
    },
    alerts: {
        rate: {
            create: {
                success: 'La valoración ha sido publicada',
                failure: 'Hubo un problema al publicar la valoración, inténtalo nuevamente',
            },
            edit: {
                success: 'La valoración ha sido editada',
                failure: 'Hubo un problema al editar la valoración, inténtalo nuevamente',
            },
            delete: {
                success: 'La valoración ha sido eliminada',
                failure: 'Hubo un problema al eliminar la valoración, inténtalo nuevamente',
            },
        },
        lesson: {
            create: {
                success: 'La lección ha sido creada',
                failure: 'Hubo un problema al crear la lección, inténtalo nuevamente',
            },
            edit: {
                success: 'La lección ha sido editada',
                failure: 'Hubo un problema al editar la lección, inténtalo nuevamente',
            },
            delete: {
                success: 'La lección ha sido eliminada',
                failure: 'Hubo un problema al eliminar la lección, inténtalo nuevamente',
            },
        },
        catalog_item: {
            create: {
                success: 'El ítem ha sido creado',
                failure: 'Ocurrió un problema al crear el ítem, intenta nuevamente',
            },
            edit: {
                success: 'El ítem ha sido editado',
                failure: 'Ocurrió un problema al editar el ítem, inténtalo nuevamente',
            },
            delete: {
                success: 'El ítem ha sido eliminado',
                failure: 'Ocurrió un problema al eliminar el ítem, intenta nuevamente',
            },
        },
        evaluation: {
            create: {
                success: 'El examen ha sido creado',
                failure: 'Ocurrió un problema al crear el examen, intenta nuevamente',
            },
            edit: {
                success: 'El examen ha sido editado',
                failure: 'Ocurrió un problema al editar el examen, inténtalo nuevamente',
            },
            delete: {
                success: 'El examen ha sido eliminado',
                failure: 'Ocurrió un problema al eliminar el examen, intenta nuevamente',
            },
        },
        room: {
            muted: {
                success: 'La conversación ha sido silenciada',
                failure: 'Hubo un problema al silenciar la conversación, inténtalo nuevamente',
            },
            unmuted: {
                success: 'La conversación ha dejado de estar silenciada',
                failure: 'Hubo un problema al dejar de silenciar la conversación, inténtalo nuevamente',
            },
        },
        certificate: {
            create: {
                success: 'El certificado ha sido creado',
                failure: 'Hubo un problema al publicar el certificado, inténtalo nuevamente',
            },
            edit: {
                success: 'El certificado ha sido editado',
                failure: 'Hubo un problema al editar el certificado, inténtalo nuevamente',
            },
            delete: {
                success: 'El certificado ha sido eliminado',
                failure: 'Hubo un problema al eliminar el certificado, inténtalo nuevamente',
            },
        },
        comment: {
            create: {
                success: 'El comentario ha sido publicado',
                failure: 'Hubo un problema al publicar el comentario, inténtalo nuevamente',
            },
            edit: {
                success: 'El comentario ha sido editado',
                failure: 'Hubo un problema al editar el comentario, inténtalo nuevamente',
            },
            delete: {
                success: 'El comentario ha sido eliminado',
                failure: 'Hubo un problema al eliminar el comentario, inténtalo nuevamente',
            },
        },
        event: {
            create: {
                success: 'El evento ha sido creado',
                failure: 'Hubo un problema al crear el evento, inténtalo nuevamente',
            },
            edit: {
                success: 'El evento ha sido editado',
                failure: 'Hubo un problema al editar el evento, inténtalo nuevamente',
            },
            delete: {
                success: 'El evento ha sido eliminado',
                failure: 'Hubo un problema al eliminar el evento, inténtalo nuevamente',
            },
        },
        board: {
            discussion: {
                create: {
                    success: 'El espacio ha sido creado',
                    failure: 'Hubo un problema al crear el espacio, inténtalo nuevamente',
                },
                edit: {
                    success: 'El espacio ha sido editado',
                    failure: 'Hubo un problema al editar el espacio, inténtalo nuevamente',
                },
                delete: {
                    success: 'El espacio ha sido eliminado',
                    failure: 'Hubo un problema al eliminar el espacio, inténtalo nuevamente',
                },
            },
            catalog: {
                create: {
                    success: 'El catálogo ha sido creado',
                    failure: 'Hubo un problema al crear el catálogo, inténtalo nuevamente',
                },
                edit: {
                    success: 'El catálogo ha sido editado',
                    failure: 'Hubo un problema al editar el catálogo, inténtalo nuevamente',
                },
                delete: {
                    success: 'El catálogo ha sido eliminado',
                    failure: 'Hubo un problema al eliminar el catálogo, inténtalo nuevamente',
                },
            },
            course: {
                create: {
                    success: 'El curso ha sido creado',
                    failure: 'Hubo un problema al crear el curso, inténtalo nuevamente',
                },
                edit: {
                    success: 'El curso ha sido editado',
                    failure: 'Hubo un problema al editar el curso, inténtalo nuevamente',
                },
                delete: {
                    success: 'El curso ha sido eliminado',
                    failure: 'Hubo un problema al eliminar el curso, inténtalo nuevamente',
                },
            },
            forum: {
                create: {
                    success: 'La charla ha sido creada',
                    failure: 'Hubo un problema al crear la charla, inténtalo nuevamente',
                },
                edit: {
                    success: 'La charla ha sido editada',
                    failure: 'Hubo un problema al editar la charla, inténtalo nuevamente',
                },
                delete: {
                    success: 'La charla ha sido eliminada',
                    failure: 'Hubo un problema al eliminar la charla, inténtalo nuevamente',
                },
            },
            story: {
                create: {
                    success: 'La historia ha sido creada',
                    failure: 'Hubo un problema al crear la historia, inténtalo nuevamente',
                },
                edit: {
                    success: 'La historia ha sido editada',
                    failure: 'Hubo un problema al editar la historia, inténtalo nuevamente',
                },
                delete: {
                    success: 'La historia ha sido eliminada',
                    failure: 'Hubo un problema al eliminar la historia, inténtalo nuevamente',
                },
            },
        },
        invitation: {
            create: {
                success: 'La invitación ha sido enviada',
                failure: 'Hubo un problema al enviar la invitación, inténtalo nuevamente',
            },
            edit: {
                success: 'La invitación ha sido editada',
                failure: 'Hubo un problema al editar la invitación, inténtalo nuevamente',
            },
            resend: {
                success: 'La invitación ha sido reenviada',
                failure: 'Hubo un problema al reenviar la invitación, inténtalo nuevamente',
                ['application.community.invitation.resend']: 'No es posible reenviar una invitación en menos de 24 horas. Por favor, inténtalo de nuevo después de ese período.',
            },
            delete: {
                success: 'La invitación ha sido anulada',
                failure: 'Hubo un problema al anular la invitación, inténtalo nuevamente',
            },
        },
        team: {
            create: {
                success: 'El equipo ha sido creado',
                failure: 'Hubo un problema al crear el equipo, inténtalo nuevamente',
            },
            edit: {
                success: 'El equipo ha sido editado',
                failure: 'Hubo un problema al editar el equipo, inténtalo nuevamente',
            },
            delete: {
                success: 'El equipo ha sido eliminado',
                failure: 'Hubo un problema al eliminar el equipo, inténtalo nuevamente',
            },
        },
        team_member: {
            create: {
                success: 'Se han agregado integrantes al equipo',
                failure: 'Hubo un problema al agregar el integrante al equipo, inténtalo nuevamente',
            },
            delete: {
                success: 'La persona ha sido removida del equipo',
                failure: 'Hubo un problema al remover a la persona del equipo, inténtalo nuevamente',
            },
        },
        community_member: {
            edit: {
                success: 'Los equipos han sido editados',
                failure: 'Hubo un problema al editar los equipos, inténtalo nuevamente',
            },
            delete: {
                success: 'La persona ha sido removida de la comunidad',
                failure: 'Hubo un problema al remover a la persona de la comunidad, inténtalo nuevamente',
            },
        },
        post: {
            create: {
                success: 'El contenido ha sido publicado',
                failure: 'Hubo un problema al publicar el contenido, inténtalo nuevamente',
            },
            edit: {
                success: 'La publicación ha sido editada',
                failure: 'Hubo un problema al editar la publicación, inténtalo nuevamente',
            },
            delete: {
                success: 'La publicación ha sido eliminada',
                failure: 'Hubo un problema al eliminar la publicación, inténtalo nuevamente',
            },
        },
        community: {
            edit: {
                success: 'La comunidad ha sido editada',
                failure: 'Hubo un problema al editar la comunidad, inténtalo nuevamente',
            },
        },
        user: {
            edit: {
                success: 'El perfil ha sido actualizado',
                failure: 'Hubo un problema al actualizar el perfil, inténtalo nuevamente',
            },
        },
        contact_us: {
            success: '¡Mensaje enviado!',
            failure: 'No pudimos enviar tu mensaje. Inténtalo nuevamente más tarde',
        },
    },
    forms: {
        report: 'Tu mensaje debe contener al menos 5 caracteres.',
        email: {
            label: 'Email',
            signup_label: '¿Cuál es tu email?',
            placeholder: 'nombre@email.com',
            validations: {
                required: 'El email no puede quedar en blanco',
                format: 'El email no es válido',
                short: 'El email es muy corto, debe tener por lo menos 5 caracteres',
                long: 'El email es muy largo, debe tener a lo más 50 caracteres',
            },
        },
        password: {
            label: 'Contraseña',
            signup_label: 'Crea tu contraseña',
            placeholder: '•••••••••',
            prompt: 'Debe tener 8 caracteres como mínimo',
            validations: {
                required: 'Ingresa una contraseña',
                format: 'Tu contraseña debe contener al menos una letra y un número',
                short: 'Tu contraseña es muy corta (debe tener más de 8 caracteres)',
                long: 'Tu contraseña es muy larga (debe tener a lo más de 20 caracteres)',
            },
        },
        name: {
            label: 'Nombre y Apellido',
            signup_label: '¿Cuál es tu nombre y apellido?',
            placeholder: 'Juan Manzana',
            validations: {
                required: 'El nombre no puede quedar en blanco',
                format: 'Incorpora un espacio entre el nombre y apellido',
                pattern: 'Sólo puede incluir letras.',
                short: 'Debe tener mínimo {0} caracteres',
                long: 'Debe tener máximo {0} caracteres',
            },
        },
    },
    imageUpload: {
        banner: {
            actions: {
                cancel: 'Cancelar',
                accept: 'Guardar',
            },
            validations: {
                type: 'Solo puedes subir archivos JPG o PNG',
            },
        },
        avatar: {
            actions: {
                cancel: 'Cancelar',
                accept: 'Guardar',
            },
            validations: {
                type: 'Solo puedes subir archivos JPG o PNG',
            },
        },
    },
    certificate: {
        preamblePerson: 'Certifica que',
        preambleCourse: 'participó y finalizó el curso',
        preambleTeacher: 'dictado por',
        preambleDate: 'finalizado en',
        preambleUrl: 'Verifica este certificado en',
    },
    system: {
        api_error: {
            title: 'Tuvimos un problema ',
            description: 'Estamos experimentando un problema en nuestro sitio y lo estamos solucionando. Por favor, danos un tiempo e inténtalo nuevamente.',
            action: 'Actualizar página',
        },
        access_error: {
            title: 'Contenido no disponible',
            description: 'Este contenido tiene acceso restringido',
            action: 'Ir a inicio',
        },
        not_found: {
            title: 'Contenido no encontrado',
            description: 'No es posible mostrar el contenido.',
            action: 'Volver',
        },
    },
};
export default strings;
