import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { Card } from 'antd';
import { useEffect } from 'react';
import { FooterModal } from './components/FooterModal';
import { InformationModalBody } from './information_modal/InformationModalBody';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Router } from '@Router';
import { setErrorAction } from '@state/actions';
import { useLocation } from 'react-use';
import { useIsMount } from '@hooks/useIsMount';
export const getRedirectCommunity = (communities) => {
    if (communities && communities.length > 0) {
        if (communities.find((c) => c.publicKey == 'turingo')) {
            return 'turingo';
        }
        else {
            return communities[0].publicKey;
        }
    }
    return 'turingo';
};
export const SystemError = (props) => {
    const breakpoints = useBreakpoints();
    const { error } = props;
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const auth_communities = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.currentEntity) === null || _b === void 0 ? void 0 : _b.communities; });
    const redirectCommunity = getRedirectCommunity(auth_communities);
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();
    const isMount = useIsMount();
    useEffect(() => {
        if (!isMount) {
            dispatch(setErrorAction());
        }
    }, [location.pathname]);
    const onAction = () => {
        dispatch(setErrorAction());
        history.push(Router.Path.community_home({ cm_pk: redirectCommunity }));
    };
    const onBack = () => {
        dispatch(setErrorAction());
        history.push(Router.Path.community_home({ cm_pk: redirectCommunity }));
    };
    if (error == 'access.notallowed') {
        if (isLogged) {
            return (_jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    marginTop: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 24,
                }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'warning', title: strings.system.access_error.title, description: strings.system.access_error.description }), _jsx(FooterModal, { primary: {
                                action: onAction,
                                customText: strings.system.access_error.action,
                            } })] }) }));
        }
        else {
            dispatch(setErrorAction());
            history.push(Router.Path.community_login({ cm_pk: redirectCommunity }), { next: location.pathname });
        }
    }
    if (error == 'application.post.notfound') {
        if (isLogged) {
            return (_jsx("div", { style: {
                    display: 'flex',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                    marginTop: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 24,
                }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'warning', title: strings.system.not_found.title, description: strings.system.not_found.description }), _jsx(FooterModal, { primary: {
                                action: onBack,
                                customText: strings.system.not_found.action,
                            } })] }) }));
        }
        else {
            dispatch(setErrorAction());
            history.push(Router.Path.community_login({ cm_pk: redirectCommunity }), { next: location.pathname });
        }
    }
    return (_jsx("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            marginTop: isMobile ? 16 : 24,
            marginBottom: isMobile ? 16 : 24,
        }, children: _jsxs(Card, { style: { height: 'fit-content', maxWidth: 572, width: '100%' }, children: [_jsx(InformationModalBody, { kind: 'error', title: strings.system.api_error.title, description: strings.system.api_error.description }), _jsx(FooterModal, { primary: {
                        action: onAction,
                        customText: strings.system.api_error.action,
                    } })] }) }));
};
