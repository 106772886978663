import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Switch } from 'antd';
import { Moon, Sun } from '@icon-park/react';
import { setCurrentTheme } from '@state/reducers/UserInterface/UserInterfaceReducer';
const SwitchTheme = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const dispatch = useAppDispatch();
    const onChange = (checked) => {
        dispatch(setCurrentTheme(checked ? 'light' : 'dark'));
    };
    return (_jsxs("div", { style: { display: 'flex' }, children: [_jsx(Moon, { size: "16", style: { lineHeight: 2.2, marginRight: 5 } }), _jsx(Switch, { defaultChecked: !isDark, onChange: onChange }), _jsx(Sun, { size: "16", style: { lineHeight: 2.2, marginLeft: 5 } })] }));
};
export { SwitchTheme };
