import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Typography } from 'antd';
import { Down, LocalTwo } from '@icon-park/react';
import dayjs from 'dayjs';
import { useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { google, outlook, yahoo, ics } from 'calendar-link';
import { useParams } from 'react-router';
import SVG from '@components/assets/SVG';
import GoogleCalendar from '@bundle/assets/icons/google.svg';
import iCalendar from '@bundle/assets/icons/apple.svg';
import Yahoo from '@bundle/assets/icons/yahoo.svg';
import Outlook from '@bundle/assets/icons/outlook.svg';
import { API } from '@api/API';
import useBreakpoints from '@hooks/useBreakpoints';
require('dayjs/locale/es');
require('dayjs/locale/en');
const EventDetails = (props) => {
    var _a, _b, _c;
    const { item, live, compact } = props;
    const { cm_pk, e_pk } = useParams();
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const event = {
        title: item.title,
        description: item.description + `\n\n${strings.screens.events.detail.moreDetails} ${window.location.href}`,
        start: item.dateStart,
        end: item.dateEnd,
        location: (_a = item.place) === null || _a === void 0 ? void 0 : _a.name,
        url: (_b = item.online) === null || _b === void 0 ? void 0 : _b.join_url,
    };
    const language = useAppSelector((state) => state.ui.language);
    dayjs.locale(language);
    const getGMT = (timezone) => {
        const number = timezone / 60;
        const sign = number > 0 ? '-' : '+';
        return `(GMT ${sign}${Math.abs(number)})`;
    };
    const onClick = (shareMethod) => {
        void API.analyticsAction({
            bodyParams: {
                action: 'event.save',
                sender: 'event.detail.card',
                shareMethod: shareMethod,
                referenceCommunity: cm_pk,
                referenceEvent: e_pk,
            },
        });
        switch (shareMethod) {
            case 'google':
                window.open(google(event), isMobile ? '_self' : '_blank');
                break;
            case 'outlook':
                window.open(outlook(event), isMobile ? '_self' : '_blank');
                break;
            case 'yahoo':
                window.open(yahoo(event), isMobile ? '_self' : '_blank');
                break;
            case 'local':
                window.open(ics(event), '_self');
                break;
            default:
                break;
        }
    };
    const items = [
        {
            label: 'Google calendar',
            key: '1',
            icon: _jsx(SVG, { style: { width: '14px', height: '14px', display: 'flex' }, content: GoogleCalendar }),
            onClick: () => {
                onClick('google');
            },
        },
        {
            label: 'Outlook calendar',
            key: '2',
            icon: _jsx(SVG, { style: { width: '14px', height: '14px', display: 'flex' }, content: Outlook }),
            onClick: () => {
                onClick('outlook');
            },
        },
        {
            label: 'Yahoo!',
            key: '3',
            icon: _jsx(SVG, { style: { width: '14px', height: '14px', display: 'flex' }, content: Yahoo }),
            onClick: () => {
                onClick('yahoo');
            },
        },
        {
            label: 'iCal',
            key: '4',
            icon: _jsx(SVG, { style: { width: '14px', height: '14px', display: 'flex' }, content: iCalendar }),
            onClick: () => {
                onClick('local');
            },
        },
    ];
    const menuProps = {
        items,
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 4 }, children: [_jsx(CalendarOutlined, { style: Object.assign({ marginRight: 8, alignSelf: 'center' }, (live && { color: 'var(--ant-primary-color)' })) }), !live && _jsx(Typography.Paragraph, { children: dayjs(item.dateStart).format(strings.general.date) }), live && _jsx(Typography.Paragraph, { style: { color: 'var(--ant-primary-color)' }, children: strings.screens.events.detail.rightNow })] }), _jsxs("div", { style: { display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 4 }, children: [_jsx(ClockCircleOutlined, { style: { marginRight: 8, alignSelf: 'center' } }), _jsxs(Typography.Paragraph, { children: [dayjs(item.dateStart).format('HH:mm'), " ", getGMT(new Date(item.dateStart).getTimezoneOffset())] })] }), _jsxs("div", { style: { display: 'flex', marginBottom: compact ? 0 : e_pk ? 16 : 16 }, children: [_jsx(LocalTwo, { style: { display: 'flex', alignItems: 'center', marginRight: 8 }, theme: "outline", size: "14" }), _jsxs(Typography.Paragraph, { children: [item.kind == 'presential' ? (_c = item.place) === null || _c === void 0 ? void 0 : _c.name : 'Online', " "] })] }), e_pk && !compact && (_jsx("div", { children: _jsx(Dropdown, { menu: menuProps, trigger: ['click'], children: _jsxs(Button, { block: true, style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, children: [_jsx(CalendarOutlined, { style: { marginRight: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' } }), _jsx(Space, { children: strings.screens.events.detail.addtoCalendar }), _jsx(Down, { style: { marginLeft: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' } })] }) }) }))] }));
};
export { EventDetails };
