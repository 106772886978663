import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import useBreakpoints from '@hooks/useBreakpoints';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { capitalizeFirstLetter } from '@util/string/Functions';
const EntityName = (props) => {
    const { visibility, name, teams } = props;
    const breakpoints = useBreakpoints();
    const teamWithBadge = teams === null || teams === void 0 ? void 0 : teams.find((t) => t.badge);
    return (_jsxs("div", { style: { display: 'flex', marginBottom: 8, alignItems: 'center', padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }, children: [visibility == 'private' && _jsx(LockOutlined, { style: { fontSize: 16, marginRight: 8 } }), visibility == 'public' && _jsx(UnlockOutlined, { style: { fontSize: 16, marginRight: 8 } }), _jsx(Title, { level: 4, style: { marginBottom: 0 }, children: name }), teamWithBadge && teamWithBadge.badge.title && (_jsx(TuringoTag, { kind: "tag", color: "neutral", size: "small", style: { marginLeft: 8 }, children: capitalizeFirstLetter(teamWithBadge.badge.title) }, teamWithBadge.badge.title))] }));
};
export { EntityName };
