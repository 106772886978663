import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { Down } from '@icon-park/react';
import style from './MaskButton.scss';
import { PostContext } from '../../Post';
const MaskButton = (props) => {
    var _a, _b;
    const { onClick } = props;
    const { mask } = useContext(PostContext);
    return (_jsxs("div", { className: style.mask_button, onClick: onClick, children: [_jsx(AvatarTuringo, { size: 16, style: { height: 32, width: 32, marginRight: 8, lineHeight: '32px', minWidth: 32 }, name: (_a = mask === null || mask === void 0 ? void 0 : mask.get) === null || _a === void 0 ? void 0 : _a.name, avatar: (_b = mask === null || mask === void 0 ? void 0 : mask.get) === null || _b === void 0 ? void 0 : _b.avatarUrl }), _jsx(Down, { style: { display: 'flex' }, theme: "outline", size: "16", strokeLinejoin: "bevel" })] }));
};
export { MaskButton };
