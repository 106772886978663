import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import Meta from 'react-meta-tags';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Card, Divider } from 'antd';
import strings from '@resources/localization';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { TuringoList } from '@components/list/TuringoList';
import { Router } from '@Router';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
export const Debug = (props) => {
    const { cm_pk } = useParams();
    const history = useHistory();
    const notifications = useContext(NotificationContext);
    const breakpoints = useBreakpoints();
    const [permission, setPermission] = useState(Notification.permission);
    function askForNPerm() {
        void Notification.requestPermission(function (result) {
            console.log('User choice', result);
            if (result !== 'granted') {
                console.log('No notification permission granted!');
            }
            else {
                setPermission('granted');
                void subscribeUserToPush();
            }
        });
    }
    console.log(permission);
    function subscribeUserToPush() {
    }
    function send() {
    }
    return (_jsx(ContentLayout, { left: _jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.settings.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.settings.browserDescription })] }), _jsxs(Card, { bordered: false, style: { width: '100%', height: '100%' }, bodyStyle: { padding: 0 }, children: [_jsx("div", { style: { padding: '16px 24px 0px 24px' }, children: _jsx(Paragraph, { strong: true, className: "turingo-text", style: { fontSize: breakpoints.isMobile ? 18 : 16 }, children: 'Debug' }) }), _jsx(Divider, {}), _jsx(Paragraph, { strong: true, className: "turingo-text", style: { fontSize: breakpoints.isMobile ? 18 : 16, marginLeft: 24, marginBottom: 16 }, children: 'Emails' }), _jsx(TuringoList, { loading: false, items: [
                                { publicKey: 'digest', name: 'Digest' },
                                { publicKey: 'event', name: 'Event' },
                                { publicKey: 'chat', name: 'Messages' },
                            ], onClick: (value) => history.push(Router.Path.debug_detail({ cm_pk, debug_section: value })) }), _jsxs("div", { style: { padding: '16px 24px 0px 24px' }, children: [permission != 'granted' && _jsx(Button, { onClick: askForNPerm, children: "Pedir permisos" }), permission == 'granted' && _jsx(Button, { onClick: send, children: "Enviar notificacion" })] })] })] }) }));
};
