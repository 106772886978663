import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button, Skeleton, Typography } from 'antd';
import dayjs from 'dayjs';
import { LinkUnderline } from '@components/buttons/link_underline/LinkUnderline';
import { AvatarTuringo } from '@components/avatar/Avatar';
import { useAppSelector } from '@hooks/useStore';
import { Router } from '@Router';
import { Colors } from '@util/color/Colors';
import { DownOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import Paragraph from 'antd/lib/typography/Paragraph';
import { TuringoTag } from '@components/content/tag/TuringoTag';
import { capitalizeFirstLetter } from '@util/string/Functions';
import { PostContext } from '../Post';
import { useParams } from 'react-router';
export const PostAuthor = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { author, postKey, date, size = 'medium', type = 'post', creation, board } = props;
    const language = useAppSelector((state) => state.ui.language);
    const postContext = useContext(PostContext);
    const { isFeed } = postContext;
    const { cm_pk, p_pk: pr_pk } = useParams();
    let { b_pk } = postContext;
    const badges = (_a = author === null || author === void 0 ? void 0 : author.teams) === null || _a === void 0 ? void 0 : _a.map((t) => t.badge);
    const breakpoints = useBreakpoints();
    if (isFeed)
        b_pk = ((_b = creation === null || creation === void 0 ? void 0 : creation.boardSelected) === null || _b === void 0 ? void 0 : _b.publicKey) ? (_c = creation === null || creation === void 0 ? void 0 : creation.boardSelected) === null || _c === void 0 ? void 0 : _c.publicKey : 'b_pk';
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const overrideJob = (_e = (_d = community === null || community === void 0 ? void 0 : community.item) === null || _d === void 0 ? void 0 : _d.memberProperties) === null || _e === void 0 ? void 0 : _e.find((p) => p.override == 'profile:job');
    return (_jsxs("div", { style: { display: 'flex', alignItems: !date && !postKey ? 'center' : 'start' }, children: [_jsx(AvatarTuringo, { size: size == 'medium' ? 24 : 16, style: {
                    height: size == 'medium' ? 48 : 40,
                    width: size == 'medium' ? 48 : 40,
                    lineHeight: size == 'medium' ? '48px' : '40px',
                    marginRight: breakpoints.isMobile ? 16 : 8,
                }, name: author.isDeleted ? 'Cuenta eliminada' : author === null || author === void 0 ? void 0 : author.name, avatar: author === null || author === void 0 ? void 0 : author.avatarUrl }), _jsxs("div", { style: { lineHeight: '16px', maxWidth: breakpoints.isMobile ? 'calc(100vw - 130px)' : 300 }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', marginBottom: creation ? 4 : 0 }, children: [_jsx(LinkUnderline, { href: !author.isDeleted && Router.Path.user_details({ u_pk: author.publicKey, cm_pk }), style: { color: Colors('NEUTRAL_13'), maxWidth: breakpoints.isMobile ? 'calc(100vw - 130px)' : 300 }, children: _jsx(Paragraph, { className: 'turingo-text bold', style: {
                                        lineHeight: '18px',
                                        wordBreak: 'break-word',
                                    }, ellipsis: { rows: 1 }, children: author.isDeleted ? 'Cuenta eliminada' : author.name }) }), badges &&
                                badges.map((b) => {
                                    if (b && b.title) {
                                        return (_jsxs(TuringoTag, { style: { marginLeft: 8 }, kind: "tag", color: "neutral", size: "small", children: [capitalizeFirstLetter(b.title), ' '] }, b.title));
                                    }
                                })] }), postKey && !overrideJob && (_jsx(Paragraph, { className: 'turingo-subtext', style: {
                            lineHeight: '16px',
                            wordBreak: 'break-word',
                        }, ellipsis: { rows: 1 }, children: (_f = author.profile) === null || _f === void 0 ? void 0 : _f.job })), postKey && overrideJob && ((_g = author === null || author === void 0 ? void 0 : author.memberProperties) === null || _g === void 0 ? void 0 : _g[overrideJob.id]) && (_jsxs(Paragraph, { className: 'turingo-subtext', style: {
                            lineHeight: '16px',
                            wordBreak: 'break-word',
                        }, ellipsis: { rows: 1 }, children: [overrideJob.prefix, " ", (_h = author === null || author === void 0 ? void 0 : author.memberProperties) === null || _h === void 0 ? void 0 : _h[overrideJob.id], " ", overrideJob.suffix] })), (creation === null || creation === void 0 ? void 0 : creation.loading) && _jsx(Skeleton, { title: false, active: true, paragraph: { rows: 1 }, style: { marginBottom: 0 } }), !postKey && isFeed && (creation === null || creation === void 0 ? void 0 : creation.onBoard) && !creation.isEdit && !creation.loading && (_jsx(Button, { style: {
                            fontSize: breakpoints.isMobile ? 14 : 12,
                            height: 24,
                            lineHeight: '12px',
                            padding: '1px 8px 1px 0px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            borderRadius: 4,
                            alignItems: 'center',
                        }, onClick: creation.onBoard, icon: _jsx(DownOutlined, { style: {
                                marginLeft: 4,
                                fontSize: 10,
                                display: 'flex',
                            } }), children: !((_j = creation.boardSelected) === null || _j === void 0 ? void 0 : _j.publicKey) ? strings.screens.community.publicIn : (_k = creation.boardSelected) === null || _k === void 0 ? void 0 : _k.name })), !postKey && (!(creation === null || creation === void 0 ? void 0 : creation.onBoard) || !isFeed || (creation === null || creation === void 0 ? void 0 : creation.isEdit)) && !creation.loading && (_jsx("div", { style: {
                            fontSize: 14,
                        }, children: `${strings.screens.community.publicIn} ${(_l = creation.boardSelected) === null || _l === void 0 ? void 0 : _l.name}` })), date && type == 'post' && postKey && !pr_pk && (_jsx(LinkUnderline, { type: 'secondary', href: Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey }), style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }, children: board && strings.formatString(strings.screens.community.postedIn, board.name, dayjs(date).locale(language).fromNow()) })), date && type == 'post' && !isFeed && pr_pk && (_jsx(Typography.Text, { type: 'secondary', style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }, children: dayjs(date).locale(language).fromNow() })), date && type == 'post' && !isFeed && !pr_pk && (_jsx(LinkUnderline, { type: 'secondary', href: Router.Path.community_post_details({ cm_pk, b_pk: board ? board.publicKey : b_pk, p_pk: postKey }), style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }, children: dayjs(date).locale(language).fromNow() })), date && type != 'post' && (_jsx("div", { style: { fontSize: breakpoints.isMobile ? 14 : 12, lineHeight: '16px', color: Colors('ICON') }, children: dayjs(date).locale(language).fromNow() }))] })] }));
};
