import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Checkbox } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { userDelete } from '@state/actions';
import { InformationModal } from './information_modal/InformationModal';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import strings from '@resources/localization';
export const DeleteAccountModal = () => {
    const emailModal = useAppSelector((state) => state.modal.delete_account);
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const communities = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.currentEntity) === null || _b === void 0 ? void 0 : _b.communities; });
    const dispatch = useAppDispatch();
    const history = useHistory();
    const closeEmailModal = () => {
        setConfirmDelete(false);
        dispatch(modalAction({ modal: 'delete_account', state: false }));
    };
    const changeComplete = () => {
        history.push(Router.Path.community_exit({ cm_pk: currentCommunity }), { exit: true });
        closeEmailModal();
    };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const onSubmit = () => {
        void dispatch(userDelete({
            options: {
                then: {
                    action: changeComplete,
                },
            },
        }));
    };
    const onChangeMessage = (e) => {
        setConfirmDelete(e.target.checked);
    };
    return (_jsx(_Fragment, { children: _jsxs(InformationModal, { kind: 'warning', open: emailModal, onClick: onSubmit, onCancel: closeEmailModal, onClickDisabled: !confirmDelete, onCancelText: strings.screens.settings.sections.accounts.delete.keep, onClickText: strings.general.delete, title: strings.screens.settings.sections.accounts.delete.title, description: strings.screens.settings.sections.accounts.delete.description, subdescription: (communities === null || communities === void 0 ? void 0 : communities.length) > 1
                ? strings.screens.settings.sections.accounts.delete.subdescriptionMany
                : strings.screens.settings.sections.accounts.delete.subdescriptionOne, children: [_jsx(Paragraph, { className: "turingo-text", style: { marginBottom: 16 }, children: strings.screens.settings.sections.accounts.delete.content }), _jsx(Checkbox, { style: { marginBottom: 10 }, value: confirmDelete, onChange: onChangeMessage, children: strings.screens.settings.sections.accounts.delete.confirm })] }) }));
};
