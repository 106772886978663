var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { Alert, Button, Form, Input, Select, Tooltip } from 'antd';
import strings from '@resources/localization';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { CreatePostContext } from '../../PostCreateProvider';
const { Option } = Select;
const PollScreen = (props) => {
    const { pollForm, setScreen, form } = useContext(CreatePostContext);
    const onFinish = (values) => {
        form.setFieldValue(['form', 'turingo', 'kind'], 'poll');
        form.setFieldValue(['form', 'turingo', 'poll'], Object.assign(Object.assign({}, values), { choices: values.choices.map((choice, index) => {
                return { choiceId: index, answer: choice };
            }) }));
        setScreen('creation');
    };
    return (_jsx("div", { children: _jsxs(Form, { id: "pollForm", form: pollForm, layout: "vertical", initialValues: {
                question: '',
                choices: ['', ''],
                dateFinish: 'week',
            }, onFinish: onFinish, children: [_jsx(Form.Item, { name: ['question'], label: strings.screens.boards.discussion.post.kind.poll.form.question.title, rules: [{ required: true, message: strings.screens.boards.discussion.post.kind.poll.form.question.validations.required }], children: _jsx(Input, { placeholder: strings.screens.boards.discussion.post.kind.poll.form.question.placeholder }) }), _jsx(Form.List, { name: ['choices'], rules: [
                        {
                            validator: (_, names) => __awaiter(void 0, void 0, void 0, function* () {
                                if (!names || names.length < 2) {
                                    return Promise.reject(new Error('At least 2 options'));
                                }
                            }),
                        },
                    ], children: (fields, { add, remove }, { errors }) => (_jsxs(_Fragment, { children: [fields.map((field, index) => (_jsxs(Form.Item, { className: "pollForm", label: strings.screens.boards.discussion.post.kind.poll.form.option[index].title, required: index > 1 ? false : true, children: [_jsx(Form.Item, Object.assign({}, field, { validateTrigger: ['onChange', 'onBlur'], rules: [
                                            {
                                                required: true,
                                                message: strings.screens.boards.discussion.post.kind.poll.form.option_validations.required,
                                            },
                                        ], style: { flexGrow: 1 }, noStyle: true, children: _jsx(Input, { placeholder: strings.screens.boards.discussion.post.kind.poll.form.option[index].placeholder, showCount: true, maxLength: 30 }) })), index > 1 ? (_jsx(Tooltip, { title: strings.general.delete, children: _jsx(Button, { style: { marginLeft: 16 }, shape: "circle", icon: _jsx(DeleteOutlined, {}), onClick: () => remove(field.name) }) })) : null, index == fields.length - 1 && fields.length != 4 && (_jsx(Button, { style: { marginLeft: 16 }, shape: "circle", onClick: () => add(), icon: _jsx(PlusOutlined, {}) }))] }, field.key))), _jsx(Form.Item, { children: _jsx(Form.ErrorList, { errors: errors }) })] })) }), _jsx(Form.Item, { name: ['dateFinish'], label: strings.screens.boards.discussion.post.kind.poll.form.duration.title, rules: [{ required: true, message: 'Ingresa una duracion' }], children: _jsxs(Select, { defaultValue: "week", children: [_jsx(Option, { value: "day", children: strings.screens.boards.discussion.post.kind.poll.form.duration.options.day }), _jsx(Option, { value: "3day", children: strings.screens.boards.discussion.post.kind.poll.form.duration.options['3day'] }), _jsx(Option, { value: "week", children: strings.screens.boards.discussion.post.kind.poll.form.duration.options.week }), _jsx(Option, { value: "2week", children: strings.screens.boards.discussion.post.kind.poll.form.duration.options['2week'] })] }) }), _jsx(Alert, { className: "blue", type: "info", message: strings.screens.boards.discussion.post.kind.poll.form.description })] }) }));
};
export { PollScreen };
