import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useAppSelector } from '@hooks/useStore';
import { Redirect, useLocation } from 'react-router';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Alert, Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import { LoginForm } from './LoginForm';
import { Social } from '@components/content/social/Social';
import { Router } from '@Router';
import useBreakpoints from '@hooks/useBreakpoints';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import { API } from '@api/API';
import { getRedirectCommunity } from '@components/modals/SystemError';
const Login = () => {
    var _a, _b, _c, _d, _e;
    const loginStrings = strings.screens.auth.login;
    const location = useLocation();
    const isLogged = useAppSelector((state) => !!state.auth.authToken);
    const auth_communities = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.currentEntity) === null || _b === void 0 ? void 0 : _b.communities; });
    const redirectCommunity = getRedirectCommunity(auth_communities);
    const authState = useAppSelector((state) => state.auth.authState);
    const breakpoints = useBreakpoints();
    const isSmall = breakpoints.isSmall;
    const isMobile = breakpoints.isMobile;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const community_next = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.cm_pk;
    const board_next = (_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.b_pk;
    const post_next = (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.p_pk;
    const kind_next = (_d = location === null || location === void 0 ? void 0 : location.state) === null || _d === void 0 ? void 0 : _d.kind;
    const next = (_e = location === null || location === void 0 ? void 0 : location.state) === null || _e === void 0 ? void 0 : _e.next;
    if (isLogged) {
        return (_jsx(Redirect, { to: post_next
                ? Router.Path[`${kind_next}_post_details`]({ cm_pk: community_next, b_pk: board_next, p_pk: post_next })
                : kind_next
                    ? `/${kind_next}?page=2`
                    : next
                        ? next
                        : `/${redirectCommunity}` }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: loginStrings.browserTitle }), _jsx("meta", { name: "description", content: loginStrings.browserDescription })] }), authState === 'unauth.expired' ? (_jsx(Row, { justify: "center", align: "middle", children: _jsx(Alert, { message: loginStrings.authExpired, type: "warning", showIcon: true, closable: true }) })) : undefined, _jsx(Row, { justify: "center", align: "middle", style: { minHeight: '80vh' }, children: _jsx(Col, { xs: 24, md: 18, lg: 12, style: { maxWidth: 600, marginTop: 24, marginBottom: 24 }, children: _jsxs(Card, { bodyStyle: { padding: isSmall ? 16 : 24 }, children: [_jsx(GoBackButton, { type: "default", showText: false, style: { marginBottom: 24 } }), _jsxs("div", { style: { maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }, children: [_jsxs(Paragraph, { style: {
                                                textAlign: 'center',
                                                fontWeight: 500,
                                                fontSize: isMobile ? 24 : 30,
                                                lineHeight: isMobile ? '32px' : '38px',
                                                marginBottom: 40,
                                            }, children: [loginStrings.greetings, _jsx("br", {}), loginStrings.goLogin] }), _jsx(Social, { kind: "login" }), _jsx(Divider, { plain: true, children: strings.general.email_option }), _jsx(LoginForm, {}), _jsx(Divider, { style: { margin: '16px 0' } }), _jsxs("div", { style: { width: '100%', textAlign: 'center' }, children: [loginStrings.goSignup.action != '' && (_jsxs(_Fragment, { children: [_jsx("span", { style: { marginRight: 3 }, children: loginStrings.goSignup.ask }), _jsx(Link, { onClick: () => {
                                                                void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: 'main.login' } });
                                                            }, to: { pathname: Router.Path.signup({}), state: location.state }, children: loginStrings.goSignup.action })] })), loginStrings.goSignup.action == '' && (_jsx(_Fragment, { children: _jsx(Link, { onClick: () => {
                                                            void API.analyticsAction({ bodyParams: { action: 'btn.signup', sender: 'main.login' } });
                                                        }, to: { pathname: Router.Path.signup({}), state: location.state }, children: loginStrings.goSignup.ask }) }))] })] })] }) }) })] }));
    }
};
export { Login };
