import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import strings from '@resources/localization';
import { Alert, Card, Row, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
const SegmentCard = (props) => {
    if (!props.items) {
        return null;
    }
    const { u_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const isLoggedUser = (currentEntity === null || currentEntity === void 0 ? void 0 : currentEntity.publicKey) == u_pk;
    const color = useAppSelector((state) => state.ui.color);
    const { items, kind } = props;
    return (_jsxs(Card, { bordered: false, title: strings.screens.profile.segments[kind].title, children: [items.length == 0 && isLoggedUser && (_jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.segments[kind].description_owner })), items.length == 0 && !isLoggedUser && currentEntity && _jsx(Text, { style: { color: 'var(--secondary-text)' }, children: strings.screens.profile.empty }), !currentEntity && _jsx(Alert, { message: 'Conoce a otras personas emprendedoras ¡Inicia sesión en Turingo!' }), items.length > 0 && (_jsx(Row, { gutter: [16, 16], style: { paddingLeft: 8 }, children: items.map((item) => (_jsx(Tag, { children: item.name }, `entity-sector-${item.publicKey}`))) }))] }));
};
export { SegmentCard };
