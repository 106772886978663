import { jsx as _jsx } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
import Style from './TuringoTag.scss';
const TagColor = {
    cyan: {
        color: 'var(--cyan-6)',
        borderColor: 'var(--cyan-6)',
        backgroundColor: 'var(--cyan-1)',
    },
    green: {
        color: 'var(--polar-green-6)',
        borderColor: 'var(--polar-green-6)',
        backgroundColor: 'var(--polar-green-1)',
    },
    red: {
        color: 'var(--dust-red-6)',
        borderColor: 'var(--dust-red-6)',
        backgroundColor: 'var(--dust-red-1)',
    },
    orange: {
        color: 'var(--sunset-orange-6)',
        borderColor: 'var(--sunset-orange-6)',
        backgroundColor: 'var(--sunset-orange-1)',
    },
    purple: {
        color: 'var(--purple-7)',
        borderColor: 'var(--purple-7)',
        backgroundColor: 'var(--purple-1)',
    },
    neutral: {
        color: 'var(--text)',
        borderColor: 'var(--text)',
        backgroundColor: 'var(--hover)',
    },
    neutral_2: {
        color: 'var(--text)',
        borderColor: 'var(--neutral-5)',
        backgroundColor: 'transparent',
    },
};
const TuringoTag = (props) => {
    const { kind, size = 'medium', color, onClick, disabled, selected, children, style } = props;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    if (kind == 'button') {
        return (_jsx("div", { onClick: onClick, className: disabled ? Style.itemDisabled : selected ? Style.itemSelected : Style.item, style: Object.assign({}, style), children: children }));
    }
    if (kind == 'tag') {
        return (_jsx("div", { id: props.id, style: Object.assign({ display: 'inline-block', color: `${TagColor[color].color}`, background: `${TagColor[color].backgroundColor}`, border: `1px solid ${TagColor[color].borderColor}`, borderRadius: '100px', cursor: 'default', padding: size == 'small' ? '0px 8px' : '4px 8px', fontSize: isMobile ? 16 : 14, lineHeight: !isMobile ? '20px' : '22px' }, style), children: props.children }));
    }
    else if (kind == 'topic') {
        return (_jsx("div", { id: props.id, style: Object.assign({ display: 'inline-block', color: isDark ? '#F0F0F0' : 'rgba(0, 0, 0, 0.85)', background: 'transparent', border: '1px solid var(--tag-border)', borderRadius: '100px', cursor: 'default', padding: '4px 8px', fontSize: !isMobile ? 12 : 14, lineHeight: !isMobile ? '18px' : '20px' }, style), children: props.children }));
    }
};
export { TuringoTag };
