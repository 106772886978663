import { jsx as _jsx } from "react/jsx-runtime";
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import strings from '@resources/localization';
import { postDelete } from '@state/actions';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
export const DeleteModal = (props) => {
    const { cm_pk, b_pk, p_pk } = useParams();
    const { open, onCancel, target, parents = [] } = props;
    const dispatch = useAppDispatch();
    const scope = p_pk ? 'detail' : 'all';
    const isFeed = !b_pk;
    const isPost = parents.length == 0;
    const isComment = parents.length == 1;
    const isReply = parents.length == 1;
    const commentListState = useAppSelector((state) => {
        var _a, _b, _c, _d, _e, _f, _g;
        return isPost
            ? (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.listState
            : isComment
                ? (_d = (_c = (_b = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _b === void 0 ? void 0 : _b.items[parents[0]]) === null || _c === void 0 ? void 0 : _c.childrens) === null || _d === void 0 ? void 0 : _d.listState
                : (_g = (_f = (_e = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _e === void 0 ? void 0 : _e.items[parents[0]]) === null || _f === void 0 ? void 0 : _f.childrens) === null || _g === void 0 ? void 0 : _g.items[parents[1]].childrens.listState;
    });
    const onDelete = () => {
        void dispatch(postDelete({
            urlParams: { cm_pk, b_pk: target.boardPk, target_pk: target.publicKey },
            extraParams: {
                parents: parents,
                scope: scope,
                feed: isFeed,
            },
            options: {
                alerts: {
                    success: isComment && !isPost ? strings.alerts.comment.delete.success : strings.alerts.post.delete.success,
                    error: isComment && !isPost ? strings.alerts.comment.delete.failure : strings.alerts.post.delete.failure,
                },
                then: {
                    action: onCancel,
                },
            },
        }));
    };
    return (_jsx(TuringoModal, { onCancel: onCancel, open: open, title: null, width: 572, footer: [
            _jsx(FooterModal, { primary: {
                    action: onDelete,
                    customText: strings.general.delete,
                    loading: commentListState === null || commentListState === void 0 ? void 0 : commentListState.loading,
                }, secondary: {
                    action: onCancel,
                    customText: strings.general.cancel,
                } }, 'footer_modal'),
        ], children: _jsx(InformationModalBody, { kind: 'warning', title: isComment && !isPost ? strings.comments.delete.title : strings.screens.boards.discussion.post.delete.title, description: isComment && !isPost ? strings.comments.delete.description : strings.screens.boards.discussion.post.delete.description }) }));
};
