import { jsx as _jsx } from "react/jsx-runtime";
import strings from '@resources/localization';
import { trim } from '@util/string/Functions';
import { Form, Input } from 'antd';
import { FormContext } from 'antd/es/form/context';
import { useContext } from 'react';
export const FormNameItem = (props) => {
    const { name, label, validations, style, className, placeholder, showCount, maxLength, customErrors, optionalSurname = false, supportNumbers = false, autoUpperCase = true, } = props;
    const regex = /^[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ][a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]*(([a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]* | \s{1})[a-zA-ZñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]+)*$/;
    const regexNumbers = /^[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ][a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]*(([a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]* | \s{1})[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ]+)*$/;
    const { form } = useContext(FormContext);
    const changeHandler = (e) => {
        if (Array.isArray(name)) {
            const users = form.getFieldValue('users');
            if (users && Array.isArray(users) && users.length) {
                if (users[name[0]][name[1]]) {
                    users[name[0]][name[1]] = toTitleCase(e.target.value);
                    form.setFieldsValue({ names: users });
                }
            }
        }
        else {
            form.setFieldValue(name, toTitleCase(e.target.value));
        }
    };
    const toTitleCase = (phrase) => {
        return phrase
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (_jsx(Form.Item, { style: style, className: className, name: name, label: label, rules: [
            ...((validations === null || validations === void 0 ? void 0 : validations.includes('required'))
                ? [
                    {
                        required: true,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.required) || strings.forms.name.validations.required,
                    },
                ]
                : []),
            ...((validations === null || validations === void 0 ? void 0 : validations.includes('format'))
                ? [
                    {
                        validator: (rule, value) => {
                            const newValue = trim(value);
                            try {
                                if (supportNumbers) {
                                    if (!regexNumbers.test(newValue)) {
                                        throw new Error((customErrors === null || customErrors === void 0 ? void 0 : customErrors.pattern) || strings.forms.name.validations.pattern);
                                    }
                                }
                                else {
                                    if (!regex.test(newValue)) {
                                        throw new Error((customErrors === null || customErrors === void 0 ? void 0 : customErrors.pattern) || strings.forms.name.validations.pattern);
                                    }
                                }
                                if (!optionalSurname && newValue.split(' ').length < 2) {
                                    throw new Error((customErrors === null || customErrors === void 0 ? void 0 : customErrors.format) || strings.forms.name.validations.format);
                                }
                                return Promise.resolve();
                            }
                            catch (err) {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if ((value === null || value === void 0 ? void 0 : value.length) != 0) {
                                    return Promise.reject(err);
                                }
                                else {
                                    return Promise.resolve();
                                }
                            }
                        },
                    },
                ]
                : []),
            ...((validations === null || validations === void 0 ? void 0 : validations.includes('long'))
                ? [
                    {
                        max: 50,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.long) || strings.formatString(strings.forms.name.validations.long, 50),
                    },
                ]
                : []),
            ...((validations === null || validations === void 0 ? void 0 : validations.includes('short'))
                ? [
                    {
                        min: 5,
                        message: (customErrors === null || customErrors === void 0 ? void 0 : customErrors.short) || strings.formatString(strings.forms.name.validations.short, 5),
                    },
                ]
                : []),
        ], children: _jsx(Input, { showCount: showCount, maxLength: maxLength, name: "name", autoComplete: "off", placeholder: placeholder, onChange: autoUpperCase ? changeHandler : undefined }) }));
};
