import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Meta from 'react-meta-tags';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { Card } from 'antd';
import strings from '@resources/localization';
import { ContentLayout } from '@components/content/screens/content_layout/ContentLayout';
import { LanguageScreen } from './sections/LanguageScreen';
import { AppearanceScreen } from './sections/AppearanceScreen';
import { AccountScreen } from './sections/AccountScreen';
import { NotificationsScreen } from './sections/NotificationsScreen';
import { dynamicTabName } from '@util/string/Functions';
import { NotificationContext } from 'src/SocketComponent';
export const SettingsDetail = (props) => {
    const { cm_pk, setting_section } = useParams();
    const notifications = useContext(NotificationContext);
    return (_jsx(ContentLayout, { left: _jsxs(_Fragment, { children: [_jsxs(Meta, { children: [_jsx("title", { children: dynamicTabName(strings.screens.settings.browserTitle, notifications) }), _jsx("meta", { name: "description", content: strings.screens.settings.browserDescription })] }), _jsxs(Card, { bordered: false, style: { width: '100%', height: '100%' }, bodyStyle: { padding: 0 }, children: [setting_section == 'language' && _jsx(LanguageScreen, {}), setting_section == 'appearance' && _jsx(AppearanceScreen, {}), setting_section == 'account' && _jsx(AccountScreen, {}), setting_section == 'notifications' && _jsx(NotificationsScreen, {})] })] }) }));
};
