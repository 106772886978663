import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppSelector } from '@hooks/useStore';
import { Post, PostContext } from '../Post';
import { useParams } from 'react-router';
const ListPostItem = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { cm_pk, b_pk, p_pk } = useParams();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const [currentMask, setCurrentMask] = useState(currentEntity);
    const boardAccessCommunity = (_b = (_a = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.boards; })) === null || _a === void 0 ? void 0 : _a.find((board) => board.publicKey == props.item.boardPk)) === null || _b === void 0 ? void 0 : _b.access;
    const boardAccessDetail = useAppSelector((state) => { var _a, _b, _c; return (_c = (_b = (_a = state.community.items[cm_pk]) === null || _a === void 0 ? void 0 : _a.boards) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c[props.item.boardPk]; });
    const isAdmin = ((_c = boardAccessCommunity === null || boardAccessCommunity === void 0 ? void 0 : boardAccessCommunity.posts) === null || _c === void 0 ? void 0 : _c.includes('edit')) || ((_f = (_e = (_d = boardAccessDetail === null || boardAccessDetail === void 0 ? void 0 : boardAccessDetail.item) === null || _d === void 0 ? void 0 : _d.access) === null || _e === void 0 ? void 0 : _e.posts) === null || _f === void 0 ? void 0 : _f.includes('edit'));
    const isFeed = !b_pk;
    const scope = p_pk ? 'detail' : 'all';
    return (_jsx(PostContext.Provider, { value: {
            mask: { get: currentMask, set: setCurrentMask },
            post: props.item,
            isAdmin,
            cm_pk: cm_pk,
            b_pk: (_g = props.item) === null || _g === void 0 ? void 0 : _g.boardPk,
            p_pk: (_h = props.item) === null || _h === void 0 ? void 0 : _h.publicKey,
            isFeed: isFeed,
            scope: scope,
        }, children: _jsx(Post, {}) }));
};
export { ListPostItem };
