import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef } from 'react';
import { Alert, Form } from 'antd';
import { TuringoEditor } from '@components/form/Editor/TuringoEditor';
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider';
import useBreakpoints from '@hooks/useBreakpoints';
import { useParams } from 'react-router';
import strings from '@resources/localization';
import { CrossPostingContext } from '../../CreationScreen';
const PostEditor = (props) => {
    const { form, boards, changeText } = useContext(CreatePostContext);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const { consumer } = useContext(CrossPostingContext);
    const content = Form.useWatch(['form', consumer, 'content'], form);
    const mentions = Form.useWatch(['form', consumer, 'mentions'], form);
    const kind = Form.useWatch(['form', consumer, 'kind'], form);
    const board = Form.useWatch(['form', consumer, 'board'], form);
    const { b_pk } = useParams();
    const isFeed = !b_pk;
    const { topicsHeight, onFocus } = props;
    const childRef = useRef();
    useEffect(() => {
        var _a;
        if (!isMobile) {
            (_a = childRef === null || childRef === void 0 ? void 0 : childRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    if (content == undefined || mentions == undefined)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsx(TuringoEditor, { kind: "post", minHeight: kind == 'basic' ? (mentions && mentions.length > 0 && isFeed ? (isMobile ? `calc(100dvh - 412px - ${topicsHeight}px)` : 112) : 172) : 70, maxLength: consumer == 'X' ? 280 : consumer == 'linkedin' ? 3000 : undefined, showCount: consumer != 'turingo', ref: childRef, hasMentions: consumer == 'turingo', initialMentions: mentions, onMentionsChange: (value) => form.setFieldValue(['form', 'turingo', 'mentions'], value), onError: (value) => form.setFieldValue(['form', consumer, 'error'], value), value: content, placeholder: strings.screens.boards.discussion.post.create.placeholder, bordered: false, boardSelected: board, onFocus: onFocus, onChange: (value) => {
                    form.setFieldValue(['form', consumer, 'content'], value);
                    if (consumer != 'turingo') {
                        changeText.set(true);
                    }
                    if (consumer == 'turingo' && !changeText.get) {
                        form.setFieldValue(['form', 'linkedin', 'content'], value);
                        form.setFieldValue(['form', 'X', 'content'], value);
                    }
                } }), mentions && mentions.length > 0 && isFeed && boards.length > 1 && (_jsx(Alert, { style: { marginTop: 16 }, className: 'blue', message: 'Si etiquetas a personas que no son parte del espacio seleccionado, no serán notificadas.', type: "info", showIcon: true, closable: true }))] }));
};
export { PostEditor };
