import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Row, Divider, Switch, Alert, Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import strings from '@resources/localization';
import ImageLogoFacebook from '@bundle/assets/social/small/logo-facebook.svg';
import ImageLogoGoogle from '@bundle/assets/social/small/logo-google.svg';
import ImageLogoLinkedin from '@bundle/assets/social/small/logo-linkedin.svg';
import useBreakpoints from '@hooks/useBreakpoints';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { authAccounts, authAddAccount, authRemoveAccount } from '@state/actions';
import { capitalizeFirstLetter } from '@util/string/Functions';
import SVG from '@components/assets/SVG';
import { InformationModal } from '@components/modals/information_modal/InformationModal';
import { FacebookLogin, GoogleLogin, linkedInLogin } from '@components/content/social/Social';
import { Check, Close } from '@icon-park/react';
import { useHistory } from 'react-router';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import { Router } from '@Router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SettingItem } from '@components/content/settings/SettingItem';
const AccountScreen = () => {
    const history = useHistory();
    const authEmail = useAppSelector((state) => state.auth.authEmail);
    const settingStrings = strings.screens.settings;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    const openPasswordModal = () => {
        dispatch(modalAction({ modal: 'password', state: true }));
    };
    const openDeleteAccountModal = () => {
        dispatch(modalAction({ modal: 'delete_account', state: true }));
    };
    const openPasswordCreateModal = () => {
        dispatch(modalAction({ modal: 'password_create', state: true }));
    };
    const openEmailModal = () => {
        dispatch(modalAction({ modal: 'email', state: true }));
    };
    const openEmailCreateModal = () => {
        dispatch(modalAction({ modal: 'email_create', state: true }));
    };
    const accounts = useAppSelector((state) => state.auth.connected_accounts.data);
    useEffect(() => {
        void dispatch(authAccounts({}));
    }, []);
    const [googleState, setGoogleState] = useState(false);
    const [googleStateLoading, setGoogleStateLoading] = useState(false);
    const [facebookState, setFacebookState] = useState(false);
    const [facebookStateLoading, setFacebookStateLoading] = useState(false);
    const [linkedinState, setLinkedinState] = useState(false);
    const [linkedinStateLoading, setLinkedinStateLoading] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    useEffect(() => {
        setGoogleState(accounts === null || accounts === void 0 ? void 0 : accounts.social.google);
        setFacebookState(accounts === null || accounts === void 0 ? void 0 : accounts.social.facebook);
        setLinkedinState(accounts === null || accounts === void 0 ? void 0 : accounts.social.linkedin);
    }, [accounts]);
    const [errorAdded, setErrorAdded] = useState(false);
    const [successAdded, setSuccessAdded] = useState(false);
    const [providerAdded, setProviderAdded] = useState('google');
    const [showAlert, setShowAlert] = useState(false);
    const onCloseAlert = () => {
        setShowAlert(false);
    };
    const onAddedError = (provider, showModal) => {
        if (provider == 'facebook') {
            setFacebookState(false);
            setFacebookStateLoading(false);
        }
        else if (provider == 'google') {
            setGoogleState(false);
            setGoogleStateLoading(false);
        }
        else if (provider == 'linkedin') {
            setLinkedinState(false);
            setLinkedinStateLoading(false);
        }
        if (showModal) {
            setErrorAdded(true);
        }
    };
    const onAddedSuccess = (provider) => {
        if (provider == 'facebook') {
            setFacebookStateLoading(false);
        }
        else if (provider == 'google') {
            setGoogleStateLoading(false);
        }
        else if (provider == 'linkedin') {
            setLinkedinStateLoading(false);
        }
        setProviderAdded(provider);
        setSuccessAdded(true);
    };
    const onRemoveSuccess = (provider) => {
        if (provider == 'facebook') {
            setFacebookStateLoading(false);
        }
        else if (provider == 'google') {
            setGoogleStateLoading(false);
        }
        else if (provider == 'linkedin') {
            setLinkedinStateLoading(false);
        }
    };
    const onSuccessLinkedIn = (code) => {
        void dispatch(authAddAccount({
            urlParams: { provider: 'linkedin' },
            bodyParams: { token: code, redirectUri: `${window.location.origin}/linkedin` },
            options: {
                then: {
                    error: () => onAddedError('linkedin', true),
                    action: () => onAddedSuccess('linkedin'),
                },
            },
        }));
    };
    const onSuccessFacebook = (code) => {
        void dispatch(authAddAccount({
            urlParams: { provider: 'facebook' },
            bodyParams: {
                token: code,
                redirectUri: `${window.location.origin}/facebook`,
            },
            options: {
                then: {
                    error: () => onAddedError('facebook', true),
                    action: () => onAddedSuccess('facebook'),
                },
            },
        }));
    };
    const onSuccessGoogle = (code) => {
        void dispatch(authAddAccount({
            urlParams: { provider: 'google' },
            bodyParams: {
                token: code,
                redirectUri: `${window.location.origin}/google`,
            },
            options: {
                then: {
                    error: () => onAddedError('google', true),
                    action: () => onAddedSuccess('google'),
                },
            },
        }));
    };
    const onDisconnect = (provider) => {
        if (canEdit) {
            if (provider == 'facebook') {
                setFacebookState(false);
                setFacebookStateLoading(true);
            }
            else if (provider == 'google') {
                setGoogleState(false);
                setGoogleStateLoading(true);
            }
            else if (provider == 'linkedin') {
                setLinkedinState(false);
                setLinkedinStateLoading(true);
            }
            void dispatch(authRemoveAccount({
                urlParams: { provider: provider },
                options: {
                    then: {
                        action: () => onRemoveSuccess(provider),
                    },
                },
            }));
        }
        else {
            window.scrollTo(0, document.body.scrollHeight);
            setShowAlert(true);
        }
    };
    const linkedIn = linkedInLogin(onSuccessLinkedIn, () => onAddedError('linkedin'));
    const google = GoogleLogin(onSuccessGoogle, () => onAddedError('google'));
    const facebook = FacebookLogin(onSuccessFacebook, () => onAddedError('facebook'));
    const onClick = (provider) => {
        if (provider == 'facebook') {
            facebook();
            setFacebookState(true);
            setFacebookStateLoading(true);
        }
        else if (provider == 'google') {
            google();
            setGoogleState(true);
            setGoogleStateLoading(true);
        }
        else if (provider == 'linkedin') {
            linkedIn();
            setLinkedinState(true);
            setLinkedinStateLoading(true);
        }
    };
    useEffect(() => {
        let count = 0;
        if (linkedinState)
            count += 1;
        if (googleState)
            count += 1;
        if (facebookState)
            count += 1;
        if (accounts === null || accounts === void 0 ? void 0 : accounts.password)
            count += 1;
        setCanEdit(count >= 2);
    }, [accounts, linkedinState, googleState, facebookState]);
    return (_jsxs("div", { className: "lateral-padding", style: { paddingBottom: 16 }, children: [_jsx(InformationModal, { kind: 'success', open: successAdded, onClick: () => setSuccessAdded(false), title: settingStrings.sections.accounts.modals.added.success.title, description: strings.formatString(settingStrings.sections.accounts.modals.added.success.description, capitalizeFirstLetter(providerAdded)) }), _jsx(InformationModal, { kind: 'warning', open: errorAdded, onClick: () => setErrorAdded(false), title: settingStrings.sections.accounts.modals.added.warning.title, description: strings.formatString(settingStrings.sections.accounts.modals.added.warning.description, capitalizeFirstLetter(providerAdded)) }), _jsxs("div", { style: { marginTop: 16, display: 'flex', alignItems: 'center' }, children: [_jsx(GoBackButton, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }, type: "default", showText: false, onClick: () => history.push(Router.Path.settings({})) }), _jsx(Paragraph, { strong: true, className: "turingo-text", children: strings.screens.settings.sections.signup.title })] }), _jsx(Divider, {}), _jsx(SettingItem, { gap: 2, buttonAlign: "start", description: authEmail, title: _jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(Paragraph, { strong: true, children: strings.screens.settings.sections.signup.changeEmail.description }), _jsx(Tooltip, { placement: "right", title: strings.screens.settings.sections.signup.changeEmail.help, children: _jsx(InfoCircleOutlined, { onClick: (event) => event.stopPropagation(), style: { fontSize: breakpoints.isMobile ? 16 : 14, marginLeft: 4 } }) })] }), action: [
                    {
                        title: strings.screens.settings.sections.signup.changeEmail.action,
                        btnType: 'default',
                        onClick: (accounts === null || accounts === void 0 ? void 0 : accounts.password) ? openEmailModal : openEmailCreateModal,
                    },
                ] }), _jsx(Divider, {}), _jsx(SettingItem, { gap: 2, buttonAlign: "start", description: (accounts === null || accounts === void 0 ? void 0 : accounts.password)
                    ? strings.screens.settings.sections.signup.changePassword.description
                    : strings.screens.settings.sections.signup.changePassword.description_not_setted, title: strings.screens.settings.sections.signup.changePassword.title, action: [
                    {
                        title: (accounts === null || accounts === void 0 ? void 0 : accounts.password) ? strings.screens.settings.sections.signup.changePassword.action : strings.general.create,
                        btnType: 'default',
                        onClick: (accounts === null || accounts === void 0 ? void 0 : accounts.password) ? openPasswordModal : openPasswordCreateModal,
                    },
                ] }), _jsx(Divider, {}), _jsx(Paragraph, { style: { marginBottom: 16 }, strong: true, children: strings.screens.settings.sections.accounts.title }), accounts && (_jsxs("div", { style: Object.assign({}, (!isMobile && { paddingLeft: 24 })), children: [_jsxs(Row, { justify: 'space-between', style: { marginBottom: 2 }, children: [_jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(SVG, { style: { display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }, content: ImageLogoLinkedin }), _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: "LinkedIn" })] }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: linkedinState, loading: linkedinStateLoading, onClick: linkedinState ? () => onDisconnect('linkedin') : () => onClick('linkedin') })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: linkedinState && !linkedinStateLoading
                                ? strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'LinkedIn')
                                : strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'LinkedIn') }) }), !isMobile && _jsx(Divider, {}), _jsxs(Row, { justify: 'space-between', style: { marginBottom: 2 }, children: [_jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(SVG, { style: { display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }, content: ImageLogoGoogle }), _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: "Google" })] }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: googleState, loading: googleStateLoading, onClick: googleState ? () => onDisconnect('google') : () => onClick('google') })] }), _jsx(Row, { style: { marginBottom: isMobile ? 32 : 0 }, children: _jsx("p", { children: googleState && !googleStateLoading
                                ? strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'Google')
                                : strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'Google') }) }), !isMobile && _jsx(Divider, {}), _jsxs(Row, { justify: 'space-between', style: { marginBottom: 2 }, children: [_jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx(SVG, { style: { display: 'flex', width: isMobile ? 16 : 14, height: isMobile ? 16 : 14, marginRight: 4 }, content: ImageLogoFacebook }), _jsx("p", { style: { fontWeight: 500, marginBottom: 0, alignSelf: 'center' }, children: "Facebook" })] }), _jsx(Switch, { checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }), checked: facebookState, loading: facebookStateLoading, onClick: facebookState ? () => onDisconnect('facebook') : () => onClick('facebook') })] }), _jsx(Row, { children: _jsx("p", { children: facebookState && !facebookStateLoading
                                ? strings.formatString(strings.screens.settings.sections.accounts.social.connected, 'Facebook')
                                : strings.formatString(strings.screens.settings.sections.accounts.social.disconnected, 'Facebook') }) }), showAlert && !canEdit && (_jsx(Alert, { message: _jsxs("span", { children: [strings.screens.settings.sections.accounts.error.part1, ' ', _jsx("a", { onClick: openPasswordCreateModal, children: strings.screens.settings.sections.accounts.error.part2 }), ' ', strings.screens.settings.sections.accounts.error.part3] }), style: { marginTop: 10 }, type: "error", closable: true, onClose: onCloseAlert }))] })), _jsx(Divider, {}), _jsx(SettingItem, { gap: 2, buttonAlign: "start", title: _jsx(Paragraph, { style: { marginBottom: 0, fontWeight: 500 }, children: strings.screens.settings.sections.accounts.delete.title }), action: [
                    {
                        title: strings.general.delete,
                        btnType: 'default',
                        onClick: openDeleteAccountModal,
                    },
                ] })] }));
};
export { AccountScreen };
