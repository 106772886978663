import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Form, Spin } from 'antd';
import useEntityKind from '@hooks/useEntityKind';
import { boardProgress } from '@state/actions';
import strings from '@resources/localization';
import { ReviewForm } from '@components/content/posts/post_subtypes/reviews/components/ReviewForm';
import { CourseProgress } from './CourseProgress';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { useParams } from 'react-router';
import { FooterModal } from '@components/modals/components/FooterModal';
const CompleteCourseModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { open, onCancel } = props;
    const { cm_pk, b_pk } = useParams();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState(undefined);
    const ratesState = useAppSelector((state) => { var _a; return (_a = state.community.items[cm_pk].boards.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.rates.createState; });
    const community = useAppSelector((state) => state.community.items[cm_pk]);
    const e_kind = useEntityKind();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const thenAction = (response) => {
        setLoading(false);
        setResponse(response.data);
    };
    const getCertificate = () => {
        if (open) {
            void dispatch(boardProgress({
                urlParams: {
                    cm_pk,
                    b_pk,
                },
                options: {
                    then: {
                        action: thenAction,
                    },
                },
            }));
        }
    };
    useMemo(getCertificate, [open]);
    return (_jsxs(TuringoModal, { width: 572, title: loading
            ? strings.general.loading
            : response && (response.certificate == 'needed:completion' || response.certificate == 'notstarted')
                ? null
                : strings.formatString((_a = strings.screens.boards[e_kind]) === null || _a === void 0 ? void 0 : _a.rates.title, ((_f = (_e = (_d = (_c = (_b = community === null || community === void 0 ? void 0 : community.item) === null || _b === void 0 ? void 0 : _b.ui) === null || _c === void 0 ? void 0 : _c.rename) === null || _d === void 0 ? void 0 : _d[e_kind]) === null || _e === void 0 ? void 0 : _e.single) === null || _f === void 0 ? void 0 : _f.toLowerCase()) || strings.general.course.singular.toLowerCase()), open: open, onCancel: onCancel, footer: !loading && response && response.certificate != 'needed:completion' && response.certificate != 'notstarted'
            ? [
                _jsx(FooterModal, { primary: {
                        action: form.submit,
                        loading: ratesState === null || ratesState === void 0 ? void 0 : ratesState.loading,
                        customText: strings.general.send,
                    } }, 'footer_modal'),
            ]
            : null, bodyStyle: { textAlign: loading ? 'center' : 'start' }, children: [loading && _jsx(Spin, {}), !loading &&
                response &&
                (response.certificate == 'needed:completion' || response.certificate == 'notstarted' ? (_jsx(CourseProgress, { progress: response.sequence, title: (_g = strings.screens.boards[e_kind]) === null || _g === void 0 ? void 0 : _g.rates.progress.title, description: (_h = strings.screens.boards[e_kind]) === null || _h === void 0 ? void 0 : _h.rates.progress.description })) : (_jsx(ReviewForm, { type: 'create', form: form, onFinish: onCancel, cm_pk: cm_pk, b_pk: b_pk })))] }));
};
export { CompleteCourseModal };
