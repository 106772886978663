import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Radio, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { setCurrentLanguage } from '@state/reducers/UserInterface/UserInterfaceReducer';
import strings from '@resources/localization';
import useBreakpoints from '@hooks/useBreakpoints';
import { API } from '@api/API';
export const LanguageSelector = () => {
    const language = useAppSelector((state) => state.ui.language);
    const setLanguage = (language) => {
        strings.setLanguage(language);
        dispatch(setCurrentLanguage(language));
        void API.analyticsSetting({ bodyParams: { language: language } });
    };
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const dispatch = useAppDispatch();
    return (_jsx("div", { children: _jsx(Radio.Group, { style: Object.assign({}, (isMobile && { width: '100%' })), onChange: (e) => setLanguage(e.target.value), value: language, className: 'turingo-radio-list', children: _jsxs(Space, { direction: "vertical", size: 0, children: [_jsx(Radio, { value: "es", children: strings.screens.settings.sections.language.options.es }), _jsx(Divider, {}), _jsx(Radio, { value: "en", children: strings.screens.settings.sections.language.options.en })] }) }) }));
};
