import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Input } from 'antd';
import ReactPhoneInput from '@lib/react-phone-input-2';
import GoogleLibPhoneNumber from '@lib/google-libphonenumber';
import 'react-phone-input-2/lib/high-res.css';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import { useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { useEffect, useState } from 'react';
export const FormPhoneItem = (props) => {
    const { form, name, defaultValue, label } = props;
    const language = useAppSelector((state) => state.ui.language);
    const [hasError, sethasError] = useState(false);
    const [countryPhone, setCountryPhone] = useState('');
    const [phone, setPhone] = useState(defaultValue ? defaultValue : '');
    useEffect(() => {
        form.setFieldValue(name, phone);
        void form.validateFields();
    }, [phone]);
    const getLanguage = () => {
        if (language === 'es')
            return es;
        if (language === 'pt')
            return pt;
        return undefined;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { style: { marginBottom: 0 }, label: label || strings.screens.profile.editModal.phone.label, children: _jsx(ReactPhoneInput, { containerClass: hasError ? 'phoneError' : '', masks: { cl: '. .... ....' }, country: 'cl', isValid: (value, country) => {
                        setCountryPhone(country.iso2);
                        return true;
                    }, localization: getLanguage(), value: phone, onChange: (value, country) => {
                        setCountryPhone(country.countryCode);
                        setPhone(value);
                    } }) }), _jsx(GoogleLibPhoneNumber, { children: (phoneUtil) => (_jsx(Form.Item, { name: name, validateTrigger: ['onChange'], rules: [
                        {
                            validator: (rule, value) => {
                                try {
                                    if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, countryPhone), countryPhone)) {
                                        sethasError(true);
                                        throw new Error(strings.screens.profile.editModal.phone.validations.general);
                                    }
                                    sethasError(false);
                                    return Promise.resolve();
                                }
                                catch (err) {
                                    if (value.length != 0) {
                                        return Promise.reject(err);
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                }
                            },
                        },
                    ], children: _jsx(Input, { hidden: true }) })) })] }));
};
