var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { PreviewCard } from '@components/content/posts/components/preview_card/PreviewCard';
import { Button, Form } from 'antd';
import { Close } from '@icon-park/react';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { debounce } from 'lodash';
import { API } from '@api/API';
import { CreatePostContext } from '@components/content/posts/post_modal/PostCreateProvider';
const linkify = linkifyIt().tlds(tlds);
export const PreviewLink = (props) => {
    const { form, screen, setScreen } = useContext(CreatePostContext);
    const content = Form.useWatch(['form', 'turingo', 'content'], form);
    const kind = Form.useWatch(['form', 'turingo', 'kind'], form);
    const links = Form.useWatch(['form', 'turingo', 'links'], form);
    const [previewData, setPreviewData] = useState();
    const [urls, setUrls] = useState([]);
    const fetchLink = (link) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const preview_text = yield API.linkPreview({ searchParams: { url: encodeURI(link) } });
            setPreviewData(preview_text.data);
            form.setFieldValue(['form', 'turingo', 'kind'], 'preview');
            form.setFieldValue(['form', 'turingo', 'links'], [preview_text.data.id]);
        }
        catch (error) {
            form.setFieldValue(['form', 'turingo', 'kind'], 'basic');
            form.setFieldValue(['form', 'turingo', 'links'], undefined);
            setPreviewData(undefined);
        }
    });
    useEffect(() => {
        const links = content ? linkify.match(content) : undefined;
        const isChange = (urls === null || urls === void 0 ? void 0 : urls.length) > 0
            ? links &&
                links.length > 0 &&
                (links[links.length - 1].url != urls[urls.length - 1].url ||
                    links[links.length - 1].url != (previewData === null || previewData === void 0 ? void 0 : previewData.url))
            : links && links.length > 0;
        if (isChange) {
            debounce(function () {
                if (kind == 'basic' || kind == 'preview') {
                    setUrls(links);
                    void fetchLink(links[links.length - 1].url);
                }
            }, 2000)();
        }
    }, [content]);
    if (!previewData || kind != 'preview') {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs("div", { style: { position: 'relative', marginTop: 16 }, children: [_jsx(Button, { onClick: () => form.setFieldValue(['form', 'turingo', 'kind'], 'basic'), shape: "circle", icon: _jsx(Close, { style: { display: 'flex', justifyContent: 'center' } }), style: { position: 'absolute', zIndex: 100, right: 8, top: 8 } }), _jsx(PreviewCard, { data: previewData, isClickeable: false })] }));
    }
};
