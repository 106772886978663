var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { API } from '@api/API';
import { store } from '@state/persistance/Storage';
import { lastPage } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { Route, Redirect, useHistory } from 'react-router';
import queryString from 'query-string';
import { Router } from '@Router';
import { createContext } from 'react';
const ANALYTICS = true;
export const turingoRoutes = ['billing', 'user', 'notifications', 'settings', 'terms', 'privacy', 'help', 'aboutUs', 'login', 'signup', 'debug'];
export const AnalyticsContext = createContext(null);
const AnalyticsRoute = (props) => {
    var _a, _b;
    console.log((_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname);
    const actualProps = __rest(props, []);
    const queryParams = queryString.parse(props.location.search);
    const history = useHistory();
    if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_source) {
        history.replace({
            search: '',
        });
    }
    const paths = (_b = props.location) === null || _b === void 0 ? void 0 : _b.pathname.split('/');
    if (ANALYTICS) {
        const lastPageVisited = (store === null || store === void 0 ? void 0 : store.getState()).ui.lastPageVisited;
        if (lastPageVisited != props.location.pathname) {
            store.dispatch(lastPage(props.location.pathname));
            const state = props.location.state;
            void API.analyticsRouter({
                bodyParams: Object.assign({ communityPk: paths[1], route: props.location.pathname, screen: props.name, utm: Object.assign(Object.assign({}, ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_source) && { source: queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_source })), ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_medium) && { medium: queryParams === null || queryParams === void 0 ? void 0 : queryParams.utm_medium })) }, (state && state.entityPk && { entityPk: state.entityPk })),
            });
        }
    }
    return (_jsx(AnalyticsContext.Provider, { value: { screen: props.name, route: props.location.pathname, communityPk: paths[1] }, children: _jsx(Route, Object.assign({}, actualProps)) }));
};
const RedirectSigned = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { auth } = store === null || store === void 0 ? void 0 : store.getState();
    const location = props.location.state;
    const redirectCommunity = ((_a = auth === null || auth === void 0 ? void 0 : auth.communities) === null || _a === void 0 ? void 0 : _a.items)
        ? ((_c = (_b = auth === null || auth === void 0 ? void 0 : auth.communities) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.find((c) => (c === null || c === void 0 ? void 0 : c.publicKey) == 'turingo'))
            ? 'turingo'
            : (_f = (_e = (_d = auth === null || auth === void 0 ? void 0 : auth.communities) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.publicKey
        : 'turingo';
    if (auth.authToken) {
        return (_jsx(Redirect, { from: props.path, to: {
                pathname: (location === null || location === void 0 ? void 0 : location.p_pk)
                    ? Router.Path[`${location.kind}_post_details`]({ b_pk: location === null || location === void 0 ? void 0 : location.b_pk, p_pk: location === null || location === void 0 ? void 0 : location.p_pk })
                    : (location === null || location === void 0 ? void 0 : location.kind)
                        ? `/${location === null || location === void 0 ? void 0 : location.kind}?page=2`
                        : `/${redirectCommunity}`,
            } }));
    }
    else {
        const actualProps = __rest(props, []);
        return _jsx(AnalyticsRoute, Object.assign({ name: props.name }, actualProps));
    }
};
const RoutePrivate = (props) => {
    var _a, _b, _c;
    const { auth, community } = store === null || store === void 0 ? void 0 : store.getState();
    if (auth.authToken) {
        const actualProps = __rest(props, []);
        return _jsx(AnalyticsRoute, Object.assign({ name: props.name }, actualProps));
    }
    else if (auth.authState == 'unauth.deleted') {
        return (_jsx(Redirect, { from: (_a = props.location) === null || _a === void 0 ? void 0 : _a.pathname, to: {
                pathname: Router.Path.community_exit({ cm_pk: community === null || community === void 0 ? void 0 : community.currentCommunity }),
                state: {
                    exit: true,
                },
            } }));
    }
    else {
        return (_jsx(Redirect, { from: (_b = props.location) === null || _b === void 0 ? void 0 : _b.pathname, to: {
                pathname: '/signup',
                state: Object.assign(Object.assign({}, props.location.state), { next: (_c = props.location) === null || _c === void 0 ? void 0 : _c.pathname }),
            } }));
    }
};
export { AnalyticsRoute, RedirectSigned, RoutePrivate };
