import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Form } from 'antd';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import strings from '@resources/localization';
import { modalAction } from '@state/reducers/Modal/ModalReducer';
import { authUpdatePassword } from '@state/actions';
import { InformationModal } from './information_modal/InformationModal';
import { useRequestState } from '@api/requests/AsyncRequests';
import { FormPasswordItem } from '@components/form/FormPasswordItem/FormPasswordItem';
import { FooterModal } from './components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
export const PasswordCreateModal = () => {
    const passwordModal = useAppSelector((state) => state.modal.password_create);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const closePasswordModal = () => {
        dispatch(modalAction({ modal: 'password_create', state: false }));
    };
    const changeComplete = (res) => {
        closePasswordModal();
        setInfoModal(true);
    };
    const [infoModal, setInfoModal] = useState(false);
    const [state, setState] = useRequestState(undefined, false, false);
    const onSubmit = (values) => {
        dispatch(authUpdatePassword({
            bodyParams: {
                passwordNew: values.passwordNew,
            },
            options: {
                redux: {
                    stateHandler: setState,
                },
                then: {
                    action: changeComplete,
                },
            },
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(InformationModal, { kind: 'success', open: infoModal, onClick: () => setInfoModal(false), title: strings.screens.settings.sections.signup.changePassword.success_create.title, description: strings.screens.settings.sections.signup.changePassword.success_create.description }), _jsxs(TuringoModal, { width: 572, bodyStyle: { padding: '24px 24px 0px 24px' }, title: strings.screens.settings.sections.signup.changePassword.modal_create.title, open: passwordModal, onCancel: closePasswordModal, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => {
                                form.validateFields()
                                    .then((values) => {
                                    form.resetFields();
                                    onSubmit(values);
                                })
                                    .catch((info) => { });
                            },
                            customText: strings.screens.settings.sections.signup.changePassword.modal_create.actions.accept,
                            loading: state.editing,
                        }, secondary: {
                            action: closePasswordModal,
                            customText: strings.screens.settings.sections.signup.changePassword.modal.actions.cancel,
                        } }, 'footer_modal'),
                ], children: [_jsx("p", { children: strings.screens.settings.sections.signup.changePassword.modal_create.description }), _jsx(Form, { form: form, layout: 'vertical', children: _jsx(FormPasswordItem, { name: 'passwordNew', label: strings.screens.settings.sections.signup.changePassword.modal_create.newPassword.label, validations: ['required', 'short'], help: strings.forms.password.prompt }) })] })] }));
};
