import { jsx as _jsx } from "react/jsx-runtime";
import { SponsorIcon } from '@components/content/entity/sponsor_icon/SponsorIcon';
import strings from '@resources/localization';
import { Card, Col, Row } from 'antd';
export const SponsorsCard = (props) => {
    const { items, kind } = props;
    if (items.length == 0)
        return null;
    return (_jsx(Col, { span: 24, children: _jsx(Card, { title: strings.general[kind], bordered: false, children: items === null || items === void 0 ? void 0 : items.map((item, index) => {
                return (_jsx(Row, { children: _jsx(Col, { span: 24, style: { marginBottom: 10 }, children: _jsx(SponsorIcon, { avatarUrl: item.logoUrl }) }) }, `${kind}#${index}`));
            }) }) }));
};
