import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Divider, Form, Select } from 'antd';
import { GoBackButton } from '@components/buttons/go_back_button/GoBackButton';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useHistory } from 'react-router';
import { Router } from '@Router';
import { DebounceCommunitySelect } from '@components/form/DebounceCommunitySelect/DebounceCommunitySelect';
import { useForm } from 'antd/lib/form/Form';
import { DebounceGenericSelect } from '@components/form/DebounceGenericSelect/DebounceGenericSelect';
import { API } from '@api/API';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { debugDigestEmail } from '@state/actions';
import { valueToFrequency } from '@screens/community/admin_panel/screens/notifications/NotificationScreen';
import { FormEmailItem } from '@components/form/FormEmailItem/FormEmailItem';
const Digest = (props) => {
    const history = useHistory();
    const [form] = useForm();
    const community = Form.useWatch('community', form);
    const currentEntity = useAppSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const submit = (values) => {
        var _a, _b, _c;
        setLoading(true);
        const posts = (_a = values.posts) === null || _a === void 0 ? void 0 : _a.map((i) => i.value);
        const rates = (_b = values.rates) === null || _b === void 0 ? void 0 : _b.map((i) => i.value);
        const events = (_c = values.events) === null || _c === void 0 ? void 0 : _c.map((i) => i.value);
        void dispatch(debugDigestEmail({
            bodyParams: {
                frequency: valueToFrequency(values.frequency),
                communityPk: values.community.value,
                rates: rates || [],
                posts: posts || [],
                events: events || [],
                bypassUserSettings: true,
                emails: [values.email],
            },
            options: {
                alerts: {
                    success: 'Email enviado correctamente',
                    error: 'Ha sucedido un error al enviar el email',
                },
                then: {
                    action: () => {
                        setLoading(false);
                    },
                },
            },
        }));
    };
    return (_jsxs("div", { className: "lateral-padding", style: { paddingBottom: 16 }, children: [_jsxs("div", { style: { marginTop: 16, display: 'flex', alignItems: 'center' }, children: [_jsx(GoBackButton, { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }, type: "default", showText: false, onClick: () => history.push(Router.Path.debug({})) }), _jsx(Paragraph, { strong: true, className: "turingo-text", children: 'Digest' })] }), _jsx(Divider, {}), _jsx(Paragraph, { style: { marginBottom: 16 }, children: 'Envia un email de prueba de un evento.' }), _jsxs(Form, { initialValues: {
                    email: currentEntity.authEmail,
                }, onFinish: submit, className: 'scrollStyle', form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(Form.Item, { name: 'community', label: 'Selecciona una comunidad', rules: [
                            {
                                required: true,
                                message: 'Selecciona una comunidad',
                            },
                        ], children: _jsx(DebounceCommunitySelect, { onChange: () => {
                                form.setFieldValue('events', undefined);
                            }, placeholder: 'Selecciona una comunidad', style: { width: '100%' } }) }), _jsx(Form.Item, { name: 'events', label: 'Selecciona eventos', children: _jsx(DebounceGenericSelect, { mode: "multiple", disabled: !community, nameField: "title", valueField: "publicKey", dispatch: () => API.communityEventList({
                                urlParams: {
                                    cm_pk: community === null || community === void 0 ? void 0 : community.value,
                                },
                            }), placeholder: 'Selecciona eventos', style: { width: '100%' } }) }), _jsx(Form.Item, { name: 'posts', label: 'Selecciona publicaciones', children: _jsx(DebounceGenericSelect, { mode: "multiple", disabled: !community, nameField: "content", valueField: "publicKey", dispatch: () => API.communityPosts({
                                urlParams: {
                                    cm_pk: community === null || community === void 0 ? void 0 : community.value,
                                },
                            }), placeholder: 'Selecciona publicaciones', style: { width: '100%' } }) }), _jsx(Form.Item, { name: 'rates', label: 'Selecciona valoraciones', children: _jsx(DebounceGenericSelect, { mode: "multiple", disabled: !community, nameField: "content", valueField: "publicKey", dispatch: () => API.communityPosts({
                                urlParams: {
                                    cm_pk: community === null || community === void 0 ? void 0 : community.value,
                                },
                                searchParams: {
                                    kind: 'rate',
                                },
                            }), placeholder: 'Selecciona valoraciones', style: { width: '100%' } }) }), _jsx(Form.Item, { name: "frequency", label: 'Selecciona la frequencia del digest', children: _jsx(Select, { getPopupContainer: (trigger) => trigger.parentElement, dropdownStyle: { zIndex: 2000 }, options: [
                                {
                                    label: 'Semanal',
                                    value: 'weekly',
                                },
                                {
                                    label: 'Bisemanal',
                                    value: 'biweekly',
                                },
                                {
                                    label: 'Mensual',
                                    value: 'monthly',
                                },
                            ], placeholder: 'Selecciona la frequencia del digest' }) }), _jsx(FormEmailItem, { name: 'email', placeholder: 'Email', label: 'Email (El email debe de tener cuenta de turingo)', validations: ['format', 'long', 'short'] }), _jsx("div", { style: { width: '100%', display: 'flex', justifyContent: 'end' }, children: _jsx(Button, { loading: loading, type: "primary", onClick: form.submit, children: "Enviar" }) })] })] }));
};
export { Digest };
