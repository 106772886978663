import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
export const LiquimolyProductsIcon = (props) => {
    return (_jsx(Icon, Object.assign({ component: () => {
            if (props.filled) {
                return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.width || '24', height: props.height || '24', viewBox: "0 0 24 24", fill: props.color ? props.color : 'var(--neutral-13)', children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.64448 0.699951C7.12284 0.699951 6.69995 1.12284 6.69995 1.64448V2.58901H12.4562V1.64448C12.4562 1.12284 12.0333 0.699951 11.5117 0.699951H7.64448ZM7.22271 3.27808C6.95294 3.27808 6.69605 3.39344 6.51679 3.59504L3.93861 6.4955C3.78501 6.66836 3.70006 6.89162 3.70006 7.123L3.70006 18.8102C3.70007 19.1957 3.70008 19.5602 3.69995 19.7773L3.70006 21.0664C3.70006 22.2982 4.70178 23.2999 5.93365 23.2999H17.5352C18.7671 23.2999 19.7688 22.2982 19.7688 21.0664V9.70112C19.7688 9.46053 19.677 9.22898 19.5121 9.05375L14.3558 3.57519C14.1773 3.38563 13.9285 3.27808 13.668 3.27808H7.22271ZM12.4985 11.2302C12.1973 10.6758 11.4034 10.6759 11.1022 11.2302C10.6471 12.0678 10.2576 12.8555 9.97852 13.5067C9.83914 13.832 9.7247 14.1293 9.64334 14.3855C9.56527 14.6313 9.50535 14.8762 9.50016 15.0828L9.5 15.0828V15.0953C9.5 16.3936 10.5049 17.5 11.8 17.5C13.0948 17.5 14.099 16.3942 14.1 15.0951H14.1002L14.0998 15.0816C14.0944 14.875 14.0345 14.63 13.9564 14.384C13.875 14.1277 13.7606 13.8303 13.6213 13.5051C13.3424 12.8541 12.9532 12.0669 12.4985 11.2302Z" }) }));
            }
            else {
                return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: props.width || '24', height: props.height || '24', viewBox: "0 0 24 24", fill: props.color ? props.color : 'var(--neutral-13)', children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.70004 1.64454C6.70004 1.1229 7.12294 0.700012 7.64458 0.700012H11.5118C12.0334 0.700012 12.4563 1.1229 12.4563 1.64454V2.58907H6.70004V1.64454ZM6.42316 3.70241C6.66949 3.43215 7.01827 3.27814 7.38394 3.27814L13.668 3.27818C13.9285 3.27818 14.1774 3.38572 14.3558 3.57534L19.5121 9.05386C19.677 9.22908 19.7688 9.46063 19.7688 9.70123V21.0665C19.7688 22.2983 18.7671 23.3001 17.5352 23.3001H5.93367C4.7018 23.3001 3.70007 22.2983 3.70007 21.0665V7.1231C3.70007 6.89172 3.78503 6.66847 3.93862 6.49561L6.35646 3.77556L6.42316 3.70241ZM7.91633 5.16724C7.74494 5.16724 7.58174 5.24053 7.46788 5.36863L5.74069 7.31172C5.64306 7.42155 5.58914 7.56339 5.58914 7.71033V21.0665C5.58914 21.2562 5.74396 21.411 5.93367 21.411H17.5352C17.6635 21.411 17.7541 21.3765 17.8055 21.3362C17.8487 21.3022 17.8798 21.2537 17.8798 21.1667L17.8798 21.1653C17.8815 20.8011 17.8807 20.7725 17.8803 20.7572C17.88 20.7461 17.8798 20.7356 17.8798 20.7084V10.3138C17.8798 10.1609 17.8214 10.0138 17.7167 9.90255L13.4376 5.35602C13.3242 5.23555 13.1661 5.16724 13.0007 5.16724H7.91633ZM11.1022 11.2257C11.4034 10.6715 12.1973 10.6714 12.4985 11.2257C12.9532 12.0625 13.3424 12.8496 13.6213 13.5007C13.7606 13.8259 13.875 14.1232 13.9564 14.3795C14.0345 14.6256 14.0944 14.8705 14.0998 15.0771L14.1002 15.0907H14.1C14.099 16.3897 13.0948 17.4955 11.8 17.4955C10.5049 17.4955 9.5 16.3891 9.5 15.0909V15.0783L9.50016 15.0783C9.50535 14.8718 9.56527 14.6268 9.64334 14.381C9.7247 14.1249 9.83914 13.8276 9.97852 13.5023C10.2576 12.8511 10.6471 12.0633 11.1022 11.2257Z" }) }));
            }
        } }, props)));
};
