import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button, Col, Divider, Form, Row } from 'antd';
import strings from '@resources/localization';
import { ChartHistogramTwo, PictureOne, VideoTwo } from '@icon-park/react';
import { FileTextOutlined } from '@ant-design/icons';
import useBreakpoints from '@hooks/useBreakpoints';
import { PostKindSelection } from './PostKindSelection';
import { CreatePostContext } from '../../../PostCreateProvider';
import { useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import { CrossPostingContext } from '../CreationScreen';
const PostCreationFooter = (props) => {
    var _a, _b;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const { consumer } = useContext(CrossPostingContext);
    const { form, setScreen, boards, editable, item, changeAttachments } = useContext(CreatePostContext);
    const kind = Form.useWatch(['form', consumer, 'kind'], form);
    const content = Form.useWatch(['form', consumer, 'content'], form);
    const topics = Form.useWatch(['form', consumer, 'topics'], form);
    const board = Form.useWatch(['form', consumer, 'board'], form);
    const board_item = boards.find((value) => value.publicKey == board);
    const items = [
        {
            tooltip: strings.screens.boards.discussion.post.kind.image.upload.multiple.title,
            title: strings.screens.boards.discussion.post.kind.image.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'image');
                if (consumer != 'turingo') {
                    changeAttachments.set(true);
                }
                if (consumer == 'turingo' && !changeAttachments.get) {
                    form.setFieldValue(['form', 'linkedin', 'kind'], 'image');
                    form.setFieldValue(['form', 'X', 'kind'], 'image');
                }
            },
            highlightColor: 'var(--post-type-image-icon)',
            backgroundColor: 'var(--post-type-image-background)',
            iconPark: PictureOne,
            disabled: editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'image',
            selected: kind == 'image',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.video.placeholder,
            title: strings.screens.boards.discussion.post.kind.video.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'video');
                if (consumer != 'turingo') {
                    changeAttachments.set(true);
                }
                if (consumer == 'turingo' && !changeAttachments.get) {
                    form.setFieldValue(['form', 'linkedin', 'kind'], 'video');
                    form.setFieldValue(['form', 'X', 'kind'], 'video');
                }
            },
            highlightColor: 'var(--post-type-video-icon)',
            backgroundColor: 'var(--post-type-video-background)',
            iconPark: VideoTwo,
            disabled: editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'video',
            selected: kind == 'video',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.poll.placeholder,
            title: strings.screens.boards.discussion.post.kind.poll.name,
            action: () => {
                setScreen('poll_creation');
            },
            highlightColor: 'var(--post-type-poll-icon)',
            backgroundColor: 'var(--post-type-poll-background)',
            iconPark: ChartHistogramTwo,
            disabled: consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'poll',
            selected: kind == 'poll',
            compact: true,
            showText: false,
        },
        {
            tooltip: strings.screens.boards.discussion.post.kind.attachment.placeholder,
            title: strings.screens.boards.discussion.post.kind.attachment.name,
            action: () => {
                form.setFieldValue(['form', consumer, 'kind'], 'attachment');
            },
            highlightColor: 'var(--post-type-attachment-icon)',
            backgroundColor: 'var(--post-type-attachment-background)',
            iconAnt: FileTextOutlined,
            disabled: consumer != 'turingo' || editable || kind == 'event' ? true : kind != 'basic' && kind != 'preview' && kind != 'attachment',
            selected: kind == 'attachment',
            compact: true,
            showText: false,
        },
    ];
    const { cm_pk, b_pk, p_pk } = useParams();
    const isFeed = !b_pk;
    const scope = p_pk ? 'detail' : 'all';
    const isLoading = (_a = useAppSelector((state) => { var _a; return (_a = state.posts.all[isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.createState; })) === null || _a === void 0 ? void 0 : _a.loading;
    const isEditing = editable
        ? useAppSelector((state) => { var _a, _b; return (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk].items[item === null || item === void 0 ? void 0 : item.publicKey]) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.loading; })
        : undefined;
    const toBoardSelection = () => {
        setScreen('board_final');
    };
    const toTopicSelection = () => {
        setScreen('topic_final');
    };
    if (!props.focused && isMobile && kind == 'basic') {
        return (_jsxs("div", { style: Object.assign({ marginTop: 8 }, (isMobile && {
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 100,
                backgroundColor: 'var(--background)',
                width: '100%',
                padding: 16,
                alignItems: 'center',
            })), children: [_jsx(Divider, { style: { margin: isMobile ? '0px 0px 16px 0px' : '8px 0px' } }), _jsx(Row, { gutter: [24, 24], children: items.map((item) => (_jsx(Col, { span: 24, children: _jsx(PostKindSelection, Object.assign({ firstSelection: true, showTooltip: false }, item)) }, item.title))) })] }));
    }
    return (_jsxs("div", { style: { marginTop: 8 }, children: [_jsx("div", { style: { padding: isMobile ? '0px 16px' : '16px 0px' }, children: _jsx(Divider, { style: { margin: 0 } }) }), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between', padding: isMobile ? 16 : 0 }, children: [_jsx("div", { style: {
                            width: isMobile ? 'calc(100% - 118px)' : 'unset',
                            maxWidth: isMobile ? 248 : 'unset',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gap: isMobile ? 0 : 24,
                            alignItems: 'center',
                        }, children: items.map((item) => (_jsx("div", { children: _jsx(PostKindSelection, Object.assign({}, item)) }, item.title))) }), _jsx(Button, { onClick: !board
                            ? toBoardSelection
                            : (!topics || (topics === null || topics === void 0 ? void 0 : topics.length) == 0) && ((_b = board_item === null || board_item === void 0 ? void 0 : board_item.topics) === null || _b === void 0 ? void 0 : _b.length) > 0 && !editable
                                ? toTopicSelection
                                : form.submit, type: "primary", disabled: (!content || content.trim() == '') && kind == 'basic', loading: editable ? isEditing : isLoading, style: { borderRadius: 4 }, children: editable ? strings.general.save : strings.general.topost })] })] }));
};
export { PostCreationFooter };
