import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Menu, Badge } from 'antd';
import { HomeFilled, HomeOutlined, AppstoreOutlined, AppstoreFilled, CalendarOutlined, CalendarFilled, PushpinFilled, PushpinOutlined, QuestionCircleOutlined, QuestionCircleFilled, } from '@ant-design/icons';
import { ConceptSharing, DegreeHat, PeoplesTwo, PeopleTopCard, SettingConfig } from '@icon-park/react';
import styles from './SidebarItems.scss';
import useBreakpoints from '@hooks/useBreakpoints';
import strings from '@resources/localization';
import { manualCollapseSidebar } from '@state/reducers/UserInterface/UserInterfaceReducer';
import { createElement, useEffect, useState } from 'react';
import { Router } from '@Router';
import { turingoRoutes } from '@components/routing/auth/AuthRouting';
import { MenuRightIcon } from '@components/icons/MenuRightIcon';
import { MenuLeftIcon } from '@components/icons/MenuLeftIcon';
import { CatalogueIcons } from '@components/form/IconSelector/IconSelector';
import ClassNames from '@util/style/ClassNames';
import { LiquimolyProductsIcon } from '@components/icons/LiquimolyProductsIcon';
const SidebarItems = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    const { mode: menuMode, hideCollapse, collapsed = false, afterClick } = props;
    const location = useLocation();
    const [key, setKey] = useState('');
    const currentCommunity = useAppSelector((state) => state.community.currentCommunity);
    const boards = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.boards; });
    const boardsCount = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.community.items[currentCommunity]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.boardCount; });
    const community = useAppSelector((state) => state.community.items[currentCommunity]);
    let unread = false;
    const supportPk = 'soporte';
    const supportBoard = boards === null || boards === void 0 ? void 0 : boards.find((b) => b.publicKey == supportPk);
    const discussion_boards = (_a = boards === null || boards === void 0 ? void 0 : boards.filter((b) => b.kind == 'discussion' && !b.hidden)) === null || _a === void 0 ? void 0 : _a.map((board) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (board.postCount > ((_d = (_c = (_b = (_a = community === null || community === void 0 ? void 0 : community.boards) === null || _a === void 0 ? void 0 : _a.detail) === null || _b === void 0 ? void 0 : _b[board.publicKey]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.postCount)) {
            unread = true;
        }
        return Object.assign(Object.assign({}, board), { unread: board.postCount > ((_h = (_g = (_f = (_e = community === null || community === void 0 ? void 0 : community.boards) === null || _e === void 0 ? void 0 : _e.detail) === null || _f === void 0 ? void 0 : _f[board.publicKey]) === null || _g === void 0 ? void 0 : _g.item) === null || _h === void 0 ? void 0 : _h.postCount) });
    });
    const catalog_boards = boards === null || boards === void 0 ? void 0 : boards.filter((b) => b.kind == 'catalog');
    const manual_collapsed = useAppSelector((state) => state.ui.manual_collapsed);
    const breakpoints = useBreakpoints();
    const isCollapsed = manual_collapsed || collapsed;
    const isInline = menuMode == 'inline';
    const dispatch = useAppDispatch();
    const toggle = () => {
        dispatch(manualCollapseSidebar(!manual_collapsed));
    };
    useEffect(() => {
        const paths = location.pathname.split('/');
        if (paths.length >= 3) {
            if (paths[2].startsWith('spaces')) {
                setKey(paths[3]);
            }
        }
        if (paths.length == 2) {
            if (paths[0] == '' && !turingoRoutes.includes(paths[1])) {
                setKey('home');
            }
            else {
                setKey('');
            }
        }
        if (paths.length > 2) {
            if (!paths[2].startsWith('spaces')) {
                if (paths[2].startsWith('course')) {
                    setKey('courses');
                }
                else if (paths[2].startsWith('talk')) {
                    setKey('talks');
                }
                else if (paths[2].startsWith('stories')) {
                    setKey('stories');
                }
                else if (paths[2].startsWith('event')) {
                    setKey('events');
                }
                else if (paths[2].startsWith('directory')) {
                    setKey('directory');
                }
                else if (paths[2].startsWith('highlights')) {
                    setKey('highlights');
                }
                else if (paths[2].startsWith('catalogue')) {
                    setKey(paths[3]);
                }
                else {
                    setKey('');
                }
            }
        }
    }, [location]);
    const isAdmin = (_c = (_b = community === null || community === void 0 ? void 0 : community.item) === null || _b === void 0 ? void 0 : _b.access) === null || _c === void 0 ? void 0 : _c.includes('edit');
    const hasPostToRead = ((_e = (_d = community === null || community === void 0 ? void 0 : community.item) === null || _d === void 0 ? void 0 : _d.pinneds) === null || _e === void 0 ? void 0 : _e.readMandatory) > ((_g = (_f = community === null || community === void 0 ? void 0 : community.item) === null || _f === void 0 ? void 0 : _f.pinneds) === null || _g === void 0 ? void 0 : _g.read);
    const inlineItems = currentCommunity
        ? [
            {
                label: _jsx(Link, { to: { pathname: Router.Path.community_home({ cm_pk: currentCommunity }) }, children: strings.navigation.sidebar.home }),
                key: 'home',
                icon: createElement(key == 'home' ? HomeFilled : HomeOutlined, {
                    className: styles.menuIcon,
                    style: { fontSize: 24, marginRight: 8 },
                }),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: _jsx(Link, { to: { pathname: Router.Path.community_highlights({ cm_pk: currentCommunity }) }, children: strings.navigation.sidebar.highlights }),
                key: 'highlights',
                icon: (_jsx(Badge, { dot: hasPostToRead, offset: [-7, -2], color: "var(--notification-dot)", children: createElement(key == 'highlights' ? PushpinFilled : PushpinOutlined, {
                        className: styles.menuIcon,
                        style: { fontSize: 24, marginRight: 8 },
                    }) })),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            ...(discussion_boards && ((discussion_boards.length == 1 && discussion_boards[0].publicKey != 'general') || discussion_boards.length > 1)
                ? [
                    {
                        label: _jsx("span", { style: { fontWeight: unread ? 500 : 400 }, children: strings.navigation.sidebar.spaces }),
                        icon: (_jsx(Badge, { dot: unread, offset: [-7, -2], color: "var(--notification-dot)", children: createElement(key == 'community' ? AppstoreFilled : AppstoreOutlined, {
                                className: styles.menuIcon,
                                style: { fontSize: 24, marginRight: 8 },
                            }) })),
                        key: 'spaces',
                        className: !discussion_boards ? styles.menuItemExpanded : undefined,
                        children: discussion_boards === null || discussion_boards === void 0 ? void 0 : discussion_boards.map((board) => {
                            return {
                                label: (_jsx(Link, { to: { pathname: Router.Path.community_board({ cm_pk: currentCommunity, b_pk: board.publicKey }) }, children: _jsxs("span", { style: { display: 'inline-flex' }, children: [_jsx("span", { style: {
                                                    fontWeight: board.unread ? 500 : 400,
                                                    maxWidth: board.unread ? '112px' : 'unset',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }, children: board.name }), board.unread && _jsx(Badge, { style: { marginLeft: 4 }, color: "var(--notification-dot)", status: "error" })] }) })),
                                key: board.publicKey,
                                onClick: () => {
                                    if (afterClick) {
                                        afterClick();
                                    }
                                },
                            };
                        }),
                    },
                ]
                : []),
            ...(boardsCount && boardsCount.course > 0
                ? [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.course_list({ cm_pk: currentCommunity }) }, children: ((_l = (_k = (_j = (_h = community === null || community === void 0 ? void 0 : community.item) === null || _h === void 0 ? void 0 : _h.ui) === null || _j === void 0 ? void 0 : _j.rename) === null || _k === void 0 ? void 0 : _k.course) === null || _l === void 0 ? void 0 : _l.plural) || strings.navigation.sidebar.courses })),
                        key: 'courses',
                        icon: (_jsx(DegreeHat, { className: styles.menuIcon, theme: key == 'courses' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } })),
                        className: styles.menuItemExpanded,
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
            ...(boardsCount && boardsCount.forum > 0
                ? [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.forum_list({ cm_pk: currentCommunity }) }, children: ((_q = (_p = (_o = (_m = community === null || community === void 0 ? void 0 : community.item) === null || _m === void 0 ? void 0 : _m.ui) === null || _o === void 0 ? void 0 : _o.rename) === null || _p === void 0 ? void 0 : _p.forum) === null || _q === void 0 ? void 0 : _q.plural) || strings.navigation.sidebar.forums })),
                        key: 'talks',
                        icon: (_jsx(PeopleTopCard, { className: styles.menuIcon, theme: key == 'talks' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } })),
                        className: styles.menuItemExpanded,
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
            ...(boardsCount && boardsCount.story > 0
                ? [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.story_list({ cm_pk: currentCommunity }) }, children: ((_u = (_t = (_s = (_r = community === null || community === void 0 ? void 0 : community.item) === null || _r === void 0 ? void 0 : _r.ui) === null || _s === void 0 ? void 0 : _s.rename) === null || _t === void 0 ? void 0 : _t.story) === null || _u === void 0 ? void 0 : _u.plural) || strings.navigation.sidebar.stories })),
                        key: 'stories',
                        icon: currentCommunity == 'liquimolyverse' ? (_jsx(LiquimolyProductsIcon, { color: key == 'stories' ? 'var(--ant-primary-color)' : 'var(--text)', className: styles.menuIcon, filled: key == 'stories', style: { marginRight: 8 } })) : (_jsx(ConceptSharing, { className: styles.menuIcon, theme: key == 'stories' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } })),
                        className: ClassNames(styles.menuItemExpanded),
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
            {
                label: _jsx(Link, { to: { pathname: Router.Path.event_list({ cm_pk: currentCommunity }) }, children: strings.navigation.sidebar.events }),
                key: 'events',
                icon: createElement(key == 'events' ? CalendarFilled : CalendarOutlined, {
                    className: styles.menuIcon,
                    style: { fontSize: 24, marginRight: 8 },
                }),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            {
                label: _jsx(Link, { to: { pathname: Router.Path.community_directory({ cm_pk: currentCommunity }) }, children: strings.navigation.sidebar.directory }),
                key: 'directory',
                icon: _jsx(PeoplesTwo, { className: styles.menuIcon, theme: key == 'directory' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } }),
                className: styles.menuItemExpanded,
                onClick: () => {
                    if (afterClick) {
                        afterClick();
                    }
                },
            },
            ...(catalog_boards
                ? catalog_boards.map((catalog) => {
                    var _a, _b;
                    return {
                        label: _jsx(Link, { to: { pathname: Router.Path.catalogue({ cm_pk: currentCommunity, b_pk: catalog.publicKey }) }, children: catalog.name }),
                        key: catalog.publicKey,
                        icon: createElement(CatalogueIcons[(_b = (_a = catalog.ui) === null || _a === void 0 ? void 0 : _a.menu) === null || _b === void 0 ? void 0 : _b.icon] || CalendarFilled, {
                            filled: key == catalog.publicKey,
                            className: styles.menuIcon,
                            color: key == catalog.publicKey ? 'var(--ant-primary-color)' : 'var(--text)',
                            style: { fontSize: 24, marginRight: 8 },
                        }),
                        className: ClassNames(styles.menuItemExpanded, 'hover-svg'),
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    };
                })
                : []),
            ...(isAdmin
                ? [
                    {
                        type: 'divider',
                        key: 'divider',
                    },
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_settings({ cm_pk: currentCommunity, section: 'general' }) }, children: strings.navigation.sidebar.admin })),
                        key: 'admin_panel',
                        icon: (_jsx(SettingConfig, { className: styles.menuIcon, theme: key == 'directory' ? 'filled' : 'outline', size: "24", style: { marginRight: 8 } })),
                        className: styles.menuItemExpanded,
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
            ...(isAdmin && currentCommunity != 'turingo' && supportBoard
                ? [
                    {
                        label: (_jsx(Link, { to: { pathname: Router.Path.community_board({ cm_pk: currentCommunity, b_pk: supportPk }) }, children: strings.navigation.sidebar.support })),
                        key: supportPk,
                        icon: (_jsx(Badge, { dot: supportBoard.postCount > ((_y = (_x = (_w = (_v = community === null || community === void 0 ? void 0 : community.boards) === null || _v === void 0 ? void 0 : _v.detail) === null || _w === void 0 ? void 0 : _w[supportBoard.publicKey]) === null || _x === void 0 ? void 0 : _x.item) === null || _y === void 0 ? void 0 : _y.postCount), offset: [-7, -2], color: "var(--notification-dot)", children: createElement(key == 'soporte' ? QuestionCircleFilled : QuestionCircleOutlined, {
                                className: styles.menuIcon,
                                style: { fontSize: 24, marginRight: 8 },
                            }) })),
                        className: styles.menuItemExpanded,
                        onClick: () => {
                            if (afterClick) {
                                afterClick();
                            }
                        },
                    },
                ]
                : []),
        ]
        : [];
    const onClick = (e) => {
        setKey(e.key);
    };
    return (_jsxs("div", { style: { paddingTop: !hideCollapse && isInline ? 27 : 0 }, children: [!hideCollapse &&
                !breakpoints.lg &&
                isInline &&
                (manual_collapsed ? (_jsx(MenuRightIcon, { style: { display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 8, padding: '0 24px' }, className: "hover-svg", onClick: toggle })) : (_jsx(MenuLeftIcon, { style: { display: 'flex', flexDirection: 'column', alignItems: 'end', marginBottom: 8, padding: '0 24px' }, className: "hover-svg", onClick: toggle }))), _jsx(Menu, { onClick: onClick, triggerSubMenuAction: 'click', getPopupContainer: (trigger) => trigger.parentElement, className: isInline ? styles.menu : styles.menuBottom, theme: 'light', selectedKeys: [key], defaultOpenKeys: isCollapsed ? [] : ['spaces'], mode: menuMode, items: inlineItems })] }));
};
export { SidebarItems };
