import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOne, Play } from '@icon-park/react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '@hooks/useStore';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Card, Progress } from 'antd';
import { API } from '@api/API';
import { LinkeableCard } from '@components/cards/linkeable_card/LinkeableCard';
import { EvaluationIcon } from '@components/icons/EvaluationIcon';
import strings from '@resources/localization';
import { Router } from '@Router';
import { formatSeconds } from '@util/formatters/Time';
import { Colors } from '@util/color/Colors';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { useContext, useState } from 'react';
import { LockedIcon } from '@components/icons/LockedIcon';
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import { PostContext } from '@components/content/posts/Post';
const Playlist = (props) => {
    var _a, _b, _c, _d;
    const history = useHistory();
    const { cm_pk, b_pk, p_pk, isPanelAdmin } = useContext(PostContext);
    const language = useAppSelector((state) => state.ui.language);
    const { lessons, kind } = props;
    const lessonsToShow = (_a = Object.keys(lessons)) === null || _a === void 0 ? void 0 : _a.filter((k) => !lessons[k].item.hidden);
    const [progress, setProgress] = useState();
    const quizkey = lessonsToShow.find((key) => { var _a, _b, _c; return (_c = (_b = (_a = lessons[key].item) === null || _a === void 0 ? void 0 : _a.quizinfo) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.sequential; });
    const quizIndex = lessonsToShow.findIndex((key) => { var _a, _b, _c; return (_c = (_b = (_a = lessons[key].item) === null || _a === void 0 ? void 0 : _a.quizinfo) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.sequential; });
    const liChildren = lessonsToShow.map((key, index) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
        const item = lessons[key].item;
        let isApproved = false;
        let fulfillment = 0;
        let dateRelease = undefined;
        let progressQuiz = false;
        let blockLesson = false;
        if (quizIndex >= 0 &&
            quizIndex < index &&
            (!((_d = (_c = (_b = (_a = lessons[quizkey]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.quiz) === null || _c === void 0 ? void 0 : _c.results) === null || _d === void 0 ? void 0 : _d.score) || ((_h = (_g = (_f = (_e = lessons[quizkey]) === null || _e === void 0 ? void 0 : _e.item) === null || _f === void 0 ? void 0 : _f.quiz) === null || _g === void 0 ? void 0 : _g.results) === null || _h === void 0 ? void 0 : _h.score) < ((_m = (_l = (_k = (_j = lessons[quizkey]) === null || _j === void 0 ? void 0 : _j.item) === null || _k === void 0 ? void 0 : _k.quizinfo) === null || _l === void 0 ? void 0 : _l.score) === null || _m === void 0 ? void 0 : _m.passing))) {
            blockLesson = true;
        }
        if ((item === null || item === void 0 ? void 0 : item.kind) == 'lesson') {
            fulfillment = (((_o = item.progress) === null || _o === void 0 ? void 0 : _o.fulfillment) / ((_p = item.progress) === null || _p === void 0 ? void 0 : _p.duration)) * 100;
            isApproved = true;
            dateRelease = item.dateRelease ? new Date(item.dateRelease) : undefined;
        }
        else if ((item === null || item === void 0 ? void 0 : item.kind) == 'quiz') {
            dateRelease = ((_r = (_q = item.quizinfo) === null || _q === void 0 ? void 0 : _q.meta) === null || _r === void 0 ? void 0 : _r.datefrom) ? new Date((_t = (_s = item.quizinfo) === null || _s === void 0 ? void 0 : _s.meta) === null || _t === void 0 ? void 0 : _t.datefrom) : undefined;
            isApproved = ((_v = (_u = item.quiz) === null || _u === void 0 ? void 0 : _u.results) === null || _v === void 0 ? void 0 : _v.score) && ((_x = (_w = item === null || item === void 0 ? void 0 : item.quiz) === null || _w === void 0 ? void 0 : _w.results) === null || _x === void 0 ? void 0 : _x.score) >= ((_z = (_y = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _y === void 0 ? void 0 : _y.score) === null || _z === void 0 ? void 0 : _z.passing);
            fulfillment = (((_0 = item.quizinfo) === null || _0 === void 0 ? void 0 : _0.meta) && ((_2 = (_1 = item === null || item === void 0 ? void 0 : item.quiz) === null || _1 === void 0 ? void 0 : _1.results) === null || _2 === void 0 ? void 0 : _2.repetition) == ((_4 = (_3 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _3 === void 0 ? void 0 : _3.meta) === null || _4 === void 0 ? void 0 : _4.repeats)) || isApproved ? 100 : 0;
            progressQuiz = !isApproved && fulfillment == 0 && ((_6 = (_5 = item === null || item === void 0 ? void 0 : item.quiz) === null || _5 === void 0 ? void 0 : _5.results) === null || _6 === void 0 ? void 0 : _6.repetition) > 0;
        }
        const isQuiz = item.kind == 'quiz';
        const isComplete = fulfillment >= 80;
        const notStarted = fulfillment == 0;
        const inProgress = !isComplete && !notStarted;
        const isPlaying = p_pk == item.publicKey;
        const todayDate = new Date();
        const isReleased = isQuiz
            ? ((_8 = (_7 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _7 === void 0 ? void 0 : _7.meta) === null || _8 === void 0 ? void 0 : _8.datefrom)
                ? new Date((_10 = (_9 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _9 === void 0 ? void 0 : _9.meta) === null || _10 === void 0 ? void 0 : _10.datefrom) < todayDate
                : true
            : blockLesson
                ? false
                : dateRelease
                    ? dateRelease < todayDate
                    : true;
        let isClickable = true;
        if (isQuiz && !isReleased) {
            isClickable = false;
        }
        const getOutlineColor = () => {
            if (!isReleased)
                return 'var(--neutral-6)';
            if (notStarted) {
                if (isPlaying)
                    return 'var(--alert-warning-outline)';
                return 'var(--alert-neutral-outline)';
            }
            if (inProgress)
                return 'var(--alert-warning-outline)';
            if (isComplete) {
                if (isApproved)
                    return 'var(--alert-success-outline)';
                return 'var(--alert-error-outline)';
            }
        };
        const getIconColor = () => {
            if (!isReleased)
                return 'var(--neutral-6)';
            if (notStarted) {
                if (isPlaying)
                    return 'var(--alert-warning-icon)';
                return 'var(--text)';
            }
            if (inProgress)
                return 'var(--alert-warning-icon)';
            if (isComplete) {
                if (isApproved)
                    return 'var(--alert-success-icon)';
                return 'var(--alert-error-icon)';
            }
        };
        const getColoredTextColor = () => {
            if (!isReleased)
                return 'var(--neutral-6)';
            if (notStarted) {
                if (isPlaying)
                    return 'var(--alert-warning-text)';
                return 'var(--neutral-4)';
            }
            if (inProgress)
                return 'var(--alert-warning-text)';
            if (isComplete) {
                if (isApproved)
                    return 'var(--alert-success-text)';
                return 'var(--alert-error-text)';
            }
        };
        const getTextColor = () => {
            if (!isReleased)
                return 'var(--neutral-6)';
            return 'var(--text)';
        };
        const getBackgroundColor = () => {
            if (!isReleased)
                return 'var(--neutral-3)';
            if (notStarted) {
                if (isPlaying)
                    return 'var(--alert-warning-background)';
                return 'var(--alert-neutral-background)';
            }
            if (inProgress)
                return 'var(--alert-warning-background)';
            if (isComplete) {
                if (isApproved)
                    return 'var(--alert-success-background)';
                return 'var(--alert-error-background)';
            }
        };
        const onClick = () => {
            if (!isClickable)
                return;
            if (blockLesson) {
                void API.postDetails({
                    urlParams: {
                        cm_pk,
                        b_pk,
                        p_pk: item.publicKey,
                    },
                    extraParams: {
                        scope: 'detail',
                    },
                }).then((result) => {
                    if (result.data.sequence && result.data.sequence.certificate == 'needed:completion') {
                        const sequence = result.data.sequence.sequence;
                        setProgress(sequence);
                    }
                    else {
                        history.push(Router.Path[`${kind}_post_details`]({ cm_pk, b_pk, p_pk: item.publicKey }));
                    }
                });
            }
            else {
                if (isPanelAdmin) {
                    history.push(Router.Path.community_settings_post_detail({ cm_pk, section: 'onboarding', item_pk: b_pk, p_pk: item.publicKey }));
                }
                else {
                    history.push(Router.Path[`${kind}_post_details`]({ cm_pk, b_pk, p_pk: item.publicKey }));
                }
            }
        };
        return (_jsxs("div", { style: { marginBottom: 16 }, children: [progress && (_jsx(TuringoModal, { open: !!progress, onCancel: () => setProgress(undefined), onOk: () => setProgress(undefined), width: 572, footer: [
                        _jsx(FooterModal, { primary: {
                                action: () => setProgress(undefined),
                                customText: 'OK',
                            } }, 'footer_modal'),
                    ], children: _jsx(CourseProgress, { isLesson: true, progress: progress, title: strings.screens.boards.course.certificate.progress.title, description: strings.screens.boards.course.certificate.progress.description }) })), _jsx(LinkeableCard, { linkStyle: { height: 'inherit' }, onClick: onClick, style: { height: 'inherit', borderRadius: 4, cursor: 'pointer' }, bordered: false, bodyStyle: {
                        color: isReleased ? 'inherit' : 'white',
                        padding: '8px 16px',
                        backgroundColor: getBackgroundColor(),
                        borderRadius: 4,
                        border: isPlaying ? `2px solid ${getOutlineColor()}` : '',
                    }, children: _jsxs("div", { style: { display: 'grid', gridTemplateColumns: '56px 1fr', minHeight: 96 }, children: [_jsxs("div", { style: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }, children: [isQuiz && isReleased && (_jsx(EvaluationIcon, { style: {
                                            height: 48,
                                            width: 48,
                                            color: getIconColor(),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        } })), !isReleased && (_jsx(LockedIcon, { style: {
                                            height: 48,
                                            width: 48,
                                            color: getIconColor(),
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        } })), !isQuiz && notStarted && isReleased && _jsx(Play, { theme: "outline", size: "56", fill: getIconColor(), strokeWidth: 2 }), !isQuiz && isComplete && isReleased && _jsx(CheckOne, { theme: "outline", size: "56", fill: getIconColor(), strokeWidth: 2 }), !isQuiz && inProgress && isReleased && isPlaying && (_jsx(Progress, { type: "circle", trailColor: Colors('NEUTRAL_1'), strokeColor: getIconColor(), percent: fulfillment, width: 44, showInfo: false })), !isQuiz && inProgress && isReleased && !isPlaying && (_jsx(Progress, { type: "circle", strokeColor: getIconColor(), percent: fulfillment, width: 44, trailColor: Colors('NEUTRAL_1'), format: (percent) => {
                                            return (_jsx("svg", { width: "48", height: "48", viewBox: "0 0 52 46", fill: "none", children: _jsx("path", { d: "M20 24V17.0718L26 20.5359L32 24L26 27.4641L20 30.9282V24Z", fill: "none", stroke: getIconColor(), strokeWidth: "2", strokeLinejoin: "round" }) }));
                                        } }))] }), _jsxs("div", { style: {
                                    marginLeft: 16,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: !isQuiz && isReleased ? 'space-between' : 'center',
                                }, children: [_jsxs("div", { children: [_jsx(Paragraph, { style: { fontSize: isMobile ? 14 : 12, marginBottom: 4, color: getTextColor() }, children: !isQuiz
                                                    ? item.contentSequence >= 0 && `${(_13 = (_12 = (_11 = strings.screens.boards[kind]) === null || _11 === void 0 ? void 0 : _11.post) === null || _12 === void 0 ? void 0 : _12.title) === null || _13 === void 0 ? void 0 : _13.singular} ${index + 1}`
                                                    : strings.screens.evaluation.title }), _jsx(Paragraph, { strong: true, style: { marginBottom: 8, fontSize: isMobile ? 16 : 14, color: getTextColor() }, ellipsis: { rows: 2 }, children: !isQuiz ? (_14 = item.lesson) === null || _14 === void 0 ? void 0 : _14.title : item.title })] }), !isQuiz && isReleased && (_jsx("div", { children: _jsx(Paragraph, { style: Object.assign({ fontSize: isMobile ? 14 : 12, marginBottom: 4 }, (isPlaying && { fontWeight: 500, color: getColoredTextColor() })), children: isPlaying ? 'En curso' : formatSeconds((_15 = item.progress) === null || _15 === void 0 ? void 0 : _15.duration, 'hh?:mm:ss') }) })), isQuiz && (_jsx("div", { children: _jsx(Paragraph, { style: Object.assign({ fontSize: isMobile ? 14 : 12, marginBottom: 4, color: getColoredTextColor() }, (isPlaying && { fontWeight: 500 })), children: isReleased
                                                ? isPlaying
                                                    ? isApproved
                                                        ? 'Aprobado'
                                                        : isComplete
                                                            ? 'Reprobado'
                                                            : 'En curso'
                                                    : ''
                                                : `${strings.general.availableOn} ${dayjs((_17 = (_16 = item === null || item === void 0 ? void 0 : item.quizinfo) === null || _16 === void 0 ? void 0 : _16.meta) === null || _17 === void 0 ? void 0 : _17.datefrom)
                                                    .locale(language)
                                                    .format(strings.general.dateTimeFormat)}` }) }))] })] }) })] }, `lesson-card-${item.publicKey}`));
    });
    return (_jsx(Card, { bordered: false, title: (_d = (_c = (_b = strings.screens.boards[kind]) === null || _b === void 0 ? void 0 : _b.post) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.plural, children: _jsx("div", { className: 'scrollStyle', style: { height: 600, overflow: 'auto', paddingRight: 10 }, children: liChildren }) }));
};
export { Playlist };
