import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import useBreakpoints from '@hooks/useBreakpoints';
const EntityName = (props) => {
    const { visibility, name } = props;
    const breakpoints = useBreakpoints();
    return (_jsxs("div", { style: { display: 'flex', alignItems: 'center', padding: breakpoints.isSmall ? '0px 16px' : '0px 24px' }, children: [visibility == 'private' && _jsx(LockOutlined, { style: { fontSize: 16, marginRight: 8 } }), visibility == 'public' && _jsx(UnlockOutlined, { style: { fontSize: 16, marginRight: 8 } }), _jsx(Title, { level: 4, style: { marginBottom: 0 }, children: name })] }));
};
export { EntityName };
