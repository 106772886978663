import { jsx as _jsx } from "react/jsx-runtime";
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { FooterModal } from '@components/modals/components/FooterModal';
import strings from '@resources/localization';
import { postUnpin } from '@state/actions';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useLocation, useParams } from 'react-router';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
export const UnpinModal = (props) => {
    const { cm_pk, b_pk, p_pk } = useParams();
    const { open, onCancel, target } = props;
    const dispatch = useAppDispatch();
    const pathname = useLocation().pathname.split('/');
    const isHighlight = pathname[pathname.length - 1] == 'highlights';
    const scope = p_pk ? 'detail' : 'all';
    const isFeed = !b_pk;
    const postState = useAppSelector((state) => { var _a, _b; return (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[target.publicKey]) === null || _b === void 0 ? void 0 : _b.state; });
    const onPin = () => {
        dispatch(postUnpin({
            urlParams: { cm_pk, b_pk: target.boardPk, p_pk: target.publicKey },
            extraParams: {
                scope: scope,
                feed: isFeed,
                highlight: isHighlight
            },
            options: {
                alerts: {
                    success: 'La publicación ha sido removida como destacada',
                    error: 'Error',
                },
                then: {
                    action: onCancel,
                },
            },
        }));
    };
    return (_jsx(TuringoModal, { onCancel: onCancel, open: open, title: null, width: 572, footer: [
            _jsx(FooterModal, { primary: {
                    action: onPin,
                    customText: strings.screens.markasread.actions.unhighlight,
                    loading: postState === null || postState === void 0 ? void 0 : postState.loading,
                }, secondary: {
                    action: onCancel,
                    customText: strings.general.cancel,
                } }, 'footer_modal'),
        ], children: _jsx(InformationModalBody, { kind: 'warning', title: strings.screens.markasread.unpin_modal.title, description: strings.screens.markasread.unpin_modal.description }) }));
};
