import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Alert, Divider, Form, Input, Switch } from 'antd';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import useBreakpoints from '@hooks/useBreakpoints';
import { useForm } from 'antd/lib/form/Form';
import { FooterModal } from '@components/modals/components/FooterModal';
import strings from '@resources/localization';
import Paragraph from 'antd/lib/typography/Paragraph';
import { postCreate, postDelete, postEdit } from '@state/actions';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { useHistory, useParams } from 'react-router';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { urlRules } from '@components/content/entity/community_header/components/community_edit_modal/CommunityEditModal';
import { TuringoUpload } from '@components/form/TuringoUpload/TuringoUpload';
import { Check, Close } from '@icon-park/react';
import { Router } from '@Router';
import { formatAsset } from '@api/requests/AsyncRequests';
const linkify = linkifyIt().tlds(tlds);
const CatalogueItemModal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const { onCancel, open, action, extra } = props;
    const target = props.target;
    const breakpoints = useBreakpoints();
    const [form] = useForm();
    const { cm_pk, section, item_pk, p_pk } = useParams();
    const [allowComments, setAllowComments] = useState(((_a = target === null || target === void 0 ? void 0 : target.config) === null || _a === void 0 ? void 0 : _a.comments) == true ? true : false);
    const submitCreateOrEdit = () => {
        form.submit();
    };
    const scope = p_pk ? 'detail' : 'all';
    const [error, setError] = useState(undefined);
    const dispatch = useAppDispatch();
    const post = useAppSelector((state) => { var _a; return (_a = state.posts[scope][item_pk]) === null || _a === void 0 ? void 0 : _a.items[target === null || target === void 0 ? void 0 : target.publicKey]; });
    const createState = useAppSelector((state) => { var _a; return (_a = state.posts[scope][item_pk]) === null || _a === void 0 ? void 0 : _a.createState; });
    const deleteState = useAppSelector((state) => { var _a; return (_a = state.posts[scope][item_pk]) === null || _a === void 0 ? void 0 : _a.listState; });
    const history = useHistory();
    const [hiddenModal, setHiddenModal] = useState(false);
    const afterAction = () => {
        form.resetFields();
        if (action == 'delete') {
            history.push(Router.Path.community_settings_detail({ cm_pk: cm_pk, section: section, item_pk: item_pk }));
        }
        onCancel();
    };
    const submitDelete = () => {
        void dispatch(postDelete({
            urlParams: { cm_pk, b_pk: item_pk, target_pk: target.publicKey },
            extraParams: {
                parents: [],
                scope: scope,
            },
            options: {
                then: {
                    action: afterAction,
                },
                alerts: {
                    success: strings.alerts.catalog_item.delete.success,
                    error: strings.alerts.catalog_item.delete.failure,
                },
            },
        }));
    };
    const processAttachments = (attachments, old_attachments) => {
        const new_attachments = attachments.map((attachment) => {
            return { scope: 'attachment', item: attachment.file, id: attachment.id };
        });
        return new_attachments;
    };
    const afterError = (e) => {
        if (e.error == 'application.post.unique.title') {
            setError('No pueden existir dos catálogos con el mismo título');
        }
        else {
            setError(strings.alerts.post.edit.failure);
        }
    };
    const submitForm = (values) => {
        var _a, _b, _c, _d;
        const { thumbnailLarge, mainVideo, attachments } = values;
        setError(undefined);
        const upload_attachments = processAttachments(values.attachments, (_b = (_a = post === null || post === void 0 ? void 0 : post.item) === null || _a === void 0 ? void 0 : _a.assets) === null || _b === void 0 ? void 0 : _b.attachments);
        console.log(upload_attachments);
        switch (action) {
            case 'edit':
                void dispatch(postEdit({
                    urlParams: { cm_pk, b_pk: item_pk, p_pk: target.publicKey },
                    bodyParams: Object.assign(Object.assign({ kind: 'catalog_item', content: values.description, url: ((_c = values.url) === null || _c === void 0 ? void 0 : _c.length) > 0 ? values.url : null }, (values.title != post.item.title && { title: values.title })), { allowComments: allowComments }),
                    uploads: {
                        assets: [...formatAsset(thumbnailLarge, 'banner', 'thumbnailLarge'), ...formatAsset(mainVideo, 'catalogMain', 'main')],
                        attachments: upload_attachments.length > 0 ? upload_attachments : null,
                    },
                    extraParams: {
                        scope: scope,
                    },
                    options: {
                        redux: {
                            retrieveData: true,
                        },
                        then: {
                            action: afterAction,
                            error: afterError,
                        },
                        alerts: {
                            success: strings.alerts.catalog_item.edit.success,
                            error: strings.alerts.catalog_item.edit.failure,
                        },
                    },
                }));
                break;
            case 'create':
                void dispatch(postCreate({
                    urlParams: { cm_pk, b_pk: item_pk },
                    bodyParams: {
                        kind: 'catalog_item',
                        title: values.title,
                        content: values.description,
                        url: ((_d = values.url) === null || _d === void 0 ? void 0 : _d.length) > 0 ? values.url : undefined,
                        allowComments: allowComments,
                    },
                    extraParams: {
                        position: 'last',
                    },
                    uploads: {
                        assets: [...formatAsset(thumbnailLarge, 'banner', 'thumbnailLarge'), ...formatAsset(mainVideo, 'catalogMain', 'main')],
                        attachments: upload_attachments,
                    },
                    options: {
                        then: {
                            action: afterAction,
                            error: afterError,
                        },
                        alerts: {
                            success: strings.alerts.catalog_item.create.success,
                            error: strings.alerts.catalog_item.create.failure,
                        },
                    },
                }));
                break;
        }
    };
    const getTitle = () => {
        switch (action) {
            case 'create':
                return strings.screens.admin.catalogues.items.modalAdd.title;
            case 'edit':
                return strings.screens.admin.catalogues.items.modalAdd.editTitle;
        }
    };
    const getDeleteText = () => {
        return {
            title: strings.screens.admin.catalogues.items.modalDelete.title,
            description: strings.screens.admin.catalogues.items.modalDelete.preambleConfirm,
            subdescription: strings.screens.admin.catalogues.items.modalDelete.preambleConsequence,
        };
    };
    const getCTA = () => {
        switch (action) {
            case 'create':
                return strings.general.create;
            case 'edit':
                return strings.general.save;
            case 'delete':
                return strings.general.delete;
        }
    };
    return (_jsxs(TuringoModal, { title: getTitle(), open: open, onCancel: afterAction, width: 650, hidden: hiddenModal, footer: _jsx(FooterModal, { back: extra && {
                action: extra,
                customText: getDeleteText().title,
                kind: 'link',
            }, primary: {
                action: action == 'delete' ? submitDelete : submitCreateOrEdit,
                loading: action == 'edit' ? (_b = post === null || post === void 0 ? void 0 : post.state) === null || _b === void 0 ? void 0 : _b.editing : action == 'create' ? createState === null || createState === void 0 ? void 0 : createState.loading : deleteState === null || deleteState === void 0 ? void 0 : deleteState.loading,
                customText: getCTA(),
            }, secondary: {
                action: afterAction,
                customText: strings.general.cancel,
            } }, 'footer_modal'), children: [action == 'delete' && _jsx(InformationModalBody, Object.assign({ kind: 'warning' }, getDeleteText())), action == 'create' && (_jsx(Paragraph, { style: { lineHeight: '22px', marginBottom: 16 }, children: strings.screens.admin.catalogues.items.modalAdd.preamble })), (action == 'edit' || action == 'create') && (_jsxs(Form, { initialValues: action == 'edit' && {
                    title: post === null || post === void 0 ? void 0 : post.item.title,
                    description: post === null || post === void 0 ? void 0 : post.item.content,
                    thumbnailLarge: ((_d = (_c = post === null || post === void 0 ? void 0 : post.item) === null || _c === void 0 ? void 0 : _c.assets) === null || _d === void 0 ? void 0 : _d.thumbnailLargeUrl) ? [{ preview: (_f = (_e = post === null || post === void 0 ? void 0 : post.item) === null || _e === void 0 ? void 0 : _e.assets) === null || _f === void 0 ? void 0 : _f.thumbnailLargeUrl }] : undefined,
                    url: (_g = post === null || post === void 0 ? void 0 : post.item) === null || _g === void 0 ? void 0 : _g.url,
                    mainVideo: ((_j = (_h = post === null || post === void 0 ? void 0 : post.item) === null || _h === void 0 ? void 0 : _h.assets) === null || _j === void 0 ? void 0 : _j.main) ? [{ preview: (_m = (_l = (_k = post === null || post === void 0 ? void 0 : post.item) === null || _k === void 0 ? void 0 : _k.assets) === null || _l === void 0 ? void 0 : _l.main) === null || _m === void 0 ? void 0 : _m.url }] : undefined,
                    attachments: ((_q = (_p = (_o = post === null || post === void 0 ? void 0 : post.item) === null || _o === void 0 ? void 0 : _o.assets) === null || _p === void 0 ? void 0 : _p.attachments) === null || _q === void 0 ? void 0 : _q.map((file) => {
                        return {
                            file: {
                                name: file.name,
                                type: file.mimeType,
                                size: file.size,
                            },
                            id: file.id,
                            status: 'success',
                            percent: 100,
                            preview: file.url,
                        };
                    })) || [],
                }, className: 'scrollStyle', onFinish: submitForm, form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, layout: "vertical", children: [_jsx(Form.Item, { name: 'title', label: strings.screens.admin.catalogues.items.modalAdd.fieldName.label, rules: [
                            {
                                required: true,
                                message: strings.screens.admin.boards.modalAdd.fieldName.validations.required,
                            },
                            {
                                pattern: /^[a-zA-Z0-9ñÑçÇáäâãÁÄÂÃéëêÉËÊíïîÍÏÎóöôõÖÓÔÕúüûÚÜÛ'\- ]*$/,
                                message: strings.screens.admin.boards.modalAdd.fieldName.validations.pattern,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.trim() === '') {
                                        return Promise.reject(strings.screens.admin.boards.modalAdd.fieldName.validations.required);
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ], children: _jsx(Input, { showCount: true, maxLength: 30 }) }), _jsx(TuringoUpload, { strongLabel: false, label: strings.general.image.singular, uploadArea: {
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }, editOptions: {
                            aspect_ratio: '16:9',
                            active: true,
                        }, rules: {
                            ant: [{ required: true, message: 'La imagen no puede quedar en blanco' }],
                            maxFiles: 1,
                            maxSize: 10,
                        }, style: { marginBottom: 16 }, name: 'thumbnailLarge', type: 'image' }), _jsx(Form.Item, { name: 'description', label: strings.screens.admin.catalogues.items.modalAdd.fieldDesc.label, children: _jsx(Input.TextArea, { showCount: true, maxLength: 1000, placeholder: '' }) }), _jsx(Form.Item, { name: 'url', label: strings.screens.admin.catalogues.items.modalAdd.fieldLink.label, rules: urlRules, children: _jsx(Input, { placeholder: '' }) }), _jsx(TuringoUpload, { strongLabel: false, label: 'Video', uploadArea: {
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }, editOptions: {
                            aspect_ratio: '16:9',
                            active: true,
                        }, rules: {
                            maxFiles: 1,
                            maxSize: 100,
                        }, style: { marginBottom: 16 }, name: 'mainVideo', type: 'video' }), _jsx(TuringoUpload, { strongLabel: false, label: strings.general.downloadableContent, uploadArea: {
                            showDescription: true,
                            style: {
                                height: 252,
                            },
                        }, editOptions: {
                            aspect_ratio: '16:9',
                            active: true,
                        }, rules: {
                            maxFiles: 20,
                            maxSize: 10,
                        }, style: { marginBottom: 16 }, name: 'attachments', type: 'attachment', includeCompressFiles: true }), _jsx(Divider, {}), _jsxs("div", { style: { display: 'flex', justifyContent: 'space-between' }, children: [_jsxs("span", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("p", { style: { fontWeight: 500, marginBottom: 0 }, children: 'Agregar comentarios' }), _jsx(Paragraph, { style: { marginBottom: 16 }, children: 'Al activar esta opción, la comunidad podrá comentar sobre este ítem.' })] }), _jsx(Switch, { defaultChecked: allowComments, onChange: (checked) => {
                                    setAllowComments(checked);
                                }, style: { marginLeft: 16 }, checkedChildren: _jsx(Check, { style: { display: 'flex' } }), unCheckedChildren: _jsx(Close, { style: { display: 'flex' } }) })] }), error && _jsx(Alert, { showIcon: true, message: error, type: "error", closable: true })] }))] }));
};
export { CatalogueItemModal };
