import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Skeleton } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
import { useAppSelector } from '@hooks/useStore';
export const EntityMemberSkeleton = () => {
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme === 'dark';
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.xxs || breakpoints.xs || breakpoints.sm;
    const isTablet = breakpoints.md || breakpoints.mdl;
    return (_jsxs("div", { style: {
            marginTop: 16,
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr',
            rowGap: 16,
            placeContent: 'center',
            columnGap: 16,
        }, children: [_jsxs(Card, { bordered: true, style: { height: 310 }, bodyStyle: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Skeleton.Avatar, { active: true, size: 80 }), _jsx(Skeleton, { style: { marginTop: 20, width: '80%', }, title: true, active: true, paragraph: null })] }), _jsxs(Card, { bordered: true, style: { height: 310 }, bodyStyle: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Skeleton.Avatar, { active: true, size: 80 }), _jsx(Skeleton, { style: { marginTop: 20, width: '80%', }, title: true, active: true, paragraph: null })] }), _jsxs(Card, { bordered: true, style: { height: 310 }, bodyStyle: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Skeleton.Avatar, { active: true, size: 80 }), _jsx(Skeleton, { style: { marginTop: 20, width: '80%', }, title: true, active: true, paragraph: null })] }), _jsxs(Card, { bordered: true, style: { height: 310 }, bodyStyle: { display: 'flex', flexDirection: 'column', alignItems: 'center' }, children: [_jsx(Skeleton.Avatar, { active: true, size: 80 }), _jsx(Skeleton, { style: { marginTop: 20, width: '80%', }, title: true, active: true, paragraph: null })] })] }));
};
