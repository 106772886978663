import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import strings from '@resources/localization';
import { ReportModal } from '@components/modals/report_modal/ReportModal';
import { DeleteModal } from './DeleteModal';
import { lockScroll, unlockScroll } from '@hooks/useBlockScroll';
import { MoreButton } from '@components/buttons/more_button/MoreButton';
import { PostModal } from '../post_modal/PostCreateProvider';
import { PinModal } from './PinModal';
import { UnpinModal } from './UnpinModal';
import { UnpinIcon } from '@components/icons/UnpinIcon';
import { PostContext } from '../Post';
export const PostMenu = () => {
    var _a, _b;
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visiblePin, setVisiblePin] = useState(false);
    const [visibleUnpin, setVisibleUnpin] = useState(false);
    const { mask, post, isAdmin } = useContext(PostContext);
    const [visibleReport, setVisibleReport] = useState(false);
    const handleCancel = () => {
        unlockScroll();
        setVisible(false);
        setVisibleReport(false);
        setVisiblePin(false);
        setVisibleUnpin(false);
    };
    const handleReport = () => {
        lockScroll();
        setVisibleReport(true);
    };
    const handleEdit = () => {
        lockScroll();
        setVisibleEdit(true);
    };
    const handlePin = () => {
        lockScroll();
        setVisiblePin(true);
    };
    const handleUnpin = () => {
        lockScroll();
        setVisibleUnpin(true);
    };
    const handleDelete = () => {
        lockScroll();
        setVisible(true);
    };
    if (!post && (post === null || post === void 0 ? void 0 : post.author))
        return _jsx(_Fragment, {});
    const belongsToUser = ((_a = post === null || post === void 0 ? void 0 : post.author) === null || _a === void 0 ? void 0 : _a.publicKey) === ((_b = mask.get) === null || _b === void 0 ? void 0 : _b.publicKey);
    const menuItems = [
        ...(isAdmin && post.kind != 'rate' && !(post === null || post === void 0 ? void 0 : post.pin)
            ? [
                {
                    label: strings.screens.markasread.actions.highlight,
                    key: 'post#pin',
                    onClick: handlePin,
                    icon: _jsx(PushpinOutlined, {}),
                },
            ]
            : []),
        ...(isAdmin && post.kind != 'rate' && (post === null || post === void 0 ? void 0 : post.pin)
            ? [
                {
                    label: strings.screens.markasread.actions.unhighlight,
                    key: 'post#unpin',
                    onClick: handleUnpin,
                    icon: _jsx(UnpinIcon, {}),
                },
            ]
            : []),
        ...(belongsToUser
            ? [
                {
                    label: strings.general.edit,
                    key: 'post#edit',
                    onClick: handleEdit,
                    icon: _jsx(EditOutlined, {}),
                },
            ]
            : []),
        ...(!belongsToUser
            ? [
                {
                    label: strings.general.report,
                    key: 'post#report',
                    onClick: handleReport,
                    icon: _jsx(ExclamationCircleOutlined, {}),
                },
            ]
            : []),
        ...(belongsToUser || isAdmin
            ? [
                {
                    label: strings.general.delete,
                    key: 'post#delete',
                    onClick: handleDelete,
                    icon: _jsx(DeleteOutlined, {}),
                },
            ]
            : []),
    ];
    return (_jsxs("div", { children: [visibleEdit && _jsx(PostModal, { defaultMask: mask.get, editable: true, item: post, open: visibleEdit, setVisible: setVisibleEdit }), visible && _jsx(DeleteModal, { target: post, open: visible, onCancel: handleCancel }), visiblePin && _jsx(PinModal, { target: post, open: visiblePin, onCancel: handleCancel }), visibleUnpin && _jsx(UnpinModal, { target: post, open: visibleUnpin, onCancel: handleCancel }), visibleReport && (_jsx(ReportModal, { kind: 'post', reference: { b_pk: post.boardPk, p_pk: post.publicKey }, open: visibleReport, onCancel: handleCancel })), _jsx(MoreButton, { type: "text", items: menuItems })] }));
};
