import { setErrorAction } from '@state/actions';
import { store } from '@state/persistance/Storage';
import { openAuthExpiredModal } from '@state/actions';
export class Authentication {
    constructor() {
    }
    getAnalyticsId() {
        const auth = store === null || store === void 0 ? void 0 : store.getState().auth;
        return auth.analyticsId;
    }
    getAuthToken() {
        const auth = store === null || store === void 0 ? void 0 : store.getState().auth;
        return auth.authToken;
    }
    saveAuthToken(token) {
    }
    invalidateAuthToken() {
        store.dispatch(openAuthExpiredModal());
    }
    showError(error) {
        store.dispatch(setErrorAction(error));
    }
}
