import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import useBreakpoints from '@hooks/useBreakpoints';
const PostAction = (props) => {
    const { title, onClick, active, iconActive, icon } = props;
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    return (_jsxs(Button, { className: "btnHoverPrimary", style: Object.assign(Object.assign({ padding: isMobile ? 8 : '4px 0px', height: isMobile ? 60 : 40 }, (active && { color: 'var(--ant-primary-color)' })), { display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }), type: "text", onClick: onClick, children: [active && iconActive, !active && icon, _jsx("span", { className: "turingo-text", style: { marginLeft: !isMobile ? 4 : 0, marginTop: isMobile ? 4 : 0, color: 'inherit' }, children: title })] }));
};
export { PostAction };
