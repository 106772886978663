var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
import { Button, Form } from 'antd';
import { Colors } from '@util/color/Colors';
import strings from '@resources/localization';
import { useCallback, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { AddImageIcon } from '@components/icons/AddImageIcon';
import { AddVideoIcon } from '@components/icons/AddVideoIcon';
import { AddAttachmentIcon } from '@components/icons/AddAttachmentIcon';
import useBreakpoints from '@hooks/useBreakpoints';
import { API } from '@api/API';
import { ListItem } from './ListItem';
import TransparencyDark from '@bundle/assets/general/transparency-dark.png';
import TransparencyLight from '@bundle/assets/general/transparency-light.png';
import { useAppSelector } from '@hooks/useStore';
import { Tooltip, Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Close } from '@icon-park/react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { EditImage } from '../UploadImage/components/EditImage';
import { FormContext } from 'antd/es/form/context';
import { formatMB } from '@util/string/Functions';
export const TuringoUpload = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { strongLabel = true, shape, scope = 'attachment', showHelp = true, fileOptions, uploadArea, rules, type, name, label, style, onClose, isEditing, editOptions, onChange, uploadType = 'sync', extra, includeCompressFiles, } = props;
    const acceptedFiles = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (type == 'image' && { 'image/*': ['.jpeg', '.png', '.gif', '.webp'] })), (type == 'video' && { 'video/*': ['.mp4', '.avi', '.mpeg', '.webm', '.mov'] })), (type == 'attachment' && { 'application/pdf': ['.pdf'] })), (type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] })), (type == 'attachment' && { 'application/msword': ['.docx'] })), (type == 'attachment' && { 'application/vnd.ms-excel': ['.xls'] })), (type == 'attachment' && { 'application/msexcel': ['.xls'] })), (type == 'attachment' && { 'application/x-msexcel': ['.xls'] })), (type == 'attachment' && { 'application/x-ms-excel': ['.xls'] })), (type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'] })), (type == 'attachment' && { 'application/vnd.ms-powerpoint': ['.ppt'] })), (type == 'attachment' && { 'application/vnd.ms-excel.sheet.macroenabled.12': ['.xlsm'] })), (type == 'attachment' && { 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'] })), (type == 'attachment' && { 'text/plain': ['.txt'] })), (type == 'attachment' && { 'text/csv': ['.csv'] })), (includeCompressFiles && { 'application/x-rar-compressed': ['.rar'] })), (includeCompressFiles && { 'application/zip': ['.zip'] }));
    const extensions = [...new Set(Object.values(acceptedFiles).flat())].join(',').replace(/\./g, ' ');
    const { form } = useContext(FormContext);
    const breakpoints = useBreakpoints();
    const isMobile = breakpoints.isMobile;
    const id = v4();
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const [editVisible, setEditVisible] = useState(false);
    const [editFile, setEditFile] = useState(undefined);
    const [xhr, setXhr] = useState(undefined);
    const [files, setFiles] = useState(props.fileListDefault || form.getFieldValue(name) || {});
    let setError;
    let error;
    if (!props.setError) {
        const errorState = useState({ message: undefined, new: false });
        setError = errorState[1];
        error = errorState[0];
    }
    else {
        setError = props.setError;
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.onload = function () {
                    return resolve({ base64: reader.result, width: img.width, height: img.height });
                };
                img.src = reader.result;
            };
            reader.onerror = (error) => reject(error);
        });
    }
    const cancelUpload = () => {
        if (xhr) {
            xhr.abort();
            setFiles({});
        }
    };
    const uploadFile = (id, uploadFile) => __awaiter(void 0, void 0, void 0, function* () {
        var _g, _h, _j;
        if (uploadType == 'async') {
            const URLPresigned = yield API.upload({
                bodyParams: {
                    scope: scope,
                    mimetype: (_g = uploadFile.file) === null || _g === void 0 ? void 0 : _g.type,
                    size: (_h = uploadFile.file) === null || _h === void 0 ? void 0 : _h.size,
                    name: (_j = uploadFile.file) === null || _j === void 0 ? void 0 : _j.name,
                },
            });
            const formData = new FormData();
            formData.append('acl', URLPresigned.data.presigned.bucketACL || 'private');
            formData.append('X-Amz-Credential', URLPresigned.data.presigned.bucketAccessKey);
            formData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
            formData.append('X-Amz-Date', URLPresigned.data.presigned.bucketDate);
            formData.append('key', URLPresigned.data.presigned.bucketKey);
            formData.append('Policy', URLPresigned.data.presigned.bucketPolicy);
            formData.append('X-Amz-Signature', URLPresigned.data.presigned.bucketSignature);
            formData.append('file', uploadFile.file);
            const updateProgress = function (id, evt) {
                setFiles((prev) => {
                    const percent = Math.round((evt.loaded / evt.total) * 100);
                    return Object.assign(Object.assign({}, prev), { [id]: {
                            file: uploadFile.file,
                            percent: percent,
                            status: 'uploading',
                            preview: uploadFile.preview,
                        } });
                });
            };
            void new Promise(function (resolve, reject) {
                const newXHR = new XMLHttpRequest();
                setXhr(newXHR);
                newXHR.open('POST', URLPresigned.data.presigned.bucketUrl);
                newXHR.onload = function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (this.status >= 200 && this.status < 300) {
                            const is_upload = yield API.uploadConfirm({
                                urlParams: {
                                    token: URLPresigned.data.id,
                                },
                            });
                            setFiles((prev) => {
                                return Object.assign(Object.assign({}, prev), { [id]: {
                                        file: uploadFile.file,
                                        percent: 100,
                                        status: 'success',
                                        preview: uploadFile.preview,
                                        id: is_upload.data.id,
                                        conversion: is_upload.data.conversion,
                                    } });
                            });
                            resolve(newXHR.response);
                        }
                        else {
                            reject({
                                status: this.status,
                                statusText: newXHR.statusText,
                            });
                        }
                    });
                };
                newXHR.upload.addEventListener('progress', (evt) => updateProgress(id, evt), false);
                newXHR.onerror = function () {
                    reject({
                        status: this.status,
                        statusText: newXHR.statusText,
                    });
                };
                newXHR.send(formData);
            });
        }
        else {
            setFiles((prev) => {
                return Object.assign(Object.assign({}, prev), { [id]: {
                        file: uploadFile.file,
                        percent: 100,
                        status: 'success',
                        preview: uploadFile.preview,
                    } });
            });
        }
    });
    const readFile = (file) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
    const onDrop = useCallback((acceptedFiles, fileRejections) => __awaiter(void 0, void 0, void 0, function* () {
        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === 'file-too-large') {
                    if (type == 'image') {
                        setError({
                            message: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                                ? strings.screens.boards.discussion.post.kind.image.upload.single.errors.size
                                : strings.formatString(strings.screens.boards.discussion.post.kind.image.upload.multiple.errors.size, rules === null || rules === void 0 ? void 0 : rules.maxFiles, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize)),
                            new: true,
                        });
                    }
                    else if (type == 'video') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.video.upload.errors.size, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize)),
                            new: true,
                        });
                    }
                    else if (type == 'attachment') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.single.errors.size, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize)),
                            new: true,
                        });
                    }
                }
                if (err.code === 'file-invalid-type') {
                    if (type == 'image') {
                        setError({
                            message: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                                ? strings.screens.boards.discussion.post.kind.image.upload.single.errors.format
                                : strings.screens.boards.discussion.post.kind.image.upload.multiple.errors.format,
                            new: true,
                        });
                    }
                    else if (type == 'video') {
                        setError({ message: strings.screens.boards.discussion.post.kind.video.upload.errors.format, new: true });
                    }
                    else if (type == 'attachment') {
                        setError({
                            message: strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.single.errors.format, extensions),
                            new: true,
                        });
                    }
                }
            });
        });
        for (const newfile of acceptedFiles) {
            setError({ message: undefined, new: false });
            if (type == 'image') {
                if (editOptions === null || editOptions === void 0 ? void 0 : editOptions.active) {
                    const imageDataUrl = yield readFile(newfile);
                    setEditFile(imageDataUrl);
                    setEditVisible(true);
                    break;
                }
                const file = {
                    file: newfile,
                    preview: (yield getBase64(newfile)).base64,
                    status: 'initial',
                    percent: 0,
                };
                yield uploadFile(v4(), file);
            }
            else {
                const file = {
                    file: newfile,
                    status: 'initial',
                    percent: 0,
                };
                yield uploadFile(v4(), file);
            }
        }
    }), []);
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        maxFiles: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) || 1,
        maxSize: (rules === null || rules === void 0 ? void 0 : rules.maxSize) * 1000000 || 1000000,
        accept: acceptedFiles,
    });
    const gallery = {
        [1]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
        },
        [2]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 7',
        },
        [3]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 4',
            [2]: '4 / 7',
        },
        [4]: {
            ['add']: '1 / 4',
            [0]: '4 / 7',
            [1]: '1 / 3',
            [2]: '3 / 5',
            [3]: '5 / 7',
        },
    };
    const uploadAreaComponent = (_jsxs("div", { style: Object.assign({ cursor: 'pointer', width: '100%', backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'), height: 142, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative' }, uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.style), children: [_jsx("div", { style: { marginTop: 2, display: 'flex', alignItems: 'center' }, children: _jsxs(Button, { className: "hover-svg", shape: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) ? uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape : breakpoints.xs || breakpoints.xxs ? 'circle' : 'default', style: { marginBottom: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }, children: [type == 'image' ? (_jsx(AddImageIcon, { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                            } })) : type == 'video' ? (_jsx(AddVideoIcon, { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                            } })) : (_jsx(AddAttachmentIcon, { style: {
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                            } })), (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.btnShape) != 'circle' && !breakpoints.xs && !breakpoints.xxs && (_jsx("span", { style: { marginLeft: 0 }, children: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                                ? type == 'image'
                                    ? strings.screens.boards.discussion.post.kind.image.upload.single.title
                                    : type == 'video'
                                        ? strings.screens.boards.discussion.post.kind.video.upload.title
                                        : strings.screens.boards.discussion.post.kind.attachment.upload.single.title
                                : type == 'image'
                                    ? strings.screens.boards.discussion.post.kind.image.upload.multiple.title
                                    : type == 'video'
                                        ? strings.screens.boards.discussion.post.kind.video.upload.title
                                        : strings.screens.boards.discussion.post.kind.attachment.upload.multiple.title }))] }) }), !isMobile && (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.showDescription) && (_jsx("div", { style: { fontSize: 12 }, children: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                    ? type == 'image'
                        ? strings.screens.boards.discussion.post.kind.image.upload.single.description
                        : type == 'video'
                            ? strings.screens.boards.discussion.post.kind.video.upload.description
                            : strings.screens.boards.discussion.post.kind.attachment.upload.single.description
                    : type == 'image'
                        ? strings.screens.boards.discussion.post.kind.image.upload.multiple.description
                        : type == 'video'
                            ? strings.screens.boards.discussion.post.kind.video.upload.description
                            : strings.screens.boards.discussion.post.kind.attachment.upload.multiple.description }))] }));
    const isRequired = (_a = rules === null || rules === void 0 ? void 0 : rules.ant) === null || _a === void 0 ? void 0 : _a.find((o) => o.required);
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e === null || e === void 0 ? void 0 : e.fileList;
    };
    const upload = (key, files) => __awaiter(void 0, void 0, void 0, function* () {
        yield uploadFile(key, files);
    });
    useEffect(() => {
        setError(undefined);
        for (const key of Object.keys(files)) {
            if (files[key].status == 'initial') {
                void upload(key, files[key]);
            }
        }
        if (onChange) {
            onChange(Object.values(files).slice(0, rules === null || rules === void 0 ? void 0 : rules.maxFiles));
        }
        else {
            form === null || form === void 0 ? void 0 : form.setFieldValue(name, Object.values(files).slice(0, rules === null || rules === void 0 ? void 0 : rules.maxFiles));
        }
    }, [files]);
    return (_jsxs(_Fragment, { children: [(editOptions === null || editOptions === void 0 ? void 0 : editOptions.active) && (_jsx(EditImage, { restrictPosition: (_b = props.editOptions) === null || _b === void 0 ? void 0 : _b.restrictPosition, title: strings.screens.boards.discussion.post.kind.image.upload.edit, open: editVisible, onCancel: () => {
                    var _a, _b;
                    setEditVisible(false);
                    if ((_a = props.editOptions) === null || _a === void 0 ? void 0 : _a.afterEdit)
                        (_b = props.editOptions) === null || _b === void 0 ? void 0 : _b.afterEdit();
                }, afterEdit: (_c = props.editOptions) === null || _c === void 0 ? void 0 : _c.afterEdit, file: editFile, setFile: setFiles, aspect_ratio: (_d = props.editOptions) === null || _d === void 0 ? void 0 : _d.aspect_ratio })), _jsx(Form.Item, { help: (error === null || error === void 0 ? void 0 : error.message) && (_jsx(Alert, { showIcon: true, type: "error", message: error.message, style: Object.assign(Object.assign({}, (isMobile ? { marginBottom: 4 } : { marginTop: 4 })), { borderRadius: 4, marginBottom: 16 }) })), rules: [
                    ...(isRequired
                        ? [
                            {
                                validator: (rule, value) => {
                                    if (value && value.length > 0 && value.length <= (rules === null || rules === void 0 ? void 0 : rules.maxFiles)) {
                                        return Promise.resolve();
                                    }
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    if (error && error.new) {
                                        setError(Object.assign(Object.assign({}, error), { new: false }));
                                        return Promise.reject(error);
                                    }
                                    else if (props.uploadType === 'async' && props.type === 'video') {
                                        setError(undefined);
                                        return Promise.reject();
                                    }
                                    else {
                                        setError({ message: isRequired.message, new: false });
                                        return Promise.reject(isRequired.message);
                                    }
                                },
                            },
                        ]
                        : []),
                ], className: isMobile ? 'reverseErrorMobile' : '', name: name, label: label && (_jsxs("div", { style: {
                        display: 'flex',
                        justifyContent: (onClose && !isEditing) || extra ? 'space-between' : 'start',
                        width: '100%',
                        alignItems: 'center',
                    }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center' }, children: [isRequired && (_jsx(Paragraph, { style: { color: '#FF4D2E', fontSize: 10, marginRight: 4 }, strong: strongLabel, children: '*' })), _jsx(Paragraph, { strong: strongLabel, children: label }), showHelp && label && (_jsx(Tooltip, { placement: "right", title: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                                        ? type == 'image'
                                            ? strings.formatString(strings.screens.boards.discussion.post.kind.image.upload.single.help, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize))
                                            : type == 'video'
                                                ? strings.formatString(strings.screens.boards.discussion.post.kind.video.upload.help, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize))
                                                : strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.single.help, extensions, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize))
                                        : type == 'image'
                                            ? strings.formatString(strings.screens.boards.discussion.post.kind.image.upload.multiple.help, rules === null || rules === void 0 ? void 0 : rules.maxFiles, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize))
                                            : type == 'video'
                                                ? strings.formatString(strings.screens.boards.discussion.post.kind.video.upload.help, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize))
                                                : strings.formatString(strings.screens.boards.discussion.post.kind.attachment.upload.multiple.help, rules === null || rules === void 0 ? void 0 : rules.maxFiles, extensions, formatMB(rules === null || rules === void 0 ? void 0 : rules.maxSize)), children: _jsx(InfoCircleOutlined, { onClick: (event) => event.stopPropagation(), style: { fontSize: isMobile ? 18 : 16, marginLeft: 4 } }) }))] }), extra && extra, onClose && !isEditing && (_jsx(Button, { onClick: (event) => {
                                event.stopPropagation();
                                onClose();
                            }, style: { width: 30, height: 30 }, type: "text", icon: _jsx(Close, { style: { display: 'flex', justifyContent: 'center' } }) }))] })), valuePropName: "list", getValueFromEvent: normFile, style: Object.assign({ width: '100%' }, style), children: _jsxs("div", { style: { maxWidth: ((_e = uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.style) === null || _e === void 0 ? void 0 : _e.maxWidth) ? (_f = uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.style) === null || _f === void 0 ? void 0 : _f.maxWidth : 'none' }, children: [_jsxs("div", Object.assign({ id: id }, getRootProps(), { children: [_jsx("input", Object.assign({}, getInputProps(), { multiple: true })), Object.keys(files).length == 0 && uploadAreaComponent] })), _jsxs("div", { style: {
                                background: (fileOptions === null || fileOptions === void 0 ? void 0 : fileOptions.showTransparency)
                                    ? `url(${isDark ? TransparencyDark : TransparencyLight}) repeat`
                                    : (fileOptions === null || fileOptions === void 0 ? void 0 : fileOptions.showBackground)
                                        ? 'black'
                                        : 'transparence',
                                width: '100%',
                                position: 'relative',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                                columnGap: '8px',
                                rowGap: '8px',
                            }, children: [files && Object.keys(files).length != 0 && Object.keys(files).length < (rules === null || rules === void 0 ? void 0 : rules.maxFiles) && !isEditing && (_jsxs("div", { style: Object.assign(Object.assign(Object.assign({ position: 'relative' }, (Object.keys(files) && {
                                        gridColumn: type == 'attachment'
                                            ? '1 / 7'
                                            : gallery[files && Object.keys(files).length > (rules === null || rules === void 0 ? void 0 : rules.maxFiles) ? rules === null || rules === void 0 ? void 0 : rules.maxFiles : Object.keys(files).length]['add'],
                                    })), { display: 'flex', flexDirection: 'column', backgroundColor: Colors('FORM_INPUT_AREA_BACKGROUND'), width: '100%', justifyContent: 'center', alignItems: 'center' }), uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.style), children: [_jsxs(Button, { className: "hover-svg", shape: breakpoints.xs || breakpoints.xxs ? 'circle' : 'default', style: { marginBottom: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }, onClick: () => {
                                                document.getElementById(id).click();
                                            }, children: [type == 'image' ? (_jsx(AddImageIcon, { style: {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        marginRight: !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                                                    } })) : type == 'video' ? (_jsx(AddVideoIcon, { style: {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        marginRight: !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                                                    } })) : (_jsx(AddAttachmentIcon, { style: {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        marginRight: !breakpoints.xs && !breakpoints.xxs ? 4 : 0,
                                                    } })), !breakpoints.xs && !breakpoints.xxs && (_jsx("span", { style: { marginLeft: 0 }, children: (rules === null || rules === void 0 ? void 0 : rules.maxFiles) == 1
                                                        ? type == 'image'
                                                            ? strings.screens.boards.discussion.post.kind.image.upload.single.title
                                                            : type == 'video'
                                                                ? strings.screens.boards.discussion.post.kind.video.upload.title
                                                                : strings.screens.boards.discussion.post.kind.attachment.upload.single.title
                                                        : type == 'image'
                                                            ? strings.screens.boards.discussion.post.kind.image.upload.multiple.title
                                                            : type == 'video'
                                                                ? strings.screens.boards.discussion.post.kind.video.upload.title
                                                                : strings.screens.boards.discussion.post.kind.attachment.upload.multiple.title }))] }), !isMobile && (uploadArea === null || uploadArea === void 0 ? void 0 : uploadArea.showDescription) && type == 'attachment' && (_jsx("div", { style: { fontSize: 12 }, children: strings.screens.boards.discussion.post.kind.attachment.upload.single.description }))] })), files &&
                                    Object.keys(files)
                                        .slice(0, rules === null || rules === void 0 ? void 0 : rules.maxFiles)
                                        .map((key, index) => {
                                        var _a;
                                        return (_jsx(ListItem, { uploadType: uploadType, cancelUpload: cancelUpload, uploadArea: uploadArea, showAdd: files && Object.keys(files).length != 0 && Object.keys(files).length < ((_a = props.rules) === null || _a === void 0 ? void 0 : _a.maxFiles) && !isEditing, fileStyle: fileOptions === null || fileOptions === void 0 ? void 0 : fileOptions.style, maxFiles: rules === null || rules === void 0 ? void 0 : rules.maxFiles, index: index, file: files[key], items: Object.values(files).slice(0, rules === null || rules === void 0 ? void 0 : rules.maxFiles), onClose: () => {
                                                setFiles((prev) => {
                                                    delete prev[key];
                                                    return Object.assign({}, prev);
                                                });
                                            }, filetype: type, isEditing: isEditing }, key));
                                    })] })] }) })] }));
};
