import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '@hooks/useStore';
import { useParams } from 'react-router';
import { Form } from 'antd';
import strings from '@resources/localization';
import { FooterModal } from '@components/modals/components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { CommentForm } from './CommentForm';
const CommentModal = (props) => {
    const { b_pk: br_pk, p_pk } = useParams();
    const isFeed = !br_pk;
    const { open, onCancel, comment, type, parents, b_pk } = props;
    const [form] = Form.useForm();
    const scope = p_pk ? 'detail' : 'all';
    const commentListState = useAppSelector((state) => {
        var _a, _b, _c, _d, _e, _f;
        return parents.length == 1
            ? (_c = (_b = (_a = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _a === void 0 ? void 0 : _a.items[parents[0]]) === null || _b === void 0 ? void 0 : _b.childrens) === null || _c === void 0 ? void 0 : _c.listState
            : (_f = (_e = (_d = state.posts[scope][isFeed ? 'feed' : b_pk]) === null || _d === void 0 ? void 0 : _d.items[parents[0]]) === null || _e === void 0 ? void 0 : _e.childrens) === null || _f === void 0 ? void 0 : _f.items[parents[1]].childrens.listState;
    });
    return (_jsxs(TuringoModal, { width: 572, onCancel: onCancel, open: open, title: comment && type == 'edit' ? strings.comments.form.edit_title : null, footer: [
            _jsx(FooterModal, { primary: {
                    action: form.submit,
                    customText: type == 'delete' ? strings.general.delete : strings.general.save,
                    loading: commentListState === null || commentListState === void 0 ? void 0 : commentListState.loading,
                }, secondary: {
                    action: onCancel,
                    customText: strings.general.cancel,
                } }, 'footer_modal'),
        ], children: [_jsx(CommentForm, { parents: parents, type: type, form: form, onFinish: onCancel, b_pk: b_pk, p_pk: p_pk, comment: comment }), type == 'delete' && (_jsx(InformationModalBody, { kind: 'warning', title: strings.comments.delete.title, description: strings.comments.delete.description }))] }));
};
export { CommentModal };
