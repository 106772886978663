import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProgressStep } from '../progress/ProgressStep';
import { useAppSelector } from '@hooks/useStore';
import { DegreeHat } from '@icon-park/react';
const BoardCardHeader = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { item, showProgressBar } = props;
    const theme = useAppSelector((state) => state.ui.theme);
    const isDark = theme == 'dark';
    const isCompleted = ((_a = item === null || item === void 0 ? void 0 : item.progress) === null || _a === void 0 ? void 0 : _a.lessonOverallCount) &&
        ((_b = item === null || item === void 0 ? void 0 : item.progress) === null || _b === void 0 ? void 0 : _b.lessonCompletedCount) &&
        ((_c = item === null || item === void 0 ? void 0 : item.progress) === null || _c === void 0 ? void 0 : _c.lessonOverallCount) == ((_d = item === null || item === void 0 ? void 0 : item.progress) === null || _d === void 0 ? void 0 : _d.lessonCompletedCount) &&
        ((_e = item === null || item === void 0 ? void 0 : item.progress) === null || _e === void 0 ? void 0 : _e.lessonOverallCount) != 0;
    const notStarted = !((_f = item === null || item === void 0 ? void 0 : item.progress) === null || _f === void 0 ? void 0 : _f.lessonCompletedCount) || ((_g = item === null || item === void 0 ? void 0 : item.progress) === null || _g === void 0 ? void 0 : _g.lessonCompletedCount) == 0;
    const getColorBackground = () => {
        if (isDark) {
            if (isCompleted)
                return '#112123';
            if (notStarted)
                return '#141414';
            return '#2B1D11';
        }
        else {
            if (isCompleted)
                return '#E6FFFB';
            if (notStarted)
                return '#F0F0F0';
            return '#FFF7E6';
        }
    };
    const getColorText = () => {
        if (isDark) {
            if (isCompleted)
                return '#58D1C9';
            if (notStarted)
                return '#F0F0F0';
            return '#F3B765';
        }
        else {
            if (isCompleted)
                return '#58D1C9';
            if (notStarted)
                return '#000000';
            return '#FA8C16';
        }
    };
    return (_jsxs("div", { style: { position: 'relative', paddingBottom: '56.2%', borderRadius: '4px 4px 0 0' }, children: [item.avatarUrl && (_jsx("img", { alt: "example", style: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    borderRadius: '4px 4px 0 0',
                    top: 0,
                    left: 0,
                }, src: item.avatarUrl, loading: "eager" })), !item.avatarUrl && (_jsx("div", { style: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    borderRadius: '4px 4px 0 0',
                    left: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'var(--neutral-3)',
                }, children: _jsx(DegreeHat, { style: {
                        display: 'flex',
                        alignItems: 'center',
                    }, theme: "outline", size: 56, fill: 'var(--neutral-7)' }) })), showProgressBar && (_jsx("div", { style: { position: 'absolute', width: '100%', bottom: 0 }, children: _jsx(ProgressStep, { colorText: getColorText(), colorBackground: getColorBackground(), total: (_h = item === null || item === void 0 ? void 0 : item.progress) === null || _h === void 0 ? void 0 : _h.lessonOverallCount, progress: (_j = item === null || item === void 0 ? void 0 : item.progress) === null || _j === void 0 ? void 0 : _j.lessonCompletedCount, kind: item === null || item === void 0 ? void 0 : item.kind }) }))] }));
};
export { BoardCardHeader };
