import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { Card, Button, Space, Radio, Alert, Form } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import strings from '@resources/localization';
import { InformationModalBody } from '@components/modals/information_modal/InformationModalBody';
import { KindNamePlural } from '@util/string/ModelNames';
import { ViewList } from '@icon-park/react';
import { FooterModal } from '@components/modals/components/FooterModal';
import { TuringoModal } from '@components/modals/components/TuringoModal/TuringoModal';
import { API } from '@api/API';
import { CourseProgress } from '@components/content/certificate_supplementary/CourseProgress';
import { useForm } from 'antd/lib/form/Form';
import { CertificateModal } from '@components/content/certificate_supplementary/CertificateModal';
import useBreakpoints from '@hooks/useBreakpoints';
import { RatioImage } from '@components/image/RatioImage';
import { PostContext } from '@components/content/posts/Post';
import { postList } from '@state/actions';
const QuizScreen = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    const theme = useAppSelector((state) => state.ui.theme);
    const [quiz, setQuiz] = useState();
    const history = useHistory();
    const [sendingResponses, setSendingResponses] = useState(false);
    const [step, setStep] = useState('0');
    const [quizChoices, setQuizChoices] = useState({});
    const [colors, setColors] = useState({});
    const [changeChoice, setChangeChoice] = useState(0);
    const [error, setError] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [quizComplete, setQuizComplete] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [repetitions, setRepetitions] = useState(0);
    const [showAnswers, setShowAnswers] = useState(true);
    const { cm_pk, b_pk, p_pk } = useContext(PostContext);
    const dispatch = useAppDispatch();
    const course = useAppSelector((state) => state.community.items[cm_pk].boards.detail[b_pk]);
    const lesson = useAppSelector((state) => { var _a; return (_a = state.posts.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]; });
    const firstLesson = useAppSelector((state) => { var _a; return Object.values((_a = state.posts.all[b_pk]) === null || _a === void 0 ? void 0 : _a.items)[0]; });
    const [statusVisible, setStatusVisible] = useState(false);
    const [fulfillment, setFulfillment] = useState();
    const toPercentage = (scoring, total) => {
        if (!scoring && scoring != 0)
            return -1;
        return Math.floor(100 * (scoring / total));
    };
    const hasCertificate = ((_b = (_a = lesson.item.board) === null || _a === void 0 ? void 0 : _a.merits) === null || _b === void 0 ? void 0 : _b.find((m) => m.merit.kind == 'certificate')) && !!((_e = (_d = (_c = course === null || course === void 0 ? void 0 : course.item) === null || _c === void 0 ? void 0 : _c.course) === null || _d === void 0 ? void 0 : _d.assign) === null || _e === void 0 ? void 0 : _e.cert);
    const [quizSuccess, setQuizSuccess] = useState(toPercentage((_h = (_g = (_f = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _f === void 0 ? void 0 : _f.quiz) === null || _g === void 0 ? void 0 : _g.results) === null || _h === void 0 ? void 0 : _h.score, (_l = (_k = (_j = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _j === void 0 ? void 0 : _j.quiz) === null || _k === void 0 ? void 0 : _k.scoring) === null || _l === void 0 ? void 0 : _l.maximum) >= ((_p = (_o = (_m = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _m === void 0 ? void 0 : _m.quiz) === null || _o === void 0 ? void 0 : _o.scoring) === null || _p === void 0 ? void 0 : _p.passing));
    const [form] = useForm();
    const currentEntity = useAppSelector((state) => state.auth.currentEntity);
    const breakpoints = useBreakpoints();
    const submit = () => {
        setSendingResponses(true);
        void API.answerCreate({
            urlParams: { cm_pk, b_pk, q_pk: p_pk },
            bodyParams: { answers: quizChoices },
        }).then((result) => {
            var _a, _b, _c, _d, _e;
            setSendingResponses(false);
            setQuizSuccess(toPercentage((_a = result.data.results) === null || _a === void 0 ? void 0 : _a.score, (_c = (_b = result.data) === null || _b === void 0 ? void 0 : _b.scoring) === null || _c === void 0 ? void 0 : _c.maximum) >= ((_e = (_d = result.data) === null || _d === void 0 ? void 0 : _d.scoring) === null || _e === void 0 ? void 0 : _e.passing));
            setQuiz(result.data);
            setShowConfirmModal(false);
            setQuizComplete(true);
            void dispatch(postList({ urlParams: { cm_pk, b_pk: b_pk }, searchParams: { kind: 'meta_playlist' } }));
        });
    };
    const tryToSubmit = () => {
        setError(false);
        let tempError = false;
        Object.keys(quizChoices).forEach((key) => {
            if (quizChoices[key] == undefined) {
                tempError = true;
                const tempColors = colors;
                tempColors[key] = 'var(--dust-red-5)';
                setColors(tempColors);
            }
        });
        if (!tempError)
            setShowConfirmModal(true);
        else
            setError(true);
    };
    const onChange = (event) => {
        const tempChoices = quizChoices;
        const tempColors = colors;
        tempChoices[event.target.name] = event.target.value;
        tempColors[event.target.name] = theme === 'dark' ? 'var(--neutral-5)' : 'var(--neutral-4)';
        setQuizChoices(tempChoices);
        setColors(tempColors);
        setError(false);
        setChangeChoice(changeChoice + 1);
    };
    const QuizResultInformation = () => {
        var _a, _b, _c, _d, _e, _f;
        setRepetitions(((_a = quiz === null || quiz === void 0 ? void 0 : quiz.results) === null || _a === void 0 ? void 0 : _a.repetition) ? (_b = quiz === null || quiz === void 0 ? void 0 : quiz.results) === null || _b === void 0 ? void 0 : _b.repetition : repetitions);
        if (!quizSuccess && repetitions) {
            return (_jsx(InformationModalBody, { forceLeftTextAlign: true, kind: 'error', title: strings.formatString(strings.screens.evaluation.quiz.results.error.title, currentEntity.name), titleStyle: {
                    marginTop: 0,
                }, description: strings.formatString(hasCertificate
                    ? strings.screens.evaluation.quiz.results.error.certificate
                    : strings.screens.evaluation.quiz.results.error.noCertificate, (_d = (_c = lesson.item) === null || _c === void 0 ? void 0 : _c.board) === null || _d === void 0 ? void 0 : _d.name), subdescription: (quiz === null || quiz === void 0 ? void 0 : quiz.options.repetitions) > 1
                    ? (quiz === null || quiz === void 0 ? void 0 : quiz.options.repetitions) - repetitions > 0
                        ? strings.formatString((quiz === null || quiz === void 0 ? void 0 : quiz.options.repetitions) - repetitions > 1
                            ? strings.screens.evaluation.quiz.results.error.attemptLeft
                            : strings.screens.evaluation.quiz.results.error.oneAttemptLeft, (quiz === null || quiz === void 0 ? void 0 : quiz.options.repetitions) - repetitions)
                        : strings.screens.evaluation.quiz.results.error.noAttemptLeft
                    : strings.screens.evaluation.quiz.results.error.noAttempt }));
        }
        else if (quizSuccess && repetitions) {
            return (_jsx(InformationModalBody, { forceLeftTextAlign: true, kind: 'success', color: 'var(--polar-green-5)', title: strings.formatString(hasCertificate
                    ? strings.screens.evaluation.quiz.results.success.certificate.title
                    : strings.screens.evaluation.quiz.results.success.noCertificate.title, currentEntity.name), titleStyle: {
                    marginTop: 0,
                    marginBottom: 8,
                    fontSize: 20,
                    fontWeight: 500,
                }, iconSize: "48", description: strings.formatString(hasCertificate
                    ? strings.screens.evaluation.quiz.results.success.certificate.description
                    : strings.screens.evaluation.quiz.results.success.noCertificate.description, (_f = (_e = lesson.item) === null || _e === void 0 ? void 0 : _e.board) === null || _f === void 0 ? void 0 : _f.name), subdescription: hasCertificate ? strings.screens.evaluation.quiz.results.success.certificate.subdescription : '' }));
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    const getHelp = (question_id) => {
        var _a, _b, _c, _d, _e, _f;
        const question = (_b = (_a = quiz.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.questions.find((q) => q.id == question_id);
        if (question.answer == quizChoices[question_id]) {
            return (_jsx(Alert, { showIcon: true, type: "success", message: strings.screens.evaluation.quiz.results.success.label, style: {
                    borderRadius: 4,
                    marginBottom: 16,
                } }));
        }
        else if (question.answer != quizChoices[question_id]) {
            return (_jsxs(_Fragment, { children: [_jsx(Alert, { showIcon: true, type: "error", message: strings.screens.evaluation.quiz.results.error.label, style: Object.assign(Object.assign({ borderRadius: 4 }, (!((_c = quiz === null || quiz === void 0 ? void 0 : quiz.options) === null || _c === void 0 ? void 0 : _c.showResults) && {
                            marginBottom: 16,
                        })), (((_d = quiz === null || quiz === void 0 ? void 0 : quiz.options) === null || _d === void 0 ? void 0 : _d.showResults) && {
                            marginBottom: 0,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomWidth: 0,
                        })) }), ((_e = quiz === null || quiz === void 0 ? void 0 : quiz.options) === null || _e === void 0 ? void 0 : _e.showResults) && (_jsx(Alert, { showIcon: false, type: "error", message: _jsx(Paragraph, { style: {
                                fontFamily: 'Roboto',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontWeight: 500,
                            }, children: strings.screens.evaluation.quiz.results.success.label }), description: (_f = question.alternatives.find((a) => a.id == question.answer)) === null || _f === void 0 ? void 0 : _f.title, style: {
                            borderRadius: 4,
                            marginBottom: 16,
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: 0,
                            borderTopWidth: 0,
                        } }))] }));
        }
        else
            return _jsx(_Fragment, {});
    };
    const buttonInfo = () => {
        var _a, _b, _c, _d, _e, _f;
        if (quizComplete) {
            if (quizSuccess && hasCertificate)
                return {
                    action: () => {
                        setShowCertificate(true);
                    },
                    text: strings.screens.evaluation.quiz.results.success.certificate.actions.getCertificate,
                };
            else if (quizSuccess && !hasCertificate)
                return {
                    action: () => {
                        history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}`);
                    },
                    text: strings.screens.evaluation.quiz.results.success.noCertificate.actions.finishCourse,
                };
            else if (!quizSuccess && ((_c = (_b = (_a = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _a === void 0 ? void 0 : _a.quiz) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.repetitions) - repetitions == 0)
                return {
                    action: () => {
                        history.push(`/${cm_pk}/${KindNamePlural[course.item.kind]}/${course.item.publicKey}/${firstLesson.item.publicKey}`);
                    },
                    text: strings.screens.evaluation.quiz.results.error.actions.reviewContents,
                };
            else if (!quizSuccess && ((_f = (_e = (_d = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _d === void 0 ? void 0 : _d.quiz) === null || _e === void 0 ? void 0 : _e.options) === null || _f === void 0 ? void 0 : _f.repetitions) - repetitions > 0)
                return {
                    action: () => {
                        resetQuiz();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    },
                    text: strings.screens.evaluation.quiz.results.error.actions.repeat,
                };
        }
        return {
            action: () => {
                tryToSubmit();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            text: strings.screens.evaluation.quiz.actions.send.title,
        };
    };
    function isQuizModel(model) {
        return 'id' in model;
    }
    const retrieveQuiz = () => {
        void API.quizGet({
            urlParams: {
                cm_pk,
                b_pk,
                p_pk,
            },
        }).then((result) => {
            var _a, _b;
            if (isQuizModel(result.data)) {
                setQuiz(result.data);
                setQuizChoices((_b = (_a = result.data.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.questions.reduce((element, item, index) => {
                    element[item.id] = undefined;
                    return element;
                }, {}));
                setStep('1');
            }
            else {
                setStatusVisible(true);
                setFulfillment(result.data.sequence);
            }
        });
    };
    const retrieveAnswer = () => {
        void API.answerDetails({
            urlParams: {
                cm_pk,
                b_pk,
                q_pk: p_pk,
            },
        }).then((result) => {
            var _a;
            if (result.data) {
                const answers = result.data.answers;
                const tempChoice = {};
                Object.keys(answers).map((k) => {
                    tempChoice[k] = answers[k].value;
                });
                setQuiz((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.quiz);
                setRepetitions(result.data.repetition);
                setQuizComplete(true);
                setShowAnswers(false);
                setStep('1');
            }
            else {
                setError(true);
            }
        });
    };
    const resetQuiz = () => {
        setQuizComplete(false);
        const tempChoices = Object.assign({}, quizChoices);
        const tempColors = Object.assign({}, colors);
        Object.keys(tempChoices).forEach((key) => {
            tempChoices[key] = undefined;
            tempColors[key] = theme === 'dark' ? 'var(--neutral-6)' : 'var(--neutral-4)';
        });
        setQuizChoices(tempChoices);
        setColors(tempColors);
        setError(false);
        setQuizComplete(false);
    };
    useEffect(() => { }, [changeChoice, quizComplete]);
    useEffect(() => {
        var _a, _b, _c, _d;
        if (((_b = (_a = lesson.item.quiz) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.repetitions) <= ((_d = (_c = lesson.item.quiz) === null || _c === void 0 ? void 0 : _c.results) === null || _d === void 0 ? void 0 : _d.repetition) || quizSuccess) {
            retrieveAnswer();
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(TuringoModal, { title: strings.screens.evaluation.quiz.sendModal.title, open: showConfirmModal, onCancel: () => {
                    setShowConfirmModal(false);
                }, width: 572, footer: _jsx(FooterModal, { primary: {
                        loading: sendingResponses,
                        action: submit,
                        customText: strings.screens.evaluation.quiz.actions.send.title,
                    }, secondary: {
                        action: () => {
                            setShowConfirmModal(false);
                        },
                    } }, 'footer_modal'), children: _jsx(Paragraph, { style: { marginTop: 8, marginBottom: 16, fontWeight: 400 }, children: strings.screens.evaluation.quiz.sendModal.description }) }), statusVisible && fulfillment && (_jsx(TuringoModal, { open: statusVisible, onCancel: () => setStatusVisible(false), onOk: () => setStatusVisible(false), width: 572, footer: [
                    _jsx(FooterModal, { primary: {
                            action: () => setStatusVisible(false),
                            customText: 'OK',
                        } }, 'footer_modal'),
                ], children: _jsx(CourseProgress, { isEvaluation: true, progress: fulfillment, title: strings.screens.boards.course.certificate.progress.title, description: strings.screens.boards.course.certificate.progress.description }) })), _jsx(CertificateModal, { download: true, open: showCertificate, hide: () => {
                    setShowCertificate(false);
                } }), step == '0' && (_jsxs("div", { children: [_jsx("div", { style: {
                            background: theme == 'dark' ? 'var(--neutral-3)' : 'var(--neutral-4)',
                            height: 198,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, children: !(((_r = (_q = lesson.item.quiz) === null || _q === void 0 ? void 0 : _q.options) === null || _r === void 0 ? void 0 : _r.repetitions) <= ((_t = (_s = lesson.item.quiz) === null || _s === void 0 ? void 0 : _s.results) === null || _t === void 0 ? void 0 : _t.repetition)) && !quizSuccess && (_jsxs(Button, { style: { display: 'flex', alignItems: 'center' }, onClick: () => retrieveQuiz(), children: [_jsx(ViewList, { style: { display: 'flex', marginRight: 4 }, theme: "outline" }), " ", strings.screens.evaluation.action] })) }), _jsxs("div", { style: {
                            padding: '16px 24px',
                        }, children: [_jsx("div", { children: strings.screens.evaluation.title }), _jsx(Title, { level: 4, style: { wordWrap: 'break-word', marginBottom: 0 }, children: (_u = lesson === null || lesson === void 0 ? void 0 : lesson.item) === null || _u === void 0 ? void 0 : _u.title })] })] })), step == '1' && (_jsx(Form, { preserve: true, layout: "vertical", form: form, children: _jsxs(Card, { bordered: false, cover: _jsx("div", { children: _jsxs("div", { className: "lateral-padding", children: [_jsx(Card, { children: quizComplete ? (_jsxs(_Fragment, { children: [_jsx(QuizResultInformation, {}), !showAnswers && (_jsx("div", { style: { display: 'flex', justifyContent: 'end' }, children: _jsx(Button, { block: breakpoints.isMobile, type: "primary", onClick: buttonInfo().action, htmlType: "submit", children: buttonInfo().text }) }))] })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "turingo-title", style: { marginBottom: 8 }, children: quiz.title }), _jsx("div", { className: "turingo-text", children: quiz.description })] })) }), showAnswers &&
                                    ((_x = (_w = (_v = quiz.pages) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.questions) === null || _x === void 0 ? void 0 : _x.map((item, index) => {
                                        var _a, _b, _c;
                                        return (_jsx(Form.Item, { style: { marginBottom: 0, marginTop: 16 }, name: index, rules: [{ required: true, message: strings.screens.evaluation.quiz.error.empty }], children: _jsxs(Card, { style: {
                                                    borderColor: colors[item.id],
                                                    padding: 8,
                                                }, children: [_jsx("div", { style: { marginBottom: 8 }, className: "turingo-secondary", children: strings.formatString(strings.screens.evaluation.quiz.progress, index + 1, (_b = (_a = quiz.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.questions.length) }), _jsxs(Form.Item, { className: "alignTop", style: { margin: 0 }, name: item.id, label: _jsx(Paragraph, { className: "turingo-text", style: { marginBottom: 8 }, strong: true, children: item.title }), rules: [{ required: true, message: '' }], children: [item.assetUrl && _jsx(RatioImage, { style: { marginBottom: 16 }, src: item.assetUrl }), quizComplete && getHelp(item.id), _jsx(Radio.Group, { name: item.id, onChange: onChange, value: quizChoices[item.id], disabled: quizComplete, children: _jsx(Space, { direction: "vertical", size: 8, children: (_c = item === null || item === void 0 ? void 0 : item.alternatives) === null || _c === void 0 ? void 0 : _c.map((alternative, alternativeIndex) => {
                                                                        return (_jsx(Radio, { value: alternative.id, children: _jsx(Paragraph, { className: "turingo-text", children: alternative.title }) }, alternativeIndex));
                                                                    }) }) })] })] }) }, index));
                                    }))] }) }), children: [error && (_jsx(_Fragment, { children: _jsx(Alert, { showIcon: true, type: "error", message: strings.screens.evaluation.quiz.error.help1, style: {
                                    borderRadius: 4,
                                    marginBottom: 16,
                                } }) })), showAnswers && (_jsx("div", { style: { display: 'flex', justifyContent: 'end' }, children: _jsx(Button, { block: breakpoints.isMobile, type: "primary", onClick: buttonInfo().action, htmlType: "submit", children: buttonInfo().text }) }))] }) }))] }));
};
export { QuizScreen };
