var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoints from '@hooks/useBreakpoints';
import { Button } from 'antd';
export const ExpandableButton = (props) => {
    const breakpoints = useBreakpoints();
    const { innerStyle, ByteIcon } = props, rest = __rest(props, ["innerStyle", "ByteIcon"]);
    const isMobile = breakpoints.isMobile;
    return (_jsx(Button, Object.assign({}, rest, { style: Object.assign(Object.assign({}, props.style), { borderRadius: 4 }), block: isMobile ? true : false, children: _jsxs("div", { style: Object.assign({ display: 'flex', alignItems: 'center', justifyContent: 'center' }, props.innerStyle), children: [props.ByteIcon && _jsx(props.ByteIcon, { style: { display: 'flex', marginRight: 4 } }), props.children] }) })));
};
