import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useContext } from 'react';
import { Alert } from 'antd';
import ImagePlaceholder from '@bundle/assets/general/placeholder-image.png';
import strings from '@resources/localization';
import { addWebp } from '@util/browser/addWebp';
import { Player } from '../../player/Player';
import { useAppSelector } from '@hooks/useStore';
import { PostContext } from '@components/content/posts/Post';
import { PostCardHeader } from '@components/cards/post_card/components/header/PostCardHeader';
const VideoScreen = (props) => {
    var _a, _b, _c, _d;
    const language = useAppSelector((state) => state.ui.language);
    const { cm_pk, b_pk, p_pk } = useContext(PostContext);
    const lesson = useAppSelector((state) => { var _a; return (_a = state.posts.detail[b_pk]) === null || _a === void 0 ? void 0 : _a.items[p_pk]; });
    const todayDate = new Date();
    const releaseDate = new Date(lesson.item.dateRelease);
    const isReleased = lesson.item.dateRelease ? releaseDate < todayDate : true;
    const hasVideo = (_c = (_b = (_a = lesson.item) === null || _a === void 0 ? void 0 : _a.assets) === null || _b === void 0 ? void 0 : _b.main) === null || _c === void 0 ? void 0 : _c.url;
    return (_jsxs("div", { children: [hasVideo && isReleased && _jsx(Player, { lesson: lesson }), hasVideo && !isReleased && (_jsxs("div", { style: { paddingTop: '54.6%', position: 'relative' }, children: [_jsx("img", { alt: "example", style: { objectFit: 'cover', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }, src: ((_d = lesson.item.board) === null || _d === void 0 ? void 0 : _d.bannerUrl) ? addWebp(lesson.item.board.bannerUrl) : ImagePlaceholder, loading: "lazy" }), _jsx("div", { style: {
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 16,
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 5,
                        }, children: _jsx(Alert, { showIcon: true, className: 'blue', style: { alignItems: 'baseline', textAlign: 'start' }, message: `Estreno: ${dayjs(releaseDate).locale(language).format(strings.general.dateFormat)}`, type: "info" }) })] })), !hasVideo && _jsx(PostCardHeader, { size: 'large', item: lesson.item })] }));
};
export { VideoScreen };
